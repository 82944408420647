import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import fb from '@/store/firebase'

// pages
import Start from '@/pages/Start'
import Signin from '@/pages/Signin'
import Consent from '@/pages/Consent'
import Email from '@/pages/Email'
import Cookies from '@/pages/Cookies'
import LinkVerify from '@/pages/LinkVerify'
import EmailVerify from '@/pages/EmailVerify'
import EmailSignin from '@/pages/EmailSignin'
import Manage from '@/pages/Manage'
import Admin from '@/pages/Admin'
import Starred from '@/pages/Starred'
import ChannelGroup from '@/pages/ChannelGroup'
import Channel from '@/pages/Channel'
import Inbox from '@/pages/Inbox'
import Connect from '@/pages/Connect'
import ConnectAssigned from '@/pages/ConnectAssigned'
import ConnectMentioned from '@/pages/ConnectMentioned'
import ConnectSubscribed from '@/pages/ConnectSubscribed'
import Connection from '@/pages/Connection'
import Scheduler from '@/pages/Scheduler'
import ShareAgreement from '@/pages/ShareAgreement'
import FeedbackForm from '@/pages/FeedbackForm'
import Datepicker from '@/pages/Datepicker'
import Learn from '@/pages/Learn'
import Help from '@/pages/Help'
import Talent from '@/pages/Talent'
import TalentComplete from '@/pages/TalentComplete'
import Settings from '@/pages/Settings'
import Legal from '@/pages/Legal'
import Page from '@/pages/Page'
import Terms from '@/pages/Terms'

// Remote Pages
import SigninRemote from '@/pages/SigninRemote'

// Teams
import DashTeam from '@/pages/DashTeam'
import Initiative from '@/pages/Initiative'
import Update from '@/pages/Update'

// v2 pages
import Dash from '@/pages-v2/Dash'
import User from '@/pages-v2/User'
import JobDetail from '@/pages-v2/JobDetail'
import Search from '@/pages-v2/Search'

// test page
import FocusTest from '@/pages/test/FocusTest'
import LocationTest from '@/pages/test/LocationTest'
import PlainInputTest from '@/pages/test/PlainInputTest'

Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject)
  window.open(href, '_blank')
}

// lazy loaded pages - for Twilio
const Twilio = () => import('@/pages/Twilio')
const Voice = () => import('@/pages/Voice')

// lazy loaded pages - for Tiptap
const ChemistryCheck = () => import('@/pages/ChemistryCheck')
const Duo = () => import('@/pages/Duo')
const DuoView = () => import('@/pages/DuoView')
const PostJob = () => import('@/pages/PostJob')
const ShareProposal = () => import('@/pages/ShareProposal')

Vue.use(Router)

const teamPages = {
}
const checkConsentCookiesEmail = (to, from, next) => {
  // console.log('guard.checkConsentCookies', to, from, store.state.profile)
  const isTeamDomain = `${window.location.hostname}`.toLowerCase().startsWith('teams') || `${window.location.hostname}`.toLowerCase().startsWith('app.flexibly.co')
  if (isTeamDomain && Object.keys(teamPages).includes(to.path)) {
    const redirected = teamPages[to.path]
    return next(redirected)
  } else if (!isTeamDomain && Object.values(teamPages).includes(to.path)) {
    const redirected = Object.keys(teamPages).find(key => teamPages[key] === to.path)
    return next(redirected)
  }

  if (to.name === 'Consent') {
    // Routed to consent page - check consentTimeStamp is null
    if (!store.state.profile.consentTimeStamp) {
      return next()
    } else {
      return next({ path: '/dash', replace: true })
    }
  } else if (to.name === 'Cookies') {
    // Routed to cookies page - check cookiesTimeStamp is null
    if (!store.state.profile.cookiesTimeStamp) {
      return next()
    } else {
      return next({ path: '/dash', replace: true })
    }
  } else if (to.name === 'Email') {
    // Routed to cookies page - check cookiesTimeStamp is null
    if (!store.state.profile.email) {
      return next()
    } else {
      return next({ path: '/dash', replace: true })
    }
  } else {
    // Routed to other page - check consentTimeStamp and cookiesTimeStamp are not null
    if (!store.state.profile.consentTimeStamp) {
      const consentUrl = '/consent'
      return next(consentUrl)
    } else if (!store.state.profile.cookiesTimeStamp) {
      const cookieUrl = '/cookies'
      return next(cookieUrl)
    } else if (!store.state.profile.email) {
      const emailUrl = '/email'
      return next(emailUrl)
    } else {
      return next()
    }
  }
}

const guard = async (to, from, next) => {
  if (store.state.profile) {
    return checkConsentCookiesEmail(to, from, next)
  }

  fb.auth.onAuthStateChanged(user => {
    if (user) {
      return store.dispatch('loadProfile').then(() => {
        checkConsentCookiesEmail(to, from, next)
      })
    } else {
      let signurl = '/signin'
      if (to) {
        if (to.name === 'User' && from.name === null) {
          // external to profile goto signup
          signurl = '/signup'
        }
      }
      return next(signurl)
    }
  })
}

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Start', component: Start },
    { path: '/terms', name: 'Terms', component: Terms, meta: { nomenu: true, loggedOutDrawer: true } },
    { path: '/signin', name: 'Signin', component: Signin, meta: { nomenu: true } },
    { path: '/signup', name: 'Signup', component: Signin, meta: { nomenu: true } },
    { path: '/consent', name: 'Consent', component: Consent, meta: { nomenu: true } },
    { path: '/email', name: 'Email', component: Email, meta: { nomenu: true } },
    { path: '/cookies', beforeEnter: guard, name: 'Cookies', component: Cookies, meta: { nomenu: true } },
    { path: '/link-verify', name: 'LinkVerify', component: LinkVerify, meta: { nomenu: true, nodrawer: true } },
    { path: '/email-verify', name: 'EmailVerify', component: EmailVerify, meta: { nomenu: true, nodrawer: true } },
    { path: '/email-signin', name: 'EmailSignin', component: EmailSignin },
    { path: '/dash', beforeEnter: guard, name: 'Dash', component: Dash, props: (route) => ({ query: route.query.q }) },
    { path: '/dash-team', beforeEnter: guard, name: 'DashTeam', component: DashTeam, props: (route) => ({ query: route.query.q }) },
    { path: '/initiative/:id', beforeEnter: guard, name: 'Initiative', component: Initiative },
    { path: '/update/:id', beforeEnter: guard, name: 'Update', component: Update },
    { path: '/search', beforeEnter: guard, name: 'Search', component: Search },
    { path: '/manage', beforeEnter: guard, name: 'Manage', component: Manage },
    { path: '/admin', beforeEnter: guard, name: 'Admin', component: Admin },
    { path: '/starred', beforeEnter: guard, name: 'Starred', component: Starred },
    { path: '/post-job', beforeEnter: guard, name: 'PostJob', component: PostJob },
    { path: '/job-detail/:id', beforeEnter: guard, name: 'JobDetail', component: JobDetail },
    { path: '/channel-group/:group', beforeEnter: guard, name: 'ChannelGroup', component: ChannelGroup },
    { path: '/channels/:type/:id', beforeEnter: guard, name: 'Channel', component: Channel, meta: { nofooter: true } },
    { path: '/connect', beforeEnter: guard, name: 'Inbox', component: Inbox },
    { path: '/connections', beforeEnter: guard, name: 'Connection', component: Connect },
    { path: '/connections-assigned', beforeEnter: guard, name: 'Connection', component: ConnectAssigned },
    { path: '/connections-mentioned', beforeEnter: guard, name: 'Connection', component: ConnectMentioned },
    { path: '/connections-subscribed', beforeEnter: guard, name: 'Connection', component: ConnectSubscribed },
    { path: '/connection/:id', beforeEnter: guard, name: 'Connection', component: Connection, meta: { nofooter: true } },
    { path: '/voice/:id', beforeEnter: guard, name: 'Voice', component: Voice },
    { path: '/scheduler/:id', beforeEnter: guard, name: 'Scheduler', component: Scheduler },
    { path: '/share-agreement/:id', beforeEnter: guard, name: 'ShareAgreement', component: ShareAgreement },
    { path: '/share-proposal/:id', beforeEnter: guard, name: 'ShareProposal', component: ShareProposal },
    { path: '/chemistry-check/:id', beforeEnter: guard, name: 'ChemistryCheck', component: ChemistryCheck },
    { path: '/feedback-form/:id', beforeEnter: guard, name: 'FeedbackForm', component: FeedbackForm },
    { path: '/duo-proposal/:id', beforeEnter: guard, name: 'Duo', component: Duo },
    { path: '/duo/:id/:coverId', name: 'DuoViewCover', component: DuoView },
    { path: '/duo/:id', name: 'DuoView', component: DuoView },
    { path: '/twilio/:id', beforeEnter: guard, name: 'Twilio', component: Twilio, meta: { noheader: true, nodrawer: true } },
    { path: '/datepicker', beforeEnter: guard, name: 'Datepicker', component: Datepicker },
    { path: '/learn', beforeEnter: guard, name: 'Learn', component: Learn },
    { path: '/learn/:id', beforeEnter: guard, name: 'LearnPage', component: Page },
    { path: '/help', beforeEnter: guard, name: 'Help', component: Help },
    { path: '/talent', beforeEnter: guard, name: 'Talent', component: Talent },
    { path: '/talent-complete', beforeEnter: guard, name: 'TalentComplete', component: TalentComplete },
    { path: '/settings', beforeEnter: guard, name: 'Settings', component: Settings },
    { path: '/user/:id', beforeEnter: guard, name: 'User', component: User },
    { path: '/legal', beforeEnter: guard, name: 'Legal', component: Legal },
    { path: '/my-profile', beforeEnter: guard, name: 'MyProfile', component: User },
    { path: '/focus-test', name: 'FocusTest', component: FocusTest, meta: { nomenu: true, noheader: true, loggedOutDrawer: true } },
    { path: '/location-test', name: 'LocationTest', component: LocationTest, meta: { nomenu: true, noheader: true, loggedOutDrawer: true } },
    { path: '/plain-input', name: 'PlainInputTest', component: PlainInputTest, meta: { nomenu: true, noheader: true, loggedOutDrawer: true } },

    { path: '/signin-r', name: 'SigninRemote', component: SigninRemote, meta: { nomenu: true } },
    { path: '/signup-r', name: 'SignupRemote', component: SigninRemote, meta: { nomenu: true } }

  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 120 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  next()
  const trackPage = () => {
    // eslint-disable-next-line no-undef
    if (typeof analytics !== 'undefined') {
      // eslint-disable-next-line no-undef
      analytics.page(to.path,
        {},
        {
          integrations: {
            All: true,
            'Customer.io': false
          }
        }
      )
    } else {
      setTimeout(trackPage, 1000)
    }
  }
  trackPage()
})

export default router
