<template>
  <div class="main-content full-post-page">
    <div class="main-wrapper main-content-wrapper" style="margin-top: 56px;">
      <article v-if="slug !== '' && fullPost !== null" class="full-post">
        <header class="full-post-header full-post-grid">
          <section class="full-post-tag">
            <a tag="span" @click="gotoHome()" class="blog">LEARN</a>
            <mdc-text tag="span" class="slash"> / </mdc-text>
            <a @click="gotoSlugPosts(fullPost.primary_tag.slug)" class="current-page" v-if="fullPost.primary_tag">{{ fullPost.primary_tag.name }}</a>
          </section>
          <h1 class="full-post-title">{{fullPost.title}}</h1>
          <div class="full-post-byline">
            <section class="full-post-byline-content">
              <ul class="author-list">
                  <li class="author-list-item" v-for="author in fullPost.authors" :key="author.id+author.name">
                      <a v-if="author.profile_image" class="author-avatar">
                        <img class="author-profile-image" :src="author.profile_image" :alt="author.name" />
                      </a>
                      <!-- to check -->
                      <a v-else class="author-avatar author-profile-image">{{ "icons/avatar" }}</a>
                  </li>
              </ul>
              <div class="full-post-byline-meta">
                  <h4 class="author-name">
                    <a v-for="(author, index) in fullPost.authors" :key="author.id + author.name + 'name'">{{author.name}}{{ index === fullPost.authors.length - 1 ? '' : ', ' }}</a>
                  </h4>
                  <div class="byline-meta-content">
                      <time class="byline-meta-date" :datetime="fullPost.published_at">{{dateFormat(fullPost.published_at)}}</time>
                  </div>
              </div>
            </section>
          </div>
          <figure class="full-post-image">
            <img
                :srcset="`${fullPost.feature_image} 300w, ${fullPost.feature_image} 600w, ${fullPost.feature_image} 1000w, ${fullPost.feature_image} 2000w`"
                sizes="(min-width: 1400px) 1400px, 92vw"
                :src="fullPost.feature_image"
                :alt="fullPost.title"
            />
          </figure>
        </header>
        <section class="full-post-grid full-post-content" v-html="fullPost.html"></section>
      </article>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as ghost from '../utils/ghost'
import fb from '../store/firebase'

export default {
  name: 'Page',
  data () {
    return {
      title: 'Page',
      slug: '',
      links: [],
      content: '',
      feature_image: '',
      fullPost: null
    }
  },
  computed: {
    ...mapState([
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    gotoHome () {
      this.$router.push('/learn')
    },
    gotoSlugPosts (slug) {
      this.$router.push('/learn/?tag=' + slug)
    },
    dateFormat (date) {
      return new Date(date).toDateString().slice(4)
    },
    async countPage () {
      console.log('Page.countPage', this.slug)
      const page = this.slug
      const type = 'learn'
      const companyId = (this.profile.companyId) ? this.profile.companyId : '_default'
      const pageView = fb.functions.httpsCallable('pageView')
      console.log('Page.countPage - calling pageView', page, type, companyId)
      await pageView({ page, type, companyId })
    }
  },
  beforeDestroy () {
    this.$root.$emit('enableSearch', false)
  },
  async mounted () {
    this.slug = this.$route.params.id
    const result = await ghost.postBySlug(this.slug)
    const posts = result.posts
    this.fullPost = posts[0]
    this.title = posts[0].title
    this.content = posts[0].html
    this.feature_image = posts[0].feature_image
    this.$root.$emit('enableSearch', true, { back: true, context: 'learn' })

    setTimeout(() => {
      this.setPageLoad(false)
      this.countPage()
    }, 600)
  }
}
</script>
<style scoped>
.full-post-page .main-wrapper {
  padding-left: 50px;
  padding-right: 50px;
}
.post-full-header {
  margin: 0 auto;
  padding: 16px 0 2vw;
  max-width: 1040px;
  text-align: center;
}
.post-full-title {
  margin: 0;
}
.post-full-image {
  margin: 0 -10vw -165px;
  background: #c5d2d9 50%;
  border-radius: 5px;
  overflow: hidden;
}
.post-full-content {
  position: relative;
  padding-left: 2%;
  margin: 0 auto;
  min-height: 230px;
  font-size: 1.0rem;
  line-height: 1.6em;
  background: #fff;
  margin-left: var(--mdc-layout-grid-margin-desktop, 24px);
  margin-right: var(--mdc-layout-grid-margin-desktop, 24px);
}
.post-content {
  display: flex;
  flex-direction: column;
  max-width: 920px;
}
img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@media (max-width: 840px) {
  .post-full-content {
    margin-left: var(--mdc-layout-grid-margin-phone, 16px);
    margin-right: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

</style>

<style>
.post-full-content img {
  display: block;
  height: auto;
}
.post-full-content .kg-image {
  max-width: 100%;
}
.post-full-content figure {
  margin: 1.5em 0 3em;
}
.post-full-content figure img {
  margin: 0;
}
</style>
<style>
.full-post {
  font-family: 'Nunito Sans';
  padding: 8vmin 0;
}
.full-post-header {
  padding: 0 0 6vmin;
}
.full-post-grid {
  display: grid;
  grid-template-columns:
      [full-start]
      minmax(4vmin, auto)
          [wide-start]
          minmax(auto, 240px)
              [main-start]
                  min(720px, calc(100% - 8vw))
              [main-end]
          minmax(auto, 240px)
          [wide-end]
      minmax(4vmin, auto)
      [full-end];
}
.full-post-grid > * {
  grid-column: main-start/main-end;
}
.full-post-tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  color: #738a94;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: .02em;
  font-weight: 600;
  position: relative;
}
.full-post-tag a {
  margin-right: 5px;
  font-weight: 800;
  color: #000000;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
}
.full-post-tag a.current-page {
  color: #cc0000 !important;
  cursor: default;
  margin-left: 10px;
}
.full-post-title {
  color: #0a0b0c;
  font-family: 'Lato', sans-serif;
  font-size: 48px;
}
.full-post-byline {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}
.full-post-byline-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.full-post-byline-content .author-list {
  justify-content: flex-start;
  padding: 0 12px 0 0;
  flex-wrap: wrap;
  margin: 0 0 0 4px;
  list-style: none;
}
.full-post-grid .full-post-image {
  grid-column: wide-start/wide-end;
  width: 100%;
  margin: 6vmin 0 0;
}
.full-post-grid .full-post-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 235px;
  width: 100%;
}
.author-avatar {
  display: block;
  overflow: hidden;
  margin: 0 -4px;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 100%;
  transition: all .5s cubic-bezier(.4,.01,.165,.99) .7s;
  cursor: default;
}
.author-profile-image, .avatar-wrapper {
  display: block;
  width: 100%;
  height: 100% !important;
  background: #e4eaed;
  border-radius: 100%;
  object-fit: cover;
}
.full-post-byline-meta {
  color: #738a94;
  font-size: 14px;
  line-height: 12px;
}
.full-post-byline-meta h4 {
  margin: 0 0 8px;
  font-size: 16px;
  color: #15171a;
  font-weight: 600;
}
.full-post-byline-meta h4 a {
  cursor: default;
  line-height: 17px;
}
.full-post-byline-meta .byline-meta-content {
  font-size: 15px;
}
.full-post-content {
  line-height: 25px;
}
.full-post-content > * + * {
  margin-top: 4vmin;
  margin-bottom: 0;
}

.full-post-content strong {
  font-weight: 700;
}
.full-post-content figure {
  margin: 4vmin 0 0 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.full-post-content figure img {
  max-width: 100%;
  height: auto;
}
.kg-image-card {
  max-width: 100%;
  height: auto;
}

.full-post-content > [id] {
  margin: 0;
  color: #15171A;
}
.full-post-content > [id]:not(:first-child) {
  margin: 2em 0 0;
  font-size: 28px;
  font-weight: 700;
}

.full-post-content > [id] + * {
  margin-top: 15px !important;
}

.full-post-content > hr,
.full-post-content > blockquote {
  position: relative;
  margin-top: 6vmin;
}
.full-post-content > hr + *,
.full-post-content > blockquote + * {
  margin-top: 6vmin !important;
}

.full-post-content a {
  color: #cc0000;
  text-decoration: underline;
  word-break: break-word;
  font-weight: 400;
}

.full-post-content > blockquote,
.full-post-content > ol,
.full-post-content > ul,
.full-post-content > dl,
.full-post-content > p {
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  color: #35373a;
}
.full-post-content > * {
  font-family: 'Nunito Sans';
}

.full-post-content > ul,
.full-post-content > ol,
.full-post-content > dl {
  padding-left: 19px;
}

.full-post-content > blockquote {
  position: relative;
  font-style: italic;
  padding: 0;
}

.full-post-content > blockquote::before {
  content: "";
  position: absolute;
  left: -1.5em;
  top: 0;
  bottom: 0;
  width: 0.3rem;
  background: #cc0000;
}

.full-post-content :not(pre) > code {
  vertical-align: middle;
  padding: 0.15em 0.4em 0.15em;
  border: #e1eaef 1px solid;
  font-weight: 400 !important;
  font-size: 0.9em;
  line-height: 1em;
  color: #15171A;
  background: #f0f6f9;
  border-radius: 0.25em;
}

.full-post-content pre {
  overflow: auto;
  padding: 16px 20px;
  border: 1px solid color-mod(#15171A l(-20%));
  color: #e5eff5;
  font-size: 14px;
  line-height: 15px;
  background: #15171A;
  border-radius: 5px;
  box-shadow: 0 2px 6px -2px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.4);
}
.full-post figcaption {
  text-align: center;
}

@media (max-width: 650px) {
  .full-post-content blockquote,
  .full-post-content ol,
  .full-post-content ul,
  .full-post-content dl,
  .full-post-content p {
      font-size: 17px;
  }

  .full-post-content blockquote::before {
      left: -4vmin;
  }
}
@media (max-width: 600px) {
  .full-post-title {
    font-size: 28px;
  }
}
</style>
