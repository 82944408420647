<template>

  <app-dialog
    class="first-modal fullsize-mobile"
    style="z-index:11;"
    accept=""
    required
    cancel=""
    v-model="open"
    title=""
    :subtitle="subtitle"
    >
      <div style="display:flex; justify-content: center; align-items: center;" slot="title">
        <mdc-body typo="headline5" v-if="screen === 1">Welcome</mdc-body>
        <div style="display:flex; align-items:center; flex:1" v-if="screen > 1 && screen < 6">
          <round-button noHover style="margin-left:-6px;" background="transparent" @click="screen--" icon="arrow_back">
          </round-button>
          <div style="margin-left:auto; margin-right:auto;">
            <span class="dot" :class="{active: screen >= 1}"/>
            <span class="dot" :class="{active: screen >= 2}"/>
            <span class="dot" :class="{active: screen >= 3}"/>
            <span class="dot" :class="{active: screen >= 4}"/>
            <span class="dot" :class="{active: screen >= 5}"/>
          </div>
          <div style="width:59px;height:31px;" class="spacer"></div>
        </div>
        <mdc-body typo="headline5" v-if="screen === 6">Thats it you are all done!</mdc-body>
      </div>

      <profile-card style="margin-top:16px;margin-bottom:16px;max-width:255px;min-width:150px;" @avatar="openAvatarDialog" @cardDesign="updateCardDesign" @openCardDesign="openCardDesign = true" :user="cardUser" hoverHigh :medium="true" hideConnect hideShare hideBookmark hideMore :profile="profile"></profile-card>

      <mdc-body tag="div" style="margin-bottom:16px;" v-if="text">{{text}}</mdc-body>

      <div v-if="screen === 2" class="profile-section">
          <auto-complete-outline
          label="Role"
          prompt="What type of role best describes what you do?"
          :required="true"
          :value="role"
          autocompleteId="'role'"
          :field="'role'"
          :scrollFocus="false"
          @set="setRole"
          @input="setRoleValue"
          :items="roles"
          ariaLabelledBy="roleLabel"
          style="z-index:99;"
          maxlength="120"
          helptext = "- E.g. Project Manager"
          :valid="roleValid || !saveFailed"
          >
            <mdc-text-field-counter slot="counter">0 / 120</mdc-text-field-counter>
          </auto-complete-outline>
        </div>

        <div v-if="screen === 3" class="profile-section">
          <auto-complete-location
            label="Location"
            prompt="Where do you live?"
            helptext="- E.g. London, this helps us match opportunities with a reasonable commute"
            :value="location"
            @updateAddress="updateAddress"
            scrollIntoView
            validationMessage="Select a location option from list"
            :valid="locationValid || !saveFailed">
          </auto-complete-location>
        </div>

        <div v-if="screen === 4" class="profile-section">
          <day-slider-range :valid="flexscheduleValid" validationMessage="You must select the number of days to proceed" modal :show="screen === 4" @change="setFlexSchedule" v-model="flexschedule"/>

          <mdc-body tag="div" class="sub-label nomargin">
            This helps us match opportunities offering similar hours
          </mdc-body>
        </div>

        <div v-if="screen === 5" class="profile-section">

          <mdc-body style="width:100%">What is your preferred workstyle? <i class="required"></i></mdc-body>

          <mdc-body tag="div" v-if="!workstyleValid" typo="body2" class="workstyle-msg--invalid">You must choose a workstyle to proceed</mdc-body>

          <mdc-layout-grid style="padding-left:0;padding-right;0;">
              <mdc-layout-cell span="4">
                <mdc-radio :valid="workstyleValid" class="radio-button" tabindex="7" v-model="workstyle" name="radios" label="Office" />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You want to work most of the time in the office</div>
                </div>

              </mdc-layout-cell>
              <mdc-layout-cell span="4">
                <mdc-radio :valid="workstyleValid" class="radio-button" tabindex="8" v-model="workstyle" name="radios" label="Hybrid"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>Working part of the week in the office and part remote</div>
                </div>

              </mdc-layout-cell>
              <mdc-layout-cell span="4">
                <mdc-radio :valid="workstyleValid" class="radio-button" tabindex="9" v-model="workstyle" name="radios" label="Remote"  />
                <div class="mdc-form-field stageinfo">
                  <div class="label">This means</div>
                  <div>You are remote and will rarely attend the office</div>
                </div>

              </mdc-layout-cell>
          </mdc-layout-grid>

        </div>

      <round-button noHover fullWidth high :disabled="acceptDisabled" @disabled-click="validate" @click="accept" hasSlot icon="arrow_forward">
        <span v-if="screen < 6">Next</span>
        <span v-else>Finished</span>
      </round-button>

      <!-- Avatar photo -->
      <photo-modal :openPhoto="avatarmodalopen" @upload="uploadAvatarFile" @change="changeAvatarModalOpen" :profile="profile" :accept-disabled="saveAvatarDisabled" />

      <!-- Card design -->
      <card-design-modal v-show="openCardDesign" :advancedEnabled="tasksCompleted" @openPhoto="avatarmodalopen = true;openCardDesign = false" @openTasks="profileNotification = true" :openCardDesign="openCardDesign" @change="changeCardDesignModalOpen" @cardDesign="updateCardDesign" :profile="profile" />
  </app-dialog>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppDialog from '../components/AppDialog'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import AutoCompleteLocation from '../components/AutoCompleteLocation'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
import DaySliderRange from '../components/DaySliderRange'
import PhotoModal from '../components/profile-v2/PhotoModal.vue'
import CardDesignModal from '../components/profile-v2/CardDesignModal.vue'

export default {
  name: 'FirstLogin',
  props: {
    firstModalOpen: {
      type: Boolean
    }
  },
  components: {
    AppDialog,
    ProfileCard,
    AutoCompleteOutline,
    AutoCompleteLocation,
    DaySliderRange,
    CardDesignModal,
    PhotoModal
  },
  mounted () {
    if (this.profile) {
      this.completed = 1
      if (this.profile.basicCompleted) {
        this.basic = true
        this.completed++
      }
      if (this.profile.experienceCompleted) {
        this.experience = true
        this.completed++
      }
      if (this.profile.photoURL) {
        this.personalise = true
        this.completed++
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'roles'
    ]),
    cardUser () {
      if (this.screen === 6) {
        return this.user
      }
      return {}
    },
    subtitle () {
      return this.screenData[this.screen] ? this.screenData[this.screen].subtitle : ''
    },
    text () {
      return this.screenData[this.screen] ? this.screenData[this.screen].text : ''
    },
    helpText () {
      return this.screenData[this.screen] ? this.screenData[this.screen].helpText : ''
    },
    acceptCloses () {
      if (this.screen > 4) {
        return true
      }
      return false
    },
    acceptDisabled () {
      if (this.screen === 1) {
        return false
      }
      if (this.screen === 2 && this.role) {
        return false
      }
      if (this.screen === 3 && this.location) {
        return false
      }
      if (this.screen === 4 && this.flexschedule) {
        if (this.flexschedule !== '0 Days') {
          return false
        }
      }
      if (this.screen === 5 && this.workstyle) {
        return false
      }
      if (this.screen === 6) {
        return false
      }
      return true
    },
    tasksCompleted () {
      return this.completed === 4
    }
  },
  data () {
    return {
      open: false,
      openCardDesign: false,
      avatarmodalopen: false,
      saveAvatarDisabled: true,
      profileNotification: false,
      completed: 1,
      basic: false,
      experience: false,
      personalise: false,
      completeIntro: false,
      saveFailed: false,
      role: '',
      roleValid: true,
      location: '',
      locationValid: true,
      flexscheduleValid: true,
      flexschedule: '',
      workstyle: false,
      workstyleValid: true,
      screen: 1,
      screenData: {
        1: {
          text: 'The next questions fill out your business card and make the content relevant'
        },
        2: {
        },
        3: {
        },
        4: {
          text: 'What is your ideal working schedule in days?'
        },
        5: {
        },
        6: {
          text: 'Make your business card stand out by updating its design'
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'updateProfile',
      'uploadAvatar'
    ]),
    validate () {
      this.saveFailed = true
      if (this.screen === 2) {
        this.roleValid = false
      }
      if (this.screen === 3) {
        this.$set(this, 'locationValid', false)
      }
      if (this.screen === 4) {
        this.flexscheduleValid = false
      }
      if (this.screen === 5) {
        this.workstyleValid = false
      }
    },
    uploadAvatarFile (file, filename) {
      const self = this
      this.uploadAvatar({ file, filename }).then(() => {
        self.avatarmodalopen = false
      })
    },
    changeAvatarModalOpen ($event) {
      this.avatarmodalopen = $event
      console.log('reapply scroll lock')
      setTimeout(() => {
        document.getElementsByClassName('body')[0].classList.add('mdc-dialog-scroll-lock')
      }, 0)
    },
    changeCardDesignModalOpen ($event) {
      this.openCardDesign = $event
      console.log('reapply scroll lock')
      setTimeout(() => {
        document.getElementsByClassName('body')[0].classList.add('mdc-dialog-scroll-lock')
      }, 0)
    },
    accept () {
      this.saveFailed = false
      this.roleValid = true
      this.$set(this, 'locationValid', true)
      this.flexscheduleValid = true
      this.workstyleValid = true

      if (this.screen === 2) {
        this.profile.role = this.role
        this.updateProfile({ profile: { role: this.profile.role }, noSnack: true })
      }
      if (this.screen === 3) {
        this.profile.location = this.location
        this.profile.locationFull = this.locationFull
        this.profile.locationLat = this.locationLat
        this.profile.locationLon = this.locationLon
        this.updateProfile({ profile: { location: this.profile.location, locationFull: this.profile.locationFull, locationLat: this.profile.locationLat, locationLon: this.profile.locationLon }, noSnack: true })
      }
      if (this.screen === 4) {
        this.profile.flexschedule = this.flexschedule
        this.updateProfile({ profile: { flexschedule: this.profile.flexschedule }, noSnack: true })
      }
      if (this.screen === 5) {
        this.profile.workstyle = this.workstyle
        this.profile.seenIntro = true
        this.updateProfile({ profile: { seenIntro: true, workstyle: this.profile.workstyle }, noSnack: true })
      }
      if (this.screen === 6) {
        this.open = false
        window.scrollTo({
          top: 0
        })
        this.$emit('close')
      }
      this.screen++
    },
    setRoleValue: function (val) {
      // console.log('ProfileModals.setRoleValue', val)
      this.role = val
      this.roleValid = true
    },
    updateCardDesign: function (design, layout) {
      this.profile.cardDesign = design
      this.profile.cardDesignBackground = layout
      this.updateProfile({ profile: { cardDesign: this.profile.cardDesign, cardDesignBackground: this.profile.cardDesignBackground } })
    },
    setRole (role) {
      if (typeof role.role !== 'undefined') {
        this.role = role.role
      } else {
        this.role = role
      }
    },
    setFlexSchedule (fs) {
      this.flexschedule = fs
    },
    updateAddress (addressData) {
      this.location = addressData.location
      this.locationFull = addressData.locationFull
      this.locationLat = addressData.locationLat
      this.locationLon = addressData.locationLon
      this.$set(this, 'locationValid', true)
    },
    updateLocation (val) {
      this.locationInputVal = val.newVal
      this.$set(this, 'locationValid', true)
    },
    dashAction (action) {
      this.setAction(action)
      this.open = false
    },
    openAvatarDialog: function () {
      this.avatarmodalopen = true
    },
    setAvatarSaveDisabled (loaded) {
      this.saveAvatarDisabled = !loaded
    },
    routeTo (url) {
      this.open = false
      const self = this
      this.$nextTick(() => {
        setTimeout(function () {
          self.$router.push(url)
        }, 300)
      })
    }
  },
  watch: {
    workstyle () {
      if (this.workstyle) {
        this.workstyleValid = true
      }
    },
    firstModalOpen: {
      handler: function (newo, oldo) {
        if (this.firstModalOpen) {
          this.open = true
        } else {
          this.open = false
        }
      },
      immediate: true
    },
    open (newo, oldo) {
      if (!newo) {
        if (!this.completeIntro) {
          this.$emit('modalClosed')
        }
      }
    }
  }
}
</script>

<style scoped>
.first-modal .link-button {
  display:block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  padding-left:16px;
  max-width:100%;
  margin-left: auto;
  margin-right: auto;
}
.first-modal .item {
  align-items: flex-start;
  display: flex;
  flex:0;
  flex-direction: column;
  margin-top:auto;
  margin-top:12px;
  min-height:65px;
  padding:12px;
  cursor:normal;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 600ms ease-in-out;
  max-height: 100px;
}
.first-modal .item .item-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  width:100%;
}

.first-modal .mdc-radio-wrapper.radio-button {
  border:1px solid silver;
  border-radius:20px;
  padding-right:12px;
  white-space: nowrap;
  width:calc(100% - 16px);
  justify-content: center;
  align-items: center;
}

.first-modal .mdc-radio-wrapper.radio-button .mdc-radio {
  padding-right: 6px;
}
.first-modal .mdc-radio-wrapper.radio-button label {
  padding-left:2px;
}
.first-modal .stageinfo {
  display:block;
  margin-top:6px;
  margin-left:26px;
  border-left:2px solid silver;
  padding-left:6px;
}
.first-modal .stageinfo .label{
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin-bottom:12px;
}

.item img, .item .image-spacer {
    height:80px;
    width:80px;
    min-width:80px;
  }
p.mdc-body{
  margin-bottom:0;
}
.item .info {
  flex: 1;
  padding-left:24px;
  padding-right:24px;
}
.first-modal .item.expanded {
  max-height: 600px;
  overflow: hidden;
}
.item .info .mdc-title {
  font-size:16px;
}
.first-modal .tick {
  border-radius:50%;
  padding:10px;
  background-color:transparent;
  border-color:transparent;
  border-width: 2px;
  border-style: solid;
  width:10px;
  height:10px;
  text-align: center;
  font-style: normal;
  display: block;
  font-weight:500;
  font-size:22px;
  line-height: 10px;
  text-indent: 1px;
}
.first-modal .tick.material-icons {
  text-indent: -6px;
}

.tick.blue {
  border-color: #3560af;
}
.tick.pink {
  border-color: #C10457;
}
.tick.orange {
  border-color: #f4854d;
}
.tick.green {
  border-color: #2cbcb6;
}
.tick.done.blue {
  background-color: #3560AF;
  color:white;
}
.tick.done.pink {
  background-color: #C10457;
  color:white;
}
.tick.done.orange {
  background-color: #f4854d;
  color:white;
}
.tick.done.green {
  background-color: #2cbcb6;
  color:white;
}
p, h1, h2, h3 {
  color: #24292e;
  margin-bottom:6px;
  margin-top:6px;
}
.modal-title-wrapper {
  display:flex;
  flex-flow:row;
  align-items: flex-end;
  padding-top: 12px;
}
.modal-title-wrapper .modal-title {
  flex:1
}
.modal-title-wrapper .modal-progression {
  flex:0;
  min-width: 100px;
}
@media all and (max-width: 670px), all and (max-height: 670px){
  .item {
    min-height:65px;
  }
  .modal-title-wrapper .modal-progression {
    font-size: 2.4em;
    min-width: 90px;
  }
  h2 {
    font-size: 1.2em;
  }
  p.mdc-typography--body2 {
    font-size: 0.8em;
    line-height: 1.4em;
  }
  .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .item {
    padding:8px;
  }
  .item img, .item .image-spacer {
    height:65px;
    width:65px;
    min-width:65px;
  }
  .item .info {
    padding-left:16px;
    padding-right:0px;
  }
  .item .info .mdc-title {
    font-size:0.9em;
    line-height:1.4em;
  }
}
@media all and (max-width: 570px), all and (max-height: 570px){
  .item {
    min-height:50px;
  }
  .modal-title-wrapper .modal-progression {
    font-size: 2.2em;
    min-width: 80px;
  }
  h2 {
    font-size: 1em;
  }
  p.mdc-typography--body2 {
    font-size: 0.7em;
    line-height: 1.4em;
  }
  .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .item {
    padding:6px;
  }
  .item img, .item .image-spacer  {
    height:55px;
    width:55px;
    min-width:55px;
  }
  .item .info {
    padding-left:16px;
    padding-right:0px;
  }
  .item .info .mdc-title {
    font-size:0.8em;
    line-height:1.4em;
  }
  .first-modal .tick {
    width:6px;
    height:6px;
    font-size:20px;
    line-height: 6px;
    text-indent: -2px;
  }
  .first-modal .tick.material-icons {
    text-indent: -7px;
  }
}

.close-modal {
  position: absolute;
  top: -10px;
  right: 16px;
  min-width: 0px;
  color:gray;
}
.switch-wrapper {
  display:flex;
  flex-flow:row;
  height:30px;
  z-index:9;
  align-items: center;
  width:99%;
  justify-content:flex-end;
  padding-top: 6px;
}
.workstyle-msg--invalid, .flexschedule-msg--invalid {
  color: rgb(176, 0, 32);
  text-align: left;
  margin-top:6px;
  margin-left: 0px;
  padding-right:0px;
  width: 100%;
}
</style>

<style lang="scss">
.first-modal .profile-card .default-span {
  padding-top:0%;
}
.first-modal .autocomplete .input-bottom {
  display: none;
}
.first-modal .mdc-dialog__surface {
  max-width: 520px !important;
  min-width: unset !important;
}
.first-modal .round-button.disabled {
  background-color: #F2F2F2;
  color: rgba(0, 0, 0, 0.54);
}
.first-modal .mdc-dialog__body {
  position:relative;
  padding:0px;
  margin-top:16px;
  overflow:visible;
  max-height:calc(100vh - 80px);
}
@media all and (max-width: 570px), all and (max-height: 700px){
  .first-modal .mdc-dialog__body {
    max-height:calc(100vh - 65px);
  }
}
</style>
