<template>
  <!-- experience -->
  <app-section class="experience"
  :editable="editable"
  @change="setEditing"
  @cancel="cancelEdit"
  @save="updateProfileExperience"
  animationTime="0ms"
  title="Experience"
  >

    <div v-if="(editable && editingExperience) || editonly" >

      <div>
        <div v-for="(exp, index) in experienceBars" :key="'exp-'+index" :title="exp.role">
          <div style="padding:4px;position:relative;display:flex;" >
            <div :class="'exp-bar-wrapper exp-bar-wrapper-' + exp.class" >
              <mdc-body typo="body2" :class="'exp-bar exp-bar-' + exp.class">
                <div class="bar" :style="{'width': exp.width}"></div>
                <span class="exp-bar-role">
                  {{exp.role}}
                  <span v-if="exp.class === 'other'">
                    <mdc-icon class="other-expand" icon="indeterminate_check_box" outlined/>
                  </span>
                </span>
                <span class="exp-bar-label">{{exp.years}} Years</span>
              </mdc-body>
            </div>
            <round-button style="margin-top:0;margin-bottom:0;margin-right:2px;" v-if="exp.class !== 'other'" @click.prevent.stop="deleteRole(exp.index)" iconOutline title="Delete" icon="delete" label="Delete"/>
            <span v-else style="width:45px;"/>

          </div>

          <div v-if="exp.class === 'other'">
              <div>
                <div class="exp-bar-wrapper exp-bar-wrapper-detail" v-for="(detail, index) in exp.details" :key="index">
                  <mdc-body typo="body2" class="exp-bar exp-bar-detail">
                    <span class="exp-tree-branch"/>
                    <span class="exp-bar-role">{{detail.role}}</span>
                    <span class="exp-bar-label">{{detail.years}} Years</span>
                  </mdc-body>
                  <round-button style="margin-top:0;margin-bottom:0;margin-right:6px;" @click.prevent.stop="deleteRole(detail.index)" iconOutline title="Delete" icon="delete" label="Delete"/>

                </div>
              </div>
            </div>

        </div>
      </div>

      <div class="add-row">
        <mdc-body typo="body2" style="color:#b00020" v-if="expValiationMessage">{{expValiationMessage}}</mdc-body>
        <mdc-body class="sub-label" typo="body2">Provide your experience by adding roles and specialities</mdc-body>
        <div style="display:flex;flex-flow: row wrap;padding-left:6px;padding-top:0px;">
          <div style="margin-right:6px;flex:1;">
            <auto-complete-outline
              autocompleteId="'newrole'"
              :field="'role'"
              promptMobileOverlay="Provide your experience by adding roles and specialities"
              :value="newRoleName"
              v-on:input="setNewRole"
              v-on:set="setNewRole"
              label="Role / Speciality"
              :items="roles"
              ariaLabelledBy="newroleLabel"
              maxlength="100"
              :isMobile="isMobile"
              :mobileOverlay="true"
              next="experienceYears"
              @focusOn="focusOn"
              ref="experienceRole"
              helptext = "- E.g. Project Manager"
              :valid="roleNameValid || newRoleName !== ''"
            >
              <mdc-text-field-counter slot="counter"></mdc-text-field-counter>
            </auto-complete-outline>
          </div>
          <div style="margin-right:6px;display:flex;flex:1;width:100%">
            <div style="flex:1; min-width:60px">
              <app-select
              outlined
              style="width:calc(100% - 16px);height: 56px;"
              :valid="roleExpValid || newRoleExp !== ''"
              v-model="newRoleExp"
              label="Years Exp"
              :isMobile="isMobile"
              :mobileOverlay="true"
              prev="experienceRole"
              :finish="true"
              @finish="addRole"
              @focusOn="focusOn"
              helptext = "- E.g. 3 Years"
              :promptMobileOverlay="'How many years of Experience do you have in this Role? - ' + newRoleName"
              ref="experienceYears">
                <select-item v-for="i in 10" :key="'expy' + i" :value="i"/>
                <select-item value="10+"/>
              </app-select>
            </div>
            <div class="round-button-wrapper-right">
              <round-button
                high
                @click="addRole"
                elevated
                title="Add"
                icon="add"
                label="Add Entry"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else >
      <div v-for="(exp, index) in experienceBars" :key="'exp-'+index" :title="exp.role">
        <div style="padding:4px;position:relative;" >
          <div :class="'exp-bar-wrapper exp-bar-wrapper-' + exp.class" >
            <mdc-body typo="body2" :class="'exp-bar exp-bar-' + exp.class">
              <div class="bar" :style="{'width': exp.width}"></div>
              <span class="exp-bar-role">
                {{exp.role}}
                <span v-if="exp.class === 'other'">
                  <mdc-icon v-if="!otherExpanded" @click.native="otherExpanded = true" class="other-expand" icon="add_box" outlined/>
                  <mdc-icon v-else @click.native="otherExpanded = false" class="other-expand" icon="indeterminate_check_box" outlined/>
                </span>
              </span>
              <span class="exp-bar-label">{{exp.years}} Years</span>
            </mdc-body>
          </div>
        </div>

        <div v-if="exp.class === 'other'">
          <div v-if="otherExpanded">
            <div class="exp-bar-wrapper exp-bar-wrapper-detail" v-for="(detail, index) in exp.details" :key="index">
              <mdc-body typo="body2" class="exp-bar exp-bar-detail">
                <span class="exp-tree-branch"/>
                <span class="exp-bar-role">{{detail.role}}</span>
                <span class="exp-bar-label" style="margin-right:4px;">{{detail.years}} Years</span>
              </mdc-body>
            </div>
          </div>
        </div>

      </div>
    </div>

  </app-section>
</template>

<script>
import { mapState } from 'vuex'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import { getYearArray, getMonthArray, differenceYears, differenceYearsMonths } from '../../utils/time'
import RoundButton from '../../components/RoundButton'
import AppSection from '../../components/profile-v2/AppSection.vue'
import AppSelect from '../../components/AppSelect'

function compareFrom (a, b) {
  if (a.fromYear < b.fromYear) {
    return 1
  }
  return -1
}

function compareYears (a, b) {
  if (a.years < b.years) {
    return 1
  }
  return -1
}

export default {
  name: 'ExperienceSection',
  components: {
    AutoCompleteOutline,
    RoundButton,
    AppSection,
    AppSelect
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    editonly: {
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    },
    basic: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: false
    },
    defautRole: {},
    profile: Object,
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      detailedEnabled: false,
      editingExperience: false,
      expExpanded: -1,
      expValiationMessage: '',
      expDescUpdates: {},
      experience: [],
      currentExp: null,
      newRoleName: '',
      newRoleExp: '',
      profileRoles: [],
      detailed: null,
      otherExpanded: false,
      roleNameValid: true,
      roleExpValid: true
    }
  },
  computed: {
    ...mapState([
      'roles'
    ]),
    experienceBars: function () {
      const expBars = []
      const expBarMap = {}
      if (!this.detailed || !this.detailedEnabled) {
        if (this.profileRoles) {
          for (const [index, exp] of this.profileRoles.entries()) {
            const role = exp.name
            if (expBarMap[role]) {
              expBarMap[role].years += Number(exp.experience.replace('+', ''))
            } else {
              expBarMap[role] = { index, years: Number(exp.experience.replace('+', '')), role: role }
            }
          }
        }
      } else {
        if (this.profile.experience) {
          for (const exp of this.profile.experience) {
            const role = exp.role ? exp.role : exp.position
            if (expBarMap[role]) {
              expBarMap[role].years += this.getYearDiff(exp)
            } else {
              expBarMap[role] = { years: this.getYearDiff(exp), role: role }
            }
          }
        }
      }
      const expBarsArr = Object.values(expBarMap)
      expBarsArr.sort(compareYears)
      if (expBarsArr[0]) {
        const fullWidthYears = expBarsArr[0].years
        expBars.push({ index: expBarsArr[0].index, years: expBarsArr[0].years, role: expBarsArr[0].role, width: 'calc(100% - 10px)', class: 'full' })
        if (expBarsArr[1]) {
          let width = '100%'
          if (expBarsArr[1].years !== fullWidthYears) {
            const medWidth = Math.round((expBarsArr[1].years * 100) / fullWidthYears)
            width = `calc(${medWidth}% - 8px)`
          }
          expBars.push({ index: expBarsArr[1].index, years: expBarsArr[1].years, role: expBarsArr[1].role, width: width, class: 'med' })
          if (expBarsArr[2]) {
            let width = '100%'
            if (expBarsArr[2].years !== fullWidthYears) {
              const smallWidth = Math.round((expBarsArr[2].years * 100) / fullWidthYears)
              width = `calc(${smallWidth}% - 8px)`
            }
            expBars.push({ index: expBarsArr[2].index, years: expBarsArr[2].years, role: expBarsArr[2].role, width, class: 'small' })
            if (expBarsArr.length > 3) {
              let otherYears = 0
              const otherExp = []
              for (let i = 3; i < expBarsArr.length; i++) {
                const exp = expBarsArr[i]
                otherYears += exp.years
                otherExp.push(exp)
              }
              const otherWidth = Math.round((otherYears * 100) / fullWidthYears)
              let width = '100%'
              if (otherWidth < 100) {
                width = `calc(${otherWidth}% - 8px)`
              }
              expBars.push({ years: otherYears, role: 'Other Experience', width: width, class: 'other', details: otherExp })
            }
          }
        }
      }
      return expBars
    },
    experienceGrouped: function () {
      const expGrouped = {}
      if (this.profile.experience) {
        let lastPlace = null
        let lastPlaceIndex = null
        let i = 0
        const experience = this.profile.experience
        if (experience) {
          experience.sort(compareFrom)
          for (const exp of experience) {
            if (exp.place === lastPlace) {
              if (exp.fromYear) {
                if (expGrouped[exp.place + '-' + lastPlaceIndex].from < exp.fromYear) {
                  expGrouped[exp.place + '-' + lastPlaceIndex].from = exp.fromYear
                }
              }
              if (exp.toYear) {
                if (expGrouped[exp.place + '-' + lastPlaceIndex].from > exp.toYear) {
                  expGrouped[exp.place + '-' + lastPlaceIndex].from = exp.toYear
                }
              }
              expGrouped[exp.place + '-' + lastPlaceIndex].experience.push(exp)
            } else {
              expGrouped[exp.place + '-' + i] = { experience: [exp], place: exp.place }
              expGrouped[exp.place + '-' + i].from = exp.fromYear
              expGrouped[exp.place + '-' + i].to = exp.toYear

              lastPlaceIndex = i
              lastPlace = exp.place
            }
            i++
          }
          for (const group in expGrouped) {
            if (expGrouped[group].length > 1) {
              expGrouped[group].sort(compareFrom)
            }
          }
        }
      }
      return expGrouped
    }
  },
  methods: {
    focusOn (target, direction) {
      console.log('focusOn', target, this.$refs[target])
      this.focusField = target
      if (this.$refs[target]) {
        try {
          this.$refs[target].focus()
        } catch (err) {
          if (this.$refs[target].$el) {
            this.$refs[target].$el.focus()
          }
        }
      }
    },
    triggerNext (next) {
      console.log('ProfileModals.TriggerNext', next)
      this.focusOn(next)
    },
    triggerPrev (prev) {
      console.log('ProfileModals.TriggerPrev', prev)
      this.focusOn(prev, 'previous')
    },
    closePortal () {
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focusField = false
    },
    setRole: function (role, index) {
      const experience = this.experience[index]
      experience.role = role.role
    },
    addRole: function () {
      this.expValiationMessage = ''
      this.roleNameValid = true
      this.roleExpValid = true
      if (this.newRoleName && this.newRoleExp) {
        const newRole = { name: this.newRoleName, experience: this.newRoleExp }
        this.profileRoles.push(newRole)
        this.newRoleName = ''
        this.newRoleExp = null
        this.$emit('updateExperience', { roles: this.profileRoles })
      } else {
        if (this.newRoleName === '') {
          this.roleNameValid = false
        }
        if (this.newRoleExp === '') {
          this.roleExpValid = false
        }
        this.expValiationMessage = 'Please enter a role and years experience.'
      }
    },
    deleteRole: function (index) {
      this.expValiationMessage = ''
      this.profileRoles.splice(index, 1)
      this.$emit('updateExperience', { roles: this.profileRoles })
    },
    setNewRole: function (role) {
      if (typeof role === 'object') {
        this.newRoleName = role.role
      } else {
        this.newRoleName = role
      }
    },
    editProfileExperience: function () {
      this.editingExperience = true
      this.experience = this.profile.experience
      this.profileRoles = [...this.profile.roles]
    },
    updateProfileExperience: function () {
      if (!this.detailed || !this.detailedEnabled) {
        this.editingExperience = false
        if (this.newRoleName && this.newRoleExp) {
          this.addRole()
        }
        this.$emit('updateProfileSection', { roles: this.profileRoles })
      } else {
        if (this.experienceValid()) {
          this.editingExperience = false
          for (const descUpdateKey in this.expDescUpdates) {
            const index = parseInt(descUpdateKey.replace('idx', ''))
            this.experience[index].description = this.expDescUpdates[descUpdateKey]
          }
          this.$emit('updateProfileSection', { experience: this.experience })
          this.editingExperience = false
        }
      }
    },
    getMonths: function () {
      return getMonthArray()
    },
    getYears: function () {
      return getYearArray()
    },
    getYearDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYears(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return 0
    },
    getYearMonthDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYearsMonths(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return ''
    },
    getYearsAfter: function (after) {
      const years = getYearArray()
      if (after) {
        const afterInt = parseInt(after)
        const index = years.indexOf(afterInt)
        if (index <= 0) {
          return years
        }
        return years.slice(0, index + 1)
      }
      return years
    },
    getYearsBefore: function (before) {
      const years = getYearArray()
      if (before) {
        const beforeInt = parseInt(before)
        const index = years.indexOf(beforeInt)
        if (index === -1) {
          return years
        }
        return years.slice(index)
      }
      return years
    },
    getMonthsAfter: function (beforeYear, afterYear, afterMonth) {
      const months = getMonthArray()
      if (afterYear && (afterYear === beforeYear) && afterMonth) {
        const index = months.indexOf(afterMonth)
        if (index === -1) {
          return months
        }
        return months.slice(index)
      }
      return months
    },
    getMonthsBefore: function (beforeYear, afterYear, beforeMonth) {
      const months = getMonthArray()
      if (afterYear && (afterYear === beforeYear) && beforeMonth) {
        const index = months.indexOf(beforeMonth)
        if (index <= 0) {
          return months
        }
        return months.slice(0, index + 1)
      }
      return months
    },
    onExpDescriptionUpdate: function (text, contentKey) {
      this.expDescUpdates[contentKey] = text
    },
    addExperience: function () {
      if (!this.experience) {
        this.experience = []
      }
      if (this.experienceValid()) {
        if (this.editonly) {
          for (const descUpdateKey in this.expDescUpdates) {
            const index = parseInt(descUpdateKey.replace('idx', ''))
            this.experience[index].description = this.expDescUpdates[descUpdateKey]
          }
          this.$emit('updateProfileSection', { experience: this.experience })
        }
        this.experience.push({
          place: '',
          position: '',
          show: true,
          years: '',
          fromMonth: '',
          fromYear: '',
          toMonth: '',
          toYear: ''
        })
        this.setExpExpanded(this.experience.length - 1)
      }
    },
    moveExpDown: function (index) {
      if (index < this.experience.length - 1) {
        const item = this.experience[index]
        this.experience.splice(index, 1)
        this.experience.splice(index + 1, 0, item)
        if (this.expExpanded !== -1) {
          this.expExpanded = index + 1
        }
      }
    },
    moveExpUp: function (index) {
      if (index > 0) {
        const item = this.experience[index]
        this.experience.splice(index, 1)
        this.experience.splice(index - 1, 0, item)
        if (this.expExpanded !== -1) {
          this.expExpanded = index - 1
        }
      }
    },
    deleteExp: function (index) {
      this.experience.splice(index, 1)
      this.expExpanded = -1
    },
    setExpExpanded: function (indx) {
      if (this.expExpanded === indx) {
        this.expExpanded = -1
        this.currentExp = null
      } else {
        this.expExpanded = indx
        this.currentExp = this.experience[indx]
      }
    },
    experienceValid: function () {
      let valid = true
      this.expValiationMessage = ''
      this.experience.forEach((ex, i) => {
        if (ex.place === '' || ex.position === '' || ex.fromMonth === '' || ex.fromYear === '' || ex.toMonth === '' || ex.toYear === '') {
          valid = false
          if (this.expExpanded !== i) {
            this.setExpExpanded(i)
          }
          this.expValiationMessage = '* Please fill out the Company, Role Title and Dates'
        }
      })
      return valid
    },
    cronoOrder: function (list) {
      return list
    },
    setEditing (val) {
      if (!val) {
        this.$emit('editing', false)
      } else {
        this.$emit('editing', 'experience')
      }
      this.editingExperience = val
      if (this.editingExperience) {
        this.editProfileExperience()
      }
    },
    cancelEdit () {
      this.profileRoles = []
      this.profileRoles = [...this.profile.roles]
    }
  },
  mounted () {
    if (this.editonly) {
      this.editProfileExperience()
    }
    if (!this.detailedEnabled) {
      this.detailed = !this.basic
    }
    if (typeof this.defautRole !== 'undefined') {
      this.newRoleName = this.defautRole
    }
  },
  watch: {
    profile: {
      handler (newp, oldp) {
        if (this.profile) {
          this.profileRoles = [...this.profile.roles]
        }
      },
      immediate: true
    },
    defautRole: function (newd, oldd) {
      if (typeof this.defautRole !== 'undefined') {
        this.newRoleName = this.defautRole
      }
    },
    detailed: function (newd, oldd) {
      if (oldd !== null) {
        this.$emit('updateProfileSection', { experienceDetailed: this.detailed })
      }
    },
    newRoleName: function (newr, oldr) {
      this.$emit('updateNewRoleName', this.newRoleName)
    },
    newRoleExp: function (newr, oldr) {
      this.$emit('updateNewRoleExp', this.newRoleExp)
    },
    reset: function (newr, oldr) {
      if (this.reset) {
        this.newRoleName = ''
        this.newRoleExp = ''
      }
    }
  }
}
</script>

<style scoped>

.round-button-wrapper-right {
  margin-bottom: 2px;
  margin-top: 11px;
}
.round-button-wrapper-right .round-button {
  margin-left:0;
  margin-right:0;
}

.experience .sub-label {
  font-size:0.8em;
  margin-top:16;
  margin-bottom:6px;
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}

.experience .other-expand {
  font-size: 20px;
  position: absolute;
  margin-left: 12px;
  cursor:pointer;
}

.experience .exp-tree-branch {
  height:20px;
  width:8px;
  border-left: 1px solid #828282;
  border-bottom: 1px solid #828282;
  margin-top:-16px;
  margin-left:6px
}

.experience .mdc-card-text {
  padding:0;
}

.experience.negative-margin {
  margin-left: -24px;
  margin-right: -24px;
}

.experience.negative-margin li, .experience.negative-margin .add-row {
  padding-left: 20px;
  padding-right: 20px;
}

.experience.negative-margin li.mdc-list-item {
  padding-left: 32px;
  padding-right: 20px;
}

@media (max-width: 840px) {
  .experience.negative-margin {
    margin-left: -16px;
    margin-right: -16px;
  }
  .experience.negative-margin li, .experience.negative-margin .add-row {
    padding-left: 12px;
    padding-right: 12px;
  }
  .experience.negative-margin li.mdc-list-item {
    padding-left: 24px;
    padding-right: 12px;
  }
}

.experience {
  min-width:230px;
}
.experience .bar {
  position:absolute;
}
.experience .flex-row {
  display: flex;
  flex-flow: row wrap;
  margin-top:16px;
}
.experience .flex-left {
  flex: 1;
}
.exp-bar-label {
  position: absolute;
  top:3px;
  right:12px;
  background-color:transparent;
  font-size: 12px;
  font-weight: 500;
  padding:4px 6px 4px 6px;
  height:calc(100% - 16px);
}
.exp-bar-role {
  z-index: 9;
  padding-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 80px)
}

.exp-bar-wrapper {
  overflow: hidden;
}
.exp-bar {
  overflow: hidden;
}

.exp-bar-wrapper-detail {
  background-color:white;
  flex:1;
  overflow:hidden;
  border-radius: 3px;
  position: relative;
  display:flex;
}
.exp-bar-detail {
  flex:1;
  border-radius:3px;
  padding:4px 0px 0px 6px;
  margin:0;
  width:auto;
  font-weight: 500;
  display: flex;
  align-items: center;
  height:31px;
  color:#828282;
  position: relative;
  margin-right: 6px;
}
.exp-bar-detail .exp-bar-label {
  padding-right:0;
}

.exp-bar-wrapper-other {
  background-color:#BDBDBD;
  flex:1;
  overflow:hidden;
  border-radius: 3px;
  position: relative;
  display:flex;
}
.exp-bar-other {
  flex:1;
  border-radius:3px;
  padding:5px 6px 6px 0px;
  margin:0;
  width:auto;
  font-weight: 500;
  display: flex;
  align-items: center;
  color:white;
}
.exp-bar-other .bar {
  height:31px;
  background-color:#828282;
  border-radius: 3px;
}
.exp-bar-other > span {
  white-space: nowrap;
}

.exp-bar-wrapper-full {
  flex:1;
  overflow:hidden;
  border-radius: 3px;
  position: relative;
  display:flex;
}
.exp-bar-full {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  width:auto;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-full .bar {
  width:100% !important;
  height:31px;
  background-color:#C10457;
  border-radius: 3px;
}
.exp-bar-full > span {
  white-space: nowrap;
}
.exp-bar-wrapper-med {
  flex:1;
  background-color:#BDBDBD;
  border-radius: 3px;
  position: relative;
  display:flex;
}
.exp-bar-med {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-med .bar {
  width:60%;
  height:31px;
  background-color:#FF6C00;
  border-radius: 3px;
}
.exp-bar-med > span {
  white-space: nowrap;
}
.exp-bar-wrapper-small {
  flex:1;
  background-color:#BDBDBD;
  border-radius: 3px;
  position: relative;
  display:flex;
}
.exp-bar-small {
  flex:1;
  color:white;
  padding:5px 6px 6px 0px;
  margin:0;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.exp-bar-small .bar {
  width:30%;
  background-color:#27BCB5;
  border-radius: 3px;
  height:31px;
}
.exp-bar-small > span {
  white-space: nowrap;
}

.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  margin-right:12px;
}
.education .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.education {
  margin-bottom:16px;
}

.round-button-wrapper-right {
  margin-bottom: 2px;
  margin-top: 11px;
  margin-right:0;
}

@media (max-width: 479px) {
  .flex-row .flex-left {
    min-width:100%;
    margin-right:0 !important;
  }
}
</style>
<style>
.experience .mdc-switch-label {
  padding-left:12px;
}
.experience .mdc-list-item__meta {
  z-index:1;
}
.experience .mdc-list-item {
  padding-top:6px;
  padding-bottom:6px;
  padding-right:0px;
}
.experience .end-detail .round-button {
  margin-right: 6px !important;
}
</style>
