<template>
  <div>
  <portal to="destination" :disabled="!(focused && isMobile && mobileOverlay)">

  <round-button style="position:fixed; top:0;left:0" v-if="focused && isMobile && mobileOverlay" noHover icon="close" background="transparent" @click="closePortal"></round-button>

  <mdc-body v-if="prompt" typo="body1" class="padded-label" style="margin-top:0;" :class="{prompt: promptMobileOverlay}">{{prompt}}</mdc-body>
  <mdc-body v-if="promptMobileOverlay" typo="body1" class="padded-label prompt-mobile-overlay">{{promptMobileOverlay}}</mdc-body>

  <div class="mdc-textfield-wrapper autocomplete" :class="{required: required}" ref="inputtop">
    <div class="autocomplete mdc-text-field mdc-text-field--upgraded outlined mdc-text-field--outlined"
      :class="{'mdc-text-field--focused': focused, 'mdc-text-field--disabled': disabled, 'scroll-focus': scrollFocus, 'mdc-text-field--invalid': !valid }"
      aria-haspopup="listbox"
      aria-owns="autocomplete-results"
      ref="inputwrap"
      :aria-expanded="isOpen"
    >

      <slot name="leading-icon"></slot>

      <input
        type="text"
        :id="'bk-' + id"
        class="mdc-text-field__input autocomplete-input background"
        v-model="searchCompleted"
        @keyup.prevent
        disabled="disabled"
        tabindex="-1"
      />
      <input
        type="text"
        class="mdc-text-field__input autocomplete-input"
        ref="input"
        :name="'autocomplete' + Date.now()"
        :id="id"
        @input="onChange"
        @keyup="$emit('keyup', $event)"
        :value="search"
        autocomplete="new-password"
        autofill="off"
        :tabindex="tabindex"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.esc.prevent="onEnter"
        @focus="focus"
        role="searchbox"
        v-bind="$attrs"
        :required="required"
        :maxlength="$attrs.maxlength"
        aria-autocomplete="list"
        aria-controls="autocomplete-results"
        :aria-activedescendant="activedescendant"
        :disabled="disabled"
      />
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="mdc-notched-outline__notch">
          <label :for="id" class="mdc-floating-label" :class="{ 'mdc-floating-label--float-above': search, 'mdc-floating-label--required': this.required }" >
            {{label}}
            <i v-if="tooltip" @click="focused = !focused" class="material-icons tooltip-icon">help_outline</i>
          </label>
        </div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>

      <ul
        :id="id + '-results'"
        v-show="isOpen && results.length > 0"
        class="autocomplete-results"
        role="listbox"
        @mouseover="overResults = true"
        @mouseleave="overResults = false"
      >
        <li
          class="loading"
          v-if="isLoading"
        >
          Loading results...
        </li>
        <li
          v-else
          v-for="(result, i) in results"
          :key="i"
          @click.prevent.stop="setResult($event, result, i)"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
          :id="getId(i)"
          role="option"
          :aria-selected="isSelected(i)"
        >
          <div>
            <span v-if="format === 'text'">
              {{ result[field] }}
            </span>
            <span class="autocomplete-option" v-else-if="format === 'image-text'">
              <img style="result-icon" :src="result[imagefield]"> {{result[field]}}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <div class="mdc-text-field-helper-line">
    <mdc-body typo="body2" v-if="helptext" class="sub-label" :class="{prompt: helptextMobileOverlay}">{{helptext}}</mdc-body>
    <mdc-body typo="body2" v-if="helptextMobileOverlay" class="sub-label prompt-mobile-overlay">{{helptextMobileOverlay}}</mdc-body>
    <span style="margin-left:auto" ref="counter"><slot v-if="hasCounter" name="counter"/></span>
    </div>

    <div v-if="tooltip" class="mdc-text-field-helper-text tooltip">{{tooltip}}</div>

    <div v-if="focused && isMobile && mobileOverlay" style="display:flex; width:100%; position:relative;left:0;z-index:-1;">
      <round-button :disabled="!prev" class="prev" noHover style="margin-right:auto"  @click="triggerPrev" hasSlot icon="arrow_back">
        <span>Previous</span>
      </round-button>
      <round-button v-if="!finish" :disabled="!next" class="next" noHover style="margin-left:auto"  @click="triggerNext" hasSlot trailingIcon="arrow_forward">
        <span>Next</span>
      </round-button>
      <round-button v-if="finish" class="next" noHover style="margin-left:auto"  @click="closePortalFinish" hasSlot icon="done">
        <span>Done</span>
      </round-button>
    </div>

    <span v-if="!mobileOverlay" ref="inputbottom" class="input-bottom"></span>
  </div>
  </portal>
  </div>
</template>

<script>
import scrollIntoView from 'scroll-into-view-if-needed'

function setCaretPosition (elemId, pos) {
  const elem = document.getElementById(elemId)
  if (elem != null) {
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos)
    } else if (elem.createTextRange) {
      const range = elem.createTextRange()
      range.collapse(true)
      range.moveEnd('character', pos)
      range.moveStart('character', pos)
      range.select()
    }
  }
}

export default {
  name: 'autocomplete-outline',
  props: {
    autocompleteId: {
      type: String,
      required: true
    },
    index: {
      required: false,
      default: null
    },
    field: {
      type: String,
      required: true
    },
    value: {
    },
    scrollFocus: {
      type: Boolean,
      default: true
    },
    min: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    helptext: {
      type: String,
      required: false
    },
    imagefield: {
      type: String,
      required: false
    },
    format: {
      type: String,
      required: false,
      default: 'text'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    },
    ariaLabelledBy: {
      type: String,
      required: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mobileOverlay: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: String
    },
    promptMobileOverlay: {
      type: String
    },
    helptextMobileOverlay: {
      type: String
    },
    tabindex: {
    },
    next: {
    },
    prev: {
    },
    finish: {
    },
    onEnterSelectTop: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      id: '',
      isOpen: false,
      results: [],
      search: '',
      searchCompleted: '',
      isLoading: false,
      arrowCounter: -1,
      activedescendant: '',
      focused: false,
      timeout: null,
      overResults: false,
      inputLength: 0,
      refocus: false
    }
  },

  methods: {
    getRndId () {
      this.id = 'autocomplete' + Math.floor(100000 + Math.random() * 900000)
    },
    triggerNext () {
      // console.log('AutocompleteOutline.TriggerNext', this.next)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.next)
    },
    triggerPrev () {
      // console.log('AutocompleteOutline.TriggerPrev', this.prev)
      this.refocus = false
      this.focused = false
      this.$emit('focusOn', this.prev, 'previous')
    },
    updateBackInputPos ($event) {
      const setBackPos = false
      if (setBackPos) {
        const backid = 'bk-' + this.id
        const caratPos = $event.target.selectionStart
        setCaretPosition(backid, caratPos)
      }
    },
    onChange ($event) {
      const self = this
      this.search = $event.target.value
      this.inputLength = $event.target.value.length || '0'
      this.updateBackInputPos($event)
      self.$nextTick(() => {
        self.filterResults()
        self.isOpen = true
        self.$emit('input', this.search)
        self.updateSearchComplete()
      })
      this.updateCounter()
    },
    updateCounter () {
      if (this.hasCounter()) {
        if (this.$refs.counter) {
          const counterEl = this.$refs.counter.querySelector('.mdc-text-field-character-counter')
          if (counterEl) {
            if (!this.inputLength) {
              this.inputLength = '0'
            }
            if (!this.$attrs.maxlength) {
              counterEl.textContent = this.inputLength
            } else {
              counterEl.textContent = `${this.inputLength} / ${this.$attrs.maxlength}`
            }
          }
        }
      }
    },
    filterResults () {
      if (this.search !== null) {
        this.results = []
      }
      if (this.items) {
        if (this.search.length >= this.min) {
          this.results = this.items.filter((item) => {
            const a = item[this.field] || null
            const b = this.search || ''
            if (a) {
              if (b) {
                const aParts = a.split(' / ')
                let inItem = false
                for (const part of aParts) {
                  inItem = inItem || part.toLowerCase().indexOf(b.toLowerCase()) === 0
                }
                return inItem
              }
            }
            return true
          })
        }
        this.results.sort((a, b) => a[this.field].localeCompare(b[this.field]))
      }
    },
    getResult () {
      if (this.search === '') {
        return null
      }
      if (this.arrowCounter < 0 && this.results.length >= 1) {
        this.arrowCounter = -1
      }
      if (this.results.length >= 1) {
        return this.results[this.arrowCounter]
      }
      return null
    },
    setResult (event, result, idx) {
      // console.log('AutocompleteOutline.setResult', event, result, idx)
      this.arrowCounter = idx
      this.search = result[this.field]
      const searchVal = {}
      searchVal[this.field] = result[this.field]
      searchVal.index = this.arrowCounter
      if (this.index !== null) {
        this.$emit('set', searchVal, result, this.index, this.arrowCounter)
      } else {
        this.$emit('set', searchVal, result)
      }
      if (this.search) {
        this.inputLength = this.search.length || '0'
      }
      this.updateCounter()
      this.isOpen = false
      this.filterResults()
      this.updateSearchComplete()
      this.arrowCounter = -1
      if (!(this.focused && this.isMobile && this.mobileOverlay)) {
        this.blur()
      }
    },
    onArrowDown (evt) {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
        this.setActiveDescendent()
        this.updateSearchComplete()
      }
    },
    onArrowUp () {
      if (this.arrowCounter > -1) {
        this.arrowCounter = this.arrowCounter - 1
        this.setActiveDescendent()
        this.updateSearchComplete()
      }
    },
    onEnter () {
      const self = this
      console.log('AutocompleteOutline.onEnter')
      self.$nextTick(() => {
        const selectedVal = this.getResult()
        if (selectedVal && (this.onEnterSelectTop || this.arrowCounter >= 0)) {
          const searchVal = {}
          searchVal[this.field] = selectedVal[this.field]
          searchVal.index = this.arrowCounter
          this.search = selectedVal[this.field]
          if (this.index !== null) {
            this.$emit('set', searchVal, selectedVal, this.index)
          } else {
            this.$emit('set', searchVal, selectedVal)
          }
        } else {
          const searchVal = {}
          searchVal[this.field] = this.search
          if (this.index !== null) {
            this.$emit('set', searchVal, null, this.index)
          } else {
            this.$emit('set', searchVal, null)
          }
        }
        this.isOpen = false
        this.arrowCounter = -1
        this.filterResults()
        this.updateSearchComplete()
        if (this.search) {
          this.inputLength = this.search.length || '0'
        }
        this.updateCounter()
        if (!(this.focused && this.isMobile && this.mobileOverlay)) {
          this.blur()
        }
      })
    },
    closePortal () {
      this.$emit('blur')
      this.$root.$children[0].$refs.view.$emit('inputBlurred')
      this.focused = false
      this.refocus = false
    },
    closePortalFinish () {
      this.closePortal()
      this.$emit('finish')
    },
    handleClickOutside (evt) {
      if (this.focused) {
        // console.log('AutoCompleteOutline.handleClickOutside', this.label, evt.target, this.isMobile, this.mobileOverlay, this.refocus, !this.$el.contains(evt.target))
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
          if (!(this.isMobile && this.mobileOverlay)) {
            this.blur()
          } else {
            this.refocus = true
            const elId = this.id
            // console.log('AutocompleteOutline.focus - setting refocus', this)
            setTimeout(function () {
              // console.log('AutocompleteOutline.focus - refocus', elId)
              const elementInput = document.getElementById(elId)
              if (elementInput) {
                elementInput.focus()
                elementInput.selectionStart = elementInput.selectionEnd = 10000
              }
            }, 300)
          }
        }
      }
    },
    setActiveDescendent () {
      this.activedescendant = this.getId(this.arrowCounter)
      const list = document.getElementById(`${this.id}-results`)
      const targetLi = document.getElementById(this.activedescendant)
      if (targetLi) {
        list.scrollTop = (targetLi.offsetTop - 50)
      }
    },
    getId (index) {
      return `${this.id}-result-item-${index}`
    },
    isSelected (i) {
      return i === this.arrowCounter
    },
    focus () {
      // console.log('AutoCompleteOutline.Focused', this.label, this.isMobile, this.mobileOverlay, this.refocus)
      this.$emit('focus')
      this.focused = true
      if (this.isMobile && this.mobileOverlay) {
        // Mobile overlay refocus
        if (!this.refocus) {
          this.refocus = true
          this.$root.$children[0].$refs.view.$emit('inputFocused')
          // console.log('AutocompleteOutline.focus - setting refocus')
          const self = this
          setTimeout(function () {
            // console.log('AutocompleteOutline.focus - refocus', self.$refs['input'])
            if (self.$refs.input) {
              self.$refs.input.focus()
            }
          }, 400)
        } else {
          this.refocus = false
        }
        const self = this
        setTimeout(
          function () {
            self.updateCounter()
          }, 300)
      }

      if (!this.mobileOverlay) {
        // Old scroll method
        if (this.scrollFocus) {
          const self = this
          setTimeout(
            function () {
              if (window.innerWidth < 570) {
                self.$nextTick(() => {
                  try {
                    console.log('Autocomplete.scrollend', self.$refs.inputbottom)
                    // initial scroll if needed up to fit options
                    scrollIntoView(
                      self.$refs.inputbottom,
                      {
                        behavior: 'instant',
                        block: 'end',
                        scrollMode: 'if-needed',
                        skipOverflowHiddenElements: true
                      }
                    )
                    setTimeout(() => {
                      console.log('Autocomplete.scrollstart', self.$refs.inputtop)
                      // scroll down to make sure input visible at top if needed
                      scrollIntoView(
                        self.$refs.inputtop,
                        {
                          behavior: 'instant',
                          block: 'start',
                          scrollMode: 'if-needed',
                          skipOverflowHiddenElements: true
                        }
                      )
                    }, 200)
                  } catch (error) {
                    console.log('AutocompleteOutline.scrollError', error)
                  }
                })
              }
            }, 200)
        }
      }
    },
    blur (event) {
      if (!this.refocus) {
        const self = this
        if (this.isMobile && this.mobileOverlay) {
          setTimeout(function () {
            self.$emit('blur')
            self.$root.$children[0].$refs.view.$emit('inputBlurred')
            self.focused = false
            setTimeout(
              function () {
                self.updateCounter()
              }, 300)
          }, 300)
        } else {
          self.$root.$children[0].$refs.view.$emit('inputBlurred')
          self.focused = false
          self.$emit('blur')
        }
      } else {
        const self = this
        setTimeout(function () {
          // console.log('AutocompleteOutline.blur - refocus', self.$refs['input'])
          if (self.$refs.input) {
            self.$refs.input.focus()
          }
        }, 400)
      }
    },
    updateSearchComplete () {
      this.searchCompleted = ''
      if (this.arrowCounter >= 0) {
        const result = this.getResult()
        if (result) {
          if (this.search) {
            this.searchCompleted = this.search + result[this.field].substring(this.search.length)
          }
        }
      } else {
        if (this.search) {
          if (this.results.length > 0) {
            this.searchCompleted = this.search + this.results[0][this.field].substring(this.search.length)
          }
        } else {
          this.searchCompleted = ''
        }
      }
    },
    hasCounter () {
      if (this.$slots.counter) return true
      return false
    }
  },
  watch: {
    items: function (val, oldValue) {
      // actually compare them
      if (val) {
        let oldlen = 0
        if (oldValue) {
          oldlen = oldValue.length
        }
        if (val.length !== oldlen) {
          this.results = val
          this.isLoading = false
        }
      }
      this.filterResults()
    },
    isMobile: function () {
      if (this.focused && this.mobileOverlay) {
        this.refocus = false
        this.focus()
      }
    },
    isOpen: function () {
      if (this.isOpen) {
        if (!this.mobileOverlay) {
          console.log('AutocompleteOutline.isOpen', this.isOpen)
          try {
            const self = this
            console.log('Autocomplete.scrollend', self.$refs.inputbottom)
            // initial scroll if needed up to fit options
            scrollIntoView(
              self.$refs.inputbottom,
              {
                behavior: 'instant',
                block: 'end',
                scrollMode: 'if-needed',
                skipOverflowHiddenElements: true
              }
            )
            setTimeout(() => {
              console.log('Autocomplete.scrollstart', self.$refs.inputtop)
              // scroll down to make sure input visible at top if needed
              scrollIntoView(
                self.$refs.inputtop,
                {
                  behavior: 'instant',
                  block: 'start',
                  scrollMode: 'if-needed',
                  skipOverflowHiddenElements: true
                }
              )
            }, 200)
          } catch (error) {
            console.log('AutocompleteOutline.scrollError', error)
          }
        }
      }
    },
    value: function (val, oldValue) {
      if (this.value) {
        this.inputLength = this.value.length || '0'
      }
      this.updateCounter()
      if (val !== null) {
        if (typeof val === 'string' || val instanceof String) {
          this.search = val
          this.searchCompleted = val
        } else {
          if (val) {
            this.search = val[this.field]
            this.searchCompleted = val[this.field]
          }
        }
        this.filterResults()
      }
    }
  },
  mounted () {
    this.getRndId()
    const val = this.value
    if (this.value) {
      this.inputLength = this.value.length || '0'
    }
    this.updateCounter()
    if (val) {
      if (typeof val === 'string' || val instanceof String) {
        this.search = val
      } else {
        this.search = val[this.field]
      }
    }
    this.filterResults()
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped>
.autocomplete {
  position: relative;
  min-width: 120px;
  margin-right:24px;
  width:100%;
  display:flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top:6px !important;
  margin-left:0 !important;
  min-height: 63px;
  height: auto;
}

.round-button.next:not(.disabled), .round-button.prev:not(.disabled) {
  color: var(--mdc-theme-primary, #6200ee);
}

.autocomplete .sub-label {
    font-size: 0.8em;
    margin-bottom: 6px;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
    line-height: 26px;
    margin-top: 0;
}

.autocomplete .result-icon {
  width:18px;
}

.padded-label {
  margin-right:auto;
}

.autocomplete .mdc-text-field-helper-line {
  width:100%;
  padding-left: 0;
  padding-right: 0;
}

.autocomplete.mdc-textfield-wrapper {
  margin-top:2px !important;
}

.autocomplete .tooltip {
  z-index:-1;
}

.autocomplete .autocomplete-input {
    max-width: calc(100% - 24px);
    left: 0;
    top: 0;
    text-overflow: ellipsis;
}

.autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined {
  margin-bottom:0px;
  height:auto;
  min-height:58px;
}

.autocomplete.mdc-text-field.outlined .mdc-text-field__input {
    border: none!important;
    background-color: transparent;
    margin:0px;
    margin-top:2px;
    height:54px;
    margin-left: 16px;
}

.autocomplete.mdc-text-field.outlined.mdc-text-field--with-leading-icon .mdc-text-field__input {
  padding-left: 48px;
  width: calc(100% - 48px);
}

.autocomplete.mdc-text-field.outlined .mdc-text-field__input:not(.background) {
    z-index:1;
    position: absolute;
}

.autocomplete .mdc-textfield .mdc-notched-outline {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
    opacity: 1;
    max-height:58px;
}

.mdc-notched-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    overflow: visible;
    display: flex;
    max-height:58px;
}

.mdc-text-field--outlined .mdc-floating-label {
  margin-top:1px;
}

.autocomplete .mdc-notched-outline .mdc-floating-label--float-above, .autocomplete .mdc-notched-outline.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    bottom:16px;
    left:0px;
    background:white;
    padding-left:6px;
    padding-right:6px;
}
.autocomplete.required .mdc-notched-outline .mdc-floating-label--float-above, .autocomplete .mdc-notched-outline.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    padding-right:12px;
}

.mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field.outlined.mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__leading {
    border-color: rgba(0, 0, 0, 0.06) !important;
}
.mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}
.mdc-text-field.outlined.mdc-text-field--disabled .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.06) !important;
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch {
    border-color: rgba(0, 0, 0, 0.06) !important;
}

.mdc-text-field--outlined .mdc-notched-outline__notch {
    border-right: none !important;
}

.mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0,0,0,.24);
}
.mdc-notched-outline__leading {
    border-left: 1px solid;
    border-right: none;
    width: 12px;
}

.mdc-notched-outline__notch {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 12px * 2);
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    -webkit-transition: border .15s cubic-bezier(.4,0,.2,1);
    -o-transition: border .15s cubic-bezier(.4,0,.2,1);
    transition: border .15s cubic-bezier(.4,0,.2,1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none;
}

.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020 !important;
}

.mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
}

.mdc-text-field .mdc-floating-label {
  overflow:hidden !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
  border-color: #2D9CDB;
  border-color: var(--mdc-theme-primary, #2D9CDB);
}

.mdc-notched-outline__leading[dir=rtl], .mdc-notched-outline__trailing, [dir=rtl] .mdc-notched-outline__leading {
  border-left: none;
  border-right: 1px solid;
  flex-grow: 1;
}

.mdc-floating-label--float-above i.required:hover::after {
  font-size: 18px;
  left: 6px;
  padding:6px;
}

.autocomplete .autocomplete-label .material-icons.tooltip-icon {
  font-size: 16px;
  position:relative;
  top: 4px;
  cursor: pointer;
}

.autocomplete .autocomplete-label {
  padding:6px;
}

.autocomplete .mdc-textfield.mdc-text-field.mdc-text-field--upgraded {
  align-items: start;
}

.autocomplete .mdc-text-field__input.background {
  position:absolute;
  left:0;
  top:0;
  border-bottom:none !important;
  z-index: -1;
  opacity:1;
  color:#AAA;
}

.autocomplete-results {
  position:relative;
  top:-6px;
  left:1px;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 24px;
  max-height:200px;
  overflow: auto;
  min-width: 200px;
  background-color:white;
  margin-top: 64px;
  z-index:5;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-left: -16px;
  width: calc(100% + 30px);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 12px;
  cursor: pointer;
  background-color:white;
  border-left: 3px solid transparent;
}

.autocomplete-option {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    flex-flow: row nowrap;
}

.autocomplete-option img {
  margin-right:16px;
  width:18px;
}

.autocomplete-result.is-active {
  background-color: #F5F5F5;
  border-left: 3px solid var(--mdc-theme-primary, #2D9CDB);
  color: #333;
}
.autocomplete-result:hover {
  background-color: #F5F5F5 !important;
  border-left: 3px solid var(--mdc-theme-primary, #2D9CDB) !important;
  color: #333;
}
.autocomplete-results:hover .autocomplete-result.is-active {
  background-color:white;
  border-left: 3px solid transparent;
}

.input-bottom {
  top: 190px !important;
  height: 1px;
  width: 100%;
  opacity: 0;
  z-index: -1;
}

.mdc-textfield-wrapper{
  z-index:9;
  position:relative;
}
@media all and (max-width: 570px), all and (max-height: 570px){
  .autocomplete-results {
    min-height: 24px;
    max-height:100px;
  }
  .input-bottom {
    position:absolute;
    margin-top:75px;
  }
}
</style>
