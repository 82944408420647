<template>
  <div class="main-content">
    <div v-if="profile" class="main-wrapper settings">

      <mdc-layout-grid >
        <mdc-layout-cell span="12">

          <app-section class="settings-section"
            style="margin-top:64px;"
            :editable="false"
            title="Settings"
            >

          <h3>Personal Information</h3>
          <div  class="profile-section">
            <h4>Registered Email</h4>
            <div class="mdc-textfield-wrapper">
              <div class="mdc-textfield">{{profile.email}}</div>
            </div>
          </div>

          <div  class="profile-section">
            <h4>First Name</h4>
            <div class="mdc-textfield-wrapper">
              <div class="mdc-textfield">{{profile.firstName}}</div>
            </div>
          </div>

          <div  class="profile-section">
            <h4>Last Name</h4>
            <div class="mdc-textfield-wrapper">
              <div class="mdc-textfield">{{profile.lastName}}</div>
            </div>
          </div>

          <mdc-body typo="body2">If you would like to change this data or request a copy of all the data we hold about you,
            please email support for assistance, Email: <a class="link" target="_blank" href="mailto:support@duome.co">support@duome.co</a></mdc-body>
          <hr style="margin-bottom:32px"/>

          <h3>Notifications</h3>

          <div class="profile-section">
            <div class="toggle-row">
              <h4>Messages</h4>
              <mdc-switch @change="saveNotifications" v-model="profileNotifications" style="z-index:9;display:flex">
                <span style="padding-left:24px;" v-if="profileNotifications">
                  On
                </span>
                <span style="padding-left:24px;" v-else>
                  Off
                </span>
              </mdc-switch>
            </div>
            <mdc-body typo="body2">Receive email notification of messages sent to you via the platform from potential partners or employers</mdc-body>
            <mdc-body style="color:#828282;" v-if="!profileNotifications && profile.notificationsUnsubscribeDate" typo="body2">This was turned off by clicking unsubscribe in an email on {{datetimeFormat(profile.notificationsUnsubscribeDate.toDate())}}</mdc-body>
          </div>

          <div class="profile-section">
            <div class="toggle-row">
              <h4>Alerts</h4>
              <mdc-switch @change="saveAlerts" v-model="profileAlerts" style="z-index:9;display:flex">
                <span style="padding-left:24px;" v-if="profileAlerts">
                  On
                </span>
                <span style="padding-left:24px;" v-else>
                  Off
                </span>
              </mdc-switch>
            </div>
            <mdc-body typo="body2">Receive alerts when new potential Job Share partners or jobs are added to the platform</mdc-body>
            <mdc-body style="color:#828282;" v-if="!profileAlerts && profile.alertsUnsubscribeDate" typo="body2">This was turned off by clicking unsubscribe in an email on {{datetimeFormat(profile.alertsUnsubscribeDate.toDate())}}</mdc-body>
          </div>

          <div class="profile-section">
            <div class="toggle-row">
              <h4>Awareness</h4>
              <mdc-switch @change="saveAwareness" v-model="customerIo" style="z-index:9;display:flex">
                <span style="padding-left:24px;" v-if="customerIo">
                  On
                </span>
                <span style="padding-left:24px;" v-else>
                  Off
                </span>
              </mdc-switch>
            </div>
            <mdc-body typo="body2">Recieve awareness emails about how to use and get the most from the platform</mdc-body>
            <mdc-body style="color:#828282;" v-if="!customerIo && profile.customerIOUnsubscribeDate" typo="body2">This was turned off by clicking unsubscribe in an email on {{datetimeFormat(profile.customerIOUnsubscribeDate.toDate())}}</mdc-body>
          </div>

          <hr style="margin-bottom:32px"/>

          <div class="profile-section">
            <h4>Consent</h4>
            <mdc-body v-if="profile.consentTimeStamp" typo="body2">User Consent was provided by accepting terms upon signup on <b>{{datetimeFormat(profile.consentTimeStamp)}}.</b>
            You can remove consent at anytime by contacting support and requesting your account be deleted, Email: <a class="link" target="_blank" href="mailto:support@duome.co">support@duome.co</a></mdc-body>
          </div>

          <div class="profile-section">
            <div style="display:flex;justify-content:flex-start;align-items:center;">
              <h4 style="flex:1">Usage Data</h4>
              <round-button
                hasSlot
                style="padding-left:16px;text-transform:none;margin-top:0;margin-bottom:0;"
                medium
                onclick="event.stopPropagation(); window.consentManager.openConsentManager()"
              >
                Cookie Preferences
              </round-button>
            </div>
            <mdc-body style="flex:1" typo="body2">Usage data is collected to help improve the performance and security of the web site</mdc-body>

          </div>
          </app-section>
        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { datetimeFormatFull } from '../utils/time'
import AppSection from '../components/profile-v2/AppSection.vue'
import * as backend from '../utils/backend'
export default {
  name: 'settings',
  data () {
    return {
      title: 'settings',
      links: [
      ],
      profileChemistry: true,
      customerIo: true,
      profilePublic: false,
      profileVisible: true,
      profileNotifications: true,
      profileAlerts: true,
      publicProfile: 'On'
    }
  },
  components: {
    AppSection
  },
  computed: {
    ...mapState([
      'user',
      'isBranded',
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setPageLoad',
      'updateProfile',
      'setSnackMessage'
    ]),
    datetimeFormat (ts) {
      if (ts) {
        return datetimeFormatFull(ts)
      }
      return ''
    },
    saveAlerts () {
      this.profile.alertsUnsubscribeDate = null
      this.saveProfile()
    },
    saveAwareness () {
      this.profile.customerIOUnsubscribeDate = null
      this.saveProfile()
    },
    saveNotifications () {
      this.profile.notificationsUnsubscribeDate = null
      this.saveProfile()
    },
    async confirmCookies (prefs) {
      const cookiesTimeStamp = new Date().toISOString()
      return this.updateProfile({ profile: { cookiesTimeStamp }, noSnack: true }).then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 500)
        return true
      })
    },
    async saveProfile () {
      this.profile.customerIo = this.customerIo
      await backend.search('cio-unsubscribe', {
        data: {
          id: this.user.uid,
          unsubscribed: !this.customerIo
        }
      })
      this.profile.profileVisible = this.profileVisible
      this.profile.profileNotifications = this.profileNotifications
      this.profile.profileAlerts = this.profileAlerts
      this.profile.profileChemistry = this.profileChemistry
      this.updateProfile({ profile: this.profile })
    },
    setProfile () {
      if (this.profile.customerIo !== undefined) {
        this.customerIo = this.profile.customerIo
      }
      if (this.profile.profileVisible !== undefined) {
        this.profileVisible = this.profile.profileVisible
      }
      if (this.profile.profileChemistry !== undefined) {
        this.profileChemistry = this.profile.profileChemistry
      }
      if (this.profile.companyId) {
        if (this.profile.profilePublic) {
          this.profile.profilePublic = false
          this.updateProfile({ profile: this.profile, noSnack: true })
        }
        this.publicProfile = 'Off'
      } else if (this.profile.profilePublic) {
        if (this.profile.profilePublicLimited) {
          this.publicProfile = 'Limited'
        } else {
          this.publicProfile = 'On'
        }
      } else {
        this.publicProfile = 'Off'
      }
      if (this.profile.profileNotifications !== undefined) {
        this.profileNotifications = this.profile.profileNotifications
      }
      if (this.profile.profileAlerts !== undefined) {
        this.profileAlerts = this.profile.profileAlerts
      }
      // console.log('Settings.setProfile', this.profile.companyId, this.profile.profilePublic, this.profile.profilePublicLimited, this.publicProfile)
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
  },
  mounted () {
    const self = this
    this.$emit('setLinks', this.links)
    setTimeout(() => {
      this.setPageLoad(false)
      if (window.consentManager) {
        const prefs = window.consentManager.preferences
        // console.log('window.consentManager.preferences', prefs)
        prefs.onPreferencesSaved(self.confirmCookies)
      }
    }, 600)
    this.$root.$emit('enableSearch', true, { context: 'settings' }, { })
  },
  watch: {
    profile: {
      handler: function (val, oldVal) {
        if (!oldVal) {
          if (val) {
            this.setProfile()
          }
        }
      },
      immediate: true
    },
    publicProfile: {
      handler: function (val, oldVal) {
        if (val === 'On') {
          this.profile.profilePublic = true
          this.profile.profilePublicLimited = false
        } else if (val === 'Limited') {
          this.profile.profilePublic = true
          this.profile.profilePublicLimited = true
        } else {
          this.profile.profilePublic = false
          this.profile.profilePublicLimited = false
        }
        this.saveProfile()
      }
    }
  }
}
</script>

<style>
.settings .disabled {
  color: #bbb !important;
}
.settings .disabled .mdc-radio-wrapper label {
  color: #bbb !important;
}

.settings .profile-section {
  margin-bottom: 16px;
  margin-top:32px;
}
.settings h3 {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin-bottom:12px;
}
.settings h4 {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin-bottom:6px;
}
.settings .mdc-body.mdc-typography--body2 {
  color: rgba(0,0,0,.6);
  margin-top:0;
}
.settings .mdc-radio-wrapper {
  border:1px solid silver;
  border-radius:20px;
  padding-right:20px;
  white-space: nowrap;
  margin-top:16px;
  margin-bottom:16px;
  margin-right:16px;
}
.settings .toggle-row {
  display:flex;
  width:100%;
  justify-content: center;
}
.settings .toggle-row h4 {
  flex:1;
  margin-bottom:12px;
  margin-top:0px;
}
.settings .toggle-row .mdc-switch {
  margin-bottom:0px;
  margin-top:0px;
  order:2;
  margin-right:16px;
}
.settings .toggle-row label {
  margin-right:16px;
  font-weight:600;
  order:1;
}
.settings .settings-section {
  padding-top: 2px;
}
</style>
