<template>
  <div class="main-content">
    <div class="main-wrapper">
      <mdc-body>
        <div class="flexrow">
          <div class="flex-left">
            <datepicker :highlighted="highlighted" v-model="datepicked" :inline="true" @input="dateSelected"></datepicker>
          </div>
          <div class="flex-right">
            <mdc-card>
              <mdc-card-header
                title="Available Times"
                subtitle="Add meeting times here" >
              </mdc-card-header>
              <mdc-card-text>
                <div v-for="(dateRange, index) in selectedDates"
                  :key="index"
                  >
                  <mdc-title>
                    <span>{{dateRange.fromFormatted}}</span>
                    <span>- {{dateRange.toFormatted}}</span>
                    <mdc-card-actions style="float:right">
                      <mdc-card-action-icons>
                        <mdc-card-action-icon @click="removeDateRange(index)" icon="clear" />
                      </mdc-card-action-icons>
                    </mdc-card-actions>
                  </mdc-title>
                  <div v-if="dateRange.dayview">
                    <div>
                      <div v-for="(day, index) in dateRange.days" :key="index">
                        <span style="width:120px;display:inline-block;">{{day.dayFormatted}}</span>
                        <mdc-select v-model="day.startTime" label="start time">
                          <select-item v-for="(timeslot, index) in timeslots" v-bind:key="index" :value="timeslot" />
                        </mdc-select>
                        <mdc-select v-model="day.endTime" label="end time">
                          <select-item v-for="(timeslot, index) in timeslots" v-bind:key="index" :value="timeslot" />
                        </mdc-select>
                      </div>
                    </div>
                    <mdc-button @click="dateRange.dayview=false">Or set times for all days</mdc-button>
                  </div>
                  <div v-else>
                    <div>
                      <mdc-select v-model="dateRange.startTime" label="start time">
                        <select-item v-for="(timeslot, index) in timeslotsBefore(dateRange.endTime)" :key="index" :value="timeslot" />
                      </mdc-select>
                      <mdc-select v-model="dateRange.endTime" label="end time">
                        <select-item v-for="(timeslot, index) in timeslotsAfter(dateRange.startTime)" :key="index" :value="timeslot" />
                      </mdc-select>
                    </div>
                    <mdc-button @click="dateRange.dayview=true">Or set times for each day</mdc-button>
                  </div>
                </div>
              </mdc-card-text>
            </mdc-card>
          </div>
        </div>

        <mdc-list>
          <mdc-list-item v-for="(date, index) in selectedDateTimes" :key="index">
            <span style="width: 120px">{{date.date}}</span>
            <span>{{date.time}}</span>
          </mdc-list-item>
        </mdc-list>
      </mdc-body>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateExtFormat, datesBetween } from '@/utils/time'

function range (start, end, step) {
  const _end = end || start
  const _start = end ? start : 0
  const _step = step || 1
  return Array((_end - _start) / _step).fill(0).map((v, i) => _start + (i * _step))
}

export default {
  name: 'DatepickerTest',
  data () {
    return {
      links: [],
      datepicked: new Date(),
      dateTitle: '',
      selectedTime: null,
      timeslots: [],
      currentSelectedDate: null,
      selectedDates: [],
      highlighted: {
        customPredictor: this.highlightDate
      },
      selectedDateTimes: []
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ])
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    removeDateRange (indx) {
      if (!this.selectedDates[indx].to) {
        this.currentSelectedDate = null
      }
      this.selectedDates.splice(indx, 1)
    },
    dateSelected () {
      // check not already selected
      if (!this.highlightDate(this.datepicked)) {
        let datepickedFormatted = dateExtFormat(this.datepicked)
        this.dateTitle = dateExtFormat(this.datepicked)

        if (!this.currentSelectedDate) {
          const newDate = {
            dayview: false,
            days: [{ day: this.datepicked, dayFormatted: dateExtFormat(this.datepicked) }],
            from: this.datepicked,
            fromFormatted: datepickedFormatted
          }
          this.selectedDates.push(newDate)
          this.currentSelectedDate = newDate
        } else if (this.currentSelectedDate.to) {
          const newDate = {
            dayview: false,
            days: [{ day: this.datepicked, dayFormatted: dateExtFormat(this.datepicked) }],
            from: this.datepicked,
            fromFormatted: datepickedFormatted
          }
          this.selectedDates.push(newDate)
          this.currentSelectedDate = newDate
        } else {
          if (this.currentSelectedDate.from > this.datepicked) {
            // If the second selected date is less than the first swap to and from
            const to = this.currentSelectedDate.from
            const toFormatted = this.currentSelectedDate.fromFormatted
            const from = this.datepicked
            const fromFormatted = datepickedFormatted
            this.currentSelectedDate.from = from
            this.currentSelectedDate.fromFormatted = fromFormatted
            this.datepicked = to
            datepickedFormatted = toFormatted
          }

          // Check if a date range selected is fully enclosed by the new date range and if so remove it
          this.removeContainedSelectedDates(this.currentSelectedDate.from, this.datepicked)
          this.currentSelectedDate.to = this.datepicked
          this.currentSelectedDate.toFormatted = datepickedFormatted
          this.currentSelectedDate.days = datesBetween(this.currentSelectedDate.from, this.currentSelectedDate.to).map((dt) => { return { day: dt, dayFormatted: dateExtFormat(dt) } })
        }
        this.sortSelectedDates()
      }
    },
    sortSelectedDates () {
      if (this.selectedDates) {
        this.selectedDates.sort(function (obj1, obj2) {
          return obj1.from > obj2.from
        })
      }
    },
    highlightDate (date) {
      const self = this
      let dateHighlighted = false
      if (self.selectedDates) {
        self.selectedDates.forEach((selectedDate) => {
          dateHighlighted = dateHighlighted || ((date >= selectedDate.from) && (date <= selectedDate.to))
        })
      }
      return dateHighlighted
    },
    removeContainedSelectedDates (from, to) {
      // eslint-disable-next-line array-callback-return
      this.selectedDates = this.selectedDates.filter((dateRange) => {
        const inRange = (from < dateRange.from && to > dateRange.to)
        if (!inRange) {
          return dateRange
        }
      })
    },
    onSaveClick () {
      if (this.selectedTime) {
        this.selectedDates.dates.push(this.datepicked)
        this.selectedDateTimes.push({
          date: dateExtFormat(this.datepicked),
          time: this.selectedTime
        })
      }
    },
    timeslotsBefore (before) {
      if (before) {
        return this.timeslots.filter((ts) => {
          return ts < before
        })
      }
      return this.timeslots
    },
    timeslotsAfter (after) {
      if (after) {
        return this.timeslots.filter((ts) => {
          return ts > after
        })
      }
      return this.timeslots
    }
  },
  mounted () {
    this.dateTitle = dateExtFormat(this.datepicked)
    range(8, 20).forEach((h) => {
      range(0, 60, 15).forEach((m) => {
        const hh = (h < 10) ? `0${h}` : h
        const mm = (m < 10) ? `0${m}` : m
        this.timeslots.push(`${hh}:${mm}`)
      })
    })
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdc-card {
  max-height: calc(100vh - 120px);
  min-height: 200px;
}
.mdc-card > .mdc-card-text.mdc-card__supporting-text {
  min-height:142px;
  overflow-y:auto;
}
.mdc-select--box {
  width: 120px;
}
.flexrow {
  display:flex;
  flex-flow:row wrap;
}
.flex-left {
  margin-bottom:12px;
  margin-right:12px;
}
.flex-right {
  flex:1;
  min-width:300px;
  margin-bottom:12px;
  margin-right:12px;
}
</style>
