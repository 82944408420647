<template>
  <div class="main-content">
    <div class="main-wrapper">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>
        <mdc-card v-if="feedbackForm" class="feedback page-card">

          <mdc-card-primary-action>
            <mdc-card-media class="image-cover" src="/static/img/feedback.png">
              <mdc-card-header>
                <mdc-card-action-icon style="color:white" @click="$router.go(-1)" icon="arrow_back_ios" />
                <mdc-card-title>Chemistry Feedback</mdc-card-title>
                <mdc-card-subtitle>Do we fit...</mdc-card-subtitle>
              </mdc-card-header>
            </mdc-card-media>
          </mdc-card-primary-action>

          <mdc-card-actions>
            <mdc-card-action-icons>
              <mdc-card-action-icon @click="confirmModal = true" icon="done" />
            </mdc-card-action-icons>
          </mdc-card-actions>

          <mdc-card-text>
            <section v-for="section  in feedbackForm.public" :key="section.key">
              <mdc-title>{{section.title}}</mdc-title>
              <mdc-body typo="body2">{{publicInfo}}</mdc-body>

              <mdc-tab-bar class="mobile-vertical trafficlight">
                <mdc-tab :active="section.rating === 'Interested to Proceed'"
                @click.native="onSelected('Interested to Proceed', section)">
                  <img src="/static/img/icons/outline_thumb_up.svg"/> Interested to Proceed
                </mdc-tab>
                <mdc-tab :active="section.rating === 'Schedule another meeting'"
                @click.native="onSelected('Schedule another meeting', section)">
                  <img src="/static/img/icons/outline_access_time.svg"/> Schedule another meeting
                </mdc-tab>
                <mdc-tab :active="section.rating === 'Not right now'"
                @click.native="onSelected('Not right now', section)">
                  <img src="/static/img/icons/outline_cancel.svg"/> Not right now
                </mdc-tab>
              </mdc-tab-bar>

              <div
                class="mdc-text-field--textarea"
                style="width:calc(100% - 24px);padding:12px;margin-top:12px;">
                <mdc-body v-if="section.rating === 'Interested to Proceed'" typo="body2">
                  It was great to meet you and I feel like we are a good match to partner for opportunities.
                </mdc-body>
                <mdc-body v-if="section.rating === 'Schedule another meeting'" typo="body2">
                  It was great to talk with you and I'd like to meet again so we can continue the discussion.  If you agree I'll submit some dates to find another time.
                </mdc-body>
                <mdc-body v-if="section.rating === 'Not right now'" typo="body2">
                  Thanks for your time, it was good to meet you.  At the moment I'd like to meet some other potential partners before I make a decision.  I'll come back to you if anything changes.
                </mdc-body>
              </div>

            </section>

            <hr>

            <mdc-title>Detailed Feedback</mdc-title>
            <mdc-body typo="body2">
              It's a good idea to capture your detailed feedback so you can compare candidates and possibly share with a manager or perspective employer.
              It demonstrated a professional and credible process which can help your proposal
            </mdc-body>
            <section v-for="section in feedbackForm.private" :key="section.key">
              <mdc-title>{{section.title}}</mdc-title>
              <mdc-body typo="body2">{{section.info}}</mdc-body>

              <mdc-tab-bar class="feedback-rating">
                <mdc-tab :active="section.rating === '1'" @click.native="onSelected('1', section)">1</mdc-tab>
                <mdc-tab :active="section.rating === '2'" @click.native="onSelected('2', section)">2</mdc-tab>
                <mdc-tab :active="section.rating === '3'" @click.native="onSelected('3', section)">3</mdc-tab>
                <mdc-tab :active="section.rating === '4'" @click.native="onSelected('4', section)">4</mdc-tab>
                <mdc-tab :active="section.rating === '5'" @click.native="onSelected('5', section)">5</mdc-tab>
                <mdc-tab v-if="Object.keys(section.rating_info).includes('NA')" :active="section.rating === 'NA'" @click.native="onSelected('NA', section)" >NA</mdc-tab>
              </mdc-tab-bar>

              <div style="margin-bottom:18px;margin-top:12px;">
                <mdc-title tag="div" style="color:#999;font-size:italic" v-if="section.rating" >{{feedbackForm.rating_title[section.rating]}}</mdc-title>
                <mdc-body typo="body2" style="color:#999;font-size:italic" tag="div" v-if="section.rating">{{section.rating_info[section.rating]}}</mdc-body>
              </div>

              <mdc-text-area-autosize
              style="margin-top: 4px; padding-left:4px;"
              class="mdc-text-field__input message-input section-notes"
              ref="messageInput"
              v-model="section.comment"
              @blur="onNoteFinish"
              rows="2"
              label="Add notes..."
              :minHeight="20"
              :maxHeight="90"
              ></mdc-text-area-autosize>

            </section>

            <mdc-text-area-autosize
              style="margin-top: 4px; padding-left:4px;"
              class="mdc-text-field__input message-input section-notes"
              ref="messageInput"
              v-model="feedbackForm.comment"
              @blur="onNoteFinish"
              rows="2"
              label="Add General notes..."
              :minHeight="20"
              :maxHeight="90"
              ></mdc-text-area-autosize>

          </mdc-card-text>
        </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>

      <mdc-dialog v-model="confirmModal"
        :title="'Confirm Send Feedback'" accept="Send Feedback" cancel="Cancel"
        @accept="sendFeedback"
        >
          <span>You are about the send feedback to {{otherUser().displayName}}</span>

          <div
            class="mdc-text-field--textarea"
            style="width:calc(100% - 24px);padding:12px;margin-top:12px;">
            <mdc-body v-if="feedbackForm.public[0].rating === 'Interested to Proceed'" typo="body2">
              It was great to meet you and I feel like we are a good match to partner for opportunities.
            </mdc-body>
            <mdc-body v-if="feedbackForm.public[0].rating === 'Schedule another meeting'" typo="body2">
              It was great to talk with you and I'd like to meet again so we can continue the discussion.  If you agree I'll submit some dates to find another time.
            </mdc-body>
            <mdc-body v-if="feedbackForm.public[0].rating === 'Not right now'" typo="body2">
              Thanks for your time, it was good to meet you.  At the moment I'd like to meet some other potential partners before I make a decision.  I'll come back to you if anything changes.
            </mdc-body>
          </div>
      </mdc-dialog>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as backend from '../utils/backend'

export default {
  name: 'FeedbackForm',
  data () {
    return {
      title: 'Feedback Form',
      links: [
      ],
      confirmModal: false,
      editing: [],
      connectionId: null,
      connection: null,
      sectionBackup: null,
      feedbackFormActivity: null,
      feedbackFormDefault: {
        title: 'Partner Feedback',
        icon: 'chat',
        private: [
          {
            key: 'experience',
            title: 'Prior Work Experience',
            info: ' Has the candidate acquired necessary skills or qualifications through past work experiences?',
            rating: '',
            rating_info: {
              1: 'Partner is generally unacceptable.',
              2: 'Partner below standards on a core need or requirement.',
              3: 'Partner is competent and dependable. Meets basic standards required.',
              4: 'Partner exceeds some requirements.',
              5: 'Partner is exceptional; easily recognised as far superior to others.',
              NA: 'No feedback'
            },
            comment: ''
          },
          {
            key: 'technical',
            title: 'Technical Qualifications/Skills ',
            info: 'Technical Qualifications/Skills – Does the candidate have the technical or other skills necessary?',
            rating: '',
            rating_info: {
              1: 'Not competent in this role or area; competency needs substantial development.',
              2: 'Marginal skills in this competency; some training would be required to bring skills up to an acceptable standard.',
              3: 'Adequate skills in this competency; no additional training is needed at this time.',
              4: 'Good skills in this competency; above-average ability is apparent.',
              5: 'Superior skills in this competency; could mentor or teach others.',
              NA: 'No feedback'
            },
            comment: ''
          },
          {
            key: 'communication',
            title: 'Communication Skills',
            info: 'How were the candidate’s communication skills during the interview?',
            rating: '',
            rating_info: {
              1: 'Partner is generally unacceptable.',
              2: 'Partner is deficient or below standards.',
              3: 'Partner is a competent communicator. Meets basic standards required.',
              4: 'Partner exceeds expectation.',
              5: 'Partner is exceptional; easily recognised as far superior to others.',
              NA: 'No feedback'
            },
            comment: ''
          },
          {
            key: 'education',
            title: 'Educational Background',
            info: 'Does the candidate have the appropriate educational qualifications or training?',
            rating: '',
            rating_info: {
              1: 'Partner is generally unacceptable.',
              2: 'Partner is deficient or below standards on a core need or requirement.',
              3: 'Partner is competent and dependable. Meets basic standards required.',
              4: 'Partner exceeds some requirements.',
              5: 'Partner is exceptional; easily recognised as far superior to others.',
              NA: 'No feedback'
            },
            comment: ''
          },
          {
            key: 'enthusiasm',
            title: 'Candidate Enthusiasm',
            info: 'How much interest did the candidate show in the position?',
            rating: '',
            rating_info: {
              1: 'Partner is generally unacceptable.',
              2: 'Partner is deficient or below standards on a core need or requirement.',
              3: 'Partner is competent and dependable. Meets basic standards required.',
              4: 'Partner exceeds some requirements.',
              5: 'Partner is exceptional; easily recognised as far superior to others.',
              NA: 'No feedback'
            },
            comment: ''
          },
          {
            key: 'overall',
            title: 'Overall Impression and Recommendation',
            info: 'Final comments and recommendations for proceeding with this candidate.',
            rating: '',
            rating_info: {
              1: 'Partner is generally unacceptable.',
              2: 'Partner is deficient or below standards on a core need or requirement.',
              3: 'Partner is competent and dependable. Meets basic standards required.',
              4: 'Partner exceeds some requirements.',
              5: 'Partner is exceptional; easily recognised as far superior to others.'
            },
            comment: ''
          }
        ],
        public: [
          {
            key: 'public',
            title: 'Next Steps',
            comment: '',
            rating: 'Interested to Proceed',
            rating_info: {
              'Interested to Proceed': 'It was great to meet you and I feel like we are a good match to partner for opportunities.',
              'Schedule another meeting': 'It was great to talk with you and I\'d like to meet again so we can continue the discussion.  If you agree I\'ll submit some dates to find another time.',
              'Not right now': 'Thanks for your time, it was good to meet you.  At the moment I\'d like to meet some other potential partners before I make a decision.  I\'ll come back to you if anything changes.'
            }
          }
        ],
        rating_title: {
          1: 'Unsatisfactory',
          2: 'Improvement Needed',
          3: 'Good',
          4: 'Very Good',
          5: 'Outstanding',
          NA: 'Not Applicable'
        },
        comment: ''
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      profiles: state => state.profiles,
      user: state => state.user,
      connections: state => state.connections
    }),
    feedbackForm: function () {
      if (this.feedbackFormActivity) {
        return this.feedbackFormActivity
      } else {
        return this.feedbackFormDefault
      }
    },
    publicInfo: function () {
      const otherUser = ''
      return `Lets get back to ${otherUser} and let them know what you thought.  Your decision on how to proceed and feedback will be shared with ${otherUser} when you hit submit.`
    }
  },
  methods: {
    ...mapActions(
      [
        'getFeedbackFormForConnection',
        'setFeedbackFormForConnection',
        'setSnackMessage',
        'setPageLoad'
      ]
    ),
    save () {
      this.setFeedbackFormForConnection({ connectionId: this.connectionId, userId: this.user.uid, feedbackForm: this.feedbackForm })
    },
    async sendFeedback () {
      this.save()
      const rating = this.feedbackForm.public[0].rating
      const text = this.feedbackForm.public[0].rating_info[rating]
      await backend.appFunction('botmessage', {
        status: 'ChemistryFeedbackResponse',
        connectionId: this.connectionId,
        connection: this.connection,
        text,
        rating
      })
      this.$router.push(`/connection/${this.connectionId}`)
      this.setSnackMessage('Feedback Sent')
    },
    onSelected: function (tab, section) {
      section.rating = tab
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    otherUser: function () {
      if (this.connection) {
        if (this.profile) {
          const usersCopy = Object.assign({}, this.connection.users)
          delete usersCopy[this.profile.id]
          if (Object.keys(usersCopy).length === 1) {
            const profileId = Object.keys(usersCopy)[0]
            return this.getProfile(profileId)
          }
        }
      }
      return {}
    },
    onNoteFinish: function () {
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.connectionId = (this.$route) ? this.$route.params.id : 'ijk789'
    if (this.connections) {
      const connectionsMap = new Map(this.connections.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)
    }
    if (this.user.uid) {
      this.getFeedbackFormForConnection({ connectionId: this.connectionId, userId: this.user.uid }).then(res => {
        this.feedbackFormActivity = res
      })
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    user: function (newU, oldU) {
      if (newU.uid) {
        this.getFeedbackFormForConnection({ connectionId: this.connectionId, userId: this.user.uid }).then(res => {
          this.feedbackFormActivity = res
        })
      }
    },
    connections: function (newC, oldC) {
      const connectionsMap = new Map(newC.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.trafficlight {
  margin-top:24px;
}

.trafficlight .mdc-tab--active .mdc-tab__text-label {
  color:black;
}

.trafficlight .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
  background-color:black;
}

.feedback {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
}

.feedback .mdc-tab-bar .mdc-tab {
  flex:0
}

.feedback .mdc-tab-bar .mdc-tab-scroller__scroll-content {
  flex:0;
  flex-flow:row;
  margin-left:auto;
  margin-right:auto;
}

.feedback .question-wrapper {
  padding:16px;
}
.feedback .section-notes {
  margin-top:16px;
}
.feedback .mdc-text-field--fullwidth {
    width: calc(100% - 10px);
}
.feedback .mdc-title {
  margin-bottom:0;
}
.feedback select option {
  /* wrap text in compatible browsers */
  -moz-white-space:pre-wrap;
  -o-white-space:pre-wrap;
  white-space:pre-wrap;

  /* hide text that can't wrap with an ellipsis */
  overflow: hidden;
  text-overflow:ellipsis;

  /* add border after every option */
  border-bottom: 1px solid #DDD;
}
.feedback .trafficlight a {
    line-height: 48px;
}
.feedback .trafficlight .mdc-tab:nth-child(1) {
  background-color: #e8f5e9;
}
.feedback .trafficlight .mdc-tab:nth-child(2) {
  background-color: #ffecb3;
}
.feedback .trafficlight .mdc-tab:nth-child(3) {
  background-color: #ffcdd2;
}

.feedback .mdc-tab span img {
  top: 6px;
  position:relative;
}

.feedback .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  border-color: #ccc;
}
@media all and (max-width: 1138px) {
  .feedback .mdc-tab-bar.mobile-vertical {
    display: flex;
    flex-flow: column;
    height:auto;
  }
  .feedback .mdc-tab-bar.mobile-vertical .mdc-tab--active {
    border-bottom: 2px solid black;
  }
  .feedback .mdc-tab-bar.mobile-vertical .mdc-tab-bar__indicator {
    display:none;
  }
}

.section-notes .mdc-textfield {
  border-color: #ccc;
}
.section-notes textarea::placeholder {
  color: #333 !important;
}
.section-notes .mdc-text-field__input {
  padding:6px;
}
.question-input .mdc-button {
  margin-top: 6px;
  height: 46px;
}
.question-input .mdc-list {
  height: 200px;
  overflow: hidden scroll;
}
.question-input .mdc-list .mdc-list-item {
  display: flex;
  flex-flow: row;
  height: auto;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.question-input .mdc-list .mdc-list-item p.active{
  font-weight: 500;
}
.feedback .section-notes {
  white-space: pre;
}
.feedback-rating .mdc-tab {
  min-width: 60px;
}
.feedback-rating .mdc-tab:last-of-type {
  left: 24px;
}
@media(max-width: 528px) {
  .feedback-rating .mdc-tab {
    min-width: 16.6666666667%;
  }
}
@media(max-width: 630px) {
  .feedback-rating .mdc-tab:last-of-type {
    left: 0;
  }
}

@media(max-width: 1170px) {
  .feedback .mdc-tab-bar.trafficlight .mdc-tab-scroller__scroll-content {
    flex:1;
    flex-flow:column;
  }
}

</style>
