<template>
  <div class="main-content">
    <div class="main-wrapper" style="margin-top: 56px;">

      <reject-modal :connection="connection" :open="rejectOpen" ></reject-modal>

      <div class="message-section">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell desktop=12 tablet=8 phone=4>

          <message-section
            v-if="profile && profiles"
            :connectionId="connectionId"
            :connection="connection"
            :profile="profile"
            :profiles="profiles"
            :user="user"
            :messages="messages"
            @action="onAction"
            >
          </message-section>

        </mdc-layout-cell>
      </mdc-layout-grid>
      </div>

      <message-bar v-on:focus="setInputFocused" :after="messageBarAfter" :disabled="sendDisabled" :disabledClick="showNotConnectedNotification" :connectionId="connectionId" :profile="profile" @send="sendMessage" ></message-bar>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'
import MessageSection from '../components/MessageSection.vue'
import MessageBar from '../components/MessageBar.vue'
import MeetingButtons from '../components/MeetingButtons.vue'
import ShareAgreementButtons from '../components/ShareAgreementButtons.vue'
import NextActionButton from '../components/NextActionButton.vue'
import RejectModal from '../components/RejectModal.vue'
import * as backend from '../utils/backend'
export default {
  name: 'connection',
  data () {
    return {
      title: 'Connection',
      links: [
        { component: ConnectMenu }
      ],
      connectionId: null,
      connection: null,
      actionsDisabled: false,
      inputFocused: false,
      rejectOpen: false,
      sendDisabled: true,
      searchAfter: []
    }
  },
  components: {
    MessageSection,
    MessageBar,
    RejectModal
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'messages',
      'connections',
      'company',
      'remote'
    ]),
    messageBarAfter () {
      const after = []
      if (this.connection) {
        if (this.connection.nextAction) {
          after.push({
            component: NextActionButton,
            props: { connection: this.connection }
          })
        }
        if (this.connection.actionComponent) {
          if (this.connection.actionComponent.component === 'MeetingButtons') {
            after.push({
              component: MeetingButtons,
              props: { connection: this.connection, ...this.connection.actionComponent.props }
            })
          } else if (this.connection.actionComponent.component === 'ShareAgreementButtons') {
            after.push({
              component: ShareAgreementButtons,
              props: { connection: this.connection, ...this.connection.actionComponent.props }
            })
          }
        }
      }
      return after
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'loadConnectionMessages',
      'addConnectionMessage',
      'updateConnectionMessage',
      'clearUnread',
      'clearMessages',
      'setLoading',
      'updateConnection',
      'setPageLoad'
    ]),
    setSearchAfter () {
      const profile = this.getSearchProfile()
      this.searchAfter = [{ profile }]
      setTimeout(() => {
        this.$root.$emit('enableSearch', true, { back: true, context: 'messages', after: this.searchAfter }, { action: this.onAction })
      }, 300)
    },
    sendMessage: function (message) {
      // eslint-disable-next-line no-undef
      analytics.track('sendConnectionMessage', {
        id: this.profile.id,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage
      })
      this.addConnectionMessage({ message })
      this.checkBothMessaged()
    },
    checkBothMessaged: async function () {
      if (this.connection.status === 'Connection') {
        const otherId = this.otherUserId()
        const otherMessages = this.messages.filter((message) => message.fromId === otherId && message.origin !== 'bot')
        const connectionId = this.connectionId
        if (otherMessages.length > 0) {
          if (!this.connection.chemistryIntroSent) {
            await backend.appFunction('botmessage', {
              status: 'ChemistryIntroduction',
              connectionId,
              connection: this.connection
            })
            this.connection.chemistryIntroSent = true
            this.updateConnection({ connectionId, connection: { chemistryIntroSent: true } })
          }
        }
      }
    },
    showNotConnectedNotification () {
      this.setSnackMessage('The other user must accept your connection invitation before you can send messages.')
    },
    scrollToBottom: function (mutations) {
      const self = this
      if (!self.unread) {
        setTimeout(function () {
          const messages = document.querySelectorAll('.message')
          const lastMessage = messages[messages.length - 1]
          if (lastMessage) {
            lastMessage.scrollIntoView(true)
          }
        }, 200)
      }
    },
    scrollToUnread: function () {
      const self = this
      setTimeout(function () {
        const messages = document.querySelectorAll('.message')
        const unreadMessage = messages[messages.length - self.unread]
        if (unreadMessage) {
          unreadMessage.scrollIntoView(true)
          self.unread = 0
        }
      }, 200)
    },
    getSearchProfile: function () {
      let searchProfile = null
      if (this.connection) {
        searchProfile = {
        }
        if (this.otherUser() !== {}) {
          searchProfile = this.otherUser()
        }
        searchProfile.status = this.connection.status
      }
      return searchProfile
    },
    setInputFocused (val) {
      this.inputFocused = val
      this.$emit('hideBottomNav', val)
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    otherUserId: function () {
      if (this.connection) {
        if (this.profile) {
          const usersCopy = Object.assign({}, this.connection.users)
          delete usersCopy[this.profile.id]
          if (Object.keys(usersCopy).length === 1) {
            return Object.keys(usersCopy)[0]
          }
        }
      }
      return null
    },
    otherUser: function () {
      if (this.connection) {
        if (this.profile) {
          const usersCopy = Object.assign({}, this.connection.users)
          delete usersCopy[this.profile.id]
          if (Object.keys(usersCopy).length === 1) {
            const profileId = Object.keys(usersCopy)[0]
            return this.getProfile(profileId)
          }
        }
      }
      return {}
    },
    initialize: async function () {
      const self = this
      this.loadConnectionMessages(this.connectionId)

      if (this.connections) {
        const connectionsMap = new Map(this.connections.map(i => [i.id, i]))
        this.connection = connectionsMap.get(this.connectionId)

        if (this.connection) {
          this.setSearchAfter()

          if (this.connection.status !== 'Invite') {
            this.sendDisabled = false
          }
          if (this.connection.unread) {
            if (this.connection.unread[this.user.uid] > 0) {
              this.unread = this.connection.unread[this.user.uid]
              self.scrollToBottom()
              this.clearUnread({ connectionId: this.connectionId })
            }
          }
        }
      }
    },
    onAction: async function (action) {
      let payload = {}
      let snackMessage = null
      let proceedToFunction = true
      let chemDisabled = false
      if (this.company.assignedCompany && this.company.assignedCompany.chemistryDisabled) {
        chemDisabled = true
      }
      console.log('connection.onAction', action)

      switch (action) {
        case 'Connection':
          snackMessage = 'Invite accepted'
          payload = {
            status: action,
            chemDisabled
          }
          break
        case 'ChemistryRequest':
          snackMessage = 'Chemistry Request Sent'
          payload = {
            status: action
          }
          break
        case 'Chemistry':
          snackMessage = 'Proceeding with Chemistry Check'
          payload = {
            status: action
          }
          break
        case 'DeclineChemistry':
          // show declined modal
          this.rejectOpen = true
          proceedToFunction = false
          break
        case 'ChemistryScheduled':
          snackMessage = 'Chemistry Check scheduled'
          payload = {
            status: 'Chemistry'
          }
          break
        case 'ChemistryCompleted':
          snackMessage = 'Chemistry Check completed'
          payload = {
            status: 'Chemistry'
          }
          break
        case 'Feedback':
          snackMessage = 'Feedback Form'
          payload = {
            status: 'Chemistry'
          }
          break
        case 'ShareAgreement':
          snackMessage = 'Share Agreement'
          payload = {
            status: 'Chemistry'
          }
          break
        case 'ShareAgreementReceiver':
          snackMessage = 'Share Agreement Confirmed'
          payload = {
            status: 'ShareAgreementConfirm',
            from: 'Receiver'
          }
          break
        case 'ShareAgreementSender':
          snackMessage = 'Share Agreement Confirmed'
          payload = {
            status: 'ShareAgreementConfirm',
            from: 'Sender'
          }
          break
      }
      if (proceedToFunction) {
        const res = await backend.appFunction('botmessage', {
          connection: this.connection,
          connectionId: this.connectionId,
          ...payload
        })
        if (res.error) {
          const errMsg = 'Unexpected server error, please try again in few minutes'
          this.setSnackMessage(errMsg)
        } else if (snackMessage) {
          this.setSnackMessage(snackMessage)
        }
      }
    }
  },
  beforeDestroy () {
    this.$emit('setNavActive', null)
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
    this.clearMessages()
  },
  beforeRouteUpdate (to, from, next) {
    this.setLoading(true)
    this.clearMessages()
    this.connectionId = to.params.id
    this.initialize()
    next()
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  mounted () {
    this.$root.$on('action', (action) => {
      this.onAction(action)
    })
    this.$emit('setNavActive', 'Connect')
    if (this.remote) {
      this.links = [{ component: ConnectMenuRemote }]
    } else {
      this.links = [{ component: ConnectMenu }]
    }
    this.$emit('setLinks', this.links)
    this.setLoading(true)
    this.connectionId = this.$route.params.id
    this.initialize()
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    remote () {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
      this.$emit('setLinks', this.links)
    },
    inputFocused: function (newf, oldf) {
      if (newf) {
        // this.scrollToBottom()
      }
    },
    profile: function (newP, oldP) {
    },
    connections: function (newC, oldC) {
      const connectionsMap = new Map(newC.map(i => [i.id, i]))
      this.connection = connectionsMap.get(this.connectionId)

      if (this.connection) {
        this.setSearchAfter()
        if (this.connection.status !== 'Invite') {
          this.sendDisabled = false
        }
        if (this.connection.unread) {
          if (this.connection.unread[this.user.uid] > 0) {
            this.unread = this.connection.unread[this.user.uid]
            this.scrollToUnread()
            this.clearUnread({ connectionId: this.connectionId })
          }
        }
      }
    },
    messages: function (newM, oldM) {
      if (newM !== null) {
        this.setLoading(false)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.messagebar_field {
  border:none;
  height:auto;
  margin:0;
}
.message-pre {
  white-space: pre-wrap;
}

.mdc-drawer-app-content {
  height:100%;
}
main {
  height: calc(100% - 4.5rem);
}
.main-content {
  height:100%;
}
.main-content .main-wrapper {
  height: 100%;
}
.avatar {
    width:35px;
    height: 35px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.bottom-bar-wrapper {
  position:relative;
  width: calc(100% - 294px);
  position:fixed;
  min-height:45px;
  height:auto;
  bottom:0;
  left:0;
  padding-left:288px;
}
.bottom-bar {
  margin:6px;
  box-sizing: border-box;
  margin: 0;
  width:100%;
  max-width:1200px;
  margin-left:auto;
  margin-right:auto;
  padding-right:32px;
  z-index: 4;
}
.bottom-message-bar .mdc-textfield {
    margin: 0;
}

/* Removes width of grid padding, wrapper padding and collapsed sidebar */
@media (min-width: 571px) and (max-width: 840px) {
  .bottom-bar-wrapper {
    padding-left: calc(24px + var(--mdc-layout-grid-margin-phone, 16px));
    width: calc(100% - 16px - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
  }
}

@media all and (max-width: 570px) {
  .bottom-bar-wrapper {
    width: calc(100% - 2px);
    padding-left: 1px;
    bottom: 0px;
  }
  .bottom-bar {
    padding-right:0px;
  }
}

.bottom-message-bar {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  background-color:#FCFCFC;
  width:100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.bottom-message-bar .mdc-button {
  min-width: 37px;
  width: 37px;
  height: 30px;
}
.bottom-bar .mdc-layout-grid {
  padding: 4px 12px;
}
.bottom-bar .mdc-textfield {
  height:48px;
  overflow: hidden;
  width:100%;
}
.bottom-bar .mdc-icon.mdc-icon--material.material-icons {
  width: 24px;
  height: 24px;
  margin-right: 0;
}
.message {
  min-height:66px;
  overflow: visible;
  height:auto;
  flex-flow: row wrap;
  padding: 0;
}
.message:first-of-type > .dateline > hr {
  margin-top: -8px;
}
.dateline {
  width:100%;
  font-size:0.8em;
  color:#999;
  text-align: center;
}
.dateline hr {
  position:relative;
  top:19px;
  border-color: #ccc;
  border-top: 0px solid #ccc;
}
.dateline span {
  display:inline-block;
  padding-left:12px;
  padding-right:12px;
  background-color: #fff;
  position: relative;
}
.connection-spacer {
    width: 100%;
    height: 8px;
}
.messageTS {
  font-style: italic;
  font-size: 10px;
}
.message-text > span:first-of-type + div > span.messageTS{
  margin-left: 15px;
}
.message-text.message-right > span:first-of-type + div > span.messageTS {
  margin-right: 15px;
}
.messageName {
  font-weight: 500;
}
.message-text {
  padding:6px;
  margin:2px 0;
  background-color:#F2F2F2;
  border: 0.25px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0.503638px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 5px;
  width:fit-content;
  position: relative;
}
.dateline + .mdc-layout-grid .message-text {
    border-radius: 0 0 5px 5px;
}
.message-text .avatar {
  position: absolute;
  top: -5px;
  left: -20px;
}
.message-text.message-right .avatar {
  left: auto;
  right: -20px;

}
.message-text.message-right {
  text-align: right;
  margin-left: auto;
  background-color:white
}
.message .mdc-layout-grid {
  padding:2px;
  margin:0px;
  width:100%;
}
.mdc-headline  {
  margin:0;
}
.flexrow {
  display:flex;
  flex-flow: row;
  align-items: center;
}
.flexrow .left{
  flex-grow: 1;
  min-width: 0;
}
.flexrow .right{
  min-width: 0;
  flex: none;
}
.flexrow .left > div {
  width: 100%;
  margin: 0;
  height: 30px;
}
.bottom-bar .mdc-textfield-wrapper.inline .mdc-text-field {
  margin: 0;
}
.status-menu {
  position: fixed;
  margin-top: 10px;
  right: 50px;
}
.status-display {
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding-left:12px;
  padding-right:12px;
}
.status-button {
  cursor:pointer;
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding-left:12px;
  padding-right:12px;
}
.status-button-active {
  color: var(--mdc-theme-primary, #2D9CDB);
  background-color:#abe0f0;
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding-left:12px;
  padding-right:12px;
}
</style>
<style>
.mdc-textfield-wrapper.inline {
  width:100%;
}
.mdc-textfield-wrapper.inline .mdc-text-field {
  min-height:30px !important;
  height:auto;
  margin-top:0;
  margin-bottom: 0;
}
.mdc-textfield-wrapper.inline .mdc-text-field .mdc-text-field__input {
  padding:0;
}
.status-menu .mdc-list-item {
  padding:0;
  height:28px;
}
</style>
