<template>
  <mdc-list v-if="profile" class="connectmenu">

    <mdc-list-item class="side-menu-item">
      <router-link v-on:click.native="toggleSidebar" to="/connect">
        <div class="side-menu-heading sublist1">
          <span class="mdc-list-item__graphic">
            <mdc-icon slot="start-detail" icon="person_outline"></mdc-icon>
          </span>
          <mdc-text typo='body2' tag="div">Connections</mdc-text>
        </div>
      </router-link>
      <mdc-list  v-if="profile" interactive>
        <mdc-list-item
          v-for="con in connections"
          :key="con.id"
          >
          <router-link v-on:click.native="toggleSidebar" :to="'/connection/' + con.id">
            <div class="side-menu-item connectionrow sublist2">
              <img v-if="profiles && hasPhoto(otherUser(con.users))" class="avatar" :src="getPhoto(otherUser(con.users))"/>
              <default-avatar v-else class="avatar" fontSize="12px" :profile="otherUser(con.users)"/>
              <div v-if="profiles">
                <span v-if="otherUser(con.users)">{{otherUser(con.users).displayName}}</span>
              </div>
              &middot;
              <time-ago v-if="con.lastUpdate" refresh short :datetime="con.lastUpdate.toDate ? con.lastUpdate.toDate() : con.lastUpdate"></time-ago>
            </div>
          </router-link>
        </mdc-list-item>
      </mdc-list>
    </mdc-list-item>

  </mdc-list>
</template>

<script>
import { mapState } from 'vuex'
import TimeAgo from '../components/TimeAgo.vue'
import DefaultAvatar from '../components/DefaultAvatar'
export default {
  name: 'ConnectMenu',
  data () {
    return {
      channelGroups: [
        {
          title: 'My Company',
          link: 'Company',
          filter: 'Company',
          icon: 'work'
        }
      ]
    }
  },
  components: {
    TimeAgo,
    DefaultAvatar
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'connections'
    ])
  },
  methods: {
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto: function (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    getChannelsForGroup: function (channelType) {
      if (channelType === 'Team') {
        const teamChannels = this.$store.state.channelMessages.teamChannels
        return [...teamChannels]
      } else {
        return this.$store.state.channelMessages.companyChannels
      }
    },
    toggleSidebar: function () {
      this.$root.$emit('toggleSidebar')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.connectmenu .avatar {
  width:20px;
  height: 20px;
  margin-right: 6px;
  position: relative;
}

.connectmenu .connectionrow, .connectionrow > div {
  display:flex;
  font-size:12px;
  flex-flow: row nowrap;
  white-space: nowrap;
  margin-right:6px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
}
.connectmenu a {
  color: #333333;
  text-decoration: none;
}
.connectmenu .mdc-list-item {
  height:24px;
}
.connectmenu .side-menu-item > a {
  margin-bottom: 5px;
}
</style>
