<template>
  <div class="main-content dash dash-team">
    <div class="main-wrapper" style="margin-top: 56px;">

      <div class="mobile-links-wrapper mobileonly">
        <div class="mobileonly mobile-links">

          <round-button v-if="section === 'team'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('team')">
            Team
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" outlined icon="people"></mdc-icon>
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('team')">
            Team
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" outlined icon="people"></mdc-icon>
          </round-button>

          <round-button v-if="section === 'initiatives'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('tempo')">
            Tempo
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" outlined icon="model_training"></mdc-icon>
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('tempos')">
            Tempo
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" outlined icon="model_training"></mdc-icon>
          </round-button>

          <round-button v-if="section === 'initiatives'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('initiatives')">
            Initiatives
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" class="icon" outlined icon="emoji_objects"></mdc-icon>
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('initiatives')">
            Initiatives
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" outlined icon="emoji_objects"></mdc-icon>
          </round-button>

          <round-button v-if="section === 'people'" medium  class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="showSection('people')">
            People
            <mdc-icon slot="trailing-icon" style="margin-left:6px;color:#2D9CDB" outlined class="icon" icon="person"></mdc-icon>
          </round-button>
          <round-button v-else class="mobile-link" style="flex-grow:2;" elevated hasSlot @click="setAction('people')">
            People
            <mdc-icon slot="trailing-icon" style="margin-left:6px;" class="icon" outlined icon="person"></mdc-icon>
          </round-button>

          <span style="width:1px; background: white;flex:0;">&nbsp;</span>
        </div>
      </div>

      <section v-if="section === 'team'">

        <mdc-layout-grid>
          <mdc-layout-cell span="12">

            <mdc-title style="margin-top:0;">Week {{currentWeek}}</mdc-title>
            <team-schedule @getSchedule="getSchedule"
            v-if="profile.team && currentWeek"
            :profiles="teamProfiles"
            :office="officeTeam"
            :topOffice="topOffice"
            :topRemote="topRemote"
            :remote="remoteTeam"
            :tbc="tbcTeam"
            :week="currentWeek"
            :team="profile.team" />

            <mdc-body typo="body1" style="display:flex;align-items:center;font-weight: 500;">
              <span>Round Table</span>
            </mdc-body>
            <update-section v-if="profile.team" @showUpdate="showUpdate" hasLink :updates="teamUpdatesRoundTableCurrent" :week="lastWeek" :profiles="teamProfiles" :team="profile.team"/>

            <mdc-body typo="body1" style="display:flex;align-items:center;font-weight: 500;">
              <span>Looking Forward</span>
            </mdc-body>
            <update-section v-if="profile.team" @showUpdate="showUpdate" hasLink :updates="teamUpdatesLookingForwardCurrent" :week="currentWeek" :profiles="teamProfiles" :team="profile.team"/>

          </mdc-layout-cell>
          <mdc-layout-cell span="12">

            <mdc-title style="margin-top:0;">Week {{lastWeek}}</mdc-title>

            <mdc-body v-if="profile.team && teamUpdatesRoundTableLast" typo="body1" style="display:flex;align-items:center;font-weight: 500;">
              <span>Round Table</span>
            </mdc-body>
            <update-section v-if="profile.team && teamUpdatesRoundTableLast" @showUpdate="showUpdate" hasLink :updates="teamUpdatesRoundTableLast" :week="lastWeek" :profiles="teamProfiles" :team="profile.team"/>

            <mdc-body v-if="profile.team && teamUpdatesLookingForwardLast" typo="body1" style="display:flex;align-items:center;font-weight: 500;">
              <span>Looking Forward</span>
            </mdc-body>
            <update-section v-if="profile.team && teamUpdatesLookingForwardLast" @showUpdate="showUpdate" hasLink :updates="teamUpdatesLookingForwardLast" :week="currentWeek" :profiles="teamProfiles" :team="profile.team"/>

          </mdc-layout-cell>
        </mdc-layout-grid>

        <app-dialog
          v-if="update"
          v-model="updateShowing"
          @close="update = null"
          :pauseFocus="isMobile"
          class="update-modal fullsize-mobile with-messagebar"
          style="z-index: 13;"
          accept=""
          :title="groupDisplay(update.group)"
          >
          <update-section style="margin-top:12px;" :showReplyCount="false" :updates="[update]" :profiles="teamProfiles" :team="profile.team"/>

          <div style="display:flex;align-items:center;">
            <round-button icon="rss_feed" style="border: 1px solid;width:160px !important;" hasSlot>
              <span v-if="isSubscriber(update)" @click="unsubscribe(update)">Unsubscribe</span>
              <span v-else @click="subscribe(update)">Subscribe</span>
            </round-button>
            <round-button icon="thumb_up" @click="toggleLike(update)" :iconOutline="!likes(update)"></round-button>
            <span v-for="like in update.likes" :key="'like-'+like" :title="getProfile(like).displayName" class="avatar avatar-link">
            <router-link class="update-avatar" v-if="like !== 'bot'" :to="'/user/' + like">
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </router-link>
            <span class="update-avatar" v-else>
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </span>
            </span>
          </div>
          <mdc-title style="display:flex;margin-top: 0;margin-bottom: 12px;">
            <span class="update-reply-count-wrapper">
              <span class="update-reply-count">{{commentCount(update)}}</span>
            </span>
            Comments
          </mdc-title>

          <update-section v-if="update" showDateLines :updates="updateComments" :profiles="teamProfiles" :team="profile.team"/>

          <message-bar oneline relative v-on:focus="setInputFocused" :after="[]" :disabled="sendDisabled" :profile="profile" @send="addComment" ></message-bar>
        </app-dialog>

      </section>

      <section v-if="section === 'tempos'">
          <mdc-title class="section-title" style="margin-bottom:0">Tempos</mdc-title>
          <mdc-body class="section-title"  typo="body2">Team rituals that enhance communication and reduce calls</mdc-body>
          <mdc-layout-grid>
          <template v-for="tempo in tempos">
            <mdc-layout-cell :key="tempo.id" span=4 class="profiles">
              <tempo-card @edit="editTempo(tempo)" :tempo="tempo"></tempo-card>
            </mdc-layout-cell>
          </template>
          <mdc-layout-cell style="display:none" span=4 class="profiles">
            <div class="tempo-card" @click="addTempoShowing = true">
              <div class="tempo-card-wrapper">
                <mdc-card class="tempo-add-wrapper">
                  <div style="min-height:300px;display:flex;align-items:center;justify-content:center;flex-flow:column">
                    <mdc-icon style="font-size:64px;color:#777;" icon="add"/>
                    <h2>Add Tempo</h2>
                  </div>
                </mdc-card>
              </div>
            </div>
          </mdc-layout-cell>
          </mdc-layout-grid>
          <app-dialog
          v-model="editTempoShowing"
          :pauseFocus="isMobile"
          class="update-modal fullsize-mobile add-tempo-modal"
          style="z-index: 13;"
          accept="Save"
          @accept="onSaveTempo"
          v-if="tempo"
          :title="tempo.name"
          :footer="getNextScheduledDateText(tempo)"
          >
          <mdc-body style="margin-top:24px;margin-bottom:-16px;" v-if="tempo.name === 'Looking Forward'" typo="body2">
            Ask the team what they plan to work on to create a central log of ongoing activities and reduce the need to update calls.
          </mdc-body>
          <mdc-body style="margin-top:24px;margin-bottom:-16px;" v-if="tempo.name === 'Round Table'" typo="body2">
            Ask the team to share an update on progress and other key info similar to a round table update in a meeting. This creates a record and lets people catch up in their own time.
          </mdc-body>
          <mdc-body style="margin-top:24px;margin-bottom:-16px;" v-if="tempo.name === 'Remote Today'" typo="body2">
            When a team member is working remotely, check in to see if they are ok and connect them to other team members.
          </mdc-body>
          <mdc-body style="margin-top:24px;margin-bottom:-16px;" v-if="tempo.name === 'Working From'" typo="body2">
            Ask the team where they plan to work from so the team can see who is in the office and remote workers can be engaged.
          </mdc-body>
          <div class="form-section">
            <app-text-field
                type="text"
                style="display:none"
                label="Tempo Name"
                outlined
                v-model="tempo.name"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="- E.g. Looking Forward"
                >
              </app-text-field>
              <app-text-field
                type="text"
                style="display:none"
                label="Tempo Title"
                outlined
                v-model="tempo.title"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="E.g. WHATS BEING WORKED ON"
                >
              </app-text-field>
              <app-text-field
                type="text"
                label="Question asked"
                outlined
                v-model="tempo.question"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="This question is automatically sent"
                >
              </app-text-field>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Recpients <mdc-icon style="margin-left:16px;" outlined icon="people"/></mdc-subheading>
              <div class="member-row" v-for="member in teamProfiles" :key="member.id">
              <span :title="member.displayName" class="avatar avatar-link">
                <router-link class="update-avatar" :to="'/user/' + member.id">
                    <img :class="{grayscale: !isMember(member.id)}" v-if="hasPhoto(member)" :src="getPhoto(member)"/>
                    <default-avatar :class="{grayscale: !isMember(member.id)}" v-else fontSize="16px" :profile="member"/>
                </router-link>
              </span>
              <span :class="{lighter: !isMember(member.id)}" class="member-text">{{member.displayName}}</span>
              <mdc-checkbox :checked="isMember(member.id)" @click="toggleMember(member.id)" class="member-check"></mdc-checkbox>
              </div>
              <div class="sub-label">These team members are included</div>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Schedule</mdc-subheading>

              <div v-if="tempo.name === 'Remote Today'">
                <div><mdc-radio :picked="tempo.frequency === 'Day before remote'" @change="tempo.frequency = 'Day before remote'" :value="'Daily'" name="radio-freq" :label="'Day before being remote'" /></div>
                <div><mdc-radio :picked="tempo.frequency === 'Day remote'" @change="tempo.frequency = 'Day remote'" :value="'Daily remote'" name="radio-freq" :label="'Day they are remote'" /></div>
              </div>
              <div v-else>
              <div v-if="frequencyAvailable('Daily', tempo)" ><mdc-radio :picked="tempo.frequency === 'Daily'" @change="tempo.frequency = 'Daily'" :value="'Daily'" name="radio-freq" :label="'Daily'" /></div>
              <div class="button-row weekly-buttons" v-if="tempo.frequency === 'Daily'">
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Monday')" @click="toggleDay(tempo, 'Monday')" hasSlot>MON</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Tuesday')" @click="toggleDay(tempo, 'Tuesday')" hasSlot>TUE</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Wednesday')" @click="toggleDay(tempo, 'Wednesday')" hasSlot>WED</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Thursday')" @click="toggleDay(tempo, 'Thursday')" hasSlot>THU</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Friday')" @click="toggleDay(tempo, 'Friday')" hasSlot>FRI</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Saturday')" @click="toggleDay(tempo, 'Saturday')" hasSlot>SAT</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="isDayActive(tempo, 'Sunday')" @click="toggleDay(tempo, 'Sunday')" hasSlot>SUN</round-button>
              </div>
              <div v-if="frequencyAvailable('Weekly', tempo)"><mdc-radio :picked="tempo.frequency === 'Weekly'" @change="tempo.frequency = 'Weekly'" :value="'Once a week'" name="radio-freq" :label="'Once a week'" /></div>
              <div class="button-row weekly-buttons" v-if="tempo.frequency === 'Weekly'">
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Monday'" @click="tempo.day = 'Monday'" hasSlot>MON</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Tuesday'" @click="tempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Wednesday'" @click="tempo.day = 'Wednesday'" hasSlot>WED</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Thursday'" @click="tempo.day = 'Thursday'" hasSlot>THU</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Friday'" @click="tempo.day = 'Friday'" hasSlot>FRI</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Saturday'" @click="tempo.day = 'Saturday'" hasSlot>SAT</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Sunday'" @click="tempo.day = 'Sunday'" hasSlot>SUN</round-button>
              </div>
              <div v-if="frequencyAvailable('Every other week', tempo)"><mdc-radio :picked="tempo.frequency === 'Every other week'" @change="tempo.frequency = 'Every other week'" :value="'Every other week'" name="radio-freq" :label="'Every other week'" /></div>
              <div v-if="tempo.frequency === 'Every other week'">
                <mdc-body typo="body2">On the first</mdc-body>
                <div class="button-row weekly-buttons">
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Monday'" @click="tempo.day = 'Monday'" hasSlot>MON</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Tuesday'" @click="tempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Wednesday'" @click="tempo.day = 'Wednesday'" hasSlot>WED</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Thursday'" @click="tempo.day = 'Thursday'" hasSlot>THU</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Friday'" @click="tempo.day = 'Friday'" hasSlot>FRI</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Saturday'" @click="tempo.day = 'Saturday'" hasSlot>SAT</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Sunday'" @click="tempo.day = 'Sunday'" hasSlot>SUN</round-button>
                </div>
              </div>
              <div v-if="frequencyAvailable('Once a month', tempo)"><mdc-radio :picked="tempo.frequency === 'Once a month'" @change="tempo.frequency = 'Once a month'" :value="'Once a month'" name="radio-freq" :label="'Once a month'" /></div>
              <div v-if="tempo.frequency === 'Once a month'">
                <mdc-body typo="body2">On the first</mdc-body>
                <div class="button-row weekly-buttons">
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Monday'" @click="tempo.day = 'Monday'" hasSlot>MON</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Tuesday'" @click="tempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Wednesday'" @click="tempo.day = 'Wednesday'" hasSlot>WED</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Thursday'" @click="tempo.day = 'Thursday'" hasSlot>THU</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Friday'" @click="tempo.day = 'Friday'" hasSlot>FRI</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Saturday'" @click="tempo.day = 'Saturday'" hasSlot>SAT</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="tempo.day === 'Sunday'" @click="tempo.day = 'Sunday'" hasSlot>SUN</round-button>
                </div>
              </div>
            </div>
            </div>
            <div class="form-section">
              <mdc-body typo="body2" style="display:flex;font-size:1em;">At what time?</mdc-body>
              <div><mdc-radio :picked="tempo.time === '9:00'" @change="tempo.time = '9:00'" :value="'9:00'" name="radio-time" :label="'Start of day (9:00)'" /></div>
              <div><mdc-radio :picked="tempo.time === '4:30'" @change="tempo.time = '4:30'" :value="'4:30'" name="radio-time" :label="'End of day (4:30)'" /></div>
              <div><mdc-radio :picked="tempo.time !== '9:00' && tempo.time !== '4:30'" @change="tempo.time = '12:00'" :value="'12:00'" name="radio-freq" :label="'Specific time'" /></div>
              <mdc-select outlined
              v-if="tempo.time !== '9:00' && tempo.time !== '4:30'"
              v-model="tempo.time"
              label="Time">
                <select-item v-for="(timeslot, index) in timeslots" :key="index" :value="timeslot" />
              </mdc-select>
            </div>
          </app-dialog>
          <app-dialog
          v-model="addTempoShowing"
          :pauseFocus="isMobile"
          class="update-modal fullsize-mobile add-tempo-modal"
          style="z-index: 13;"
          accept="Add"
          @accept="onAddTempo"
          :title="'Add Tempo'"
          >
          <div class="form-section">
            <app-text-field
                type="text"
                label="Tempo Name"
                outlined
                v-model="newTempo.name"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="- E.g. Looking Forward"
                >
              </app-text-field>
              <app-text-field
                type="text"
                label="Tempo Title"
                outlined
                v-model="newTempo.title"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="E.g. WHATS BEING WORKED ON"
                >
              </app-text-field>
              <app-text-field
                type="text"
                label="Question"
                outlined
                v-model="newTempo.question"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="E.g. What are you planning to work on?"
                >
              </app-text-field>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Recpients <mdc-icon style="margin-left:16px;" outlined icon="people"/></mdc-subheading>
              <div class="member-row" v-for="member in teamProfiles" :key="member.id">
              <span :title="member.displayName" class="avatar avatar-link">
                <router-link class="update-avatar" :to="'/user/' + member.id">
                    <img :class="{grayscale: !isMemberNew(member.id)}" v-if="hasPhoto(member)" :src="getPhoto(member)"/>
                    <default-avatar :class="{grayscale: !isMemberNew(member.id)}" v-else fontSize="16px" :profile="member"/>
                </router-link>
              </span>
              <span :class="{lighter: !isMemberNew(member.id)}" class="member-text">{{member.displayName}}</span>
              <mdc-checkbox :checked="isMemberNew(member.id)" @click="toggleMemberNew(member.id)" class="member-check"></mdc-checkbox>
              </div>
              <div class="sub-label">These team members are included</div>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Schedule</mdc-subheading>
              <div v-if="frequencyAvailable('Daily', newTempo)"><mdc-radio :picked="newTempo.frequency === 'Daily'" @change="newTempo.frequency = 'Daily'" :value="'Daily'" name="radio-freq" :label="'Daily'" /></div>
              <div class="button-row weekly-buttons" v-if="newTempo.frequency === 'Daily'">
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Monday']" @click="newTempo.days['Monday'] = true" hasSlot>MON</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Tuesday']" @click="newTempo.days['Tuesday'] = true" hasSlot>TUE</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Wednesday']" @click="newTempo.days['Wednesday'] = true" hasSlot>WED</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Thursday']" @click="newTempo.days['Thursday'] = true" hasSlot>THU</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Friday']" @click="newTempo.days['Friday'] = true" hasSlot>FRI</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Saturday']" @click="newTempo.days['Saturday'] = true" hasSlot>SAT</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.days['Sunday']" @click="newTempo.days['Sunday'] = true" hasSlot>SUN</round-button>
              </div>
              <div v-if="frequencyAvailable('Weekly', newTempo)"><mdc-radio :picked="newTempo.frequency === 'Weekly'" @change="newTempo.frequency = 'Weekly'" :value="'Once a week'" name="radio-freq" :label="'Once a week'" /></div>
              <div class="button-row weekly-buttons" v-if="newTempo.frequency === 'Weekly'">
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Monday'" @click="newTempo.day = 'Monday'" hasSlot>MON</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Tuesday'" @click="newTempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Wednesday'" @click="newTempo.day = 'Wednesday'" hasSlot>WED</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Thursday'" @click="newTempo.day = 'Thursday'" hasSlot>THU</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Friday'" @click="newTempo.day = 'Friday'" hasSlot>FRI</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Saturday'" @click="newTempo.day = 'Saturday'" hasSlot>SAT</round-button>
                <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Sunday'" @click="newTempo.day = 'Sunday'" hasSlot>SUN</round-button>
              </div>
              <div v-if="frequencyAvailable('Every other week', newTempo)"><mdc-radio :picked="newTempo.frequency === 'Every other week'" @change="newTempo.frequency = 'Every other week'" :value="'Every other week'" name="radio-freq" :label="'Every other week'" /></div>
              <div v-if="newTempo.frequency === 'Every other week'">
                <mdc-body typo="body2">On the first</mdc-body>
                <div class="button-row weekly-buttons">
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Monday'" @click="newTempo.day = 'Monday'" hasSlot>MON</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Tuesday'" @click="newTempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Wednesday'" @click="newTempo.day = 'Wednesday'" hasSlot>WED</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Thursday'" @click="newTempo.day = 'Thursday'" hasSlot>THU</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Friday'" @click="newTempo.day = 'Friday'" hasSlot>FRI</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Saturday'" @click="newTempo.day = 'Saturday'" hasSlot>SAT</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Sunday'" @click="newTempo.day = 'Sunday'" hasSlot>SUN</round-button>
                </div>
              </div>
              <div v-if="frequencyAvailable('Once a month', newTempo)" ><mdc-radio :picked="newTempo.frequency === 'Once a month'" @change="newTempo.frequency = 'Once a month'" :value="'Once a month'" name="radio-freq" :label="'Once a month'" /></div>
              <div v-if="newTempo.frequency === 'Once a month'">
                <mdc-body typo="body2">On the first</mdc-body>
                <div class="button-row weekly-buttons">
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Monday'" @click="newTempo.day = 'Monday'" hasSlot>MON</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Tuesday'" @click="newTempo.day = 'Tuesday'" hasSlot>TUE</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Wednesday'" @click="newTempo.day = 'Wednesday'" hasSlot>WED</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Thursday'" @click="newTempo.day = 'Thursday'" hasSlot>THU</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Friday'" @click="newTempo.day = 'Friday'" hasSlot>FRI</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Saturday'" @click="newTempo.day = 'Saturday'" hasSlot>SAT</round-button>
                  <round-button style="padding-left:16px;border:1px solid" :medium="newTempo.day === 'Sunday'" @click="newTempo.day = 'Sunday'" hasSlot>SUN</round-button>
                </div>
              </div>
            </div>
            <div class="form-section">
              <mdc-body typo="body2" style="display:flex;font-size:1em;">At what time?</mdc-body>
              <div><mdc-radio :picked="newTempo.time === '9:00'" @change="newTempo.time = '9:00'" :value="'9:00'" name="radio-time" :label="'Start of day (9:00)'" /></div>
              <div><mdc-radio :picked="newTempo.time === '4:30'" @change="newTempo.time = '4:30'" :value="'4:30'" name="radio-time" :label="'End of day (4:30)'" /></div>
              <div><mdc-radio :picked="newTempo.time !== '9:00' && newTempo.time !== '4:30'" @change="newTempo.time = '12:00'" :value="'12:00'" name="radio-freq" :label="'Specific time'" /></div>

              <mdc-select outlined
              v-if="newTempo.time !== '9:00' && newTempo.time !== '4:30'"
              v-model="newTempo.time"
              label="Time">
                <select-item v-for="(timeslot, index) in timeslots" :key="index" :value="timeslot" />
              </mdc-select>
            </div>
          </app-dialog>
      </section>

      <section v-if="section === 'initiatives'">
          <mdc-title class="section-title" style="margin-bottom:0">Team Initiatives</mdc-title>
          <mdc-body class="section-title"  typo="body2">Projects, responsibilities, activities or anything that requires tracking and focus</mdc-body>
          <mdc-layout-grid>
          <template v-for="initiative in initiatives">
            <mdc-layout-cell :key="initiative.id" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'Initiative', params: {id: initiative.id} }">
                <initiative-card :initiative="initiative" :scheduleMessage="getScheduleMessage(initiative)" ></initiative-card>
              </router-link>
            </mdc-layout-cell>
          </template>
          <mdc-layout-cell span=4 class="profiles">
            <div class="initiative-card" @click="addInitShowing = true">
              <div class="initiative-card-wrapper">
                <mdc-card class="initiative-add-wrapper">
                  <div style="min-height:300px;display:flex;align-items:center;justify-content:center;flex-flow:column">
                    <mdc-icon style="font-size:64px;color:#777;" icon="add"/>
                    <h2>Add Initiative</h2>
                  </div>
                </mdc-card>
              </div>
            </div>
          </mdc-layout-cell>
          </mdc-layout-grid>
          <app-dialog
          v-model="addInitShowing"
          :pauseFocus="isMobile"
          :footer="'Tip: Tagging '+ getTag(newInitiative.name) +' into an update or task will associate it into this initiative.'"
          class="update-modal fullsize-mobile add-init-modal"
          style="z-index: 13;"
          accept="Add"
          @accept="addInitiative"
          :title="'Add Initiative'"
          >
          <div class="form-section">
            <app-text-field
                type="text"
                label="Initiative Name"
                outlined
                v-model="newInitiative.name"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="- E.g. My Project"
                >
              </app-text-field>
              <app-text-field
                type="text"
                label="Add a description"
                outlined
                v-model="newInitiative.description"
                autocomplete="new-password"
                autofill="off"
                :isMobile="isMobile"
                :mobileOverlay="true"
                helptext="initiatives can be projects, responsibilities, activities or anything that requires focus."
                >
              </app-text-field>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Core Members <mdc-icon style="margin-left:16px;" outlined icon="people"/></mdc-subheading>
              <div class="member-row" v-for="member in teamProfiles" :key="member.id">
              <span :title="member.displayName" class="avatar avatar-link">
                <router-link class="update-avatar" :to="'/user/' + member.id">
                    <img :class="{grayscale: !isMemberInit(member.id)}" v-if="hasPhoto(member)" :src="getPhoto(member)"/>
                    <default-avatar :class="{grayscale: !isMemberInit(member.id)}" v-else fontSize="16px" :profile="member"/>
                </router-link>
              </span>
              <span :class="{lighter: !isMemberInit(member.id)}" class="member-text">{{member.displayName}}</span>
              <mdc-checkbox :checked="isMemberInit(member.id)" @click="toggleMemberInit(member.id)" class="member-check"></mdc-checkbox>
              </div>
              <div class="sub-label">Initiatives are added to their dashboard so they can provide updates</div>
            </div>
            <div class="form-section">
              <mdc-subheading style="display:flex;font-size:1em;">Visibility <mdc-icon style="margin-left:16px;" icon="visibility"/></mdc-subheading>
                <div><mdc-radio :picked="newInitiative.visibility === 'Core Members'" @change="newInitiative.visibility = 'Core Members'" :value="'Core Members'" name="radios" :label="'Visible to Core Members'" /></div>
                <div><mdc-radio :picked="newInitiative.visibility === 'Team'" @change="newInitiative.visibility = 'Team'" :value="'Team'" name="radios" :label="'Visible to Team'" /></div>
                <div><mdc-radio :picked="newInitiative.visibility === 'Org'" @change="newInitiative.visibility = 'Org'" :value="'Org'" name="radios" :label="'Visible to Org'" /></div>
              <div class="sub-label">Initiatives visible beyond the Core Members enables others to contribute or comment</div>
            </div>
          </app-dialog>
      </section>

      <section v-if="section === 'people'">

        <div v-for="(group, index) in groups" :key="index">
          <mdc-title class="section-title" style="margin-bottom:0">Team Members interested in {{index}}</mdc-title>
          <mdc-layout-grid>
          <template v-for="event in events[index]">
            <mdc-layout-cell :key="event.id" span=4 class="profiles">
              <event-card :event="event"></event-card>
            </mdc-layout-cell>
          </template>
          <template v-for="prof in group">
            <mdc-layout-cell v-if="prof !== profile.id" :key="prof" span=4 class="profiles">
              <router-link class="profile-card-link" :to="{ name: 'User', params: {id: prof} }">
                <profile-card-mobile hoverHigh :showMore="false" showStatus hideShare class="mobileonly" @blocked="showCompleteProfileNotification" :profile="getProfile(prof)"></profile-card-mobile>
                <profile-card hoverHigh :showMore="false" showStatus :medium="true" hideShare class="desktoponly" @blocked="showCompleteProfileNotification" :profile="getProfile(prof)"></profile-card>
              </router-link>
            </mdc-layout-cell>
          </template>
        </mdc-layout-grid>
        </div>

      </section>

      <first-login-teams v-if="teamMode" @close="loadPageItems" required :firstModalOpen="firstOpen"></first-login-teams>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FirstLoginTeams from '../components/FirstLoginTeams.vue'
import TeamSchedule from '../components/TeamSchedule.vue'
import UpdateSection from '../components/UpdateSection.vue'
import AppDialog from '../components/AppDialog.vue'
import MessageBar from '../components/MessageBar.vue'
import { dateDisplayFormat, dateTimeDisplayFormat, dateYearFormat, getTimeslotsBetween, getNextDay, getFirstDayOfMonth } from '../utils/time'
import * as backend from '../utils/backend'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import EventCard from '../components/profile-v2/EventCard.vue'
import InitiativeCard from '../components/profile-v2/InitiativeCard.vue'
import TempoCard from '../components/profile-v2/TempoCard.vue'
import AppTextField from '../components/AppTextField'
import DefaultAvatar from '../components/DefaultAvatar'

export default {
  name: 'DashTeam',
  components: {
    FirstLoginTeams,
    TeamSchedule,
    UpdateSection,
    AppDialog,
    MessageBar,
    ProfileCardMobile,
    ProfileCard,
    EventCard,
    InitiativeCard,
    TempoCard,
    AppTextField,
    DefaultAvatar
  },
  props: {
    query: {
      type: String
    },
    search: {
      type: String
    },
    isMobile: {
      type: Boolean
    },
    isTablet: {
      type: Boolean
    },
    isDesktopLarge: {
      type: Boolean
    },
    isTabletLarge: {
      type: Boolean
    }
  },
  data () {
    return {
      update: null,
      updateShowing: false,
      addInitShowing: false,
      addTempoShowing: false,
      editTempoShowing: false,
      tempo: null,
      timeslots: [],
      newTempo: {
        name: '',
        title: '',
        question: '',
        members: [],
        frequency: 'Daily',
        day: '',
        days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        time: '9:00',
        active: false
      },
      newInitiative: {
        name: '',
        description: '',
        visibility: 'Core Members',
        members: []
      },
      updateInputOpen: false,
      updateRoundTableInputOpen: false,
      sendDisabled: false,
      officeTeam: [
      ],
      remoteTeam: [
      ],
      tbcTeam: [
      ],
      daysShort: [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri'
      ],
      dayLong: {
        Mon: 'Monday',
        Tue: 'Tuesday',
        Wed: 'Wednesday',
        Thu: 'Thursday',
        Fri: 'Friday',
        Sat: 'Saturday',
        Sun: 'Sunday'
      },
      topRemote: null,
      topRemoteCount: 0,
      topOffice: null,
      topOfficeCount: 0,
      listSize: 8,
      title: 'Discover',
      section: 'team',
      showTooltip: false,
      showTooltipJobs: false,
      pageActions: ['team', 'tempos', 'initiatives', 'people'],
      links: [
        {
          title: 'Team',
          icon: 'people_outline',
          action: 'team',
          scrollTop: true
        },
        {
          title: 'Tempos',
          icon: 'model_training',
          iconOutlined: true,
          action: 'tempos',
          scrollTop: true
        },
        {
          title: 'Initiatives',
          icon: 'emoji_objects',
          iconOutlined: true,
          action: 'initiatives',
          scrollTop: true
        },
        {
          title: 'People',
          icon: 'person',
          iconOutlined: true,
          action: 'people',
          scrollTop: true
        }
      ],
      statusOptionsOpen: false,
      statusIcons: {
        'Not Set': 'close',
        Remote: 'home_work',
        Office: 'business',
        'Parental Leave': 'emoji_people',
        Sabbatical: 'emoji_people'
      },
      statusClasses: {
        'Not Set': 'not-set',
        Remote: 'remote',
        Office: 'office',
        'Parental Leave': 'parental-leave',
        Sabbatical: 'sabbatical'
      },
      statusOrder: {
        'Not Set': 5,
        Office: 2,
        Remote: 1,
        Sabbatical: 3,
        'Parental Leave': 4
      },
      groups: {
        'Peer Plan': [],
        'Coffee Chat': [],
        'Flash Chat': []
      },
      events: {
        'Coffee Chat': [
        ],
        'Flash Chat': [
          {
            bannerText: 'Networking',
            title: 'Speed Networking',
            location: 'London',
            date: 'Tomorrow 12pm',
            interest: '17 people interested',
            description: 'Speed Networking event where you get 2 minutes each to introduce yourself and what you do.'
          }
        ],
        'Peer Plan': [
          {
            bannerText: 'Lunch Event',
            title: 'Remote Communication',
            location: 'Remote',
            date: 'Today 7pm',
            interest: '12 people interested',
            description: 'Lunch video session - learn more about remote communication'
          }
        ]
      },
      completed: 1,
      profileNotification: false,
      firstOpen: false,
      jobsfound: null,
      profilesFound: null,
      publicProfilesFound: null,
      companyProfilesFound: null,
      jobs: [],
      publicProfiles: [],
      companyProfiles: [],
      bookmarks: [],
      jobFacets: {},
      itemsQuery: '',
      companyPartnersDefaultQuery: '',
      companyPartnerQuery: '',
      publicPartnersDefaultQuery: '',
      publicPartnerQuery: '',
      companyDefaultQuery: '',
      jobsDefaultQuery: '',
      jobsDefaultQueryWithoutInterest: '',
      jobsDefaultQueryLocCat: '',
      jobsQuery: '',
      rows: 16,
      startProfiles: 0,
      startCompanyProfiles: 0,
      startJobs: 0,
      loadJobsVisible: true,
      loadProfilesVisible: true,
      loadCompanyProfilesVisible: true,
      loading: false,
      postSearchDisabled: true,
      filtersOpen: false,
      filterInterested: false,
      filterFlex: 'Very Likely',
      filterLocation: '',
      filterLocationLat: null,
      filterLocationLon: null,
      filterCompany: null,
      defaultJobsQueryDisplay: '',
      extraMessage: '',
      connectionId: 'dummy001',
      connection: {
        id: 'dummy001'
      },
      messages: [
      ]
    }
  },
  methods: {
    ...mapActions([
      'setAction',
      'updateProfile',
      'setPageLoad',
      'setFilters',
      'saveUpdate',
      'deleteUpdate',
      'saveComment',
      'loadUpdateComments',
      'clearUpdateComments',
      'saveInitiative',
      'getUserIdsFromEmails',
      'getCurrentSchedules',
      'saveTempo'
    ]),
    frequencyAvailable (freq, tempo) {
      if (tempo.name === 'Working From') {
        return ['Weekly', 'Every other week'].includes(freq)
      }
      return true
    },
    isMemberInit (memberId) {
      if (this.newInitiative.members) {
        return this.newInitiative.members.includes(memberId)
      }
      return false
    },
    toggleMemberInit (memberId) {
      if (this.newInitiative.members) {
        if (this.newInitiative.members.includes(memberId)) {
          this.newInitiative.members = this.newInitiative.members.filter((id) => id !== memberId)
          this.newInitiative = { ...this.newInitiative }
        } else {
          this.newInitiative.members.push(memberId)
          this.newInitiative = { ...this.newInitiative }
        }
      }
    },
    isMember (memberId) {
      if (this.tempo.members) {
        return this.tempo.members.includes(memberId)
      }
      return false
    },
    toggleMember (memberId) {
      if (this.tempo.members) {
        if (this.tempo.members.includes(memberId)) {
          this.tempo.members = this.tempo.members.filter((id) => id !== memberId)
          this.tempo = { ...this.tempo }
        } else {
          this.tempo.members.push(memberId)
          this.tempo = { ...this.tempo }
        }
      }
    },
    isMemberNew (memberId) {
      if (this.newTempo.members) {
        return this.newTempo.members.includes(memberId)
      }
      return false
    },
    isDayActive (tempo, day) {
      if (tempo.days) {
        return tempo.days.includes(day)
      }
      return false
    },
    toggleDay (tempo, day) {
      if (tempo.days) {
        if (tempo.days.includes(day)) {
          tempo.days = tempo.days.filter(d => {
            return d !== day
          })
          tempo = { ...tempo }
        } else {
          return tempo.days.push(day)
        }
      }
    },
    toggleMemberNew (memberId) {
      if (this.newTempo.members) {
        if (this.newTempo.members.includes(memberId)) {
          this.newTempo.members = this.newTempo.members.filter((id) => id !== memberId)
          this.newTempo = { ...this.newTempo }
        } else {
          this.newTempo.members.push(memberId)
          this.newTempo = { ...this.newTempo }
        }
      }
    },
    editTempo (tempo) {
      this.tempo = tempo
      this.editTempoShowing = true
    },
    onSaveTempo () {
      this.saveTempo({ teamId: this.profile.team, tempoId: this.tempo.id, tempo: this.tempo }).then(() => {
        this.editTempoShowing = false
        this.tempo = null
      })
    },
    onAddTempo () {
      this.saveTempo({ teamId: this.profile.team, tempo: this.newTempo }).then(() => {
        this.addTempoShowing = false
        this.newTempo = {
          name: '',
          title: '',
          question: '',
          members: [],
          frequency: 'Daily',
          day: '',
          time: '9:00',
          active: false
        }
      })
    },
    getNextScheduledDateText (tempo) {
      let nextDate = false
      if (tempo.frequency === 'Daily') {
        nextDate = getNextDay(tempo.days, tempo.lastRun, tempo.time)
      } else if (tempo.frequency === 'Weekly') {
        nextDate = getNextDay([tempo.day], tempo.lastRun, tempo.time)
      } else if (tempo.frequency === 'Every other week') {
        nextDate = getNextDay([tempo.day], tempo.lastRun, tempo.time, 8)
      } else if (tempo.frequency === 'Once a month') {
        nextDate = getFirstDayOfMonth(tempo.day, tempo.lastRun, tempo.time)
      }
      if (nextDate) {
        return `Scheduled to ask the team next on ${dateTimeDisplayFormat(nextDate)}`
      } else {
        return ''
      }
    },
    getTimeslots () {
      const start = '09:30'
      const end = '16:00'
      const timeslots = getTimeslotsBetween(start, end).map(a => a.startTime)
      // console.log('getTimeslots', timeslots)
      return timeslots
    },
    dateFormat (date) {
      return dateDisplayFormat(date.toDate())
    },
    groupDisplay (group) {
      if (group === 'lookingForward') {
        return 'Looking Forward'
      }
      if (group === 'roundTable') {
        return 'Round Table'
      }
      return group
    },
    updateLookingForward () {
      const lfs = this.$refs.lookingforward.getContent()
      for (const update of lfs) {
        if (update.changed) {
          update.group = 'lookingForward'
          this.saveUpdate({ teamId: this.profile.team, updateId: update.id, update })
        }
      }
    },
    updateRoundTable () {
      const rts = this.$refs.roundtable.getContent()
      for (const update of rts) {
        if (update.changed) {
          update.group = 'roundTable'
          this.saveUpdate({ teamId: this.profile.team, updateId: update.id, update })
        }
      }
    },
    getTag (name) {
      if (!name) {
        return '<span class="hashtag">#myInitiative</span>'
      }
      return `<span class="hashtag">#${name.replace(' ', '-')}</span>`
    },
    async getGroups () {
      const scheduleDate = this.currentWeekDate
      const schedules = await this.getCurrentSchedules({ teamId: this.profile.team, date: scheduleDate })
      // console.log('getGroups', schedules)
      this.groups['Coffee Chat'] = []
      this.groups['Peer Plan'] = []
      this.groups['Flash Chat'] = []
      for (const schedule of schedules) {
        if (schedule.coffeeChat) {
          // console.log('groups.coffeeChat:', this.groups['Coffee Chat'])
          if (!this.groups['Coffee Chat'].includes(schedule.userId)) {
            this.groups['Coffee Chat'].push(schedule.userId)
          }
        }
        if (schedule.peerPlan) {
          // console.log('groups.peerPlan:', this.groups['Peer Plan'])
          if (!this.groups['Peer Plan'].includes(schedule.userId)) {
            this.groups['Peer Plan'].push(schedule.userId)
          }
        }
        if (schedule.flashChat) {
          // console.log('groups.flashChat:', this.groups['Flash Chat'])
          if (!this.groups['Flash Chat'].includes(schedule.userId)) {
            this.groups['Flash Chat'].push(schedule.userId)
          }
        }
      }
    },
    getScheduleMessage (initiative) {
      // console.log('getScheduleMessage', initiative)
      if (initiative) {
        if (initiative.members) {
          let topOfficeCount = 0
          let topOffice = ''
          const members = initiative.members
          // console.log('getScheduleMessage.members', members)
          const scheduleDate = this.currentWeekDate
          return this.getCurrentSchedules({ teamId: this.profile.team, date: scheduleDate }).then((schedules) => {
            // console.log('getScheduleMessage.schedules', schedules)
            if (members) {
              schedules = schedules.filter((s) => {
                return members.includes(s.userId)
              })
            }
            const totals = {}
            for (const schedule of schedules) {
              for (const d in schedule.dates) {
                const dstr = this.daysShort[d - 1]

                if (!totals[dstr]) {
                  totals[dstr] = {
                    office: []
                  }
                }

                if (schedule.dates[d].office[0]) {
                  totals[dstr].office.push(schedule.dates[d].office[0])
                }
              }
            }
            for (const tDay of this.daysShort) {
              if (totals[tDay]) {
                if (totals[tDay].office) {
                  if (totals[tDay].office.length > topOfficeCount) {
                    topOfficeCount = totals[tDay].office.length
                    topOffice = this.dayLong[tDay]
                  }
                }
              }
            }
            // console.log('getScheduleMessage.totals', totals)
            if (topOfficeCount > 0) {
              const percent = Math.trunc(100.0 * (topOfficeCount / members.length))
              return `${percent}% of Members in Office on ${topOffice}`
            }
          })
        }
      }
      return ''
    },
    async getSchedule (day, members) {
      const dayStr = this.daysShort[day - 1]
      // console.log('getSchedule Day', day, dayStr)
      const scheduleDate = this.currentWeekDate
      let schedules = await this.getCurrentSchedules({ teamId: this.profile.team, date: scheduleDate })
      // console.log('getSchedule', day, members, schedules)
      if (members) {
        schedules = schedules.filter((s) => {
          return members.includes(s.userId)
        })
      }

      this.officeTeam = []
      this.remoteTeam = []
      this.topOfficeCount = 0
      this.topRemoteCount = 0
      const totals = {}

      let tbcTeam = [this.profile.id]
      if (this.teamProfiles) {
        tbcTeam = this.teamProfiles.map(p => p.id)
      }

      // console.log('DashTeam.getSchedule Schedules', schedules)
      for (const schedule of schedules) {
        for (const d in schedule.dates) {
          const dstr = this.daysShort[d - 1]

          if (!totals[dstr]) {
            totals[dstr] = {
              office: [],
              remote: []
            }
          }

          if (schedule.dates[d].office[0]) {
            totals[dstr].office.push(schedule.dates[d].office[0])
          }
          if (schedule.dates[d].remote[0]) {
            totals[dstr].remote.push(schedule.dates[d].remote[0])
          }
        }
      }
      // console.log('DashTeam.getSchedule Totals', totals)

      for (const tDay of this.daysShort) {
        if (totals[tDay]) {
          if (totals[tDay].office) {
            if (totals[tDay].office.length > this.topOfficeCount) {
              this.topOfficeCount = totals[tDay].office.length
              this.topOffice = tDay
            }
          }
          if (totals[tDay].remote) {
            if (totals[tDay].remote.length > this.topRemoteCount) {
              this.topRemoteCount = totals[tDay].remote.length
              this.topRemote = tDay
            }
          }
        }
      }

      // console.log('DashTeam.getSchedule topOffice', this.topOffice, this.topOfficeCount)
      // console.log('DashTeam.getSchedule topRemote', this.topRemote, this.topRemoteCount)

      if (totals[dayStr]) {
        if (totals[dayStr].office) {
          this.officeTeam = totals[dayStr].office
        }
        if (totals[dayStr].remote) {
          this.remoteTeam = totals[dayStr].remote
        }
        this.tbcTeam = tbcTeam.filter((profile) => ![...this.officeTeam, ...this.remoteTeam].includes(profile))
      }

      // console.log('getSchedule', this.officeTeam, this.remoteTeam, this.tbcTeam)
    },
    commentCount (update) {
      if (update.commentCount) {
        return update.commentCount
      }
      return 0
    },
    likes (update) {
      if (update.likes) {
        return update.likes.includes(this.profile.id)
      }
      return false
    },
    toggleLike (update) {
      if (!update.likes) {
        update.likes = []
      }
      if (update.likes.includes(this.profile.id)) {
        update.likes = update.likes.filter((prof) => prof !== this.profile.id)
      } else {
        update.likes.push(this.profile.id)
      }
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { likes: this.update.likes } })
    },
    isSubscriber (update) {
      if (update.subscribers) {
        return update.subscribers.includes(this.profile.id)
      }
      return false
    },
    subscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (!update.subscribers.includes(this.profile.id)) {
        update.subscribers.push(this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    unsubscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (update.subscribers.includes(this.profile.id)) {
        update.subscribers = update.subscribers.filter((prof) => prof !== this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    statusSelect (val) {
      this.statusOptionsOpen = false
      this.profile.currentStatus = val
      this.updateProfile({ profile: { currentStatus: this.profile.currentStatus }, noSnack: true })
      const self = this
      setTimeout(function () {
        self.loadPageItems()
      }, 1000)
    },
    showUpdate (update, week) {
      // console.log('showUpdate', update, week)
      this.update = update
      if (update) {
        this.updateShowing = true
      }
    },
    addInitiative () {
      // console.log('addInitiative', this.profile.team, this.newInitiative)
      const initiative = { ...this.newInitiative }
      initiative.tag = `#${initiative.name.replace(' ', '-')}`
      this.saveInitiative({ teamId: this.profile.team, initiative })
    },
    addComment (message) {
      // console.log('DashTeam.addComment', message)
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date()
      }
      this.saveComment({ teamId: this.profile.team, updateId: this.update.id, commentId: comment.id, comment })
      this.update.commentCount = this.updateComments.length + 1
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { commentCount: this.update.commentCount } })
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      let prof = {}
      if (this.teamProfiles) {
        const profileMap = new Map(this.teamProfiles.map(i => [i.id, i]))
        prof = profileMap.get(profileId)
        if (!prof) {
          prof = {}
        }
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    setInputFocused () {
    },
    toggleStatusOptions () {
      this.statusOptionsOpen = !this.statusOptionsOpen
    },
    statusText (status) {
      if (status === 'Not Set') {
        return 'Not Set'
      } else if (status === 'Remote') {
        return 'working remotely'
      } else if (status === 'Office') {
        return 'in the office'
      } else if (status === 'Parental Leave') {
        return 'on parental leave'
      } else if (status === 'Sabbatical') {
        return 'on sabbatical'
      }
      return ''
    },
    calcCompleted () {
      if (this.profile) {
        this.completed = 1
        if (this.profile.basicCompleted) {
          this.basic = true
          this.completed++
        }
        if (this.profile.experienceCompleted) {
          this.experience = true
          this.completed++
        }
      }
    },
    async setSection (section) {
      // console.log('Dash.setSection', section)
      this.section = section
    },
    clearFilter (filter) {
      // console.log('DashTeam.clearFilter', filter)
      if (filter === 'jobs' || !filter) {
        this.filterCompany = null
        // console.log('Dash.clearFilter loadPageItems')
        this.loadPageItems('jobs')
      }
    },
    async showSection (section) {
      // console.log('Dash.showSection', section)
      this.$emit('filterSearch', section)
      this.section = section
      if (section !== 'jobs') {
        this.$root.$emit('setSearchFilter', '')
        if (this.filterCompany) {
          if (this.jobs) {
            this.clearFilter('jobs')
          }
        }
      }

      if (!this.query || this.query === '') {
        this.$root.$emit('setSearchQuery', '')
      }
    },
    gotoProfile: function () {
      this.$router.push('/user/' + this.profile.id)
    },
    showCompleteProfileNotification: function () {
      this.$emit('openProfileTasks')
    },
    handleResize () {
      if (window.innerWidth < 450) {
        this.listSize = 4
      } else {
        this.listSize = 8
      }
    },
    handleScroll () {
      if (this.isMobile) {
        if (this.showTooltip) {
          this.showTooltip = false
        }
      }
    },
    finishLoad () {
      setTimeout(() => {
        this.setPageLoad(false)
      }, 300)
    },
    async profilesSearch (query, fq) {
      const res = await backend.search('profiles', {
        query: { query, fq }
      })
      if (res.error) {
        // console.log('Dash.profilesSearch.error', query, res, res.error)
        return
      }
      const result = res.result.response
      // console.log('Dash.profilesSearch', query, res)
      return result
    },
    loadPageItems (section) {
      // console.log('DashTeam.loadPageItems', section)
      let dayIndex = new Date().getDay()
      if (dayIndex === 7 || dayIndex === 0) {
        dayIndex = 1
      }
      this.timeslots = this.getTimeslots()
      this.getSchedule(dayIndex)
      this.getGroups()
      this.setPageLoad(true)
      this.finishLoad()
    },
    mouseOverTooltip () {
      if (!this.profile.hotspotClicked.partners) {
        this.showTooltip = true
      }
    },
    mouseOverTooltipJobs () {
      if (!this.profile.hotspotClicked.jobs) {
        this.showTooltipJobs = true
      }
    },
    updateHotspot (hotspot) {
      if (!this.profile.hotspotSeen[hotspot]) {
        this.profile.hotspotSeen[hotspot] = 1
      } else {
        this.profile.hotspotSeen[hotspot] = this.profile.hotspotSeen[hotspot] + 1
      }
      this.updateProfile({ profile: { hotspotSeen: this.profile.hotspotSeen }, noSnack: true })
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'company',
      'action',
      'filters',
      'locations',
      'isBranded',
      'remote',
      'teamMode',
      'teamProfiles',
      'teamUpdatesLookingForward',
      'teamUpdatesRoundTable',
      'updateComments',
      'initiatives',
      'tempos',
      'team',
      'teamProfiles'
    ]),
    currentWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 1
      return dateYearFormat(new Date(d.setDate(diff)))
    },
    currentWeekDate () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 1
      return new Date(d.setDate(diff))
    },
    nextWeekDate () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) + 6
      return new Date(d.setDate(diff))
    },
    lastWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 7
      return dateYearFormat(new Date(d.setDate(diff)))
    },
    lastWeekDate () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 7
      return new Date(d.setDate(diff))
    },
    teamUpdatesRoundTableCurrent () {
      return this.teamUpdatesRoundTable.filter((update) => {
        return update.date.toDate() >= this.currentWeekDate
      })
    },
    teamUpdatesRoundTableLast () {
      return this.teamUpdatesRoundTable.filter((update) => {
        const betweenDates = update.date.toDate() >= this.lastWeekDate && update.date.toDate() <= this.currentWeekDate
        return betweenDates
      })
    },
    teamUpdatesLookingForwardCurrent () {
      return this.teamUpdatesLookingForward.filter((update) => {
        return update.date.toDate() >= this.currentWeekDate
      })
    },
    teamUpdatesLookingForwardLast () {
      return this.teamUpdatesLookingForward.filter((update) => {
        const betweenDates = update.date.toDate() >= this.lastWeekDate && update.date.toDate() <= this.currentWeekDate
        return betweenDates
      })
    },
    groupUpdatesByWeek () {
      return []
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  async beforeRouteUpdate (to, from, next) {
    next()
    // console.log('Dash.beforeRouteUpdate')
    this.setPageLoad(true)
    this.$emit('setLinks', this.links)
    // setTimeout(() => {
    //   console.log('Dash.beforeRouteUpdate.LoadPageItems:query', this.query)
    //   this.loadPageItems()
    // }, 300)
  },
  mounted () {
    console.log('Dash.mounted:query', this.query)
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true)
    if (this.profile) {
      if (!this.profile.seenIntro) {
        this.firstOpen = true
      }
    }
    this.loadPageItems()
  },
  activated () {
    console.log('Dash.activated:query', this.query, this.search, this.itemsQuery)
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true)

    if (this.itemsQuery !== this.query) {
      if (!this.isBranded && this.profile) {
        this.loadPageItems()
      }
    }
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true)
    this.$root.$emit('setSearchQuery', this.query)
    // console.log('SetSearch in activated Done')
    if (!this.pageActions.includes(this.action) && this.action !== 'team') {
      this.setAction('team')
    }
    this.calcCompleted()
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  created () {
    // console.log('Dash.created', this.query)
    this.setPageLoad(true)
    // set Filters
    this.filterLocation = this.filters.filterLocation || ''
    this.filterLocationLat = this.filters.filterLocationLat
    this.filterLocationLon = this.filters.filterLocationLon
    this.filterFlex = this.filters.filterFlex || 'Very Likely'
    this.filterCompany = this.filters.filterCompany
    if (this.filters.filterInterested) {
      this.filterInterested = this.filters.filterInterested
    } else {
      this.filterInterested = false
    }

    // this.$root.$emit('enableSearch', true)
    // this.$root.$emit('setSearchQuery', this.query)

    if (this.profile) {
      if (!this.isBranded || this.company.assignedCompany) {
        // console.log('Dash.created loadPageItems')
        this.loadPageItems()
      }
      if (!this.profile.seenIntro) {
        this.firstOpen = true
      }
    }
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('touchmove', this.handleScroll, false)
    document.addEventListener('touchstart', this.handleScroll, false)
    this.handleResize()
  },
  destroyed () {
    // console.log('Dash.destroyed', this.query)
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('touchmove', this.handleScroll)
    document.removeEventListener('touchstart', this.handleScroll)
  },
  watch: {
    filterCompany: {
      handler (newf, oldf) {
        if (this.filterCompany) {
          this.$root.$emit('enableSearch', true, { back: true, backAction: 'clearFilter' })
        } else {
          this.$root.$emit('enableSearch', true)
        }
      },
      immediate: true
    },
    query: function (newQ, oldQ) {
      console.log('Dash.watch:query', newQ)
      this.loadPageItems()
    },
    team: function (newT, oldT) {
      if (!oldT) {
        this.loadPageItems()
      } else if (newT.id !== oldT.id) {
        this.loadPageItems()
      }
    },
    action: function (newA, oldA) {
      console.log('Dash.watch:action', newA)
      if (newA !== '') {
        this.showSection(newA)
      }
    },
    update: async function (newU, oldU) {
      if (newU) {
        this.loadUpdateComments({ teamId: this.profile.team, updateId: this.update.id })
      } else {
        this.clearUpdateComments()
      }
    },
    profile: function (newP, oldP) {
      // console.log('Dash.watch:profile', newP)
      if (this.profile) {
        if (!this.profile.seenIntro) {
          this.firstOpen = true
        }
        if (!oldP) {
          // console.log('Dash.watch profile - loadPageItems', this.isBranded, this.assignedCompany)
          if (!this.isBranded || this.assignedCompany) {
            this.loadPageItems()
          }
          this.loadBookmarks()
        } else if (newP.bookmarks !== oldP.bookmarks) {
          if (this.action === 'bookmarked') {
            this.loadBookmarks()
          }
        }
      }
    },
    assignedCompany: function (newAC, oldAC) {
      if (this.assignedCompany && !oldAC) {
        if (this.profile) {
          // console.log('Dash.watch assignedCompany - loadPageItems', this.assignedCompany.name)
          this.loadPageItems()
        }
      }
    }
  }
}
</script>

<style>
.button-row.weekly-buttons .round-button {
  min-width: 60px;
}
.button-row .mdc-button {
  margin:6px;
}
@media (max-width: 550px) {
  .button-row.weekly-buttons {
    flex-flow: row wrap;
  }
}
@media (max-width: 480px) {
  .button-row .mdc-button {
    margin:3px;
  }
}

@media (max-width: 340px) {
  .button-row{
    flex-flow: row wrap;
  }
}

.dash-team .member-text.lighter {
  opacity:0.6 !important;
}
.dash-team .default-avatar.grayscale .default-text {
  filter: grayscale(1) !important;
  opacity:0.8 !important;
}
.dash-team .avatar img.grayscale {
  filter: grayscale(1) !important;
  opacity:0.8 !important;
}
.dash-team .member-row {
  display:flex;
  width:100%;
  align-items:center;
}
.dash-team .member-row .member-text {
  margin-left:16px;
  margin-right:auto;
}
.dash-team .member-row .member-checkbox {
  margin-left:auto;
}
.dash-team .add-init-modal .mdc-radio-wrapper label {
  font-size:var(--mdc-typography-subtitle2-font-size, 0.875rem);
}
.dash-team .weekly-buttons {
  display:flex;
}
.dash-team .app-dialog .footer-wrapper .hashtag {
  padding-left:6px;
  color:#333;
}
.dash-team .form-section {
  margin-top:24px;
}
.dash-team .app-text-field {
  width:100%;
}
.dash-team .initiative-add-wrapper {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  border-radius:13px;
  cursor:pointer;
}
.dash-team .update-input-modal .mdc-dialog__surface {
  height: 100%;
}
.dash-team .update-modal .mdc-dialog__surface {
  position: relative;
}
.dash-team .update-modal .mdc-dialog__surface{
  margin-bottom:6px;
}
.dash-team .update-input-modal .mdc-dialog__surface  .mdc-dialog__content  {
  padding-left:0;
  padding-right:0;
}
.dash-team .update-modal .mdc-dialog__surface .bottom-bar-wrapper {
  min-height: 42px;
  max-width: unset;
  z-index:2;
}
.dash-team .update-modal .mdc-dialog__surface .bottom-bar-wrapper .bottom-bar {
  padding-right: 0;
}
.dash-team .update-modal.with-messagebar .mdc-dialog__content {
  margin-bottom:40px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dash-team .message-bar-spacer {
  height:30px;
  width: 100%;
}
.mdc-title {
  margin-top:24px;
}

.update-reply-count-wrapper {
  display:flex;
  align-items: center;
  margin-right:6px;
  padding-right:6px;
  align-self: stretch;
}

.update-reply-count {
  border-radius:16px;
  width:26px;
  height:26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
}

.avatar {
    width: 31px;
    height: 31px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.add-init-modal .avatar {
  width: 21px;
  height: 21px;
  box-shadow: 0 0 0 0.5px #979797;
}
.avatar.add {
  cursor:pointer;
}
.avatar.add:hover {
  box-shadow: 0 0 0 1.5px #979797;
}

.social-interest-section {
  margin:12px;
  width:100%;
  max-width: 800px;
}
.social-interest-section .social-tick {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0 !important;
    margin-left: -16px;
    font-size: 32px;
}
.social-interest-section .interest-row {
  display:flex;
}
.social-interest-section .interest-row > div {
  display:flex;
  flex-flow: row wrap;
  align-items: center;
}
.social-interest-section .interest-row > div > span {
  padding: 12px;
  white-space:normal;
}

.additional {
  color:rgba(0, 0, 0, 0.54);
  display:flex;
  margin-bottom:32px;
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
}
.additional > span {
  display:flex;
}

.filter-modal .toggle-row {
  display:flex;
  width:100%;
  justify-content: center;
}
.filter-modal h4 {
  flex:1;
  margin-bottom:12px;
  margin-top:0px;
}

.filter-modal .autocomplete.mdc-text-field.outlined .mdc-text-field__input.autocomplete-input::placeholder {
  color: #979797 !important;
  opacity:1 !important;
}

.mobile-links-wrapper {
  margin-left:-16px;
  margin-right:-16px;
  overflow:hidden;
  height:50px;
}
.mobile-links {
  display: flex;
  justify-content:space-between;
  flex-wrap: nowrap;
  margin-top:8px;
  padding-bottom: 10px;
  height:90px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.mobile-links .mobile-link {
  padding-left:12px;
  padding-right:6px;
  flex-grow:1;
  flex-shrink:0;
  margin-left:6px;
  margin-right:6px;
  margin-bottom:2px;
  margin-top:2px;
}
.mobile-links .mobile-link .material-icons {
    margin: 2px;
    margin-top: 0;
    margin-left: 2px !important;
}

.section-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
}

.section-sub-title {
  padding-left:var(--mdc-layout-grid-margin-desktop, 24px);
  padding-right:var(--mdc-layout-grid-margin-desktop, 24px);
  margin-bottom:0;
  margin-top:0;
}

.featured-card-link {
  text-decoration: none;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}
.job-card-link {
  text-decoration: none;
  color: #333;
  margin-top: 0;
  width: 100%;
}

@media (min-width: 481px) and (max-width:569px) {
  .mobile-links-wrapper .mobile-links .mobile-link:first-child {
    margin-left:32px;
  }
}

@media (max-width:840px) {
  .additional {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section-title {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section-sub-title {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
  .section > .mdc-layout-grid {
    padding-left:var(--mdc-layout-grid-margin-tablet, 16px);
    padding-right:var(--mdc-layout-grid-margin-tablet, 16px);
  }
}

@media (max-width: 480px) {
  .section-title {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .section-sub-title {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .additional {
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .mobile-links {
    padding-left:0px;
    padding-right:0px;
  }
  section > .mdc-layout-grid {
    padding-left:0;
    padding-right:0;
  }
  .not-mobile {
    display:none;
  }
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */

@media (min-width: 481px) and (max-width: 595px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 8;
  }
}
@media (min-width: 595px) and (max-width: 671px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 12;
  }
}
@media (min-width: 840px) and (max-width: 920px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 920px) and (max-width:   1180px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 6;
  }
}
@media (min-width: 1180px) and (max-width:   1380px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 4;
  }
}
@media (min-width: 1480px) {
  .profiles, .jobroles, .companies {
      grid-column-end: span 3;
  }
}
</style>
<style>
.dash .status-row {
  display:flex;
  flex-flow: row;
  align-items: flex-start;
}
.dash .status-button .material-icons {
  transition: transform 0.6s ease-in-out;
  transform: rotate(0deg);
}

.dash .status-button .material-icons.rotate {
  transform: rotate(180deg);
  transition: transform 0.6s ease-in-out;
}

.dash .status-button {
  color:white;
  width:100%;
  display:flex;
}

.dash .status-button .material-icons.mdc-button__icon {
  outline: none !important;
}

.dash .status-button-content {
  flex:1;
  text-align: left
}
.dash .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
}
.dash .status-button-dropdown {
  margin-left: 2px;
  margin-right: 2px;
  transition: max-height 1s;
  transition-delay: 100ms;
  overflow: hidden;
  max-height:400px;
  background:white;
}

.dash .status-button-dropdown-wrapper {
  transition: height 1s;
  transition-delay: 0;
  height: 260px;
  overflow: hidden;
  margin: 2px;
  position: absolute;
  z-index: 9;
}

.dash .status-button-dropdown.hidden {
  max-height: 0;
  transition-delay: 0;
}
.dash .status-button-dropdown-wrapper.hidden {
  height: 15px;
  transition-delay: 100ms;

}

.dash .status-button-dropdown .mdc-list-item__secondary-text span {
  display: inline-block;
  margin: 0;
  font-size: 12px;
}
.dash .status-button-dropdown .mdc-list-item__secondary-text::before {
  display:none;
}
.dash .mdc-list .mdc-list-item__graphic {
  margin-top: 8px;
  margin-bottom: auto;
}
.dash .not-set {
  background-color: #880E4F;
}
.dash .remote {
  background-color: #3560AF;
}
.dash .office {
  background-color: #008080;
}
.dash .parental-leave {
  background-color: #FF6C00;
}
.dash .sabbatical {
  background-color: #FFD12B;
}

.dash .not-set-icon {
  color: #880E4F;
}
.dash .remote-icon {
  color: #3560AF;
}
.dash .office-icon {
  color: #008080;
}
.dash .parental-leave-icon {
  color: #FF6C00;
}
.dash .sabbatical-icon {
  color: #FFD12B;
}

.dash .jobs-hotspot .wrapper {
  margin:0;
}
.dash .partners-hotspot .wrapper {
  margin:0;
}
.dash .jobs-hotspot .wrapper i, .dash .partners-hotspot .wrapper i{
  margin-top: -0.01px;
  margin-left: 0.36px;
}
.dash .filter-modal .autocomplete-input {
  margin-left:26px !important;
}
.dash .filter-modal .autocomplete-results {
  margin-top:26px !important;
}
.dash .filter-modal .toggle-row label {
  padding-right:16px;
  order:1;
  display:none;
}
.dash .filter-modal .toggle-row .mdc-switch {
  margin-bottom:0px;
  margin-top:3px;
  order:2;
  margin-right:16px;
}

.dash .additional .inline-button {
    margin: 0px !important;
    margin-top:-1px !important;
    padding-left: 6px !important;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 1em;
    line-height: 1em;
    height: 20px !important;
    padding-right: 4px !important;
    text-decoration: underline;
}
.dash .additional .inline-button:hover {
  box-shadow:none !important;
  text-decoration: underline;
}
.dash .highlight-padding.mobileonly {
  height:0px;
  margin-top:12px;
}
@media (any-hover: hover) {
  .dash .mobile-links .mobile-link:hover {
    box-shadow: 0 0 0 1.5px #979797;
  }
}
</style>
