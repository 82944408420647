<template>
  <div class="main-content">
    <div class="main-wrapper">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

        <mdc-card class="share-proposal page-card">
          <mdc-card-primary-action>
            <mdc-card-media class="image-cover" src="/static/img/proposal.png">
            <mdc-card-header>
              <mdc-card-title>Joint Application</mdc-card-title>
              <mdc-card-subtitle>Partnership Agreement</mdc-card-subtitle>
            </mdc-card-header>
            </mdc-card-media>
          </mdc-card-primary-action>

          <mdc-card-actions>
            <mdc-card-action-icons>
            </mdc-card-action-icons>
          </mdc-card-actions>

          <mdc-card-text v-if="duo">
            <section>
              <mdc-tab-bar class="mobile-vertical" @change="onSelected">
                <mdc-tab :active="section === 'Cover Letter'"
                  v-bind:class="{'mdc-tab--active': section === 'Cover Letter'}"
                  >
                  Cover Letter
                </mdc-tab>
                <mdc-tab :active="section === 'Joint Application'"
                  v-bind:class="{'mdc-tab--active': section === 'Joint Application'}"
                  >
                  Joint Application
                </mdc-tab>
                <mdc-tab :active="section === 'Operating Agreement'"
                  v-bind:class="{'mdc-tab--active': section === 'Operating Agreement'}"
                  >
                  Operating Agreement
                </mdc-tab>
                <mdc-tab :active="section === 'Learn Moret'"
                  v-bind:class="{'mdc-tab--active': section === 'Learn More'}"
                  >
                  Learn More
                </mdc-tab>
              </mdc-tab-bar>
            </section>

            <section v-show="section === 'Cover Letter'">
            <div class="cover-letter-icons">
              <div style="text-align:center">
                <img src="/static/img/icons/briefcase.png" alt="Experience" />
                <div style="color:#4F4F4F; font-size:0.9em;">Experience</div>
                <div>{{totalExp}}</div>
              </div>
              <div style="text-align:center">
                <img src="/static/img/icons/education.png" alt="Education" />
                <div style="color:#4F4F4F; font-size:0.9em;">Education</div>
                <div>{{getEducation()}}</div>
              </div>
              <div style="text-align:center">
                <img src="/static/img/icons/watch.png" alt="Schedule" />
                <div style="color:#4F4F4F; font-size:0.9em;">Flex Schedule</div>
                <div>{{getFlexSchedue()}}</div>
              </div>
              <div style="text-align:center">
                <img src="/static/img/icons/work.png" alt="Work" />
                <div style="color:#4F4F4F; font-size:0.9em;">Work Style</div>
                <div>{{getWorkStyle()}}</div>
              </div>
            </div>

            <div v-if="duoCoverId"><span v-html="duo.covers[duoCoverId].text"></span></div>
            <div v-else><span v-html="duo.coverText"></span></div>

            </section>

            <section v-show="section === 'Joint Application'">
              <div v-if="shareAgreement">
                <div v-for="(profile, index) in shareAgreement.userProfiles" :key="index">
                  <mdc-title>Applicant {{index+1}}</mdc-title>
                  <mdc-layout-grid>
                    <mdc-layout-cell span="4">
                      <!-- Profile Card -->
                      <div v-if="profile" class="profile-card-wrapper">
                        <profile-card v-if="profile" :detail="false" :user="{}" :profile="profile"></profile-card>
                        <div v-if="profile.resume">
                          <mdc-chip-set v-if="profile.resume">
                            <div class="resumelink mdc-chip mdc-ripple-upgraded" @click="getCV(profile)">
                              <img class="mdc-chip__icon mdc-chip__icon--leading material-icons" src="/static/img/icons/outline-description.svg"/>
                              <div class="mdc-chip__text">CV / Resume</div>
                            </div>
                          </mdc-chip-set>

                          <div v-if="cvmodalSelected == profile.id">
                            <a ref="download" :href="profile.resume" download="file.docx"></a>
                            <mdc-dialog v-model="cvmodalopen"
                              :title="profile.displayName + ' CV/Resume'" accept="ok"
                              >
                              <iframe v-if="cvmodalopen" :src="cvfile + '#toolbar=0'" width="100%" height="350"></iframe>
                            </mdc-dialog>
                          </div>
                        </div>
                      </div>
                    </mdc-layout-cell>
                    <mdc-layout-cell span="4">
                      <!-- Experience -->
                      <experience-section v-if="profile" :profile="profile" :basic="!profile.experienceDetailed"  :condensed="true" :editable="false"></experience-section>
                    </mdc-layout-cell>
                    <mdc-layout-cell span="4">
                      <!-- Skills -->
                      <skill-section v-if="profile" :profile="profile" :editable="false"></skill-section>
                    </mdc-layout-cell>
                  </mdc-layout-grid>
                </div>
              </div>

            </section>

            <section v-show="section === 'Operating Agreement'">

              <div style="padding-top:24px;padding-bottom:12px;">
                <mdc-body>
                  The operating agreement is a commitment the Duo has made on how the shared job will work. It's based on best practice from other successful Job Shares.
                </mdc-body>
              </div>

              <div class="agreement-section" v-if="shareAgreement">

                <mdc-title>{{shareAgreement.shareType}}</mdc-title>

                <mdc-layout-grid v-if="shareAgreement.shareType === 'Shared Responsibility'">
                  <mdc-layout-cell>
                    <img  src="/static/img/icons/resp_shared.png">
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    This means the tasks or outcomes of the role are mostly shared between the partners.
                    This is the most frequently selected approach.
                  </mdc-layout-cell>
                </mdc-layout-grid>

                <mdc-layout-grid v-if="shareAgreement.shareType === 'Split Responsibility'">
                  <mdc-layout-cell>
                    <img  src="/static/img/icons/resp_split.png">
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell>
                     This means the tasks or outcomes of the role are mostly split between partners.
                     This still requires handover and awareness across partners but is appropriate in some instances:
                    {{shareAgreement.responsibilities}}
                  </mdc-layout-cell>
                </mdc-layout-grid>

                <mdc-layout-grid v-if="shareAgreement.shareType === 'Hybrid'">
                  <mdc-layout-cell>
                    <img  src="/static/img/icons/resp_hybrid.png">
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>

                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    This means that partners work alternate weeks.
                    {{shareAgreement.responsibilities}}
                  </mdc-layout-cell>
                </mdc-layout-grid>

              </div>

              <div class="agreement-section" v-if="shareAgreement">

                <mdc-title>{{shareAgreement.shareSplit}}</mdc-title>

                <mdc-layout-grid v-if="shareAgreement.shareSplit === 'Alternate Weeks'">
                  <mdc-layout-cell>
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>
                    <span>
                      <span style="background-color:#FBD303;padding:6px;">Week 1</span>
                      <span style="background-color:#FBD303;padding:6px;">Week 2</span>
                    </span>
                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    This means that partners work alternate weeks.
                  </mdc-layout-cell>
                </mdc-layout-grid>

                <mdc-layout-grid v-else-if="shareAgreement.shareSplit === 'Split Week'">
                  <mdc-layout-cell>
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>
                    <span  style="background-color:#FBD303;padding:12px;" >
                      Working Week
                    </span>
                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    A split week approach means the Duo will share the 5 working days across the week.  They have initially agreed this schedule.
                  </mdc-layout-cell>

                  <mdc-layout-cell span="8">
                    <div style="flex:2;min-width:400px;border-left:1px solid silver;margin-left:16px;">
                      <table class="share-table" cell-spacing="4">
                        <tr>
                          <th></th>
                          <th v-for="(day, index) in shareAgreement.days" :key="index">
                            {{day}}
                          </th>
                        </tr>
                        <tr v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                          <th>{{slot}}</th>
                          <td style="background-color:#eee" v-for="(day, index) in shareAgreement.days" :key="index">
                            <div class="wrapper">
                              <img v-if="shareAgreement.selected.week1[day][slot].selected" :src="getProfile(shareAgreement.selected.week1[day][slot].user).photoURL" class="avatar schedule-slot-background">
                            </div>
                          </td>
                        </tr>
                      </table>

                      <div v-if="shareAgreement.scheduleType === 'Two Week Schedule'">
                        <table class="share-table" cell-spacing="4">
                          <tr>
                            <th></th>
                            <th v-for="(day, index) in shareAgreement.days" :key="index">
                              {{day}}
                            </th>
                          </tr>
                          <tr v-for="(slot, index) in shareAgreement.daySlots" :key="index">
                            <th>{{slot}}</th>
                            <td style="background-color:#eee" v-for="(day, index) in shareAgreement.days" :key="index">
                              <div class="wrapper">
                                <img v-if="shareAgreement.selected.week2[day][slot].selected" :src="getProfile(shareAgreement.selected.week2[day][slot].user).photoURL" class="avatar schedule-slot-background">
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </mdc-layout-cell>
                </mdc-layout-grid>

                <mdc-layout-grid v-else-if="shareAgreement.shareSplit === 'Split Day'">
                  <mdc-layout-cell>
                    <div v-if="shareAgreement" class="avatar-group">
                      <span v-for="(profile, index) in shareAgreement.userProfiles" :key="index" class="avatar"><img :src="profile.photoURL"/></span>
                    </div>
                    <span  style="background-color:#FBD303;padding:12px;">
                      AM / PM
                    </span>
                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    A split day approach means the Duo will share the working day across the week.  They have initially agreed this schedule.
                  </mdc-layout-cell>
                </mdc-layout-grid>

                <mdc-layout-grid v-else>
                  <mdc-layout-cell>
                    <span  style="background-color:#FBD303;padding:12px;">
                      {{shareAgreement.shareSplit}}
                    </span>
                  </mdc-layout-cell>
                  <mdc-layout-cell>
                    {{shareAgreement.otherSchedule}}
                  </mdc-layout-cell>
                </mdc-layout-grid>

              </div>

              <div v-if="shareAgreement" class="agreement-section">

                <mdc-title>Commitment</mdc-title>

                <p class="commitment" v-if="shareAgreement.commitmentHandover">
                  <mdc-icon icon="done" />
                  <span>
                    We agree to provide timely and effective handovers to ensure that both parties are
                    effective and informed when they are the primary person in the role.
                  </span>
                </p>

                <p class="commitment" v-if="shareAgreement.commitmentFeedback">
                  <mdc-icon icon="done" />
                  <span>
                    We agree to routinely discuss feedback we receive about how the Job Share
                    operates with an aim to improve and optimize our joint performance.
                  </span>
                </p>

                <p class="commitment" v-if="shareAgreement.commitmentReview">
                  <mdc-icon icon="done" />
                  <span>
                    We agree to periodically allow time to discuss how we are jointly performing the
                    direction we need to take the joint role.
                  </span>
                </p>

                <p class="commitment" v-if="shareAgreement.commitmentMeetings">
                  <mdc-icon icon="done" />
                  <span>
                    We agree to schedule joint meetings with our manager to make the most effective
                    use of everybody's time.
                  </span>
                </p>

                <p class="commitment" v-if="shareAgreement.commitmentResolve">
                  <mdc-icon icon="done" />
                  <span>
                    We agree to provide honest and fair feedback to our partner with an aim to resolve
                    issues within our partnership as a first priority.
                  </span>
                </p>

                <p class="commitment" v-if="shareAgreement.otherCommitment">
                  <mdc-icon icon="done" />
                  <span>{{shareAgreement.otherCommitment}}</span>
                </p>
              </div>

            </section>

            <section v-show="section === 'Learn More'">
            </section>

          </mdc-card-text>
          <mdc-card-text v-else>
            <mdc-title>{{message}}</mdc-title>
          </mdc-card-text>
        </mdc-card>
        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ProfileCard from '../components/ProfileCard.vue'
import SkillSection from '../components/SkillSection.vue'
import ExperienceSection from '../components/ExperienceSection.vue'
import { differenceYears } from '../utils/time'
import { appFunction } from '../utils/backend'
import { docxFunctionUrl } from '../utils/docx'
export default {
  name: 'ShareProposal',
  components: {
    ProfileCard,
    SkillSection,
    ExperienceSection
  },
  data () {
    return {
      title: 'share-proposal Form',
      links: [],
      message: '',
      duoId: null,
      duoCoverId: null,
      duo: null,
      cvmodalSelected: null,
      cvmodalopen: false,
      editing: [],
      connectionId: null,
      sectionBackup: null,
      section: 'Cover Letter',
      shareAgreementActivity: null,
      totalExp: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters([
      'getProfile'
    ]),
    shareAgreement: function () {
      if (this.shareAgreementActivity) {
        const userProfiles = []
        if (this.shareAgreementActivity.users) {
          for (const prof of this.shareAgreementActivity.users) {
            userProfiles.push(this.getProfile(prof))
          }
        }
        return { ...this.shareAgreementActivity, userProfiles }
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions(
      [
        'setLoading',
        'getDuoConnection',
        'setPageLoad'
      ]
    ),
    getTotalExp () {
      let totalExp = 0
      if (this.shareAgreement) {
        if (this.shareAgreement.userProfiles) {
          for (const prof of this.shareAgreement.userProfiles) {
            if (prof.experienceDetailed) {
              // detailed
              if (prof.experience) {
                for (const exp of prof.experience) {
                  totalExp = totalExp + this.getYearDiff(exp)
                }
              }
            } else {
              // basic
              if (prof.roles) {
                for (const exp of prof.roles) {
                  totalExp = totalExp + Number(exp.experience.replace('+', ''))
                }
              }
            }
          }
          return `${totalExp} Years`
        }
      }
      return ''
    },
    getYearDiff: function (exp) {
      if (exp) {
        if (exp.fromYear && exp.toYear) {
          return differenceYears(exp.fromYear, exp.toYear, exp.fromMonth, exp.toMonth)
        }
      }
      return 0
    },
    getEducation () {
      let totalDegree = 0
      if (this.shareAgreement) {
        for (const prof of this.shareAgreement.userProfiles) {
          if (prof.educationHistory) {
            for (const ed of prof.educationHistory) {
              if (ed.award) {
                totalDegree = totalDegree + 1
              }
            }
          } else {
            if (prof.educationLevel) {
              if (prof.educationLevel.indexOf('degree') >= 0) {
                totalDegree = totalDegree + 1
              }
            }
          }
        }
        return `${totalDegree} Degrees`
      }
      return ''
    },
    getFlexSchedue () {
      return '3 & 2 Day Split'
    },
    getWorkStyle () {
      return 'Office'
    },
    getCV: function (profile) {
      const file = profile.resumeFilename || ''
      if (file.split('.').pop() === 'docx') {
        // this.$refs.download.click()
        this.cvfile = docxFunctionUrl(profile.resumeCipher)
        this.cvmodalopen = true
        this.cvmodalSelected = profile.id
      } else {
        this.cvfile = profile.resume
        this.cvmodalopen = true
        this.cvmodalSelected = profile.id
      }
    },
    onSelected (tabIdx) {
      switch (tabIdx) {
        case 0:
          this.section = 'Cover Letter'
          break
        case 1:
          this.section = 'Joint Application'
          break
        case 2:
          this.section = 'Operating Agreement'
          break
        case 3:
          this.section = 'Learn More'
          break
      }
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.duoId = this.$route.params.id
    this.duoCoverId = this.$route.params.coverId
    this.getDuoConnection({ duoId: this.duoId }).then(async res => {
      if (res) {
        this.duo = res
        this.connectionId = this.duo.connection
        const data = { id: this.duoId, user: this.user.uid, email: this.user.email }
        await appFunction('duo-data', data).then((res) => {
          if (res.error) {
            this.message = res.error
          } else {
            this.shareAgreementActivity = res.shareAgreement
            this.totalExp = this.getTotalExp()
          }
        })
      } else {
        this.message = 'Duo not found'
      }
      setTimeout(() => {
        this.setPageLoad(false)
      }, 600)
    }).catch((ex) => {
      if (!this.user) {
        // Redirect to email-signin
        this.$router.replace(`/email-signin?duo=${this.duoId}&cover=${this.duoCoverId}`)
      } else {
        // Not public
        this.message = 'You do not have permission to view this Duo.'
        setTimeout(() => {
          this.setPageLoad(false)
        }, 600)
      }
    })
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.share-proposal {
  box-sizing: border-box;
  border-radius: 5px;
}
.share-proposal .agreement-section {
  padding-bottom:24px;
}
.share-proposal .question-wrapper {
  padding:16px;
}
.share-proposal .section-notes {
  margin-top:16px;
}
.share-proposal .mdc-text-field--fullwidth {
    width: calc(100% - 10px);
}
.share-proposal select option {
  /* wrap text in compatible browsers */
  -moz-white-space:pre-wrap;
  -o-white-space:pre-wrap;
  white-space:pre-wrap;

  /* hide text that can't wrap with an ellipsis */
  overflow: hidden;
  text-overflow:ellipsis;

  /* add border after every option */
  border-bottom: 1px solid #DDD;
}
.share-proposal .mdc-tab-bar.mobile-vertical {
  margin:0;
}
.share-proposal .trafficlight a {
    line-height: 48px;
}
.share-proposal .trafficlight .mdc-tab:nth-child(1) {
  background-color: #e8f5e9;
}
.share-proposal .trafficlight .mdc-tab:nth-child(2) {
  background-color: #ffecb3;
}
.share-proposal .trafficlight .mdc-tab:nth-child(3) {
  background-color: #ffcdd2;
}

.share-proposal .mdc-tab span img {
  top: 6px;
  position:relative;
}

.share-proposal .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  border-color: #ccc;
}

.share-proposal .profile-card-wrapper {
  max-width: 430px;
}

@media all and (max-width: 1138px) {
  .share-proposal .mdc-tab-bar.mobile-vertical {
    display: flex;
    flex-flow: column;
    height:auto;
  }
  .share-proposal .mdc-tab-bar.mobile-vertical .mdc-tab--active {
    border-bottom: 2px solid black;
  }
  .share-proposal .mdc-tab-bar.mobile-vertical .mdc-tab-bar__indicator {
    display:none;
  }
}

.section-notes .mdc-textfield {
  border-color: #ccc;
}
.section-notes textarea::placeholder {
  color: #333 !important;
}
.section-notes .mdc-text-field__input {
  padding:6px;
}
.question-input .mdc-button {
  margin-top: 6px;
  height: 46px;
}
.question-input .mdc-list {
  height: 200px;
  overflow: hidden scroll;
}
.question-input .mdc-list .mdc-list-item {
  display: flex;
  flex-flow: row;
  height: auto;
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
}
.question-input .mdc-list .mdc-list-item p.active{
  font-weight: 500;
}
.share-proposal .section-notes {
  white-space: pre;
}
@media(max-width: 570px) {
  .share-proposal .mdc-card-header h1 {
    font-size: 2em;
  }
  .share-proposal .mdc-card-header h2 {
    font-size: 1em;
  }
  .share-proposal .mdc-card-header h1,
  .share-proposal .mdc-card-header h2 {
    text-shadow: 0px 2.18764px 2.18764px rgba(0,0,0,0.25);
  }
}
.share-proposal .commitment {
  display:flex;
}
.share-proposal .mdc-icon {
  padding-right:16px;
}
.share-proposal .avatar-group {
  display:flex;
  padding:6px;
  padding-bottom:12px;

}
.share-proposal .avatar-group .avatar {
    width:64px;
    height: 64px;
    border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-left:-6px;
    margin-right:-6px;
}
.share-proposal .avatar-group .avatar img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}
.share-proposal .share-table {
  table-layout: fixed;
}
.share-proposal table.share-table tr th {
  height:60px;
  min-width:60px;
  padding-left:6px;
  padding-right:6px;
}
.share-proposal table.share-table tr td {
  height:60px;
  width:60px;
  background-color:#eee;
  position: relative;
}
.cover-letter-icons {
  display:flex;
  width:100%;
  justify-content:space-evenly;
  margin-top:48px;
  margin-bottom:48px;
}
@media(max-width: 840px) {
  .cover-letter-icons {
    margin-top:24px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .cover-letter-icons > div {
    text-align: center;
    width: 50%;
    margin-top: 24px;
  }
}
</style>
