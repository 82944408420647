<template>
  <div class="message-list-wrapper" id="message-list-wrapper">
    <div class="message-list" id="message-list">
      <mdc-list>
        <template v-for="(message, index) in messages">
          <mdc-list-item class="message"
          :key="message.id">

          <div class="dateline" v-if="!sameday(message.created, previousMessage(index).created)">
              <hr>
              <span v-if="message.created">{{message.created.toDate() | dateDisplayFormat}}</span>
          </div>
          <div class="connection-spacer" v-if="message.fromId !== previousMessage(index).fromId && (sameday(message.created, previousMessage(index).created))"></div>

              <div class="message-text" v-bind:class="{'message-right': message.fromId === user.uid && !isTempoChannel(channelName), 'message-updates': message.isUpdates && message.fromId === user.uid}" >
                  <span v-if="message.fromId !== previousMessage(index).fromId || (!sameday(message.created, previousMessage(index).created))">
                  <span :title="getProfile(message.fromId).displayName" class="avatar avatar-link">
                    <router-link v-if="message.fromId !== 'bot'" :to="'/user/' + message.fromId">
                      <img v-if="hasPhoto(getProfile(message.fromId))" :src="getPhoto(getProfile(message.fromId))"/>
                      <default-avatar v-else fontSize="16px" :profile="getProfile(message.fromId)"/>
                    </router-link>
                    <span v-else>
                      <img v-if="hasPhoto(getProfile(message.fromId))" :src="getPhoto(getProfile(message.fromId))"/>
                      <default-avatar v-else fontSize="16px" :profile="getProfile(message.fromId)"/>
                    </span>
                  </span>
                  </span>

                  <div :class="{'update-header': message.isUpdates && message.fromId === profile.id }">
                    <div>
                    <mdc-body v-if="message.created" tag="span" class="messageTS">
                        <span v-if="message.fromId !== previousMessage(index).fromId || (!sameday(message.created, previousMessage(index).created))">
                          {{getProfile(message.fromId).displayName}}
                        </span>
                        <time-ago refresh long :datetime="message.created.toDate()"></time-ago>
                    </mdc-body>
                    </div>
                    <mdc-title style="margin:0;display:flex;" v-if="message.isUpdates && message.fromId === profile.id">
                      <span>{{channelName}}</span>
                      <round-button hasSlot
                      style="margin:0;margin-left:auto;margin-right:16px;"
                      :high="updateButton === index"
                      :disabled="updateButton !== index"
                      icon="publish"
                      iconOutlined
                      @click="saveUpdates(index)">
                      Update
                      </round-button>
                      <mdc-icon v-if="channelName === 'Looking Forward'" style="cursor:pointer;margin-top:4px;" @click.native="$emit('action', 'editLookingForward', { id: message.requestId, created: message.requestDate })" class="update-icon popout-icon" icon="launch"/>
                      <mdc-icon v-if="channelName=== 'Round Table'" style="cursor:pointer;margin-top:4px;" @click.native="$emit('action', 'editRoundTable', { id: message.requestId, created: message.requestDate })" class="update-icon popout-icon" icon="launch"/>

                    </mdc-title>
                  </div>

                  <div>
                  <mdc-body class="flex-inline" tag="span" typo="body2">

                    <!-- message parts and button tags -->
                    <span class="flex-inline" v-for="(part, pIndex) in messageText(message)" :key="pIndex">
                      <span v-if="part.text" class="message-pre" v-html="part.text"></span>
                      <span v-if="part.buttons">
                        <span v-for="(btn, bIndex) in part.buttons" :key="bIndex">
                          <div v-if="btn.schedule && profile">
                            <schedule :teamId="profile.team" :scheduleId="message.id" :lastScheduleId="message.lastScheduleId"></schedule>
                          </div>
                          <div v-else-if="btn.remote && profile">
                            <remote-connect :teamId="profile.team" :scheduleId="message.id" :lastScheduleId="message.lastScheduleId"></remote-connect>
                          </div>
                          <span v-else-if="!btn.hidden">
                            <v-popover
                              v-if="btn.tooltip"
                              offset="1"
                              delay="100"
                              container='#message-list-wrapper'
                              placement='auto-end'
                              :disabled="false"
                              trigger="hover focus"
                            >
                            <span class="tooltip-target" v-if="btn.disabled">
                              <mdc-chip-set>
                                  <mdc-chip outlined disabled :class="{ disabled: true }">
                                    <i class="material-icons">done</i>
                                    <span v-if="btn.disabledText">{{btn.disabledText}}</span>
                                    <span v-else>{{btn.text}}</span>
                                  </mdc-chip>
                              </mdc-chip-set>
                            </span>
                            <span v-else>
                              <round-button v-if="btn.round" :disabled="actionsDisabled" @click="messageAction(btn, message)" hasSlot :icon="btn.icon" :high="btn.high">{{btn.text}}</round-button>
                              <mdc-chip-set v-else>
                                <mdc-chip outlined
                                    @click.native="messageAction(btn, message)"
                                    :disabled="actionsDisabled"
                                    :class="{ disabled: actionsDisabled }"
                                    >
                                    <i v-if="btn.icon" class="material-icons">{{btn.icon}}</i>
                                    <span>{{btn.text}}</span>
                                </mdc-chip>
                              </mdc-chip-set>
                            </span>

                            <template slot="popover">
                              <mdc-title v-if="btn.tooltipTitle">{{btn.tooltipTitle}}</mdc-title>
                              <div>
                                <p :id="message.id + '-' + bIndex">{{buttonText(btn)}}</p>
                              </div>
                              <div style="width:100%;text-align:right">
                                <mdc-button @click="copyDivToClipboard(message.id + '-' + bIndex)" v-if="btn.tooltipCopyBtn">
                                  <mdc-icon style="height: 24px;" icon="file_copy"/> COPY
                                </mdc-button>
                                <a class="mdc-button" v-close-popover><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                              </div>
                            </template>
                          </v-popover>
                          <div v-else>
                            <span v-if="btn.disabled">
                              <mdc-chip-set>
                                  <mdc-chip outlined disabled :class="{ disabled: true }">
                                    <i class="material-icons">done</i>
                                    <span v-if="btn.disabledText">{{btn.disabledText}}</span>
                                    <span v-else>{{btn.text}}</span>
                                  </mdc-chip>
                              </mdc-chip-set>
                            </span>
                            <span v-else>
                              <round-button v-if="btn.round" :disabled="actionsDisabled" @click="messageAction(btn, message)" hasSlot :icon="btn.icon" :high="btn.high">{{btn.text}}</round-button>
                              <mdc-chip-set v-else>
                                <mdc-chip outlined
                                    @click.native="messageAction(btn, message)"
                                    :disabled="actionsDisabled"
                                    :class="{ disabled: actionsDisabled }"
                                    >
                                    <i v-if="btn.icon" class="material-icons">{{btn.icon}}</i>
                                    <span>{{btn.text}}</span>
                                </mdc-chip>
                              </mdc-chip-set>
                            </span>
                          </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </mdc-body>
                  <update-input
                      :key="'us-' + index"
                      v-if="message.isUpdates && message.fromId === user.uid && reloading !== message.requestId"
                      :checkId="message.requestId"
                      :ref="'updates'+index"
                      disableNotes
                      @updatesChanged="showUpdateButton(index)"
                      @deleteUpdate="onDeleteUpdate"
                      :updateGroup="filterUpdates(channelName, message.requestId)"
                      :group="getGroup(channelName)"
                      :dateDesc="false"
                      :groupByDate="false"
                      :team="profile.team"/>
                  </div>
              </div>
          </mdc-list-item>
        </template>
      </mdc-list>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { dateFormat } from '../utils/time'
import fb from '../store/firebase'
import saveAs from 'file-saver'
import TimeAgo from '../components/TimeAgo.vue'
import DefaultAvatar from '../components/DefaultAvatar'
import UpdateInput from '../components/UpdateInput.vue'
import Schedule from '../components/Schedule.vue'
import RemoteConnect from '../components/RemoteConnect.vue'

export default {
  name: 'MessageSection',
  props: {
    connectionType: {},
    channelName: {},
    connectionId: {},
    connection: {},
    profile: {},
    profiles: {},
    user: {},
    messages: {},
    scrollBottom: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TimeAgo,
    DefaultAvatar,
    UpdateInput,
    Schedule,
    RemoteConnect
  },
  computed: {
    ...mapState([
      'teamMode',
      'teamUpdatesLookingForward',
      'teamUpdatesRoundTable'
    ])
  },
  data () {
    return {
      reloading: false,
      updateButton: false,
      actionsDisabled: false,
      messageList: null
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'loadConnectionMessages',
      'updateConnectionMessage',
      'setLoading',
      'updateConnection',
      'saveUpdate',
      'saveUpdatesBatch',
      'deleteUpdate'
    ]),
    dateFormat (date) {
      return dateFormat(date)
    },
    isTempoChannel (channelName) {
      return ['Looking Forward', 'Round Table', 'Remote Today', 'Working From'].includes(channelName)
    },
    onDeleteUpdate (teamId, updateId) {
      console.log('onDeleteUpdate', teamId, updateId)
      this.deleteUpdate({ teamId: teamId, updateId })
    },
    saveUpdates (index) {
      console.log('MessageSection.saveUpdates', 'updates' + index)
      this.updateButton = false
      const updatesInput = this.$refs['updates' + index]
      if (updatesInput) {
        const updates = updatesInput[0].getContent()
        this.saveUpdatesBatch({ teamId: this.profile.team, updates: updates.filter(up => up.changed) }).then(() => {
          if (this.$refs['updates' + index]) {
            // this.$refs['updates' + index].setUpdated()
          }
        })
      }
    },
    showUpdate (update, week) {
      console.log('showUpdate', update, week)
      this.$emit('showUpdate', update, week)
    },
    showUpdateButton (index) {
      console.log('showUpdateButton', index)
      this.updateButton = index
    },
    updatesChanged (messageId) {
      console.log('updatesChanged', messageId)
      this.reloading = messageId
      const self = this
      setTimeout(function () {
        self.reloading = false
      }, 200)
    },
    getGroup (channelName) {
      if (channelName === 'Looking Forward') {
        return 'lookingForward'
      }
      if (channelName === 'Round Table') {
        return 'roundTable'
      }
    },
    filterUpdates (group, checkId) {
      console.log('MessageSection.filterUpdate', group, checkId)
      if (group === 'Looking Forward') {
        return this.teamUpdatesLookingForward.filter(up => {
          return up.checkId === checkId && up.fromId === this.profile.id
        })
      }
      if (group === 'Round Table') {
        return this.teamUpdatesRoundTable.filter(up => {
          // console.log('Round Table Filter', up)
          return up.checkId === checkId && up.fromId === this.profile.id
        })
      }
      return []
    },
    copyDivToClipboard (divId) {
      const range = document.createRange()
      range.selectNode(document.getElementById(divId))
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.setSnackMessage('Copied')
      window.getSelection().removeAllRanges()
    },
    buttonText (button) {
      let formattedMessage = button.tooltipText || ''
      const profileName = this.profile.firstName || this.profile.displayName
      formattedMessage = formattedMessage.replace('<role>', this.profile.role)
      formattedMessage = formattedMessage.replace('<flexschedule>', this.profile.flexschedule)
      formattedMessage = formattedMessage.replace('<first_name>', profileName)
      const otherUser = this.otherUser()
      const otherName = otherUser.firstName || otherUser.displayName
      const otherRole = otherUser.role
      const otherFlexSchedule = otherUser.flexschedule
      formattedMessage = formattedMessage.replace('<other_role>', otherRole)
      formattedMessage = formattedMessage.replace('<other_flexschedule>', otherFlexSchedule)
      formattedMessage = formattedMessage.replace('<other_first_name>', otherName)
      return formattedMessage
    },
    messageText (message) {
      // Section for replacing variables
      let formattedMessage = message.text
      const otherUser = this.otherUser()
      const otherName = otherUser.firstName || otherUser.displayName
      const profileName = this.profile.firstName || this.profile.displayName
      const otherRole = otherUser.role
      const otherFlexSchedule = otherUser.flexschedule

      if (message.fromId === 'bot' || message.origin === 'bot') {
        if (message.initiatedBy === 'bot') {
          // bot message to users
          formattedMessage = formattedMessage.replace('<role>', this.profile.role)
          formattedMessage = formattedMessage.replace('<flexschedule>', this.profile.flexschedule)
          formattedMessage = formattedMessage.replace('<first_name>', profileName)
        } else if (message.initiatedBy === this.user.uid) {
          // bot message from currentUser
          let messageAdditional = ''
          if (message.match === 'Invite') {
            messageAdditional = `\n ${message.text}`
          }
          formattedMessage = formattedMessage.replace('<role>', otherRole)
          formattedMessage = formattedMessage.replace('<flexschedule>', otherFlexSchedule)
          formattedMessage = (message.textFrom)
            ? message.textFrom.replace('<first_name>', otherName).replace('<role>', otherRole).replace('<flexschedule>', otherFlexSchedule) + messageAdditional
            : formattedMessage.replace('<first_name>', otherName)
        } else {
          // bot message from otherUser
          formattedMessage = formattedMessage.replace('<role>', otherRole)
          formattedMessage = formattedMessage.replace('<flexschedule>', otherFlexSchedule)
          formattedMessage = formattedMessage.replace('<first_name>', otherName)
        }
      } else {
        if (message.fromId === this.user.uid && message.textFrom) {
          formattedMessage = message.textFrom
        }
        if (message.fromId === this.user.uid && message.isUpdates) {
          formattedMessage = ''
        }
      }

      // Section for inserting buttons / chips into the correct place in the message
      let messageParts = [
        {
          text: formattedMessage,
          buttons: []
        }
      ]
      if (message.buttons) {
        for (const button of message.buttons) {
          // for each button the message contains
          if (!button.for || (button.for === 'sender' && message.initiatedBy === this.user.uid) || (button.for === 'receiver' && message.initiatedBy !== this.user.uid)) {
            // button if for this user
            if (button.match) {
              // if it is replacing a tag
              const newParts = []
              for (const part of messageParts) {
                // go through each part of the message and split by the tag
                const messageSplits = part.text.split(button.match)
                if (messageSplits.length > 1) {
                  // if there is more than one split
                  for (const split of messageSplits) {
                    if (split === messageSplits[messageSplits.length - 1]) {
                      // no button the last bit
                      newParts.push(
                        {
                          text: split,
                          buttons: part.buttons
                        }
                      )
                    } else {
                      newParts.push(
                        {
                          text: split,
                          buttons: [button]
                        }
                      )
                    }
                  }
                } else {
                  // no matching tag in this part
                  newParts.push(
                    {
                      text: part.text,
                      buttons: part.buttons
                    }
                  )
                }
              }
              messageParts = newParts
            } else {
              // button isnt matching any tag, add to last part
              messageParts[messageParts.length - 1].buttons.push(button)
            }
          }
        }
      }
      return messageParts
    },
    previousMessage: function (indx) {
      if (this.messages) {
        if (indx >= 1) {
          return this.messages[indx - 1]
        }
      }
      return {}
    },
    messageAction (btn, message) {
      console.log('messageAction', btn, message)
      if (btn.action === '' && btn.tooltip) {
        return
      }
      const [command, param] = btn.action.split(':')
      if (command === 'goto') {
        // Go to page
        if (param === 'learn') {
          this.$router.push(`/${param}`)
        } else {
          this.$router.push(`/${param}/${this.connection.id}`)
        }
      } else if (command === 'setStatus') {
        // Set the connection status directly without messages
        this.updateConnection({ connectionId: this.connection.id, connection: { status: param } })
      } else if (command === 'downloadInvite') {
        // Ical download
        this.setLoading(true)
        this.getICal(this.connectionId, message.scheduleId)
        setTimeout(() => {
          this.setLoading(false)
        }, 2000)
      } else if (command === 'action') {
        // Emit the param to the parent component
        this.$emit('action', param)
      } else {
        // Emit the whole action to the parent component
        this.$emit('action', btn.action, message)
      }

      let btnUpdated = false
      if (btn.choice) {
        // this was a choice, hide other buttons
        for (const otherbtn of message.buttons) {
          if (otherbtn !== btn) {
            otherbtn.hidden = true
          }
        }
        btnUpdated = true
      }
      if (btn.once) {
        // this button can only be pressed once
        btn.disabled = true
        btnUpdated = true
      }
      if (btnUpdated) {
        this.updateConnectionMessage({ messageId: message.id, message: message })
      }
    },
    sameday (day1, day2) {
      if (day2) {
        if (day1) {
          let date1 = day1
          let date2 = day2
          if (date1.toDate) {
            date1 = date1.toDate()
          }
          if (date2.toDate) {
            date2 = date2.toDate()
          }
          return dateFormat(date1) === dateFormat(date2)
        }
      }
      return false
    },
    async getICal (connectionId, scheduleId) {
      const meetingIcal = fb.functions.httpsCallable('meetingIcal')
      const res = await meetingIcal({ connectionId, scheduleId })
      if (res.data.error) {
        return
      }
      const result = res.data.result
      const cal = result.replace(/↵/g, '\n')
      const blob = new Blob([cal], { type: 'text/plain;charset=utf-8' })
      return saveAs(blob, 'duome-meeting.ics')
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      let prof = profileMap.get(profileId)
      if (!prof) {
        prof = {}
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    otherUser: function () {
      if (this.connection) {
        if (this.profile) {
          const usersCopy = Object.assign({}, this.connection.users)
          delete usersCopy[this.profile.id]
          if (Object.keys(usersCopy).length === 1) {
            const profileId = Object.keys(usersCopy)[0]
            return this.getProfile(profileId)
          }
        }
      }
      return {}
    },
    getButtonDisabled: function (message) {
      return message.buttonDisabled === true
    },
    scrollToBottom: function (mutations) {
      const self = this
      if (!self.channelName === 'Looking Forward') {
        if (!self.unread) {
          setTimeout(function () {
            const messages = document.querySelectorAll('.message')
            const lastMessage = messages[messages.length - 1]
            if (lastMessage) {
              lastMessage.scrollIntoView(true)
            }
          }, 200)
        }
      }
    },
    initialize: function () {
      const self = this
      // Get a reference to the div you want to auto-scroll.
      this.messageList = document.querySelector('.message-list')
      if (this.scrollBottom) {
        self.scrollToBottom()
        // Create an observer and pass it a callback.
        const observer = new MutationObserver(self.scrollToBottom)
        // Tell it to look for new children that will change the height.
        const config = { childList: true, subtree: true }
        observer.observe(this.messageList, config)
      }
    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

<style>
.message-text .mdc-chip__text {
  display: flex;
  align-items: center;
  height:24px;
}
.message-text .mdc-chip__text span {
  height:24px;
}
.message-text .mdc-chip__text .material-icons {
  top: -3px;
  position: relative;
  margin-right: 6px;
}
.message-text .mdc-chip {
  background-color: #ffffff;
  border: 1px solid #BDBDBD;
  box-shadow: 0.2px 0.4px 2px rgba(0, 0, 0, 0.2);
  padding-top:6px;
}
.message-text .mdc-chip.disabled {
  background-color: #F3FFFF;
  padding-top:6px;
}

.flex-inline {
  display: inline;
  flex-flow: row wrap;
  align-items: center;
}
.flex-inline .mdc-chip-set{
  display: inline;
  margin:0;
}
.flex-inline .mdc-chip-set .mdc-chip {
  display:inline-block;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.update-header {
  width:100%;
  background-color:#F2F2F2;
  padding:6px;
  margin-left:-6px;
  margin-top:-6px;
  margin-right:-6px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0.503638px 1px 2px rgba(0, 0, 0, 0.25);
}
.update-header .popout-icon {
  opacity:0;
}
.update-header:hover .popout-icon {
  opacity:1;
  color: #999;
}
@media all and (max-width: 570px) {
  .update-header:hover .popout-icon {
    opacity:1;
  }
}
.messagebar_field {
  border:none;
  height:auto;
  margin:0;
}
.message-pre {
  white-space: pre-wrap;
  vertical-align:top;
}

.mdc-drawer-app-content {
  height:100%;
}
main {
  height: calc(100% - 4.5rem);
}
.main-content {
  height:100%;
}
.main-content .main-wrapper {
  height: 100%;
}
.main-content .main-wrapper .message-list{
  height: auto;
  margin-bottom:60px;
}
.avatar {
    width:35px;
    height: 35px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.message {
  min-height:66px;
  overflow: visible;
  height:auto;
  flex-flow: row wrap;
  padding: 0;
  margin-bottom: 12px;
}
.message:first-of-type > .dateline > hr {
  margin-top: -8px;
}
.dateline {
  width:100%;
  font-size:0.8em;
  color:#999;
  text-align: center;
}
.dateline hr {
  position:relative;
  top:19px;
  border-color: #ccc;
  border-top: 0px solid #ccc;
}
.dateline span {
  display:inline-block;
  padding-left:12px;
  padding-right:12px;
  background-color: #fff;
  position: relative;
}
.connection-spacer {
    width: 100%;
    height: 8px;
}
.messageTS {
  font-style: italic;
  font-size: 10px;
}

.message-text  span.messageTS{
  padding-left: 16px;
}
.message-text.message-right span.messageTS {
  padding-left: 0px;
}
.messageName {
  font-weight: 500;
}
.message-list > ul {
  padding-top: 0px;
}
.message-text {
  padding:6px;
  margin:2px 0;
  background-color:#F2F2F2;
  border: 0.25px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0.503638px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 5px;
  width:fit-content;
  position: relative;
}
.dateline + .mdc-layout-grid .message-text {
    border-radius: 0 0 5px 5px;
}
.message-text .avatar {
  position: absolute;
  top: -5px;
  left: -20px;
}
.message-text.message-right .avatar {
  left: auto;
  right: -20px;
}

.message-text.message-right {
  text-align: left;
  margin-left: auto;
  background-color:white
}

.message .mdc-layout-grid {
  padding:2px;
  margin:0px;
  width:100%;
}
.mdc-headline  {
  margin:0;
}
.flexrow {
  display:flex;
  flex-flow: row;
  align-items: center;
}
.flexrow .left{
  flex-grow: 1;
  min-width: 0;
}
.flexrow .right{
  min-width: 0;
  flex: none;
}
.flexrow .left > div {
  width: 100%;
  margin: 0;
  height: 30px;
}
.message-text .v-popover {
  display:inline-block;
}
.message-text.message-updates {
  width: 100%;
  background-color:white
}
</style>
<style>
.mdc-textfield-wrapper.inline {
  width:100%;
}
.mdc-textfield-wrapper.inline .mdc-text-field {
  min-height:30px !important;
  height:auto;
  margin-top:0;
  margin-bottom: 0;
}
.mdc-textfield-wrapper.inline .mdc-text-field .mdc-text-field__input {
  padding:0;
}
.status-menu .mdc-list-item {
  padding:0;
  height:28px;
}
</style>
