<template>
  <div class="main-content signin" :class="{ 'hidden-consent': hideConsent }">

      <div class="back-layer-wrapper">
        <div class="back-layer">

          <highlight-section></highlight-section>

          <mdc-title style="padding-left:16px;margin-bottom:0" v-if="section === 'dummy'">Partner Profiles</mdc-title>

          <mdc-layout-grid v-if="section === 'dummy'">
            <mdc-layout-cell v-for="prof in dummyProfiles" :key="prof.id" span=4 class="profiles">
              <router-link to="/signin" class="profile-card-link">
                <profile-card hideShare class="desktoponly" :profile="prof"></profile-card>
                <profile-card-mobile hideShare class="mobileonly" :profile="prof"></profile-card-mobile>
              </router-link>
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>
      </div>

      <div class="back-overlay"></div>

      <div class="signin-card-wrapper">
      <transition name="fade">
      <mdc-card v-if="loaded" class="signup-card">
        <mdc-card-header>
            R
          <img v-if="isBranded" class="signup-logo" :src="brand.logo" :alt="brand.name" />
          <img v-else class="signup-logo" src="/static/img/logo-black.svg" alt="DuoMe" />
        </mdc-card-header>
        <mdc-tab-bar @change="onSelected">
          <mdc-tab :active="isSignin">Sign In</mdc-tab>
          <mdc-tab :active="!isSignin">Sign Up</mdc-tab>
        </mdc-tab-bar>
        <div v-if="isSignin">

          <div v-if="emailSignin">
            <mdc-body v-if="!isBranded" typo="body2" style="margin-bottom:8px;">
              Sign in with&nbsp;
              <span class="link" @click="signInWithFacebook">Facebook</span>
              ,
              <span class="link" @click="signInWithGoogle">Google</span>
              , or
              <span class="link" @click="signInWithLinkedIn">Linkedin</span>
            </mdc-body>
          </div>
          <div v-else>
            <mdc-button :dense="!desktop" unelevated @click="signInWithFacebook">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'facebook']" />
              Sign In with Facebook
            </mdc-button>
            <mdc-button :dense="!desktop" unelevated @click="signInWithGoogle">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'google']" />
              Sign In with Google
            </mdc-button>
            <mdc-button :dense="!desktop" unelevated @click="signInWithLinkedIn">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'linkedin']" />
              Sign In with Linkedin
            </mdc-button>
            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
          </div>

          <mdc-body v-if="!isBranded" typo="body2" class="or"><span>Or</span></mdc-body>

          <div v-if="emailSignin">
          <mdc-textfield :dense="!desktop" style="width: 300px" type="text" label="Email"
            outlined
            required
            @focus="focused = true"
            iconOutlined
            :valid="isValid"
            v-model="email">
            <mdc-icon v-if="emailValid" slot="trailing-icon" class="icon" outlined icon="email"></mdc-icon>
            <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
          </mdc-textfield>
          <mdc-textfield v-if="!isBranded"
            :dense="!desktop"
            style="width: 300px"
            @focus="focused = true"
            type="password"
            label="Password"
            required minlength=8
            outlined
            iconOutlined
            :valid="isValid"
            helptext-persistent
            v-model="password">
            <mdc-icon v-if="passwordValid" slot="trailing-icon" class="icon" outlined icon="lock"></mdc-icon>
            <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
          </mdc-textfield>

          <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
          <mdc-button :disabled="working" :dense="!desktop" unelevated @click="signIn">Sign In</mdc-button>
          <mdc-button v-if="!isBranded" :dense="true" style="text-transform: capitalize;" @click="forgotPassword">Forgotten password?</mdc-button>
          </div>
          <mdc-body typo="body2" v-else class="link-text">
              <mdc-button-icon style="margin-right:6px;font-size:20px;" outlined>email</mdc-button-icon>
              <span style="margin-right:4px;">Sign In with&nbsp;</span><a class="link" @click="emailSignin=true">Email</a>
          </mdc-body>
          <mdc-text typo="caption" tag="p">
            <span>By signing up you agree to DuoMe's </span>
            <router-link target="_blank" to="/terms">terms and conditions.</router-link>
          </mdc-text>
        </div>

        <div v-else>

          <div v-if="emailSignin">
            <mdc-body v-if="!isBranded" typo="body2" style="margin-bottom:8px;">
              Sign up with&nbsp;
              <span :class="{disabled: !consent}" class="link" @click="signUpWithFacebook">Facebook</span>
              ,
              <span :class="{disabled: !consent}" class="link" @click="signUpWithGoogle">Google</span>
              , or
              <span :class="{disabled: !consent}" class="link" @click="signUpWithLinkedIn">Linkedin</span>
            </mdc-body>
          </div>
          <div v-else>
            <mdc-button :disabled="!consent" :dense="!desktop" unelevated @disabledClick="checkConsent" @click="signUpWithFacebook">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'facebook']" />
              Continue with Facebook
            </mdc-button>
            <mdc-button :disabled="!consent" :dense="!desktop" unelevated @disabledClick="checkConsent" @click="signUpWithGoogle">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'google']" />
              Continue with Google
            </mdc-button>
            <mdc-button :disabled="!consent" :dense="!desktop" unelevated @disabledClick="checkConsent" @click="signUpWithLinkedIn">
              <font-awesome-icon slot="leading-icon" :icon="['fab', 'linkedin']" />
              Continue with Linkedin
            </mdc-button>
            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
          </div>

          <mdc-body v-if="!isBranded" typo="body2" class="or"><span>Or</span></mdc-body>

          <div v-if="emailSignin">
            <mdc-textfield :dense="!desktop" style="width: 300px" type="text" label="First Name"
              required
              outlined
              :valid="isValid"
              @focus="focused = true"
              v-model="firstName">
              <mdc-icon v-if="firstNameValid" slot="trailing-icon" class="icon" outlined icon="perm_identity"></mdc-icon>
              <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
            </mdc-textfield>
            <mdc-textfield :dense="!desktop" style="width: 300px" type="text" label="Last Name"
              required
              outlined
              @focus="focused = true"
              :valid="isValid"
              v-model="lastName">
              <mdc-icon v-if="lastNameValid" slot="trailing-icon" class="icon" outlined icon="perm_identity"></mdc-icon>
              <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
            </mdc-textfield>
            <mdc-textfield :dense="!desktop" style="width: 300px" type="text" label="Email"
              required
              outlined
              @focus="focused = true"
              :valid="isValid"
              v-model="email">
              <mdc-icon v-if="emailValid" slot="trailing-icon" class="icon" outlined icon="email"></mdc-icon>
              <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
            </mdc-textfield>
            <mdc-textfield v-if="!isBranded" :dense="!desktop" style="width: 300px" type="password" label="Password"
              required minlength=8
              outlined
              @focus="focused = true"
              :valid="isValid"
              helptext-persistent
              v-model="password">
              <mdc-icon v-if="passwordValid" slot="trailing-icon" class="icon" outlined icon="lock"></mdc-icon>
              <mdc-icon v-else slot="trailing-icon" class="icon error" outlined icon="error"></mdc-icon>
            </mdc-textfield>

            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>

            <mdc-button :disabled="!consent || working" :dense="!desktop" unelevated @disabledClick="checkConsent" @click="signUp">Sign Up</mdc-button>
          </div>
          <mdc-body typo="body2" v-else class="link-text">
              <mdc-button-icon style="margin-right:6px;font-size:20px;" outlined>email</mdc-button-icon>
              <span style="margin-right:4px;">Sign Up with&nbsp;</span><a class="link" @click="emailSignin=true">Email</a>
          </mdc-body>

          <mdc-text style="margin-top:6px;" typo="caption" tag="p">
            <mdc-checkbox :valid="consent || !helptext" style="padding-left:12px;" v-model="consent"/>
            <span>I agree to DuoMe's </span>
            <router-link target="_blank" to="/terms"> terms and conditions.</router-link>
          </mdc-text>
        </div>
      </mdc-card>
      </transition>

      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import fb from '../store/firebase'
import HighlightSection from '../components/HighlightSection.vue'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
import { initializeProfile } from '../utils/profile'
import { firebaseSettings } from '../settings'
import { mail } from '../utils/backend'
import { emailValidForDomain } from '../utils/role'

const profilesRef = fb.firestore.collection('profiles')
export default {
  name: 'home',
  props: {
    desktop: {
      type: Boolean
    }
  },
  data () {
    return {
      loaded: false,
      working: false,
      isValid: true,
      firstNameValid: true,
      lastNameValid: true,
      emailValid: true,
      passwordValid: true,
      firstName: '',
      lastName: '',
      email: '',
      password: null,
      msg: 'Home',
      isSignin: true,
      emailSignin: false,
      consent: false,
      consentTimeStamp: '',
      helptext: '',
      section: 'dummy',
      focused: false,
      hideConsent: false,
      dummyProfiles: [
        {
          id: 0,
          cardDesign: 'profile-colour-magenta',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 1,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'
        },
        {
          id: 2,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FdyLW0RDgkDfbzH59wQ41wxKdqBu2%2Favatar%2Fface%202.jpeg?alt=media&token=3e6c04e5-2daf-41f0-9b10-be71798a67a1'
        },
        {
          id: 3,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 4,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 5,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 6,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 7,
          cardDesign: 'profile-colour-magenta',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'

        },
        {
          id: 8,
          cardDesign: 'profile-colour-orange',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FdyLW0RDgkDfbzH59wQ41wxKdqBu2%2Favatar%2Fface%202.jpeg?alt=media&token=3e6c04e5-2daf-41f0-9b10-be71798a67a1'
        },
        {
          id: 9,
          cardDesign: 'profile-colour-orange',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'

        },
        {
          id: 10,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 11,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'

        }
      ]
    }
  },
  components: {
    HighlightSection,
    ProfileCard,
    ProfileCardMobile
  },
  computed: {
    ...mapState([
      'isBranded',
      'user',
      'profile',
      'brand'
    ]),
    fullname () {
      return `${this.firstName} ${this.lastName}`
    },
    cookiesSet () {
      return document.cookie.includes('tracking-preferences')
    }
  },
  methods: {
    ...mapActions([
      'setProfile',
      'setSnackMessage',
      'setPageLoad',
      'setRemote'
    ]),
    onSelected (idx) {
      this.isSignin = (idx === 0)
    },
    checkConsent () {
      this.helptext = ''
      if (!this.consent) {
        this.helptext = 'Please agree to DuoMe\'s terms and conditions to continue'
      }
      return this.consent
    },
    resetValidation () {
      this.isValid = true
      this.firstNameValid = true
      this.lastNameValid = true
      this.emailValid = true
      this.passwordValid = true
      this.helptext = ''
    },
    async signUp () {
      if (!this.working) {
        this.working = true
        this.resetValidation()

        this.$nextTick(async () => {
          this.email = this.email.trim()
          if (!this.checkConsent()) {
            this.working = false
            return
          }
          if (!this.firstName) {
            this.isValid = false
            this.firstNameValid = false
          }
          if (!this.lastName) {
            this.isValid = false
            this.lastNameValid = false
          }
          if (!this.email) {
            this.isValid = false
            this.emailValid = false
          } else {
            if (!this.isBranded) {
              this.emailValid = emailValidForDomain(this.email)
              this.isValid = this.isValid && this.emailValid
            }
          }
          if (this.isBranded) {
            if (!this.isValid) {
              this.showAlertMessage('Fields cannot be blank')
              this.working = false
              return
            }
            const mailBody = {
              email: this.email,
              brand: this.brand.id,
              firstName: this.firstName,
              lastName: this.lastName,
              href: window.location.href
            }
            const res = await mail('brand-signup', mailBody)
            if (res.error) {
              this.showAlertMessage(res.error)
              this.working = false
            } else {
              this.showAlertMessage('Email with authentication link has been sent')
              window.localStorage.setItem('emailForSignIn', this.email)
              this.working = false
            }
          } else {
            if (!this.password) {
              this.isValid = false
              this.passwordValid = false
            }
            if (!this.isValid) {
              if (!this.emailValid) {
                this.showAlertMessage('You are not authorised to access, contact <a target="_blank" href="mailto:support@duome.co">support@duome.co</a>')
              } else {
                this.showAlertMessage('Fields cannot be blank')
              }
              this.working = false
              return
            }
            const res = await mail('email-in-brand', { email: this.email })
            if (res.result) {
              this.showAlertMessage('Email belongs to a brand')
              this.working = false
              return
            }
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
              .then(async () => {
                const user = firebase.auth().currentUser
                await user.updateProfile({ displayName: this.fullname })
                this.setRemote(true)
                const profile = { firstName: this.firstName, lastName: this.lastName, consentTimeStamp: this.consentTimeStamp, remoteEnabled: true }
                if (this.cookiesSet) {
                  profile.cookiesTimeStamp = this.cookiesTimeStamp || new Date().toISOString()
                }
                const updated = initializeProfile(profile, user)
                if (updated) {
                  fb.setDocument(profilesRef.doc(user.uid), profile).then(() => {
                    this.setProfile(profile)
                    this.working = false
                  })
                }
              })
              .catch(err => {
                this.showAlertMessage(err)
                this.working = false
              })
          }
        })
      }
    },
    async signIn () {
      if (!this.working) {
        this.working = true
        this.resetValidation()

        this.$nextTick(async () => {
          this.email = this.email.trim()
          if (!this.email) {
            this.isValid = false
            this.emailValid = false
          } else {
            if (!this.isBranded) {
              this.emailValid = emailValidForDomain(this.email)
              this.isValid = this.isValid && this.emailValid
            }
          }

          if (this.isBranded) {
            if (!this.isValid) {
              if (!this.emailValid) {
                this.showAlertMessage('You are not authorised to access, contact <a target="_blank" href="mailto:support@duome.co">support@duome.co</a>')
              } else {
                this.showAlertMessage('Fields cannot be blank')
              }
              this.working = false
              return
            }
            const mailBody = {
              email: this.email,
              brand: this.brand.id,
              href: window.location.href
            }
            const res = await mail('brand-login', mailBody)
            if (res.error) {
              if (res.error === {}) {
                this.showAlertMessage('Account not found')
              } else {
                this.showAlertMessage(res.error)
              }
              this.working = false
            } else {
              this.showAlertMessage('Email with authentication link has been sent')
              window.localStorage.setItem('emailForSignIn', this.email)
              this.working = false
            }
          } else {
            if (!this.password) {
              this.isValid = false
              this.passwordValid = false
            }
            if (!this.isValid) {
              if (!this.emailValid) {
                this.showAlertMessage('You are not authorised to access, contact <a target="_blank" href="mailto:support@duome.co">support@duome.co</a>')
              } else {
                this.showAlertMessage('Fields cannot be blank')
              }
              this.working = false
              return
            }
            const res = await mail('email-in-brand', { email: this.email })
            if (res.result) {
              this.showAlertMessage('Email belongs to a brand')
              this.working = false
              return
            }
            firebase.auth().signInWithEmailAndPassword(this.email, this.password)
              .then(() => {
                this.setRemote(true)
                // this.$router.push('/dash')
                this.working = false
              })
              .catch(err => {
                this.showAlertMessage(err)
                this.working = false
              })
          }
        })
      }
    },
    signInWithGoogle () {
      firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(async () => {
          const user = firebase.auth().currentUser
          const existingProfile = await fb.getDocument(profilesRef.doc(user.uid))
          if (!existingProfile) {
            const profile = { consentTimeStamp: this.consentTimeStamp }
            if (this.cookiesSet) {
              profile.cookiesTimeStamp = this.cookiesTimeStamp || new Date().toISOString()
            }
            const updated = initializeProfile(profile, user)
            if (updated) {
              fb.setDocument(profilesRef.doc(user.uid), profile).then(() => {
                this.setProfile(profile)
              })
            }
          }
        })
        .catch(err => {
          this.showAlertMessage(err)
        })
    },
    signInWithFacebook () {
      const provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('email')
      firebase.auth().signInWithPopup(provider)
        .then(async () => {
          const user = firebase.auth().currentUser
          const existingProfile = await fb.getDocument(profilesRef.doc(user.uid))
          if (!existingProfile) {
            const profile = { photoURL: `${user.photoURL}?width=800`, consentTimeStamp: this.consentTimeStamp }
            if (this.cookiesSet) {
              profile.cookiesTimeStamp = this.cookiesTimeStamp || new Date().toISOString()
            }
            const updated = initializeProfile(profile, user)
            if (updated) {
              fb.setDocument(profilesRef.doc(user.uid), profile).then(() => {
                this.setProfile(profile)
              })
            }
          }
        })
        .catch(err => {
          this.showAlertMessage(err)
        })
    },
    signInWithLinkedIn () {
      window.open('/static/popup.html', 'name', 'height=585,width=400')
    },
    signUpWithGoogle () {
      if (this.checkConsent()) {
        this.signInWithGoogle()
      }
    },
    signUpWithFacebook () {
      if (this.checkConsent()) {
        this.signInWithFacebook()
      }
    },
    signUpWithLinkedIn () {
      if (this.checkConsent()) {
        this.signInWithLinkedIn()
      }
    },
    async forgotPassword () {
      this.resetValidation()
      if (!this.email) {
        this.emailValid = false
        this.showAlertMessage('Please enter your email address')
        return
      }
      const res = await mail('email-in-brand', { email: this.email })
      if (res.result) {
        this.showAlertMessage('Email belongs to a brand')
        return
      }
      const mailBody = {
        email: this.email,
        url: `https://${firebaseSettings.authDomain}`
      }
      mail('password-reset', mailBody).then((res) => {
        if (res.error) {
          this.showAlertMessage(res.error)
        } else {
          this.showAlertMessage('Email with password reset link has been sent')
        }
      })
        .catch(err => {
          this.showAlertMessage(err)
        })
    },
    userFriendlyMessage (err) {
      if (err.code === 'auth/user-not-found') {
        return 'We don\'t recognise that email, have you got another email or do you need to sign up for the first time?'
      } else if (err.code === 'auth/wrong-password') {
        return 'The password is invalid'
      }
      return (err.message) ? err.message : err
    },
    showAlertMessage (err) {
      this.isValid = false
      this.helptext = this.userFriendlyMessage(err)
      this.setSnackMessage(this.helptext)
    }
  },
  mounted () {
    /* eslint-disable no-useless-escape */
    this.loaded = false
    this.$emit('hideBottomNav', true)
    this.$emit('hideTopNav', true)

    if (document.cookie) {
      this.consentTimeStamp = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)consentTs\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      this.cookiesTimeStamp = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)ts\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      this.firstName = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)firstName\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      this.lastName = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)lastName\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      this.email = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      document.cookie = 'firstName=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = 'lastName=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie = 'email=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'

      if (this.consentTimeStamp) {
        this.consent = true
      }

      const tab = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)tab\s*\=\s*([^;]*).*$)|^.*$/, '$1') || '')
      if (tab === 'signin-email') {
        this.isSignin = true
        this.emailSignin = true
      } else if (tab === 'signup-email') {
        this.isSignin = false
        this.emailSignin = true
      } else if (tab === 'signup-social') {
        this.isSignin = false
        this.emailSignin = false
      }
      document.cookie = 'tab=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
    /* eslint-enable no-useless-escape */

    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.add('noscroll')
    }
    if (this.$route.name === 'Signup') {
      this.onSelected(1)
    }
    setTimeout(() => {
      if (this.isBranded) {
        this.emailSignin = true
      }
      this.loaded = true
      this.setPageLoad(false)
    }, 600)
  },
  beforeDestroy () {
    this.$emit('hideBottomNav', false)
    this.$emit('hideTopNav', false)
    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.remove('noscroll')
    }
  },
  watch: {
    profile: function (newp, oldp) {
      if (newp.displayName !== 'Not Authenticated') {
        this.setRemote(true)
        this.$router.replace('/dash', () => {})
      }
    },
    consent: function (newCon, oldCon) {
      if (this.consent) {
        this.helptext = ''
        this.consentTimeStamp = new Date().toISOString()
        // console.log('User Consent Given', this.consentTimeStamp)
      } else {
        this.consentTimeStamp = ''
      }
    }
  }
}
</script>

<style>
.css-17ss3j2 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    letter-spacing: -0.05px;
    position: relative;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 12px;
    line-height: 1.3;
    padding: 8px 40px 8px 8px;
    background: rgb(31, 65, 96);
}
.css-1u2wgn3:not(:last-child) {
    margin-bottom: 6px;
}
.css-1u2wgn3 {
    margin: 0px;
}
.css-1d3ybkx a, .css-1d3ybkx button {
    display: inline;
    color: inherit;
    cursor: pointer;
    padding: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    font: inherit;
    text-decoration: underline;
}

.signin .consent-action-row {
  display:flex;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.signin .fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.signin .fade-enter, .fade-leave-to {
  opacity: 0;
}

.signin .mdc-body {
  color:rgba(0,0,0,.6)
}
.signin .mdc-typography--caption {
  color:rgba(0,0,0,.6)
}
.signin .helptext {
  margin-bottom:0;
  color: #b00020;
}
.signin .helptext * {
  margin-bottom:0;
  color: #b00020 !important;
}
.signin .mdc-button {
  text-transform: none;
}
.signin .mdc-card .mdc-button i.material-icons, .signin .mdc-card .mdc-button svg {
  font-size:24px;
  margin-right:16px;
}
.signin .mdc-card .mdc-button i.material-icons {
  margin-top:-6px;
  margin-right:20px;
}
.signin .or {
  margin-top: 0px;
  margin-bottom:0px;
}
.signin .or span{
  width:auto;
  margin-left:auto;
  margin-right:auto;
  background:white;
  padding-left:16px;
  padding-right:16px;
  font-size:0.9em;
}
.signin .or::before{
  content: '';
  position:absolute;
  width: calc(100% - 48px);
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(0,0,0,.4);
  left:0;
  height: 10px;
  margin-left: 24px;
  margin-right: 24px;
  z-index:-1;
}
.link {
  text-decoration: underline;
  cursor:pointer;
  color:rgba(0,0,0,.6)
}
.link.disabled {
  color:rgba(0,0,0,.34)
}
.link-text {
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-text span, .link-text .material-icons {
  color:rgba(0,0,0,.6)
}
.mdc-layout-app.noscroll {
  overflow:hidden;
}
.mdc-layout-app.noscroll .mdc-layout-app--main-container {
  height:100%;
  overflow:hidden;
}
.mdc-snackbar {
  z-index: 999;
}

.signin .mdc-tab-bar {
  margin: 0px 24px;
  width: auto;
}

.signin .mdc-tab--active {
  background-color: #F2F2F2;
  border-radius: 5px 5px 0px 0px;
}

.signin .mdc-textfield-wrapper {
  margin-top:12px;
}

.signin .mdc-card__primary {
  padding: 16px;
  padding-bottom: 4px;
}

.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #6200ee);
}
.signin .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
  width: 100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height: 50px;
  margin-top:8px;
}
.signin .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) input.mdc-text-field__input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.signin .mdc-button.close i.material-icons {
  margin:0 !important;
}

.signin #consent-message .mdc-button:hover {
  background: rgba(255,255,255,0.3) !important;
}
</style>
<style scoped>
.main-content {
  text-align: center;
  min-height: 500px;
}
.back-layer {
  position:fixed;
  background-color:white;
  width:100%;
  height:100%;
  overflow:visible;
  z-index:0;
  filter:blur(3px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  left:0;
  right:0;
}
.back-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.6);
  position:fixed;
  left:0;
  top:0;
  overflow: hidden;
  z-index:999;
}

.signin-card-wrapper .mdc-card {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  z-index:999;
  position: relative !important;
  height:auto;
  overflow-y:auto;
  margin-top: auto;
  margin-bottom: auto;
  padding:6px;
}

.signin-card-wrapper {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  height:100%;
  width:100%;
  z-index:999;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}

.signin .mdc-card .mdc-button {
  height: 54px;
  width: 300px;
  margin-top:16px;
}

.signup-logo {
  width:unset;
  height:45px;
  max-width:200px;
  text-align: center;
  padding: 16px;
}

#consent-message .close-button-wrapper {
  position:absolute;
  display: flex;
  justify-content: flex-start;
  right:0;
}

#consent-message .close-button-wrapper .close {
  text-decoration: none;
  border-bottom: 0;
  height: 31px;
  margin: 0;
  margin-right:6px;
  color: white;
}

.main-content.signin {
  margin-top:-30px;
  margin-bottom:30px;
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */

@media (min-width: 481px) and (max-width: 595px) {
  .profiles {
      grid-column-end: span 8;
  }
}
@media (min-width: 595px) and (max-width: 671px) {
  .profiles {
      grid-column-end: span 12;
  }
}
@media (min-width: 840px) and (max-width: 920px) {
  .profiles {
      grid-column-end: span 6;
  }
}
@media (min-width: 920px) and (max-width:   1180px) {
  .profiles {
      grid-column-end: span 6;
  }
}
@media (min-width: 1180px) and (max-width:   1380px) {
  .profiles {
      grid-column-end: span 4;
  }
}
@media (min-width: 1480px) {
  .profiles {
      grid-column-end: span 3;
  }
}

@media (min-width: 840px) {
  .back-layer {
    width: calc(100% - 240px);
    left: 240px;
  }
}

@media (max-height: 740px) {
  .signup-logo {
    height:45px;
    max-width:200px;
    width: unset;
    text-align: center;
    padding: 10px;
  }
}

</style>
<style>
@media (max-width: 570px), (max-height: 570px){
  .signin .mdc-card .mdc-button {
    height: 48px !important;
    margin-top:10px !important;
  }
  .signin .mdc-card__primary {
    padding-top: 4px;
  }
  .signin .mdc-textfield-wrapper {
    margin-top:10px;
  }
  .signin .or::before{
    margin-bottom: 6px;
  }
  .signin .mdc-tab {
    height:48px
  }
}
</style>
