<template>
  <div class="main-content">
    <div class="main-wrapper">
      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell v-if="ready" span=12>

            <mdc-card class="scheduler page-card">
              <mdc-card-primary-action>
                <mdc-card-media class="image-cover" src="/static/img/schedule.png">
                  <mdc-card-header>
                    <mdc-card-action-icon style="color:white" @click="$router.go(-1)" icon="arrow_back_ios" />
                    <mdc-card-title>Schedule Meeting</mdc-card-title>
                    <mdc-card-subtitle>Arrange dates and times</mdc-card-subtitle>
                  </mdc-card-header>
                </mdc-card-media>
              </mdc-card-primary-action>

              <mdc-card-actions>
                <mdc-card-action-icons>
                </mdc-card-action-icons>
              </mdc-card-actions>

              <mdc-card-text v-if="schedule">
                <div v-if="schedule.confirmed">

                  <mdc-button @click="changeSelectedDates" v-if="schedule.datesSetBy == user.uid">Change Dates / Times </mdc-button>
                  <mdc-layout-grid>
                    <mdc-layout-cell v-for="(datetime, index) in datetimes" :key="index">
                      <mdc-card  v-bind:class="{ disabled: !isDateSelected(profile.displayName, datetime.date, datetime.time) && !(schedule.datesSetBy == user.uid) && (schedule.selectedTimeslots)}">
                        <mdc-card-header :title="datetime.date">
                        </mdc-card-header>
                        <mdc-card-text>
                          {{datetime.time}}
                          <div v-if="schedule.availability">
                            <div v-if="profile">
                              <mdc-list>
                                <mdc-list-item
                                  style="padding-left:0;height:auto"
                                  v-for="(available, participant) in schedule.availability[datetime.date][datetime.time]"
                                  :key="participant"
                                  >
                                  <div v-if="participant !== profile.displayName && available">
                                    <mdc-body v-if="schedule.selectedTimeslots" typo="body2" style="width:100%;padding-right:8px;">
                                      <span>{{participant}}</span>
                                      <span v-if="schedule.selectedTimeslots[participant]">
                                        <span v-for="(slot, index) in schedule.selectedTimeslots[participant]" :key="index">
                                          <span v-if="getTimeslot(index, datetime.date, datetime.time) !== null">
                                          {{getTimeslot(index, datetime.date, datetime.time)}}
                                          <mdc-icon style="margin-left: 6px;top: 6px;position: relative;"  icon="check_box"/>
                                          <div v-if="user.uid === schedule.datesSetBy">
                                            <div>
                                              <mdc-button
                                                v-if="!schedule.notified"
                                                style="padding-left:0"
                                                @click="notifySchedule(datetime.date + ' ' + getTimeslot(index, datetime.date, datetime.time))"
                                                >
                                                Confirm &amp; Notify
                                              </mdc-button>
                                              <div style="padding-top:12px;" v-else>
                                                <span v-if="schedule.confirmedTimeslot ===  datetime.date +' '+ getTimeslot(index, datetime.date, datetime.time)">
                                                  <b>Notification Sent</b>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          </span>
                                        </span>
                                      </span>
                                    </mdc-body>
                                  </div>
                                </mdc-list-item>
                                <mdc-list-item style="height:auto;padding-left:0;" v-if="profile">
                                  <mdc-body typo="body2" v-if="user.uid !== schedule.datesSetBy" style="width:100%;margin-top:0px;margin-bottom:0px;">
                                    <span>{{profile.displayName}}</span>
                                    <div v-if="!schedule.notified">
                                      <mdc-chip-set choice style="width:100%;">
                                        <span v-for="(timeslot, index) in getTimeslots(datetime.time)" :key="index">
                                          <mdc-chip
                                            @selected="toggleTimeslot($event, datetime.date, datetime.time, timeslot.startTime)"
                                            :pre-select="isSelected(profile.displayName, datetime.date, timeslot.startTime)">
                                            {{timeslot.startTime}}
                                          </mdc-chip>
                                        </span>
                                      </mdc-chip-set>
                                    </div>
                                    <div v-else>
                                      <span v-for="(timeslot, index) in getTimeslots(datetime.time)" :key="index">
                                        <span v-if="schedule.confirmedTimeslot ===  datetime.date +' '+ timeslot.startTime">
                                          <div style="margin-top:12px;">
                                            <div><b>Confirmed</b></div>
                                            <div>{{schedule.confirmedTimeslot}}</div>
                                          </div>
                                        </span>
                                      </span>
                                    </div>
                                  </mdc-body>
                                </mdc-list-item>
                              </mdc-list>
                            </div>
                          </div>
                        </mdc-card-text>
                      </mdc-card>
                    </mdc-layout-cell>
                  </mdc-layout-grid>
                  <div v-if="schedule.datesSetBy !== user.uid">
                    <div v-if="schedule.confirmedSelection && !schedule.notified">
                      <mdc-button v-if="!schedule.confirmedSelection[profile.displayName]" style="padding-left:0;margin-left:24px;" @click="selectTimeslot">Confirm</mdc-button>
                      <mdc-button v-else style="padding-left:0;margin-left:24px;" @click="unselectTimeslot">Change Selected Time</mdc-button>
                    </div>
                  </div>
                </div>
                <div v-else class="flexrow">
                  <div class="flex-left schedule-picker">
                    <datepicker :highlighted="highlighted" v-model="datepicked" :inline="true" @input="dateSelected"></datepicker>
                  </div>
                  <div class="flex-right">

                    <mdc-card>
                      <mdc-card-header
                        title="Available Times"
                        subtitle="Add meeting times for selected Dates" >
                      </mdc-card-header>
                      <mdc-card-text>
                        <div v-if="schedule.simpletime">
                          <mdc-title>{{schedule.selectedDates.length}} Dates Selected</mdc-title>
                          <div v-if="schedule.selectedDates.length > 0">
                            <div v-for="(time, index) in schedule.times" :key="index">
                              <mdc-select v-model="time.startTime" label="start time">
                                <select-item v-for="(timeslot, index) in timeslotsBefore(time.endTime, true)" :key="index" :value="timeslot" />
                              </mdc-select>
                              <mdc-select v-model="time.endTime" label="end time">
                                <select-item v-for="(timeslot, index) in timeslotsAfter(time.startTime, true)" :key="index" :value="timeslot" />
                              </mdc-select>
                              <span class="del-wrapper" ><mdc-card-action-icon v-if="index > 0" @click="removeTimeslot(schedule, index)" icon="clear"/></span>
                            </div>
                            <mdc-card-action-icon @click="addTimeslot(schedule)" icon="add"/>
                            <br/>
                            <mdc-button @click="schedule.simpletime=false">Or set times for each date</mdc-button>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="schedule.selectedDates.length > 0">
                            <div v-for="(date, index) in schedule.selectedDates" :key="index">
                              <mdc-title>
                                <span>{{date.dateFormatted}}</span>
                                <mdc-card-actions style="float:right">
                                  <mdc-card-action-icons>
                                    <mdc-card-action-icon @click="removeDateIndex(index)" icon="clear" />
                                  </mdc-card-action-icons>
                                </mdc-card-actions>
                              </mdc-title>
                              <div v-for="(time, index) in date.times" :key="index">
                                <mdc-select v-model="time.startTime" label="start time">
                                  <select-item v-for="(timeslot, index) in timeslotsBefore(time.endTime, true)" :key="index" :value="timeslot" />
                                </mdc-select>
                                <mdc-select v-model="time.endTime" label="end time">
                                  <select-item v-for="(timeslot, index) in timeslotsAfter(time.startTime, true)" :key="index" :value="timeslot" />
                                </mdc-select>
                                <span class="del-wrapper" ><mdc-card-action-icon v-if="index > 0" @click="removeTimeslot(date, index)" icon="clear"/></span>
                              </div>
                              <mdc-card-action-icon @click="addTimeslot(date)" icon="add"/>

                            </div>
                            <br/>
                            <mdc-button @click="schedule.simpletime=true">Or set times for all dates</mdc-button>
                          </div>
                        </div>
                      </mdc-card-text>
                      <mdc-button @click="confirmSchedule">Confirm</mdc-button>

                    </mdc-card>

                  </div>
                </div>
              </mdc-card-text>
            </mdc-card>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateExtFormat, parseDatetoTS, getTimeslotsBetween, dateTimeArrayFormat } from '@/utils/time'

function range (start, end, step) {
  const _end = end || start
  const _start = end ? start : 0
  const _step = step || 1
  return Array((_end - _start) / _step).fill(0).map((v, i) => _start + (i * _step))
}

export default {
  name: 'Scheduler',
  data () {
    return {
      links: [],
      ready: false,
      validationMessage: '',
      datepicked: new Date(),
      timeslots: [],
      schedule: null,
      scheduleId: null,
      scheduleActivity: null,
      scheduleDefault: {
        confirmed: false,
        notified: false,
        confirmedSelection: {},
        selectedDates: [],
        confirmedTimeslot: {},
        selectedTimeslots: {},
        simpletime: true,
        times: [{
          startTime: null,
          endTime: null
        }]
      },
      highlighted: {
        customPredictor: this.highlightDate
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      profile: state => state.profile,
      schedules: state => state.connectionActivities.schedules
    }),
    selected: function () {
      if (this.profile) {
        if (this.scheduleActivity) {
          if (this.scheduleActivity.datesSetBy !== this.user.uid) {
            for (const date in this.schedule.availability) {
              for (const time in this.schedule.availability[date]) {
                if (this.schedule.availability[date][time][this.profile.displayName]) {
                  return parseDatetoTS(date + ' ' + time)
                }
              }
            }
          }
        }
      }
      return false
    },
    datetimes: function () {
      const datetimes = []
      if (this.scheduleActivity) {
        for (const date in this.schedule.availability) {
          for (const time in this.schedule.availability[date]) {
            datetimes.push({ key: parseDatetoTS(date + ' ' + time), date: date, time: time })
          }
        }
      }
      function compare (a, b) {
        if (a.key < b.key) {
          return -1
        }
        if (a.key > b.key) {
          return 1
        }
        return 0
      }
      datetimes.sort(compare)
      return datetimes
    }
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  },
  methods: {
    ...mapActions(
      [
        'loadSchedulesForConnection',
        'clearSchedules',
        'setScheduleForConnection',
        'addConnectionMessage',
        'setSnackMessage',
        'setPageLoad'
      ]
    ),
    toggleSelected (indx) {
      this.$nextTick(() => {
        if (this.selected) {
          this.selected = null
        } else {
          this.selected = indx
        }
      })
    },
    changeSelectedDates () {
      this.schedule.confirmed = false
      this.schedule.notified = false
    },
    isTimeslotSelected (name, timeslot) {
      let selected = false
      if (this.schedule.selectedTimeslots) {
        if (this.schedule.selectedTimeslots[name]) {
          if (this.schedule.selectedTimeslots[name][timeslot]) {
            selected = true
          }
        }
      }
      return selected
    },
    getAvailability (dateKey, timeKey) {
      if (this.schedule) {
        if (this.schedule.availability) {
          if (this.schedule.availability[dateKey][timeKey]) {
            const avail = this.schedule.availability[dateKey][timeKey]
            const availFiltered = Object.keys(avail).filter(function (x) {
              return avail[x]
            })
            return availFiltered
          }
        }
      }
      return []
    },
    toggleTimeslot (event, date, time, timeslot) {
      if (!this.schedule.selectedTimeslots) {
        this.$set(this.schedule, 'selectedTimeslots', {})
      }
      if (this.schedule.selectedTimeslots[this.profile.displayName]) {
        if (this.schedule.selectedTimeslots[this.profile.displayName][date + ' ' + timeslot]) {
          this.$delete(this.schedule.selectedTimeslots[this.profile.displayName], date + ' ' + timeslot)
          this.schedule.availability[date][time][this.profile.displayName] = null
        } else {
          for (const slot in this.schedule.selectedTimeslots[this.profile.displayName]) {
            if (this.isInTimeslot(slot, date, time)) {
              this.$delete(this.schedule.selectedTimeslots[this.profile.displayName], slot)
            }
          }
          const slot = date + ' ' + timeslot
          this.$set(this.schedule.selectedTimeslots[this.profile.displayName], slot, true)
          this.schedule.availability[date][time][this.profile.displayName] = true
        }
      } else {
        const slot = date + ' ' + timeslot
        this.$set(this.schedule.selectedTimeslots, this.profile.displayName, {})
        this.$set(this.schedule.selectedTimeslots[this.profile.displayName], slot, true)
        this.schedule.availability[date][time][this.profile.displayName] = true
      }
    },
    getSelectedTimeslotDate () {
      let selected = null
      for (const selectedTS of Object.values(this.schedule.selectedTimeslots)) {
        selected = selectedTS
      }
      return selected
    },
    getTimeslot (slot, date, time) {
      if (slot) {
        if (this.isInTimeslot(slot, date, time)) {
          const parts = slot.split(' ')
          return parts[parts.length - 1]
        }
      }
      return null
    },
    isSelected (name, date, time) {
      const isSelected = false
      if (this.schedule.selectedTimeslots[name]) {
        return this.schedule.selectedTimeslots[name][date + ' ' + time]
      }
      return isSelected
    },
    isDateSelected (name, date, times) {
      let isSelected = false
      if (this.schedule.selectedTimeslots) {
        for (const slot in this.schedule.selectedTimeslots[name]) {
          if (this.isInTimeslot(slot, date, times)) {
            isSelected = true
          }
        }
      }
      return isSelected
    },
    isInTimeslot (slot, date, times) {
      let inTimeslot = false
      const timeslots = this.getTimeslots(times)
      if (slot.indexOf(date) >= 0) {
        for (const time of timeslots) {
          if (slot.indexOf(time.startTime) >= 0) {
            inTimeslot = true
          }
        }
      }
      return inTimeslot
    },
    getTimeslots (times) {
      const start = times.split(' - ')[0]
      const end = times.split(' - ')[1]
      const timeslots = getTimeslotsBetween(start, end)
      return timeslots
    },
    addTimeslot (date) {
      date.times.push({
        startTime: null,
        endTime: null
      })
    },
    isValid () {
      let valid = true
      if (this.schedule.selectedDates.length === 0) {
        // no dates selected
        valid = false
        this.validationMessage = 'Please select one or more dates'
      } else if (this.schedule.simpletime && this.schedule.times.length === 0) {
        // simple time selection - no times selected
        valid = false
        this.validationMessage = 'Please select times for the dates selected'
      } else if (this.schedule.simpletime) {
        for (const time of this.schedule.times) {
          if (!time.startTime || !time.endTime) {
            // simple time selection - end or start time missing
            valid = false
            this.validationMessage = 'Please select both start and end times for the dates selected'
          }
        }
      } else {
        // advanced time selection
        for (const date of this.schedule.selectedDates) {
          if (date.times.length === 0) {
            // advanced time selection - no times selected
            valid = false
            this.validationMessage = 'Please select times for all the dates selected'
          } else {
            for (const time of date.times) {
              if (!time.startTime || !time.endTime) {
                // advanced time selection - end or start time missing
                valid = false
                this.validationMessage = 'Please select both start and end times for all the dates selected'
              }
            }
          }
        }
      }

      if (!valid) {
        this.setSnackMessage(this.validationMessage)
      }
      return valid
    },
    initiateSelectedl () {
      this.$set(this.schedule, 'selectedTimeslots', {})
      this.$set(this.schedule, 'confirmedTimeslot', {})
    },
    initiateAvailability () {
      this.schedule.availability = {}
      for (const date of this.schedule.selectedDates) {
        if (!this.schedule.availability[date.dateFormatted]) {
          this.schedule.availability[date.dateFormatted] = {}
          if (this.schedule.simpletime) {
            for (const time of this.schedule.times) {
              this.schedule.availability[date.dateFormatted][time.startTime + ' - ' + time.endTime] = {}
              this.schedule.availability[date.dateFormatted][time.startTime + ' - ' + time.endTime][this.profile.displayName] = true
            }
          } else {
            for (const time of date.times) {
              this.schedule.availability[date.dateFormatted][time.startTime + ' - ' + time.endTime] = {}
              this.schedule.availability[date.dateFormatted][time.startTime + ' - ' + time.endTime][this.profile.displayName] = true
            }
          }
        }
      }
    },
    removeTimeslot (date, indx) {
      date.times.splice(indx, 1)
    },
    removeDateIndex (indx) {
      this.schedule.selectedDates.splice(indx, 1)
    },
    removeSelectedDate (date) {
      if (this.schedule.selectedDates) {
        this.schedule.selectedDates = this.schedule.selectedDates.filter((selectedDate) => {
          return dateExtFormat(date) !== selectedDate.dateFormatted
        })
      }
    },
    dateSelected () {
      // check not already selected
      if (!this.highlightDate(this.datepicked)) {
        const datepickedFormatted = dateExtFormat(this.datepicked)
        this.schedule.selectedDates.push({
          date: this.datepicked,
          dateFormatted: datepickedFormatted,
          times: [{
            startTime: null,
            endTime: null
          }]
        })
      } else {
        this.removeSelectedDate(this.datepicked)
      }
      this.sortSelectedDates()
    },
    sortSelectedDates () {
      if (this.schedule.selectedDates) {
        this.schedule.selectedDates.sort(function (obj1, obj2) {
          return obj1.date > obj2.date
        })
      }
    },
    highlightDate (date) {
      const self = this
      let dateHighlighted = false
      if (self.schedule.selectedDates) {
        self.schedule.selectedDates.forEach((selectedDate) => {
          dateHighlighted = dateHighlighted || (dateExtFormat(date) === selectedDate.dateFormatted)
        })
      }
      return dateHighlighted
    },
    timeslotsBefore (before, removeLast) {
      let results = this.timeslots
      if (before) {
        results = this.timeslots.filter((ts) => {
          return ts < before
        })
        if (removeLast) {
          results.pop()
        }
      }
      return results
    },
    timeslotsAfter (after, removeFirst) {
      let results = this.timeslots
      if (after) {
        results = results.filter((ts) => {
          return ts > after
        })
        if (removeFirst) {
          results.shift()
        }
      }
      return results
    },
    confirmSchedule () {
      const connectionId = this.connectionId
      const self = this
      if (this.isValid()) {
        this.initiateAvailability()
        this.initiateSelectedl()
        this.schedule.datesSetBy = this.user.uid
        this.schedule.confirmed = true
        this.notified = false
        this.setScheduleForConnection({ connectionId: connectionId, schedule: this.schedule }).then((docRef) => {
          let scheduleId = this.schedule.id
          if (!scheduleId && docRef) {
            scheduleId = docRef.id
          }
          const connectionId = self.connectionId
          const message = {
            connectionId: self.connectionId,
            scheduleId: scheduleId,
            fromId: self.profile.id,
            text: 'I\'ve selected possible dates and times for our Chemistry Check. <VIEW_SCHEDULE>',
            to: `/scheduler/${connectionId}`,
            buttons: [
              {
                text: 'View Schedule',
                icon: 'calendar_today',
                match: '<VIEW_SCHEDULE>',
                action: 'goto:scheduler'
              }
            ]
          }
          self.addConnectionMessage({ message })
          self.setSnackMessage('Dates & Times set - other participants will now be able to select their availability')
          self.$router.push(`/connection/${connectionId}`)
        })
      }
    },
    unselectTimeslot () {
      if (!this.schedule.confirmedSelection) {
        this.schedule.confirmedSelection = {}
      }
      this.schedule.confirmedSelection[this.profile.displayName] = false
    },
    selectTimeslot () {
      const connectionId = this.connectionId
      this.schedule.confirmedSelection[this.profile.displayName] = true
      this.setScheduleForConnection({ connectionId: this.connectionId, schedule: this.schedule })
      const message = {
        connectionId: this.connectionId,
        scheduleId: this.schedule.id,
        fromId: this.profile.id,
        text: 'I\'ve selected times for our Chemistry Check. <VIEW_SCHEDULE>',
        to: `/scheduler/${connectionId}`,
        buttons: [
          {
            text: 'View Schedule',
            icon: 'calendar_today',
            match: '<VIEW_SCHEDULE>',
            action: 'goto:scheduler'
          }
        ]
      }
      this.addConnectionMessage({ message })
      this.setSnackMessage('Timeslot Selected - the organiser will now be confirm the meeting')
      this.$router.push(`/connection/${connectionId}`)
    },
    getParticipants (selectedSlot) {
      const participants = [this.profile.displayName]
      for (const p in this.schedule.selectedTimeslots) {
        for (const slot in this.schedule.selectedTimeslots[p]) {
          if (slot.indexOf(selectedSlot) >= 0) {
            participants.push(p)
          }
        }
      }
      return participants
    },
    notifySchedule (selectedSlot) {
      const connectionId = this.connectionId
      const participants = this.getParticipants(selectedSlot)
      const attendees = participants.map((x) => {
        return { name: x }
      })
      this.schedule.confirmedTimeslot = selectedSlot
      const organizer = { name: this.profile.displayName }
      const start = dateTimeArrayFormat(selectedSlot)
      this.schedule.ics = {
        title: `Chemistry Check (${participants})`,
        duration: { minutes: '60' },
        start: start,
        organizer: organizer,
        attendees: attendees
      }
      this.schedule.notified = true
      this.setScheduleForConnection({ connectionId: this.connectionId, schedule: this.schedule })
      const message = {
        connectionId: connectionId,
        scheduleId: this.schedule.id,
        fromId: this.profile.id,
        text: `A Chemistry Check meeting has been scheduled for ${selectedSlot}. <DOWNLOAD_INVITE>`,
        buttons: [
          {
            text: 'Download Invite',
            match: '<DOWNLOAD_INVITE>',
            action: 'downloadInvite'
          }
        ]
      }
      this.addConnectionMessage({ message })
      this.setSnackMessage('Selection Confirmed - meeting participants will be notified')
      this.$router.push(`/connection/${connectionId}`)
    },
    initialize () {
      let schedule = { ...this.scheduleDefault }
      if (this.scheduleActivity) {
        schedule = { ...schedule, ...this.scheduleActivity }
      }
      this.$set(this, 'schedule', schedule)
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
    this.clearSchedules()
  },
  watch: {
    schedules: function (newS, oldS) {
      if (!this.scheduleId) {
        if (this.schedules) {
          this.scheduleActivity = this.schedules[0]
        }
        this.initialize()
        this.ready = true
      }
    }
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.connectionId = this.$route.params.id
    this.dateTitle = dateExtFormat(this.datepicked)
    range(0, 23).forEach((h) => {
      range(0, 60, 30).forEach((m) => {
        const hh = (h < 10) ? `0${h}` : h
        const mm = (m < 10) ? `0${m}` : m
        this.timeslots.push(`${hh}:${mm}`)
      })
    })
    this.loadSchedulesForConnection({ connectionId: this.connectionId })
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdc-card.disabled {
  background: #F0F0F0;
  opacity: 0.6;
}
.del-wrapper {
  display: inline-block;
  top: -12px;
  position: relative;
}
.del-wrapper .mdc-card-action-icon{
  display:inline-block;
}
.mdc-card {
  min-height: 200px;
}
.mdc-select--box {
  width: 120px;
}
.flexrow {
  display:flex;
  flex-flow:row wrap;
}
.flex-left {
  margin-bottom:12px;
  margin-right:12px;
}
.flex-right {
  flex:1;
  min-width:300px;
  margin-bottom:12px;
  margin-right:12px;
}
</style>
<style>
@media(max-width: 901px) {
  .schedule-picker .vdp-datepicker__calendar {
    width: 100%;
  }
}
</style>
