import Vue from 'vue'
import Vuex from 'vuex'
import fb from './firebase'
import sampleQuestions from './sampleQuestions'
import connectionActivities from './connectionActivities'
import connectionMessages from './connectionMessages'
import channelMessages from './channelMessages'
import company from './company'
import jobs from './jobs'
import duo from './duo'
import teamSchedules from './teamSchedules'
import { uploadCVFile, uploadAvatarFile } from './upload'
import { initializeProfile } from '../utils/profile'
import roles from '../assets/json/roles.json'
import industries from '../assets/json/industries.json'
import skills from '../assets/json/skills.json'
import companies from '../assets/json/companies.json'
import locations from '../assets/json/locations.json'
import { getLastQuarter, addDaysDate, getWC } from '../utils/time'
import omit from 'lodash/omit'
const _ = { omit }

Vue.use(Vuex)

let profilesListener
let connectionsListener
let channelsListener

// Team Listeners
let teamInitiativeListener
let teamTempoListener
let teamNotesListener
let teamUpdatesListener
let updateCommentListener
let taskListListener
let taskListCommentListener

const profilesRef = fb.firestore.collection('profiles')
const channelRef = fb.firestore.collection('channelSub')
const connectionsRef = fb.firestore.collection('connections')
const teamsRef = fb.firestore.collection('teams')

const welcomeIds = [
  'CsnCO7qIkpNWvExA136pqSw2mBo1',
  'ssoln-QGeJIlznwz',
  'ssoln-ZDuw03Xjba'
]

const store = new Vuex.Store({
  state: {
    isBranded: false,
    brand: {},
    pageLoad: false,
    loading: false,
    afterLogin: '/',
    user: { displayName: 'Not Authenticated' },
    role: { level: 0 },
    industries: null,
    locations: null,
    roles: null,
    profile: null,
    profileUpdates: {},
    profiles: null,
    companyProfiles: null,
    connections: null,
    connection: null,
    channels: null,
    messages: null,
    snack: {},
    filters: {},
    action: null,
    skills: null,
    companies: null,
    remote: null,
    teamMode: false,
    team: {},
    teamProfiles: [],
    initiatives: [],
    taskLists: [],
    tempos: [],
    teamNotes: [],
    teamUpdates: [],
    teamUpdatesLookingForward: [],
    teamUpdatesRoundTable: [],
    teamSchedules: null,
    updateComments: [],
    taskListComments: []
  },
  getters: {
    getProfile: (state) => (profileId) => {
      if (state.profile) {
        if (state.profile.id === profileId) {
          return state.profile
        }
      }
      if (state.profiles) {
        const profileMap = new Map(state.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }

      return null
    },
    getTeams: (state) => async () => {
      const teams = []
      const snapshot = await teamsRef.where('members', 'array-contains', state.profile.email).get()
      snapshot.forEach(doc => {
        teams.push({ ...doc.data(), id: doc.id })
      })
      return teams
    },
    getTeam: (state) => async (teamId) => {
      const teamDoc = await teamsRef.doc(teamId).get()
      return { id: teamDoc.id, ...teamDoc.data() }
    },
    getTeamSchedule: (state) => async (teamId, date) => {
      const scheduleDoc = await teamsRef.doc(teamId).collection('schedule').where('date', '==', date).get()
      if (scheduleDoc.exists()) {
        return { id: scheduleDoc.id, ...scheduleDoc.data() }
      } else {
        return {}
      }
    }
  },
  actions: {
    setSnackMessage ({ commit }, message) {
      commit('setSnackMessage', message)
    },
    setPageLoad ({ commit }, loading) {
      return commit('setPageLoad', loading)
    },
    setTeamMode ({ commit }, teamMode) {
      commit('setTeamMode', teamMode)
    },
    setIsBranded ({ commit }, isBranded) {
      commit('setIsBranded', isBranded)
    },
    setBrand ({ commit }, brand) {
      commit('setBrand', brand)
    },
    setLoading ({ commit }, loading) {
      commit('setLoading', loading)
    },
    setAction ({ commit }, action) {
      commit('setAction', action)
    },
    setRemote ({ commit }, remote) {
      commit('setRemote', remote)
    },
    setFilters ({ commit }, filters) {
      commit('setFilters', filters)
    },
    setProfile ({ commit }, profile) {
      commit('setProfile', profile)
    },
    setProfileUpdates ({ commit }, profileUpdates) {
      commit('setProfileUpdates', profileUpdates)
    },
    clearProfileUpdates ({ commit }) {
      commit('setProfileUpdates', {})
    },
    loadProfile ({ commit, dispatch }) {
      return profilesRef
        .doc(this.state.user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const profile = doc.data()
            const updated = initializeProfile(profile, this.state.user)
            if (updated) {
              doc.ref.set(profile, { merge: true })
            }
            profile.id = doc.id
            if (this.state.brand.id) {
              profile.companyId = this.state.brand.id
            }
            commit('setProfile', profile)

            if (profile.team) {
              if (!this.state.team) {
                dispatch('loadTeam', { teamId: profile.team })
              } else if (this.state.team.id !== profile.team) {
                dispatch('loadTeam', { teamId: profile.team })
              }
            }

            if (this.state.isBranded) {
              if (this.state.company.assignedCompany) {
                if (this.state.brand.id !== this.state.company.assignedCompany.id) {
                  dispatch('loadAssignedCompany', { companyId: this.state.brand.id })
                  dispatch('loadPublicSubCompanies', { companyId: this.state.brand.id })
                }
              } else {
                dispatch('loadAssignedCompany', { companyId: this.state.brand.id })
                dispatch('loadPublicSubCompanies', { companyId: this.state.brand.id })
              }
            }
          }
        })
    },
    async loadTeamProfiles ({ commit }, { teamId }) {
      const getTeamProfiles = fb.functions.httpsCallable('getTeamProfiles')
      const res = await getTeamProfiles({
        teamId
      })
      if (res.data.error) {
        console.log('loadTeamProfiles.error', res.data.error)
        return
      }
      const result = res.data.result
      commit('setTeamProfiles', result)
    },
    loadProfiles ({ commit }) {
      if (profilesListener) { profilesListener() }
      profilesListener = profilesRef
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            if (doc.id !== this.state.user.uid) {
              results.push({ id: doc.id, ...doc.data() })
            }
          })
          commit('setProfiles', results)
        })
    },
    updateProfileLastActive ({ commit, dispatch }) {
      return profilesRef
        .doc(this.state.user.uid)
        .update({ lastActive: new Date(), inactivityEmailSent: null })
    },
    updateProfile ({ commit, dispatch }, { profile, noSnack }) {
      profilesRef
        .doc(this.state.user.uid)
        .update(profile)
        .then(() => {
          dispatch('loadProfile')
          if (!noSnack) {
            commit('setSnackMessage', 'Profile Updated')
          }
        })
    },
    applyProfileUpdates ({ commit, dispatch }, { noSnack }) {
      // Remove temp fields in profile updates
      const omitFields = ['newSkill', 'newSkillRating', 'newRoleName', 'newRoleExp']
      const updates = _.omit(this.state.profileUpdates, omitFields)
      // Update profile with profile updates
      profilesRef
        .doc(this.state.user.uid)
        .update(updates)
        .then(() => {
          dispatch('clearProfileUpdates')
          dispatch('loadProfile')
          if (!noSnack) {
            commit('setSnackMessage', 'Profile Updated')
          }
        })
    },
    uploadCV ({ commit, dispatch }, { file }) {
      const addCV = (filename, downloadUrl) => {
        dispatch('updateProfile', { profile: { resume: downloadUrl, resumeFilename: filename } })
      }
      const userId = this.state.user.uid
      uploadCVFile(userId, file, addCV).then(snapshot => {
        commit('setSnackMessage', 'CV Uploaded')
      }).catch(err => {
        commit('setSnackMessage', 'Error Uploading CV')
        console.log('CV upload Error', err)
      })
    },
    uploadAvatar ({ commit, dispatch }, { file, filename }) {
      const addAvatar = (downloadUrl) => {
        dispatch('updateProfile', { profile: { photoURL: downloadUrl, photoFilename: filename } })
      }
      const userId = this.state.user.uid
      uploadAvatarFile(userId, file, filename, addAvatar).then(snapshot => {
        commit('setSnackMessage', 'Avatar Uploaded')
      }).catch(err => {
        commit('setSnackMessage', 'Error Uploading Avatar')
        console.log('Avatar upload Error', err)
      })
    },
    async loadTeam ({ commit, dispatch }, { teamId }) {
      const teamDoc = await teamsRef.doc(teamId).get()
      const team = { id: teamDoc.id, ...teamDoc.data() }
      const lastQuarter = getLastQuarter()
      commit('setTeam', team)

      // team channels
      dispatch('loadTeamChannels', { teamId })

      // team profiles
      dispatch('loadTeamProfiles', { teamId })

      // team initiatives
      if (teamInitiativeListener) { teamInitiativeListener() }
      teamInitiativeListener = teamsRef.doc(teamId)
        .collection('initiatives')
        .orderBy('name', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setInitiatives', results)
        })

      // team tempos
      if (teamTempoListener) { teamTempoListener() }
      teamTempoListener = teamsRef.doc(teamId)
        .collection('tempos')
        .orderBy('name', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setTempos', results)
        })

      // team notes for personal captured notes relating to team
      if (teamNotesListener) { teamNotesListener() }
      teamNotesListener = teamsRef.doc(teamId)
        .collection('updates')
        .where('group', '==', 'notes')
        .where('fromId', '==', this.state.user.uid)
        .orderBy('date', 'asc')
        .orderBy('order', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setTeamNotes', results)
        })

      // team updates
      if (teamUpdatesListener) { teamUpdatesListener() }
      teamUpdatesListener = teamsRef.doc(teamId)
        .collection('updates')
        .where('date', '>=', lastQuarter)
        .orderBy('date', 'asc')
        .orderBy('order', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          const resultsLF = []
          const resultsRT = []
          querySnapshot.forEach((doc) => {
            const update = { ...doc.data(), id: doc.id }
            if (update.group !== 'notes') {
              results.push(update)
              if (update.group === 'lookingForward') {
                resultsLF.push(update)
              } else if (update.group === 'roundTable') {
                resultsRT.push(update)
              }
            }
          })
          commit('setTeamUpdates', results)
          commit('setTeamLookingForward', resultsLF)
          commit('setTeamRoundTable', resultsRT)
        })
    },
    loadUpdateComments ({ commit }, { teamId, updateId }) {
      if (updateCommentListener) { updateCommentListener() }
      updateCommentListener = teamsRef.doc(teamId)
        .collection('updates')
        .doc(updateId)
        .collection('comments')
        .orderBy('date', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          commit('setUpdateComments', results)
        })
    },
    clearUpdateComments ({ commit }) {
      commit('setUpdateComments', null)
    },
    loadTaskListComments ({ commit }, { teamId, initiativeId, taskListId }) {
      if (taskListCommentListener) { taskListCommentListener() }
      taskListCommentListener = teamsRef.doc(teamId)
        .collection('initiatives')
        .doc(initiativeId)
        .collection('tasklists')
        .doc(taskListId)
        .collection('comments')
        .orderBy('date', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          console.log('index.loadTaskListComments', results)
          commit('setTaskListComments', results)
        })
    },
    clearTaskListComments ({ commit }) {
      commit('setTaskListComments', null)
    },
    async getUpdate ({ commit }, { teamId, updateId }) {
      const teamUpdateDoc = await teamsRef.doc(teamId).collection('updates').doc(updateId).get()
      if (teamUpdateDoc.exists) {
        return { ...teamUpdateDoc.data(), id: teamUpdateDoc.id }
      }
      return null
    },
    saveTaskList ({ commit }, { teamId, initiativeId, taskListId, taskList }) {
      if (taskListId) {
        return teamsRef.doc(teamId)
          .collection('initiatives')
          .doc(initiativeId)
          .collection('tasklists')
          .doc(taskListId)
          .update(taskList)
      } else {
        return teamsRef.doc(teamId)
          .collection('initiatives')
          .doc(initiativeId)
          .collection('tasklists')
          .add(taskList)
      }
    },
    getTaskLists  ({ commit }, { teamId, initiativeId }) {
      if (taskListListener) { taskListListener() }
      taskListListener = teamsRef.doc(teamId)
        .collection('initiatives')
        .doc(initiativeId)
        .collection('tasklists')
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setTaskLists', results)
        })
    },
    clearTasksLists ({ commit }) {
      commit('setTaskLists', [])
    },
    getUpdatesByTag ({ commit }, { teamId, tag }) {
      return teamsRef.doc(teamId)
        .collection('updates')
        .where('tags', 'array-contains', tag)
        .orderBy('date', 'desc')
        .get().then((querySnapshot) => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          return results
        })
    },
    createTeam ({ commit, dispatch }, { teamName }) {
      const team = {
        name: teamName,
        owner: this.state.profile.email,
        ownerName: this.state.profile.displayName,
        created: fb.serverTime(),
        invites: [],
        members: [this.state.profile.email]
      }
      return teamsRef.add(team).then((doc) => {
        dispatch('addTeamChannels', { teamId: doc.id })
        dispatch('updateProfile', { profile: { team: doc.id } })
        return doc
      })
    },
    inviteToTeam ({ commit }, { teamId, email }) {
      return teamsRef.doc(teamId).get().then(async (doc) => {
        const team = doc.data()
        if (!team.members.includes(email)) {
          const teamUpdates = {
            members: [...team.members, email],
            invites: [...team.invites, email]
          }
          await teamsRef.doc(teamId).update(teamUpdates)
          return `${email} invited to team`
        } else {
          return `${email} already invited to team`
        }
      })
    },
    joinTeam ({ commit, dispatch }, { teamId }) {
      return teamsRef.doc(teamId).get().then(async (doc) => {
        const team = doc.data()
        if (team.invites.includes(this.state.profile.email)) {
          const teamUpdates = {
            invites: team.invites.filter(invite => invite !== this.state.profile.email)
          }
          await teamsRef.doc(teamId).update(teamUpdates)
        }
        dispatch('loadTeam', { teamId })
        dispatch('updateProfile', { profile: { team: doc.id } })
        return team
      })
    },
    saveInitiative  ({ commit }, { teamId, initiativeId, initiative }) {
      if (initiativeId) {
        return teamsRef.doc(teamId).collection('initiatives').doc(initiativeId).update(initiative)
      } else {
        return teamsRef.doc(teamId).collection('initiatives').add(initiative)
      }
    },
    saveTempo  ({ commit }, { teamId, tempoId, tempo }) {
      if (tempoId) {
        return teamsRef.doc(teamId).collection('tempos').doc(tempoId).update(tempo)
      } else {
        return teamsRef.doc(teamId).collection('tempos').add(tempo)
      }
    },
    saveComment ({ commit }, { teamId, updateId, commentId, comment }) {
      if (commentId) {
        return teamsRef.doc(teamId).collection('updates').doc(updateId).collection('comments').doc(commentId).update(comment)
      } else {
        return teamsRef.doc(teamId).collection('updates').doc(updateId).collection('comments').add(comment)
      }
    },
    saveTaskListComment ({ commit }, { teamId, initiativeId, taskListId, commentId, comment }) {
      console.log('index.saveTaskListComment', teamId, initiativeId, initiativeId, commentId)
      if (commentId) {
        return teamsRef.doc(teamId).collection('initiatives').doc(initiativeId).collection('tasklists').doc(taskListId).collection('comments').doc(commentId).update(comment)
      } else {
        return teamsRef.doc(teamId).collection('initiatives').doc(initiativeId).collection('tasklists').doc(taskListId).collection('comments').add(comment)
      }
    },
    saveUpdate  ({ commit }, { teamId, updateId, update }) {
      const updateCopy = { ...update }
      delete updateCopy.changed
      if (updateId) {
        return teamsRef.doc(teamId).collection('updates').doc(updateId).update(updateCopy)
      } else {
        return teamsRef.doc(teamId).collection('updates').add(updateCopy)
      }
    },
    saveUpdatesBatch ({ commit }, { teamId, updates }) {
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.doc(teamId).collection('updates')
      for (const update of updates) {
        delete update.changed
        if (update.id) {
          batch.update(batchRef.doc(update.id), update)
        } else {
          const newRef = batchRef.doc()
          batch.set(newRef, update)
        }
      }
      // Commit the batch
      return batch.commit()
    },
    deleteUpdate ({ commit }, { teamId, updateId }) {
      return teamsRef.doc(teamId).collection('updates').doc(updateId).delete()
    },
    updateTeam ({ commit }, { teamId, updates }) {
      return teamsRef.doc(teamId).update(updates)
    },
    saveSchedule ({ commit }, { teamId, schedule }) {
      if (schedule.id) {
        return teamsRef.doc(teamId).collection('schedules').doc(schedule.id).update(schedule).then((doc) => {
          console.log('index.saveSchedule.update', schedule)
          return { ...schedule, id: doc.id }
        })
      } else {
        return teamsRef.doc(teamId).collection('schedules').add(schedule).then((doc) => {
          console.log('index.saveSchedule.add', doc)
          return { ...schedule, id: doc.id }
        })
      }
    },
    async getCurrentSchedules ({ commit }, { teamId, date }) {
      const results = []
      const scheduleDocs = await teamsRef.doc(teamId).collection('schedules').where('from', '>=', date).get()
      if (!scheduleDocs.empty) {
        for (const scheduleDoc of scheduleDocs.docs) {
          results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
        }
      }
      return results
    },
    async getScheduleByRequest ({ commit }, { teamId, requestId }) {
      let scheduleType = 'Weekly'
      let dayLimit = 8
      const tempoDocs = await teamsRef.doc(teamId).collection('schedules').where('name', '==', 'Working From').limit(1).get()
      if (!tempoDocs.empty) {
        const tempoDoc = tempoDocs.docs[0]
        const tempo = { id: tempoDoc.id, ...tempoDoc.data() }
        scheduleType = tempo.frequency
        if (scheduleType === 'Every other week') {
          dayLimit = 15
        }
      }
      const scheduleDocs = await teamsRef.doc(teamId).collection('schedules').where('requestId', '==', requestId).where('userId', '==', this.state.user.uid).limit(1).get()
      if (!scheduleDocs.empty) {
        const scheduleDoc = scheduleDocs.docs[0]
        return { id: scheduleDoc.id, ...scheduleDoc.data() }
      } else {
        let blankweek = {}
        let hasDefault = false
        if (this.state.profile.defaultSchedules) {
          if (this.state.profile.defaultSchedules[teamId]) {
            blankweek = this.state.profile.defaultSchedules[teamId]
            hasDefault = true
          }
        }
        if (!hasDefault) {
          const blankday = { remote: [], office: [], tbc: [this.state.user.uid] }
          const limit = dayLimit
          for (let index = 1; index < limit; index++) {
            blankweek[index] = { ...blankday }
          }
        }
        const wc = getWC(new Date())
        return { requestId, userId: this.state.user.uid, created: new Date(), dates: blankweek, from: wc, to: addDaysDate(wc, 7), type: scheduleType }
      }
    },
    updateChannel ({ commit }, { channelId, channel }) {
      return connectionsRef.update(channel)
    },
    channelJoin ({ commit }, { connectionId }) {
      return connectionsRef.get(connectionId).then((doc) => {
        const con = doc.data()
        const channel = {
          channelLogo: con.channelLogo,
          channelName: con.channelName,
          channelType: con.channelType,
          connectionId: doc.id,
          userId: this.state.user.uid
        }
        return channelRef.add(channel)
      })
    },
    channelLeave ({ commit }, { channelId }) {
      return channelRef.doc(channelId).delete()
    },
    loadChannels ({ commit }) {
      // array_contains not available yet so using object
      if (channelsListener) { channelsListener() }
      channelsListener = channelRef
        .where('userId', '==', this.state.user.uid)
        .orderBy('channelName', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          commit('setChannels', results)
        })
    },
    loadRoles ({ commit }) {
      commit('setRoles', roles)
    },
    loadIndustries ({ commit }) {
      commit('setIndustries', industries)
    },
    loadLocations ({ commit }) {
      commit('setLocations', locations)
    },
    loadSkills ({ commit }) {
      commit('setSkills', skills)
    },
    loadCompanies ({ commit }) {
      commit('setCompanies', companies)
    },
    connectWith ({ commit }, { userId }) {
      const myUserId = this.state.user.uid
      const connections = this.state.connections.filter(function (el) {
        return el.users[myUserId] && el.users[userId]
      })
      if (connections.length > 0) {
        return new Promise((resolve, reject) => {
          resolve(connections[0])
        })
      }

      const users = {}
      users[userId] = true
      users[myUserId] = true
      const unread = {}
      unread[userId] = 0
      unread[myUserId] = 0

      const connection = {
        created: fb.serverTime(),
        createdBy: myUserId,
        lastUpdate: fb.serverTime(),
        lastMessage: '',
        users,
        messages: 0,
        status: 'Invite',
        unread,
        folder: 'inbox'
      }
      return connectionsRef.add(connection)
    },
    updateConnection ({ commit }, { connectionId, connection }) {
      return connectionsRef.doc(connectionId).update(connection)
    },
    clearUnread ({ commit }, { connectionId }) {
      const connection = {}
      connectionsRef.doc(connectionId).get().then(doc => {
        const con = doc.data()
        connection.unread = con.unread
        if (!connection.unread) {
          connection.unread = {}
          Object.keys(con.users).forEach(function (key) {
            connection.unread[key] = 0
          })
        }
        connection.unread[this.state.user.uid] = 0
        return connectionsRef.doc(connectionId).update(connection)
      })
    },
    clearConnection ({ commit }) {
      commit('setConnection', null)
    },
    getConnection ({ commit }, { connectionId }) {
      // array_contains not available yet so using object
      connectionsRef.doc(connectionId).get()
        .then(doc => {
          const connection = { id: doc.id, ...doc.data() }
          commit('setConnection', connection)
        })
    },
    loadConnections ({ commit }) {
      // array_contains not available yet so using object
      if (connectionsListener) { connectionsListener() }

      function compareLastUpdate (a, b) {
        if (!a.lastUpdate) {
          return 0
        }
        if (!b.lastUpdate) {
          return 1
        }
        let compA = a.lastUpdate
        if (compA.toDate) {
          compA = compA.toDate()
        }
        let compB = b.lastUpdate
        if (compB.toDate) {
          compB = compB.toDate()
        }

        if (compA > compB) {
          return -1
        } else if (compA < compB) {
          return 1
        }
        return 0
      }

      const addMonths = (months) => {
        const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()
        const input = new Date()
        const date = new Date()
        date.setDate(1)
        date.setMonth(date.getMonth() + months)
        date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
        return date
      }
      const sixMonths = addMonths(-1)
      const userid = this.state.user.uid

      if (welcomeIds.includes(userid)) {
        connectionsListener = connectionsRef
          .where('users.' + userid, '==', true)
          .where('lastUpdate', '>=', sixMonths)
          .orderBy('lastUpdate', 'desc')
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              const con = { id: doc.id, ...doc.data() }
              results.push(con)
            })
            // results = results.sort(compareLastUpdate)
            commit('setConnections', results)
          })
      } else {
        connectionsListener = connectionsRef
          .where('users.' + userid, '==', true)
          .onSnapshot(querySnapshot => {
            let results = []
            querySnapshot.forEach((doc) => {
              const local = doc.metadata.hasPendingWrites
              const con = { id: doc.id, ...doc.data() }
              if (local && con.lastUpdate === null) {
              // If local pending write an has no last updated set to now
                con.lastUpdate = new Date()
              }
              results.push(con)
            })
            results = results.sort(compareLastUpdate)
            commit('setConnections', results)
          })
      }
    },
    clearMessages ({ commit }) {
      commit('setMessages', [])
    }
  },
  mutations: {
    setIsBranded: (state, isBranded) => {
      state.isBranded = isBranded
    },
    setBrand: (state, brand) => {
      state.brand = brand
    },
    setTeamMode: (state, teamMode) => {
      state.teamMode = teamMode
    },
    setTeam: (state, team) => {
      state.team = team
    },
    setTeamProfiles: (state, teamProfiles) => {
      state.teamProfiles = teamProfiles
    },
    setTaskLists: (state, taskLists) => {
      state.taskLists = taskLists
    },
    setInitiatives: (state, initiatives) => {
      state.initiatives = initiatives
    },
    setTempos: (state, tempos) => {
      state.tempos = tempos
    },
    setTeamNotes: (state, notes) => {
      state.teamNotes = notes
    },
    setUpdateComments: (state, comments) => {
      state.updateComments = comments
    },
    setTaskListComments: (state, comments) => {
      state.taskListComments = comments
    },
    setTeamUpdates: (state, updates) => {
      state.teamUpdates = updates
    },
    setTeamRoundTable: (state, updates) => {
      state.teamUpdatesRoundTable = updates
    },
    setTeamLookingForward: (state, updates) => {
      state.teamUpdatesLookingForward = updates
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setPageLoad: (state, loading) => {
      state.pageLoad = loading
    },
    setAction: (state, action) => {
      state.action = action
    },
    setRemote: (state, remote) => {
      state.remote = remote
    },
    setFilters: (state, filters) => {
      state.filters = filters
    },
    setUser: (state, user) => {
      state.user = user
    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setIndustries: (state, industries) => {
      state.industries = industries
    },
    setLocations: (state, locations) => {
      state.locations = locations
    },
    setSkills: (state, skills) => {
      state.skills = skills
    },
    setCompanies: (state, companies) => {
      state.companies = companies
    },
    setRole: (state, role) => {
      state.role = role
    },
    setSnack: (state, snack) => {
      state.snack = snack
    },
    setSnackMessage: (state, message) => {
      const snack = {
        message,
        actionText: 'OK',
        actionHandler () {}
      }
      state.snack = snack
    },
    setProfile: (state, profile) => {
      state.profile = profile
    },
    setProfileUpdates: (state, profileUpdates) => {
      state.profileUpdates = profileUpdates
    },
    setProfiles: (state, profiles) => {
      state.profiles = profiles
    },
    setCompanyProfiles: (state, profiles) => {
      state.companyProfiles = profiles
    },
    setConnections: (state, connections) => {
      state.connections = connections
    },
    setConnection: (state, connection) => {
      state.connection = connection
    },
    setChannels: (state, channels) => {
      state.channels = channels
    },
    setMessages: (state, messages) => {
      state.messages = messages
    }
  },
  modules: {
    sampleQuestions,
    connectionActivities,
    connectionMessages,
    channelMessages,
    company,
    jobs,
    duo,
    teamSchedules
  }
})

const disableListeners = () => {
  if (profilesListener) { profilesListener() }
  if (connectionsListener) { connectionsListener() }
  if (channelsListener) { channelsListener() }
  if (teamNotesListener) { teamNotesListener() }
  connectionMessages.resetConnectMessageListener()
  connectionActivities.resetShareAgreementListener()
  connectionActivities.resetScheduleListener()
  connectionActivities.resetShareProposalListener()
  channelMessages.resetChannelMessagesListener()
  jobs.resetJobListener()
  company.resetAssignedCompanyListener()
  company.resetAssignedSubCompanyListener()
  duo.resetDuosListener()
  duo.resetDuoListener()
  teamSchedules.resetTeamScheduleListener()
}

const clearStore = () => {
  store.commit('setProfile', null)
  store.commit('setRole', { level: 0 })
  store.commit('setProfiles', null)
  store.commit('setCompanyProfiles', null)
  store.commit('setConnections', null)
  store.commit('setConnection', null)
  store.commit('setMessages', null)
  store.commit('setChannels', null)
  store.commit('setJobs', null)
  store.commit('setAssignedCompany', null)
  store.commit('setRoles', null)
  store.commit('setIndustries', null)
  store.commit('setLocations', null)
  store.commit('setSkills', null)
  store.commit('setCompanies', null)
  store.commit('setDuos', null)
  store.commit('setProfileUpdates', {})
  store.commit('setFilters', {})
  store.commit('setFeaturedCompanies', {})
  store.commit('setRemote', null)
  store.commit('setTeamSchedules', null)
}

fb.auth.onAuthStateChanged(async user => {
  store.commit('setUser', user)
  if (user) {
    // eslint-disable-next-line no-undef
    analytics.identify(user.uid, {})
    const idTokenResult = await user.getIdTokenResult()
    user.claims = idTokenResult.claims
    if (user.claims.brand && user.claims.subCompany) {
      store.dispatch('loadAssignedSubCompany', { companyId: user.claims.brand, subCompanyId: user.claims.subCompany })
    }

    store.dispatch('updateProfileLastActive')
    store.dispatch('loadProfile')
    store.dispatch('loadProfiles')
    store.dispatch('loadDuos')
    store.dispatch('loadConnections')
    store.dispatch('loadCompanyChannels')
    store.dispatch('loadJobs')
    store.dispatch('clearProfileUpdates')

    // these are loaded from json files
    store.dispatch('loadQuestions')
    store.dispatch('loadCompanies')
    store.dispatch('loadRoles')
    store.dispatch('loadIndustries')
    store.dispatch('loadLocations')
    store.dispatch('loadSkills')
    store.dispatch('loadFeaturedCompanies')
    // console.log('Loading Listeners Finished')
  } else {
    clearStore()
    disableListeners()
  }
})

export default store
