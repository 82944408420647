<template>
  <div class="main-content initiative">

    <mdc-title v-if="message">{{message}}</mdc-title>

    <div v-if="initiative" class="main-wrapper main-content-wrapper">
      <mdc-layout-grid>
        <mdc-layout-cell span="12">

          <div class="initiative-header">
            <div class="initiative-header-detail">
              <span class="initiative-tag" v-html="getTag(initiative.name)"></span>
              <span v-if="$teamLeader()">
                <round-button icon="info" class="init-edit-icon" @click="editInitShowing = true" iconOutline title="Edit Initiative"/>
              </span>
              <div class="avatar-list">
                <span v-for="member in initiative.members" :key="member" :title="getProfile(member).displayName" class="avatar avatar-link">
                  <router-link class="update-avatar" v-if="member !== 'bot'" :to="'/user/' + member">
                      <img v-if="hasPhoto(getProfile(member))" :src="getPhoto(getProfile(member))"/>
                      <default-avatar v-else fontSize="16px" :profile="getProfile(member)"/>
                  </router-link>
                  <span class="update-avatar" v-else>
                      <img v-if="hasPhoto(getProfile(member))" :src="getPhoto(getProfile(member))"/>
                      <default-avatar v-else fontSize="16px" :profile="getProfile(member)"/>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div  class="initiative-desc">
              <mdc-body style="white-space: pre-wrap;" typo="body2" v-html="initiative.description"></mdc-body>
          </div>

          <div class="initiative-tab-menu">
              <span @click="changeTab('updates')" :class="{active: tab === 'updates'}">
                  <mdc-icon icon="trending_flat" outlined/>
                  <span class="tab-title">Updates</span>
              </span>
              <span @click="changeTab('tasks')" :class="{active: tab === 'tasks'}">
                  <mdc-icon icon="check_box" outlined/>
                  <span class="tab-title">Tasks</span>
              </span>
              <span @click="changeTab('schedule')" :class="{active: tab === 'schedule'}">
                  <mdc-icon icon="calendar_today" outlined/>
                  <span class="tab-title">Schedule</span>
              </span>
              <span @click="changeTab('files')" :class="{active: tab === 'files'}" >
                  <mdc-icon icon="description" outlined/>
                  <span class="tab-title">Files</span>
              </span>
          </div>

          <section v-if="tab === 'updates'">
            <div v-if="updates">
              <update-section v-if="profile.team && updates.length" @showUpdate="showUpdate" hasLink :updates="updates" :week="lastWeek" :profiles="teamProfiles" :team="profile.team"/>
              <mdc-body v-else class="not-found" typo="body2">No Updates Found</mdc-body>
            </div>
            <mdc-body v-else class="not-found" typo="body2">No Updates Found</mdc-body>
          </section>

          <section v-if="tab === 'tasks'">

          <app-dialog
          v-if="selectedTaskList"
          v-model="taskListViewShowing"
          @close="update = null"
          :pauseFocus="isMobile"
          class="update-modal fullsize-mobile with-messagebar"
          style="z-index: 13;"
          accept=""
          title="Task List"
          >

          <div v-if="!selectedTaskEdit" class="tasklist-header">
              <div class="task-indicator" :class="getIndicatorClass(selectedTaskList)"></div>
              <mdc-title>{{selectedTaskList.title}}</mdc-title>
              <round-button
                background="transparent"
                @click="selectedTaskEdit = true"
                noHover
                class="inline-button tooltip-icon"
                iconOutline
                style="padding:0;color:#828282;margin:0;height:16px;width:16px;min-width:16px;margin-left:32px;"
                icon="info"/>
          </div>
          <app-text-field
            v-else
            type="text"
            label="Task List Name"
            outlined
            v-model="selectedTaskListTitle"
            autocomplete="new-password"
            autofill="off"
            :isMobile="isMobile"
            :mobileOverlay="true"
            helptext="- E.g. My Project"
            >
          </app-text-field>

          <mdc-body v-if="!selectedTaskEdit" typo="body2" style="margin-top:0;" class="tasklist-desc" v-html="selectedTaskList.description"></mdc-body>
          <app-text-field
            v-else
            type="text"
            label="Add a description"
            outlined
            v-model="selectedTaskListDescription"
            autocomplete="new-password"
            autofill="off"
            :isMobile="isMobile"
            :mobileOverlay="true"
            helptext="Add further information to provide background or context"
            >
          </app-text-field>
          <div v-if="selectedTaskEdit" style="display:flex;">
            <round-button icon="done" @click="updateSelectedTaskEdit" high style="border: 1px solid;width:160px !important;" hasSlot>Update</round-button>
            <round-button icon="clear" @click="cancelSelectedTaskEdit" style="border: 1px solid;width:160px !important;" hasSlot>Cancel</round-button>
          </div>

          <div class="tasklist-tasks">
            <div class="task-line" v-for="(task, index) in selectedTaskList.tasks" :key="'task-' + index">
              <mdc-icon @click.native="completeTask(selectedTaskList, task, index)" style="margin:0;margin-right:16px;cursor:pointer" icon="check_box_outline_blank" outlined />
              <tiptap-editor reducedOptions :ref="'editors' + selectedListIndex" emitEnter @editorEnter="addTaskLine(selectedTaskList.tasks, selectedListIndex, index + 1)" style="flex:1" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onTextUpdate="onTaskUpdate" :content="task.text" :title="'Description'" :contentKey="'idx-' + selectedListIndex + '-' + index">
              </tiptap-editor>
            </div>
            <div class="task-line">
              <mdc-icon style="color: #999;margin:0;margin-right:16px;cursor:pointer" @click="addTaskLine(selectedTaskList.tasks, selectedListIndex, selectedTaskList.tasks.length)" icon="add" outlined />
              <div style="color:#999; cursor:pointer;" @click="addTaskLine(selectedTaskList.tasks, selectedListIndex, selectedTaskList.tasks.length)">Add task to this list</div>
            </div>
            <div class="task-line" v-for="(task, index) in selectedTaskList.tasksCompleted" :key="'taskComp-' + index">
              <mdc-icon @click.native="uncompleteTask(selectedTaskList, task, index)" style="color: #666;margin:0;margin-right:16px;cursor:pointer" icon="check_box" outlined />
              <div style="text-decoration: line-through; color:#666" v-html="task.text"></div>
            </div>
          </div>

          <round-button icon="rss_feed" style="border: 1px solid;width:160px !important;" hasSlot>
              <span v-if="isSubscriber(selectedTaskList)" @click="unsubscribeTaskList(selectedTaskList)">Unsubscribe</span>
              <span v-else @click="subscribeTaskList(selectedTaskList)">Subscribe</span>
          </round-button>

          <div style="display:flex;align-items:center;">
          <span v-if="selectedTaskList.commentCount" class="task-comment-count-wrapper">
              <span class="task-reply-count">{{selectedTaskList.commentCount}}</span>
          </span>
          <span>Comments</span>
          </div>

          <update-section showDateLines :updates="taskListComments" :profiles="teamProfiles" :team="profile.team"/>

          <message-bar oneline relative v-on:focus="setInputFocused" :after="[]" :disabled="sendDisabled" :profile="profile" @send="addCommentTaskList" ></message-bar>
        </app-dialog>

            <app-dialog
            v-model="addTaskListShowing"
            :pauseFocus="isMobile"
            class="add-tasklist-modal fullsize-mobile"
            style="z-index: 13;"
            accept="Add"
            @accept="addTaskList"
            :title="'Add Task List'"
            >
              <div class="form-section">
                <app-text-field
                  type="text"
                  label="Task List Name"
                  outlined
                  v-model="newTaskList.title"
                  autocomplete="new-password"
                  autofill="off"
                  :isMobile="isMobile"
                  :mobileOverlay="true"
                  helptext="- E.g. My Project"
                  >
                </app-text-field>
                <app-text-field
                  type="text"
                  label="Add a description"
                  outlined
                  v-model="newTaskList.description"
                  autocomplete="new-password"
                  autofill="off"
                  :isMobile="isMobile"
                  :mobileOverlay="true"
                  helptext="Add further information to provide background or context"
                  >
                </app-text-field>
              </div>
            </app-dialog>

            <round-button style="margin:0;margin-bottom:16px;" medium hasSlot @click="addTaskListShowing = true" icon="add">Add List</round-button>

            <div v-if="taskLists">
              <div v-for="(taskList, listIndex) in taskLists" :key="'list-' + listIndex">
                  <div style="cursor:pointer;" @click="showTaskList(taskList, listIndex)" class="tasklist-header">
                      <div class="task-indicator" :class="getIndicatorClass(taskList)"></div>
                      <mdc-title>{{taskList.title}}</mdc-title>
                      <span v-if="taskList.commentCount" class="task-reply-count-wrapper">
                        <span class="task-reply-count">{{taskList.commentCount}}</span>
                      </span>
                  </div>
                  <mdc-body typo="body2" style="margin-top:0;" class="tasklist-desc" v-html="taskList.description"></mdc-body>
                  <div class="tasklist-tasks">
                    <div class="task-line" v-for="(task, index) in taskList.tasks" :key="'task-' + index">
                      <mdc-icon @click.native="completeTask(taskList, task, index)" style="margin:0;margin-right:16px;cursor:pointer" icon="check_box_outline_blank" outlined />
                      <tiptap-editor reducedOptions :ref="'editors' + listIndex" emitEnter @editorEnter="addTaskLine(taskList.tasks, listIndex, index + 1)" style="flex:1" :hashtags="initiatives" :mentionSuggestions="teamMentions" @onTextUpdate="onTaskUpdate" :content="''+task.text" :title="'Description'" :contentKey="'idx-' + listIndex + '-' + index">
                      </tiptap-editor>
                    </div>
                    <div class="task-line">
                      <mdc-icon style="color: #999;margin:0;margin-right:16px;cursor:pointer" @click="addTaskLine(taskList.tasks, listIndex, taskList.tasks.length)" icon="add" outlined />
                      <div style="color:#999; cursor:pointer;" @click="addTaskLine(taskList.tasks, listIndex, taskList.tasks.length)">Add task to this list</div>
                    </div>
                    <div class="task-line" v-for="(task, index) in taskList.tasksCompleted" :key="'taskComp-' + index">
                      <mdc-icon @click.native="uncompleteTask(taskList, task, index)" style="color: #666;margin:0;margin-right:16px;cursor:pointer" icon="check_box" outlined />
                      <div style="text-decoration: line-through; color:#666" v-html="task.text"></div>
                    </div>
                  </div>

              </div>
            </div>

            <mdc-body v-else class="not-found" typo="body2">No Tasks Found</mdc-body>

          </section>

          <section v-if="tab === 'schedule'">
            <mdc-body class="not-found" typo="body2">No Schedules Found</mdc-body>
          </section>

          <section v-if="tab === 'files'">
            <mdc-body class="not-found" typo="body2">No Files Found</mdc-body>
          </section>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>

    <app-dialog
        v-if="initiative"
        v-model="editInitShowing"
        :pauseFocus="isMobile"
        :footer="'Tip: Tagging '+ getTag(initiative.name) +' into an update or task will associate it into this initiative.'"
        class="update-modal fullsize-mobile add-init-modal"
        style="z-index: 13;"
        accept="Update"
        @accept="updateInitiative"
        :title="'Edit Initiative'"
        >
        <div class="form-section">
        <app-text-field
            type="text"
            label="Initiative Name"
            outlined
            v-model="initiative.name"
            autocomplete="new-password"
            autofill="off"
            :isMobile="isMobile"
            :mobileOverlay="true"
            helptext="- E.g. My Project"
            >
            </app-text-field>
            <app-text-field
            type="text"
            label="Add a description"
            outlined
            v-model="initiative.description"
            autocomplete="new-password"
            autofill="off"
            :isMobile="isMobile"
            :mobileOverlay="true"
            helptext="initiatives can be projects, responsibilities, activities or anything that requires focus."
            >
            </app-text-field>
        </div>
        <div class="form-section">
            <mdc-subheading style="display:flex;font-size:1em;">Core Members <mdc-icon style="margin-left:16px;" outlined icon="people"/></mdc-subheading>
            <div class="member-row" v-for="member in teamProfiles" :key="member.id">
            <span :title="member.displayName" class="avatar avatar-link">
              <router-link class="update-avatar" :to="'/user/' + member.id">
                  <img :class="{grayscale: !isMember(member.id)}" v-if="hasPhoto(member)" :src="getPhoto(member)"/>
                  <default-avatar :class="{grayscale: !isMember(member.id)}" v-else fontSize="16px" :profile="member"/>
              </router-link>
            </span>
            <span :class="{lighter: !isMember(member.id)}" class="member-text">{{member.displayName}}</span>
            <mdc-checkbox :checked="isMember(member.id)" @click="toggleMember(member.id)" class="member-check"></mdc-checkbox>
            </div>
            <div class="sub-label">Initiatives are added to their dashboard so they can provide updates</div>
        </div>
        <div class="form-section">
            <mdc-subheading style="display:flex;font-size:1em;">Visibility <mdc-icon style="margin-left:16px;" icon="visibility"/></mdc-subheading>
            <div><mdc-radio :picked="initiative.visibility === 'Core Members'" @change="initiative.visibility = 'Core Members'" :value="'Core Members'" name="radios" :label="'Visible to Core Members'" /></div>
            <div><mdc-radio :picked="initiative.visibility === 'Team'" @change="initiative.visibility = 'Team'" :value="'Team'" name="radios" :label="'Visible to Team'" /></div>
            <div><mdc-radio :picked="initiative.visibility === 'Org'" @change="initiative.visibility = 'Org'" :value="'Org'" name="radios" :label="'Visible to Org'" /></div>
            <div class="sub-label">Initiatives visible beyond the Core Members enables others to contribute or comment</div>
        </div>
    </app-dialog>

        <app-dialog
          v-if="update"
          v-model="updateShowing"
          @close="update = null"
          :pauseFocus="isMobile"
          class="update-modal fullsize-mobile with-messagebar"
          style="z-index: 13;"
          accept=""
          :title="groupDisplay(update.group)"
          :footer="'Update ' + update.date"
          >
          <update-section style="margin-top:12px;" :showReplyCount="false" :updates="[update]" :team="profile.team" :profiles="teamProfiles"/>

          <div style="display:flex;align-items:center;">
            <round-button icon="rss_feed" style="border: 1px solid;width:160px !important;" hasSlot>
              <span v-if="isSubscriber(update)" @click="unsubscribe(update)">Unsubscribe</span>
              <span v-else @click="subscribe(update)">Subscribe</span>
            </round-button>
            <round-button icon="thumb_up" @click="toggleLike(update)" :iconOutline="!likes(update)"></round-button>
            <span v-for="like in update.likes" :key="'like-'+like" :title="getProfile(like).displayName" class="avatar avatar-link">
            <router-link class="update-avatar" v-if="like !== 'bot'" :to="'/user/' + like">
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </router-link>
            <span class="update-avatar" v-else>
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </span>
            </span>
          </div>
          <mdc-title style="display:flex;margin-top: 0;margin-bottom: 12px;">
            <span class="update-reply-count-wrapper">
              <span class="update-reply-count">{{commentCount(update)}}</span>
            </span>
            Comments
          </mdc-title>

          <update-section v-if="update" showDateLines :updates="updateComments" :team="profile.team" :profiles="teamProfiles"/>

          <message-bar oneline relative v-on:focus="setInputFocused" :after="[]" :disabled="sendDisabled" :profile="profile" @send="addComment" ></message-bar>
        </app-dialog>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppDialog from '../components/AppDialog.vue'
import AppTextField from '../components/AppTextField'
import DefaultAvatar from '../components/DefaultAvatar'
import { dateYearFormat } from '../utils/time'
import MessageBar from '../components/MessageBar.vue'
import UpdateSection from '../components/UpdateSection.vue'

export default {
  name: 'initiative',
  components: {
    AppDialog,
    AppTextField,
    DefaultAvatar,
    MessageBar,
    UpdateSection,
    TiptapEditor: () => import('../components/Tiptap')
  },
  props: {
    isMobile: {}
  },
  data () {
    return {
      tab: 'updates',
      message: '',
      sendDisabled: false,
      initiative: null,
      selectedTaskList: -1,
      selectedListIndex: null,
      selectedTaskEdit: false,
      taskListViewShowing: false,
      updateShowing: false,
      updates: [],
      update: null,
      tasks: [],
      schedules: [],
      files: [],
      updateTaskTimeout: null,
      selectedTaskListIndex: null,
      selectedTaskIndex: null,
      lastTaskValue: null,
      addTaskListShowing: false,
      newTaskList: {
        name: '',
        description: ''
      },
      editInitShowing: false,
      links: [
        {
          title: 'Team',
          icon: 'people_outline',
          action: 'team',
          route: '/dash-team'
        },
        {
          title: 'Tempos',
          icon: 'model_training',
          iconOutlined: true,
          action: 'tempos',
          route: '/dash-team'
        },
        {
          title: 'Initiatives',
          icon: 'emoji_objects',
          iconOutlined: true,
          action: 'initiatives',
          route: '/dash-team'
        },
        {
          title: 'People',
          icon: 'person',
          iconOutlined: true,
          action: 'people',
          route: '/dash-team'
        }
      ]
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'initiatives',
      'teamProfiles',
      'taskLists',
      'updateComments',
      'taskListComments'
    ]),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    },
    lastWeek () {
      const d = new Date()
      const day = d.getDay()
      const diff = d.getDate() - day + (day === 0 ? -6 : 1) - 7
      return dateYearFormat(new Date(d.setDate(diff)))
    }
  },
  methods: {
    ...mapActions([
      'setLoading',
      'saveInitiative',
      'getUpdatesByTag',
      'getTaskLists',
      'saveTaskList',
      'saveTaskListComment',
      'saveUpdate',
      'loadUpdateComments',
      'loadTaskListComments',
      'clearUpdateComments',
      'clearTaskListComments'
    ]),
    changeTab (newTab) {
      if (this.tab === 'tasks') {
        this.focusLost()
      }
      const self = this
      this.$nextTick(() => {
        self.tab = newTab
      })
    },
    isMember (memberId) {
      if (this.initiative.members) {
        return this.initiative.members.includes(memberId)
      }
      return false
    },
    toggleMember (memberId) {
      if (this.initiative.members) {
        if (this.initiative.members.includes(memberId)) {
          this.initiative.members = this.initiative.members.filter((id) => id !== memberId)
          this.initiative = { ...this.initiative }
        } else {
          this.initiative.members.push(memberId)
          this.initiative = { ...this.initiative }
        }
      }
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      let prof = {}
      if (this.teamProfiles) {
        const profileMap = new Map(this.teamProfiles.map(i => [i.id, i]))
        prof = profileMap.get(profileId)
        if (!prof) {
          prof = {}
        }
      }
      return prof
    },
    updateSelectedTaskEdit () {
      this.selectedTaskList.title = this.selectedTaskListTitle
      this.selectedTaskList.description = this.selectedTaskListDescription
      this.selectedTaskEdit = false
      this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: this.selectedTaskList.id, taskList: this.selectedTaskList })
    },
    cancelSelectedTaskEdit () {
      this.selectedTaskListTitle = this.selectedTaskList.title
      this.selectedTaskListDescription = this.selectedTaskList.description
      this.selectedTaskEdit = false
    },
    isSubscriber (obj) {
      if (obj.subscribers) {
        return obj.subscribers.includes(this.profile.id)
      }
      return false
    },
    subscribeTaskList (tl) {
      if (!tl.subscribers) {
        tl.subscribers = []
      }
      if (!tl.subscribers.includes(this.profile.id)) {
        tl.subscribers.push(this.profile.id)
        this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: tl.id, taskList: { subscribers: tl.subscribers } })
      }
    },
    unsubscribeTaskList (tl) {
      if (!tl.subscribers) {
        tl.subscribers = []
      }
      if (tl.subscribers.includes(this.profile.id)) {
        tl.subscribers = tl.subscribers.filter((prof) => prof !== this.profile.id)
        this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: tl.id, taskList: { subscribers: tl.subscribers } })
      }
    },
    subscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (!update.subscribers.includes(this.profile.id)) {
        update.subscribers.push(this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    unsubscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (update.subscribers.includes(this.profile.id)) {
        update.subscribers = update.subscribers.filter((prof) => prof !== this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    showTaskList (tl, listIndex) {
      // console.log('showTaskList', this.selectedTaskList)
      if (this.selectedTaskListIndex >= 0) {
        // console.log('showTaskList saving old task', this.selectedTaskList)
        this.saveSelected()
      }
      const self = this
      this.$nextTick(() => {
        self.selectedTaskList = tl
        self.selectedListIndex = listIndex
        self.selectedTaskListIndex = listIndex
        self.taskListViewShowing = true
        self.selectedTaskEdit = false
        self.selectedTaskListTitle = self.selectedTaskList.title
        self.selectedTaskListDescription = self.selectedTaskList.description
      })
    },
    addCommentTaskList (message) {
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date()
      }
      this.saveTaskListComment({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: this.selectedTaskList.id, commentId: comment.id, comment })
      if (!this.selectedTaskList.commentCount) {
        if (this.taskListComments) {
          this.selectedTaskList.commentCount = this.taskListComments.length
        } else {
          this.selectedTaskList.commentCount = 0
        }
      }
      this.selectedTaskList.commentCount = this.selectedTaskList.commentCount + 1
      this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: this.selectedTaskList.id, taskList: { commentCount: this.selectedTaskList.commentCount } })
    },
    initials (profile) {
      let initialFirst = ''
      let initialLast = ''
      let displayNameParts = ['', '']
      displayNameParts = profile.displayName.split(' ')
      if (profile.firstName) {
        initialFirst = profile.firstName.trim().substring(0, 1)
      } else {
        initialFirst = displayNameParts[0].substring(0, 1)
      }
      if (profile.lastName) {
        initialLast = profile.lastName.substring(0, 1)
      } else {
        if (displayNameParts.length > 1) {
          initialLast = displayNameParts[1].substring(0, 1)
        }
      }
      return `${initialFirst}${initialLast}`.toUpperCase()
    },
    addTaskLine (list, listIndex, idx) {
      list.splice(idx, 0, {
        id: null,
        complete: false,
        text: '<p></p>',
        due: new Date(),
        mentions: []
      })
      setTimeout(() => {
        this.focusAt(listIndex, idx)
      }, 100)
    },
    saveSelected () {
      // console.log('saveSelected', this.selectedTaskListIndex, this.selectedTaskIndex, this.taskLists[this.selectedTaskListIndex], this.lastTaskValue)
      if (this.selectedTaskListIndex >= 0) {
        const selectedTaskList = this.taskLists[this.selectedTaskListIndex]
        if (selectedTaskList) {
          if (this.lastTaskValue !== '' && this.lastTaskValue !== '<p></p>') {
            this.$set(selectedTaskList.tasks[this.selectedTaskIndex], 'text', this.lastTaskValue)
            this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: selectedTaskList.id, taskList: selectedTaskList })
          }
        }
      }
    },
    focusLost () {
      if (this.selectedTaskListIndex) {
        this.saveSelected()
      }
      this.selectedTaskListIndex = null
      this.selectedTaskIndex = null
    },
    focusAt (listIndex, index) {
      this.focusLost()
      this.$refs['editors' + listIndex][index].focus()
    },
    onTaskUpdate (value, key) {
      const keyParts = key.split('-')
      const listIndex = keyParts[1]
      const taskIndex = keyParts[2]
      this.selectedTaskListIndex = listIndex
      this.selectedTaskIndex = taskIndex
      this.selectedTaskList = this.taskLists[listIndex]
      this.lastTaskValue = value
      // console.log('onTaskUpdate', this.lastTaskValue, listIndex, taskIndex)
    },
    addTaskList () {
      const useDummy = false
      if (useDummy) {
        const taskList = { ...this.newTaskList, tasks: [], tasksCompleted: [] }
        this.dummyTasks.push(taskList)
      } else {
        const taskList = { ...this.newTaskList, tasks: [], tasksCompleted: [] }
        this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: false, taskList })
      }
    },
    completeTask (taskList, task, index) {
      this.saveSelected()
      this.$nextTick(function () {
        task.completed = true
        taskList.tasksCompleted.push({ ...task })
        taskList.tasks.splice(index, 1)
        this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: taskList.id, taskList })
      })
    },
    uncompleteTask (taskList, task, index) {
      this.$nextTick(function () {
        task.completed = false
        taskList.tasks.push({ ...task })
        taskList.tasksCompleted.splice(index, 1)
        this.saveTaskList({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: taskList.id, taskList })
      })
    },
    showUpdate (update, week) {
      this.update = update
      if (update) {
        this.updateShowing = true
      }
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    updateInitiative () {
      this.initiative.tag = `#${this.initiative.name.replace(' ', '-')}`
      this.saveInitiative({ teamId: this.profile.team, initiativeId: this.initiative.id, initiative: this.initiative })
    },
    async setInitiative () {
      this.initiative = this.initiatives.filter(init => init.id === this.initiativeId)[0]
      if (this.initiative) {
        this.updates = await this.getUpdatesByTag({ teamId: this.profile.team, tag: this.initiative.name })
        this.getTaskLists({ teamId: this.profile.team, initiativeId: this.initiative.id })
      }
    },
    getTag (name) {
      if (!name) {
        return '<span class="hashtag">#myInitiative</span>'
      }
      return `<span class="hashtag">#${name.replace(' ', '-')}</span>`
    },
    getIndicatorClass (taskList) {
      let total = 0
      let completed = 0
      if (taskList.tasks) {
        total = total + taskList.tasks.length
      }
      if (taskList.tasksCompleted) {
        total = total + taskList.tasksCompleted.length
        completed = taskList.tasksCompleted.length
      }
      const percent = Math.round(completed / total * 10) * 10
      return `task-indicator-${percent}`
    },
    setInputFocused () {
    },
    addComment (message) {
      // console.log('Initiative.addComment', message)
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date()
      }
      this.saveComment({ teamId: this.profile.team, updateId: this.update.id, commentId: comment.id, comment })
      if (!this.update.commentCount) {
        if (this.updateComments) {
          this.update.commentCount = this.updateComments.length
        } else {
          this.update.commentCount = 0
        }
      }
      this.update.commentCount = this.update.commentCount + 1
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { commentCount: this.update.commentCount } })
    }
  },
  beforeDestroy () {
    this.focusLost()
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  async beforeRouteUpdate (to, from, next) {
    this.focusLost()
    this.$root.$emit('enableSearch', true, { back: true })
    this.setLoading(true)
    this.initiative = null
    this.initiativeId = to.params.id
    this.setInitiative()
    this.setLoading(false)
    next()
  },
  created () {
    window.addEventListener('beforeunload', e => this.focusLost(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.focusLost(e))
  },
  async mounted () {
    this.$root.$emit('enableSearch', true, { back: true })
    this.setLoading(true)
    this.initiativeId = (this.$route) ? this.$route.params.id : ''
    this.$emit('setNavActive', 'Discover')
    this.$emit('setLinks', this.links)
    this.setInitiative()
    this.setLoading(false)
  },
  watch: {
    initiatives () {
      this.setInitiative()
    },
    update: async function (newU, oldU) {
      if (newU) {
        this.loadUpdateComments({ teamId: this.profile.team, updateId: this.update.id })
      } else {
        this.clearUpdateComments()
      }
    },
    taskListViewShowing: async function (newtlvs, oldtlvs) {
      if (newtlvs) {
        if (this.selectedTaskList) {
          // console.log('initiatives.watch taskListViewShowing loadTaskListComments')
          this.loadTaskListComments({ teamId: this.profile.team, initiativeId: this.initiativeId, taskListId: this.selectedTaskList.id })
        }
      } else {
        this.clearTaskListComments()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.initiative {
    margin-top:70px;
}
.initiative-header {
  display:flex;
  align-items: center;
  width:100%;
}
.initiative-tag {
  flex:0;
}
.init-edit-icon {
  margin: 0;
  margin-top: 6px;
}
.initiative-header-detail {
  display:flex;
  flex-flow: row wrap;
  align-items: center;
  width:100%;
}
.initiative-header-detail .avatar-list {
  display:flex;
  flex-flow: row;
  align-items: center;
  flex:1;
  position: relative;
}
.avatar-list::after {
  position: absolute;
  content: "";
  right: 0px;
  height: 100%;
  width: 24px;
  background: linear-gradient(to right, transparent, white 75%, white);
  pointer-events: none;
}
.initiative-tag, .initiative-header-detail .avatar-list {
  margin-top:6px;
}
.initiative .member-row {
  display:flex;
  width:100%;
  align-items:center;
}
.initiative .member-row .member-text {
  margin-left:16px;
  margin-right:auto;
}
.initiative .member-row .member-checkbox {
  margin-left:auto;
}
.initiative-tab-menu {
  display:flex;
  align-items: center;
  margin-bottom:16px;
  margin-left: -12px;
}
.initiative-tab-menu > span {
  cursor:pointer;
  border-bottom:2px solid transparent;
  padding:6px;
  padding-bottom:2px;
  margin:4px;
  display:flex;
  align-items: center;
}
.initiative-tab-menu > span.active {
  border-bottom:2px solid orangered;
}
.initiative-tab-menu .material-icons {
  margin-right:6px;
}
@media (max-width: 440px) {
  .initiative-tab-menu > span .tab-title {
    display:none;
  }
  .initiative-tab-menu > span.active .tab-title {
    display:block;
  }
}
.initiative .form-section {
    margin-top: 24px;
}
.initiative .avatar {
    position: relative;
    width: 21px !important;
    height: 21px !important;
    margin-left: 2px;
    margin-right: 2px;
}
.initiative .avatar .update-avatar  {
    width:21px !important;
    height: 21px !important;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
}
.initiative .avatar .update-avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.task-indicator {
  background: rgba(39, 188, 181, 0.4);
  border-radius: 4px;
  height:20px;
  width:20px;
  border: 2px solid #008080;
  margin-right:16px;
}
.task-indicator-10 {
  background: linear-gradient(to top, rgb(39, 188, 181) 10%, rgba(39, 188, 181, 0.4) 10%);
}
.task-indicator-20 {
  background: linear-gradient(to top, rgb(39, 188, 181) 20%, rgba(39, 188, 181, 0.4) 20%);
}
.task-indicator-30 {
  background: linear-gradient(to top, rgb(39, 188, 181) 30%, rgba(39, 188, 181, 0.4) 30%);
}
.task-indicator-40 {
  background: linear-gradient(to top, rgb(39, 188, 181) 40%, rgba(39, 188, 181, 0.4) 40%);
}
.task-indicator-50 {
  background: linear-gradient(to top, rgb(39, 188, 181) 50%, rgba(39, 188, 181, 0.4) 50%);
}
.task-indicator-60 {
  background: linear-gradient(to top, rgb(39, 188, 181) 60%, rgba(39, 188, 181, 0.4) 60%);
}
.task-indicator-70 {
  background: linear-gradient(to top, rgb(39, 188, 181) 70%, rgba(39, 188, 181, 0.4) 70%);
}
.task-indicator-80 {
  background: linear-gradient(to top, rgb(39, 188, 181) 80%, rgba(39, 188, 181, 0.4) 80%);
}
.task-indicator-90 {
  background: linear-gradient(to top, rgb(39, 188, 181) 90%, rgba(39, 188, 181, 0.4) 90%);
}
.task-indicator-100 {
  background:  rgb(39, 188, 181);
}
.task-line {
  display:flex;
  align-items: center;
}
.tasklist-header {
  display:flex;
  align-items: center;
  margin-top:12px;
}
.task-reply-count {
  border-radius:16px;
  width:26px;
  height:26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  background-color:#3560af;
  color:white;
}
.task-reply-count-wrapper {
  display:flex;
  align-items: center;
  border-left: 1px solid #999999;
  margin-left:6px;
  padding-left:6px;
  align-self: stretch;
}
.task-comment-count-wrapper {
  display:flex;
  align-items: center;
  margin-left:6px;
  margin-right:6px;
  padding-left:6px;
  align-self: stretch;
}
</style>
<style>
.initiative .update-modal .mdc-dialog__surface {
  position: relative;
}
.initiative .update-modal .mdc-dialog__surface{
  margin-bottom:6px;
}
.initiative .update-input-modal .mdc-dialog__surface  .mdc-dialog__content  {
  padding-left:0;
  padding-right:0;
}
.initiative .update-modal .mdc-dialog__surface .bottom-bar-wrapper {
  min-height: 42px;
  max-width: unset;
  z-index:2;
}
.initiative .update-modal .mdc-dialog__surface .bottom-bar-wrapper .bottom-bar {
  padding-right: 0;
}
.initiative .update-modal.with-messagebar .mdc-dialog__content {
  margin-bottom:40px;
}

.add-tasklist-modal .app-text-field {
  width:100%;
}
.initiative .update-modal .hashtag {
  padding-left: 10px;
}
.initiative .initiative-header .hashtag {
  padding: 6px;
  padding-left: 10px;
  padding-right: 0px;
  width: calc(100% - 16px);
  max-width:100%;
  flex:0;
  margin-right:16px;
  font-size:1.1em;
}
.initiative .task-line .hashtag {
  padding-left: 10px;
}
.task-line p {
  margin-top:4px;
  margin-bottom:4px;
}
.update-modal .mdc-text-field-wrapper {
  width:100%;
  margin-top:16px;
}
</style>
