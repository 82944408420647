import fb from './firebase'

const state = {
  jobs: null
}

const jobsRef = fb.firestore.collection('jobs')
let jobsListener

const resetJobListener = () => {
  if (jobsListener) { jobsListener() }
}

const getters = {
  getJobData: (state) => (jobId) => {
    return jobsRef.doc(jobId).get().then(doc => {
      if (doc.exists) {
        return doc.data()
      }
      return null
    })
  }
}

const actions = {
  addJob ({ commit }, { job, jobId }) {
    job.created = fb.serverTime()
    if (jobId) {
      return jobsRef.doc(jobId).set(job)
    } else {
      return jobsRef.add(job)
    }
  },
  updateJob ({ commit }, { job, jobId }) {
    job.updated = fb.serverTime()
    if (jobId) {
      return jobsRef.doc(jobId).update(job)
    }
  },
  deleteJob ({ commit }, { jobId }) {
    if (jobId) {
      return jobsRef.doc(jobId).delete()
    }
  },
  applyJob ({ commit }, { jobId, profileId }) {
    return jobsRef.doc(jobId).get().then(doc => {
      const job = doc.data()
      if (!job.applicants) {
        job.applicants = {}
      }
      job.applicants[profileId] = true
      jobsRef.doc(jobId).update({ applicants: job.applicants })
    })
  },
  setJobInterest ({ commit }, { jobId, profileId, interest }) {
    return jobsRef.doc(jobId).get().then(doc => {
      let job = {}
      if (doc.exists) {
        job = doc.data()
        if (interest) {
          if (!job.interest) {
            job.interest = {}
          }
          job.interest[profileId] = true
        } else {
          if (job.interest) {
            delete job.interest[profileId]
          }
        }
        jobsRef.doc(jobId).update({ interest: job.interest })
      } else {
        if (interest) {
          job.interest = {}
          job.interest[profileId] = true
        }
        jobsRef.doc(jobId).set({ interest: job.interest })
      }
    })
  },
  loadJobs ({ commit }) {
    if (jobsListener) { jobsListener() }
    jobsListener = jobsRef
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })
        commit('setJobs', results)
      })
  }
}

const mutations = {
  setJobs: (state, jobs) => {
    state.jobs = jobs
  }
}

export default { state, actions, getters, mutations, resetJobListener }
