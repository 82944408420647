import fb from './firebase'

const teamChannelsRef = fb.firestore.collection('teamChannels')
const companiesRef = fb.firestore.collection('companies')

let channelMessagesListener
let teamChannelListener

const state = {
  teamChannels: [],
  companyChannels: []
}

const getChannelsRef = (connectionType) => {
  if (connectionType === 'Team') {
    return teamChannelsRef
  } else {
    return companiesRef.doc('5b8uqkZSX1GQoE0YTb6i').collection('channels')
  }
}

const resetChannelMessagesListener = () => {
  if (channelMessagesListener) { channelMessagesListener() }
}

const resetTeamChannelListener = () => {
  if (teamChannelListener) { teamChannelListener() }
}

const actions = {
  loadCompanyChannels ({ commit }) {
    getChannelsRef('Company')
      .orderBy('channelName', 'asc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setCompanyChannels', results)
      }, error => {
        console.log(error)
      })
  },
  loadTeamChannels ({ commit }, { teamId }) {
    console.log('loading Team Channels: ', teamId)
    commit('setTeamChannels', [])
    if (teamChannelListener) { teamChannelListener() }
    teamChannelListener = getChannelsRef('Team')
      .where('teamId', '==', teamId)
      .orderBy('lastUpdate', 'desc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() })
        })
        commit('setTeamChannels', results)
      }, error => {
        console.log(error)
      })
  },
  addTeamChannels ({ commit }, { teamId }) {
    const lookingForward = {
      channelName: 'Looking Forward',
      channelLogo: '/static/img/icons-60.png',
      channelType: 'Team',
      channelPriority: 1,
      teamId
    }
    const roundTable = {
      channelName: 'Round Table',
      channelLogo: '/static/img/icons-60.png',
      channelType: 'Team',
      channelPriority: 1,
      teamId
    }
    const achievement = {
      channelName: 'Remote Today',
      channelLogo: '/static/img/icons-60.png',
      channelType: 'Team',
      channelPriority: 1,
      teamId
    }
    const schedule = {
      channelName: 'Working From',
      channelLogo: '/static/img/icons-60.png',
      channelType: 'Team',
      channelPriority: 1,
      teamId
    }
    const hotline = {
      channelName: 'Hotline',
      channelLogo: '/static/img/icons-60.png',
      channelType: 'Team',
      channelPriority: 0,
      teamId
    }
    const channels = [lookingForward, roundTable, achievement, schedule, hotline]
    const batch = fb.firestore.batch()
    const batchRef = teamChannelsRef
    for (const channel of channels) {
      const newRef = batchRef.doc()
      batch.set(newRef, channel)
    }
    return batch.commit()
  },
  addChannel ({ commit }, { channel }) {
    const channelsRef = getChannelsRef(channel.channelType)
    const firstMessage = channel.firstMessage
    if (firstMessage) {
      delete channel.firstMessage
    }
    channel.lastMessage = null
    channel.lastSender = null
    channel.lastUpdated = null
    channel.channelLogo = '/static/img/icons-60.png'
    return channelsRef.add(channel).then((doc) => {
      if (firstMessage) {
        if (firstMessage !== '') {
          const message = {
            connectionId: doc.id,
            connectionType: channel.channelType,
            created: fb.serverTime(),
            fromId: channel.createdBy,
            text: firstMessage
          }
          const messagesRef = channelsRef
            .doc(doc.id)
            .collection('messages')
          return messagesRef.add(message)
        }
      }
    })
  },
  addChannelMessage ({ commit }, { message }) {
    message.created = fb.serverTime()
    const connectionId = message.connectionId
    const channelsRef = getChannelsRef(message.connectionType)

    const messagesRef = channelsRef
      .doc(connectionId)
      .collection('messages')

    return messagesRef.add(message)
  },
  async getChannel ({ commit }, { connectionId, connectionType }) {
    const channelsRef = getChannelsRef(connectionType)
    const channel = await channelsRef
      .doc(connectionId)
      .get()
    return { id: channel.id, ...channel.data() }
  },
  loadChannelMessages ({ commit }, { connectionId, connectionType }) {
    const channelsRef = getChannelsRef(connectionType)

    if (channelMessagesListener) { channelMessagesListener() }

    channelMessagesListener = channelsRef
      .doc(connectionId)
      .collection('messages')
      .orderBy('created', 'asc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          if (doc.id !== this.state.user.uid) {
            results.push({ id: doc.id, ...doc.data() })
          }
        })
        commit('setMessages', results)
      })
  },
  loadChannelMessagesReverse ({ commit }, { connectionId, connectionType }) {
    const channelsRef = getChannelsRef(connectionType)

    if (channelMessagesListener) { channelMessagesListener() }

    channelMessagesListener = channelsRef
      .doc(connectionId)
      .collection('messages')
      .orderBy('created', 'desc')
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          if (doc.id !== this.state.user.uid) {
            results.push({ id: doc.id, ...doc.data() })
          }
        })
        commit('setMessages', results)
      })
  }
}

const mutations = {
  setCompanyChannels: (state, companyChannels) => {
    state.companyChannels = companyChannels
  },
  setTeamChannels: (state, teamChannels) => {
    state.teamChannels = teamChannels
  }
}

export default { state, actions, mutations, resetChannelMessagesListener, resetTeamChannelListener }
