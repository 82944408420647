const url = 'https://ghost2.duome.co/ghost/api/v3/content'
const creds = 'key=7a0b42d55356762a63f87b703e'

const doFetch = async (endpt, params = '') => {
  const result = await fetch(`${url}${endpt}?${creds}${params}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const res = await result.json()
  return res
}

export const posts = (pageNumber, tag) => {
  if (tag) {
    if (pageNumber) {
      return doFetch('/posts/', `&include=authors,tags&filter=tags:[${tag}]&page=${pageNumber}&order=published_at DESC`)
    }
    return doFetch('/posts/', `&include=authors,tags&filter=tags:[${tag}]&order=published_at DESC`)
  }
  return doFetch('/posts/', `&include=authors,tags&page=${pageNumber}&order=published_at DESC`)
}

export const post = (id) => {
  return doFetch(`/posts/${id}/`)
}

export const postBySlug = (slug) => {
  return doFetch(`/posts/slug/${slug}/`, '&include=authors,tags')
}

export const page = (id) => {
  return doFetch(`/pages/${id}/`)
}

export const pageBySlug = (slug) => {
  return doFetch(`/pages/slug/${slug}/`)
}
