<template>
  <div class="main-content">
    <div class="main-wrapper" style="margin-top: 56px;">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

        <app-section class="connect"
        dialogHeader
        :editable="false"
        animationTime="0ms"
        title="Connections"
        >
         <mdc-list style="padding: 0;">
           <template v-if="connectionsFiltered.length">
              <mdc-list-item v-for="con in connectionsFiltered" :key="con.id"  class="connection" @click="$router.push('/connection/' + con.id)">
                <div class="connectionRow" >
                  <span class="avatar" v-if="profiles">
                    <img v-if="hasPhoto(otherUser(con.users))" :src="getPhoto(otherUser(con.users))"/>
                    <default-avatar v-else fontSize="26px" :profile="otherUser(con.users)"/>
                  </span>
                  <div>
                    <div class="connectionDetails">
                      <div class="connectionTitle" v-if="profiles">
                        <span v-if="otherUser(con.users)">
                          <span>{{otherUser(con.users).displayName}}</span>
                          <span style="margin-left:6px;margin-right:6px;">&middot;</span>
                        </span>
                        <span v-if="con.lastUpdate">
                          <time-ago refresh long :datetime="con.lastUpdate.toDate()"></time-ago>
                          <span style="margin-left:6px;margin-right:6px;">&middot;</span>
                       </span>
                        <span>{{con.status || 'Connection'}}</span>
                      </div>
                      <mdc-body tag="span" typo="body2" class="lastMessage">{{con.lastMessage}}</mdc-body>
                    </div>
                  </div>
                  <span class="notification-icon">
                    <span class="unread" v-if="con.unread[user.uid] > 0">{{con.unread[user.uid]}}</span>
                  </span>
                </div>
              </mdc-list-item>
          </template>
          <template v-else>
            <mdc-list-item class="connection empty">
              Your inbox is empty, go connect with people or jobs you're interested in sharing.
            </mdc-list-item>
          </template>
          </mdc-list>
        </app-section>

        </mdc-layout-cell>
      </mdc-layout-grid>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectMenu from '../components/ConnectMenu.vue'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'
import DefaultAvatar from '../components/DefaultAvatar'
import TimeAgo from '../components/TimeAgo.vue'
import AppSection from '../components/profile-v2/AppSection.vue'
export default {
  name: 'connect',
  data () {
    return {
      title: 'Connect',
      links: [
        { component: ConnectMenu }
      ],
      filter: null
    }
  },
  components: {
    TimeAgo,
    DefaultAvatar,
    AppSection
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles',
      'connections',
      'remote'
    ]),
    connectionsFiltered: function () {
      const self = this
      if (self.filter) {
        return this.connections.filter((con) => {
          return con.status === self.filter
        })
      }
      return this.connections
    }
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    fitlerStatus: function (status) {
      this.filter = status
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto: function (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  mounted () {
    if (this.profile) {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
    }
    this.$emit('setLinks', this.links)
    this.$root.$emit('enableSearch', true, { context: 'messages' })
  },
  watch: {
    remote () {
      if (this.remote) {
        this.links = [{ component: ConnectMenuRemote }]
      } else {
        this.links = [{ component: ConnectMenu }]
      }
      this.$emit('setLinks', this.links)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.connect .avatar {
    min-width: 56px;
    height: 56px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-right: 12px;
    position: relative;
}
.connect .app-section .section-content {
  padding:0;
}
.connect .avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.connect .connection {
    height: auto;
    padding: 0;
}
.connect .connection.empty {
  padding:20px;
  color: rgba(0, 0, 0, 0.54);
}
.connect .connection:hover {
  background-color: #F9F9F9;
}
.connect .connection .connectionRow {
  cursor:pointer;
  width: calc(100% - 20px);
  border-bottom:1px solid #eee;
  display: flex;
  padding: 10px;
}
.connect .lastMessage{
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  padding-right: 20px;
}
.connect .flexrow {
  display:flex;
  flex-flow: row wrap;
}
.connect .flexrow .left{
  min-width:300px;
  flex-grow: 1;
}
.connect .flexrow .right{
  min-width:300px;
  flex: 1;
}
.connect .connection .mdc-layout-grid {
  width:100%;
  padding:12px;
}
.connectionTitle {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}
.connectionRow > div {
  min-width: 0;
}
.connect .notification-icon {
  margin-left: auto;
  min-width: auto;
  align-self: center;
  z-index:1;
}
.connect .notification-icon .unread {
  width:24px;
  height:24px;
  font-size:16px;
  font-weight: bold;
  color: #333333;
  z-index:1;
  left:0;
}
.connect .mdc-card__supporting-text,
.connect .mdc-card__supporting-text > .mdc-list {
  padding: 0;
}
.connect .mdc-card-text.mdc-card__supporting-text {
  padding: 0;
}
.connect .connection .connectionRow {
  align-items: center;
}
</style>
