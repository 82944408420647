<template>
  <portal to="destination" :disabled="!(focused && isMobile)">

  <mdc-body v-if="prompt" typo="body1" class="padded-label">{{prompt}}</mdc-body>
  <mdc-body typo="body2" style="color:#b00020;text-align:left;margin-left:0;width:100%;" v-if="!valid">{{validationMessage}}</mdc-body>

  <div class="mdc-textfield-wrapper autocomplete autocomplete-location required" ref="inputtop">
    <div class="autocomplete mdc-text-field mdc-text-field--upgraded outlined mdc-text-field--outlined outlined mdc-text-field--with-leading-icon"
      :class="{'mdc-text-field--focused': focused, 'mdc-text-field--disabled': disabled, 'mdc-text-field--invalid': !valid }"
      aria-haspopup="listbox"
      aria-owns="autocomplete-results"
      ref="inputwrap"
      :id="'wrapper-' + id"
      :aria-expanded="isOpen"
    >

      <div style="padding: 17px 0 0 17px;z-index:9">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z"/>
            <path class="icon-path" fill="#828282" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
            <circle class="icon-circle" fill="#828282" cx="12" cy="9" r="2.5"/>
        </svg>
      </div>

      <vue-google-autocomplete
        v-if="id"
        :ref="id"
        :id="id"
        v-bind="$attrs"
        :tabindex="tabindex"
        autocomplete="new-password"
        autofill="off"
        @focus="handleFocus"
        @blur="handleBlur"
        @keypress="checkKey"
        @inputChange="update"
        classname="mdc-text-field__input"
        :placeholder="value || ''"
        types="(cities)"
        :country="['uk']"
        @placechanged="getAddressData"
        >
      </vue-google-autocomplete>

      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>
        <div class="mdc-notched-outline__notch">
          <label for="location-ac" class="mdc-floating-label" :class="{'mdc-floating-label--float-above': locationInputVal || value || focused}" >
            {{label}}
            <i class="required"></i>
          </label>
        </div>
        <div class="mdc-notched-outline__trailing"></div>
      </div>
    </div>

    <div class="mdc-text-field-helper-line">
      <mdc-body typo="body2" v-if="helptext" class="sub-label">{{helptext}}</mdc-body>
    </div>
  </div>

  </portal>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'autocomplete-location',
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    validationMessage: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    helptext: {
      type: String,
      required: false
    },
    ariaLabelledBy: {
      type: String,
      required: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mobileOverlay: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: String
    },
    tabindex: {
    },
    scrollIntoView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueGoogleAutocomplete
  },
  data () {
    return {
      id: '',
      isOpen: false,
      results: [],
      search: '',
      searchCompleted: '',
      isLoading: false,
      arrowCounter: 0,
      activedescendant: '',
      focused: false,
      timeout: null,
      overResults: false,
      inputLength: 0,
      locationInputVal: '',
      refocus: false,
      address: {}
    }
  },
  methods: {
    getRndId () {
      this.id = 'autocomplete-location-' + Math.floor(100000 + Math.random() * 900000)
    },
    movePacsBody () {
      let pacs = this.$el.getElementsByClassName('pac-container')
      const body = document.getElementsByClassName('body')[0]
      let pac = pacs[0]
      if (body) {
        while (pac) {
          body.appendChild(pac)
          pacs = this.$el.getElementsByClassName('pac-container')
          pac = pacs[0]
        }
      }
    },
    movePacsHere () {
      const pacs = document.getElementsByClassName('pac-container')
      let autocomp
      for (const pac of pacs) {
        autocomp = document.getElementById('wrapper-' + this.id)
        // console.log('movePacsHere', autocomp, pac)
        if (autocomp) {
          autocomp.appendChild(pac)
        }
      }
    },
    checkKey (event) {
      // console.log('checkKey', event)
    },
    getAddressData (addressData, placeResultData, id) {
      const self = this
      self.locationInputVal = addressData.locality
      setTimeout(function () {
        self.locationInputVal = addressData.locality
        const address = {}
        address.location = addressData.locality
        address.locationFull = placeResultData.formatted_address
        address.locationLat = addressData.latitude
        address.locationLon = addressData.longitude
        self.$emit('updateAddress', address)
      }, 500)
    },
    onChange ($event) {
      const self = this
      this.search = $event.target.value
      this.inputLength = $event.target.value.length
      self.$nextTick(() => {
        self.isOpen = true
        self.$emit('input', this.search)
      })
    },
    update (val) {
      // console.log('AutocompleteLocation.update', val)
      this.locationInputVal = val.newVal
      if (this.scrollIntoView) {
        this.scrollToPacs()
      }
    },
    scrollToPacs () {
      const self = this
      setTimeout(function () {
        const pacs = self.$el.getElementsByClassName('pac-container')
        for (const pac of pacs) {
          // console.log('AutoCompleteLocation.update scrolling to pac', pac)
          pac.scrollIntoView(false)
        }
      }, 300)
    },
    handleFocus () {
      // console.log('focus', this.isMobile, this.mobileOverlay , this.refocus)
      this.focused = true
      const self = this

      setTimeout(function () {
        self.movePacsHere()
        if (this.scrollIntoView) {
          this.scrollToPacs()
        }
      }, 300)

      if (this.isMobile && this.mobileOverlay) {
        if (!this.refocus) {
          this.refocus = true

          this.$root.$children[0].$refs.view.$emit('inputFocused')
        } else {
          this.refocus = false
        }
      }
    },
    handleBlur () {
      if (!this.refocus) {
        // console.log('blur', this.isMobile, this.mobileOverlay, this.refocus)
        const self = this
        self.movePacsBody()
        if (this.isMobile && this.mobileOverlay) {
          setTimeout(function () {
            self.$root.$children[0].$refs.view.$emit('inputBlurred')
            self.focused = false
            self.$emit('blur')
          }, 300)
        } else {
          self.$root.$children[0].$refs.view.$emit('inputBlurred')
          self.focused = false
          self.$emit('blur')
        }
      } else {
        if (this.isMobile && this.mobileOverlay) {
          // console.log('blur refocus', this.isMobile, this.mobileOverlay)
          const self = this
          setTimeout(function () {
            self.$refs[self.id].focus()
          }, 300)
        }
      }
    }
  },
  watch: {
    isMobile: function () {
      if (this.focused && this.mobileOverlay) {
        this.refocus = false
        this.handleFocus()
      }
    }
  },
  mounted () {
    this.getRndId()
  }
}
</script>

<style scoped>
.autocomplete {
  position: relative;
  min-width: 120px;
  margin-right:24px;
  width:100%;
  display:flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top:6px !important;
  margin-left:0 !important;
  min-height: 63px;
  height: auto;
}

.autocomplete.mdc-text-field.outlined .mdc-text-field__input::placeholder {
  opacity: 1 !important;
}

.autocomplete .sub-label {
    font-size: 0.8em;
    margin-top: 16;
    margin-bottom: 6px;
    color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
}

.padded-label {
  margin-right:auto;
}

.autocomplete .mdc-text-field-helper-line {
  width:100%;
}

.autocomplete.mdc-textfield-wrapper {
  margin-top:2px !important;
}

.autocomplete .tooltip {
  z-index:-1;
}

.autocomplete .autocomplete-input {
    max-width: calc(100% - 24px);
    left: 0;
    top: 0;
    text-overflow: ellipsis;
}

.autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined {
  margin-bottom:0px;
  height:auto;
  min-height:58px;
}

.autocomplete.mdc-text-field.outlined .mdc-text-field__input {
    border: none!important;
    background-color: transparent;
    margin:0px;
    margin-top:2px;
    height:54px;
}

.autocomplete.mdc-text-field.outlined.mdc-text-field--with-leading-icon .mdc-text-field__input {
  padding-left: 48px;
  width: calc(100% - 48px);
}

.autocomplete.mdc-text-field.outlined .mdc-text-field__input:not(.background) {
    z-index:1;
    position: absolute;
}

.autocomplete .mdc-textfield .mdc-notched-outline {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
    opacity: 1;
    max-height:58px;
}

.mdc-notched-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 2px);
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    overflow: visible;
    display: flex;
    max-height:58px;
}

.mdc-text-field--outlined .mdc-floating-label {
  margin-top:1px;
}

.mdc-floating-label--float-above, .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    bottom:16px;
    left:0px;
    background:white;
    padding-left:6px;
    padding-right:6px;
}

.mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-radius: 4px 0 0 4px;
}
.mdc-text-field.outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-radius: 0 4px 4px 0;
}

.mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0,0,0,.24);
}
.mdc-notched-outline__leading {
    border-left: 1px solid;
    border-right: none;
    width: 12px;
}

.mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0,0,0,.24);
}
.mdc-notched-outline__notch {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 12px * 2);
}

.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020 !important;
}

.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .icon-path,
.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .icon-circle {
  fill: #b00020 !important;
}

.mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field.outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    -webkit-transition: border .15s cubic-bezier(.4,0,.2,1);
    -o-transition: border .15s cubic-bezier(.4,0,.2,1);
    transition: border .15s cubic-bezier(.4,0,.2,1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
  border-color: #2D9CDB;
  border-color: var(--mdc-theme-primary, #2D9CDB);
}

.mdc-notched-outline__leading[dir=rtl], .mdc-notched-outline__trailing, [dir=rtl] .mdc-notched-outline__leading {
  border-left: none;
  border-right: 1px solid;
  flex-grow: 1;
}

.mdc-floating-label--float-above i.required:hover::after {
  font-size: 18px;
  left: 6px;
  padding:6px;
}

.autocomplete.required .mdc-notched-outline .mdc-floating-label--float-above, .autocomplete .mdc-notched-outline.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    padding-right:12px;
}

.autocomplete .autocomplete-label .material-icons.tooltip-icon {
  font-size: 16px;
  position:relative;
  top: 4px;
  cursor: pointer;
}

.autocomplete .autocomplete-label {
  padding:6px;
}

.autocomplete .mdc-textfield.mdc-text-field.mdc-text-field--upgraded {
  align-items: start;
}

.autocomplete .mdc-text-field__input.background {
  position:absolute;
  left:0;
  top:0;
  border-bottom:none !important;
  z-index: -1;
  opacity:1;
  color:#AAA;
}

.autocomplete-results {
  position:relative;
  top:-6px;
  left:1px;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 24px;
  max-height:250px;
  overflow: auto;
  width: calc(100% - 4px);
  min-width: 200px;
  background-color:white;
  margin-top: 64px;
  z-index:5;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 12px;
  cursor: pointer;
  background-color:white;
}

.autocomplete-option {
    height: 50px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    flex-flow: row nowrap;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #abe0f0;
  color: #333;
}

.input-bottom {
  top: 290px !important;
  height: 1px;
  width: 100%;
  opacity: 0;
  z-index: -1;
}

.mdc-textfield-wrapper{
  z-index:9;
  position:relative;
}
@media all and (max-width: 570px), all and (max-height: 570px){
  .autocomplete-results {
    min-height: 24px;
    max-height:100px;
  }
  .input-bottom {
    position:absolute;
    margin-top:75px;
  }
}
</style>

<style>
.autocomplete.autocomplete-location .pac-container {
  position:relative !important;
  top:12px !important;
  left: 2px !important;
  width: calc(100% - 8px) !important;
}
</style>
