<template>
  <div id="app" class="mdc-typography" :class="browserClass">
    <div class="mdc-layout-app" :class="headroomClass">

      <portal-target
        name="destination"
        class="mobile-input-overlay"
        v-show="isMobile && inputFocused"
        @change="focusInput"
        ref="portal"
        >
      </portal-target>

      <headroom v-if="!$route.meta.noheader && !topNavHidden && !initialPageLoad"
                class="app-header"
                @top="headroomClass = 'app-headroom--pinned'"
                @pin="headroomClass = 'app-headroom--pinned'"
                @unpin="headroomClass = 'app-headroom--unpinned'"
                :disabled="desktop"
                :class="{'headroom--pinned' : desktop }"
                :style="'height:auto'">
        <app-top-bar :navActive="navActive"
                     @openProfileTasks="profileNotification = true"
                     :profileReady="profileReady"
                     :companyStyle="brandStyle"/>
      </headroom>

      <app-side-drawer v-if="!$route.meta.nodrawer"
                      :style="{'visibility': profileReady ? 'visible' : 'hidden'}"
                      :drawerStyle="brandStyle.sidebar"
                      :links="links"
                      :profile="profile"
                      :desktop="desktop"
                      :profileReady="profileReady"
                      :currentRoute="$route"
                      @showNotes="openNotesModal"
                      />

      <app-side-drawer v-if="$route.meta.loggedOutDrawer"
                      :style="{'visibility': 'visible'}"
                      :drawerStyle="brandStyle.sidebar"
                      :links="links"
                      :profile="{}"
                      :desktop="desktop"
                      :profileReady="true"/>

      <transition name="fade">
        <div v-if="initialPageLoad" style="position:absolute; width:100%;height:100%; display:flex; justify-content: center; align-items: center; flex-flow:column; background-color:rgba(255,255,255,0.8)">
          <img style="padding:16px;max-width:300px;min-width:180px;width:80%;" src="/static/img/duome-card.svg" />
          <div style="position:relative;width: 70px;">
            <div style="position:absolute;left:0;margin-top:16px;" class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </transition>

      <div class="mdc-drawer-app-content">

        <profile-tasks @checkProfile="initializePage = true" @modalClosed="profileNotification = false" :profile="profile" :tasksModalOpen="profileNotification"></profile-tasks>

        <transition name="fade">
          <div v-if="pageLoad" class="spinner-wrapper">
            <div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </transition>

        <main class="main-content" id="main-content">

          <mdc-layout-grid v-show="searchEnabled" class="no-height-grid">
            <mdc-layout-cell span=12>
              <app-search-detailed
                ref="searchbar"
                v-model="searchValue"
                :searchFilter="searchFilter"
                :filterJobs="filterJobs"
                :filterPartners="filterPartners"
                v-bind="searchOptions"
                v-on="searchEvents"
                @action="onAction"
                @hideBottomNav="hideBottomNav"
                @clear="onSearchClear"
                @clearFilter="onFilterClear"
                @search="onSearch" />
            </mdc-layout-cell>
          </mdc-layout-grid>

          <transition
            :duration="100"
            @before-enter="pageLoading"
            @after-enter="finishPageLoad">
            <keep-alive :include="['Discover', 'DashTeam']">
              <router-view
                ref="view"
                v-show="!inSearch"
                :inSearch="inSearch"
                :search="searchValue"
                :filterJobs="filterJobs"
                :filterPartners="filterPartners"
                @loadMore="loadMore"
                :initializePage="initializePage"
                @initialized="initializePage = false"
                @inputFocused="inputFocused = true"
                @inputBlurred="inputFocused = false"
                :desktop="desktop"
                :isMobile="isMobile"
                :isTablet="isTablet"
                :isTabletLarge="isTabletLarge"
                :isDesktopLarge="isDesktopLarge"
                @setNavActive="setNavActive"
                @hideTopNav="hideTopNav"
                @hideBottomNav="hideBottomNav"
                @openProfileTasks="profileNotification = true"
                @setLinks="setLinks">
              </router-view>
            </keep-alive>
          </transition>
        </main>

        <footer v-if="!$route.meta.noheader && !bottomNavHidden && !$route.meta.nofooter" class="mobileonly">
          <div v-if="user && profileReady" class="bottom-navbar" :class="{'company-navbar': this.isBranded}" :style="brandStyle.bottombar">
            <app-nav-bottom :companyStyle="brandStyle"></app-nav-bottom>
          </div>
        </footer>
      </div>

      <app-dialog
        v-if="remote && teamMode && team && profile"
        v-model="notesOpen"
        :pauseFocus="isMobile"
        class="notes-modal fullsize-mobile"
        style="z-index: 13;"
        accept="Save"
        @accept="updateNotes"
        :title="'Notes'"
        >
          <update-input
            ref="notes"
            defaultType="note"
            showDateLines
            showBlankDates
            @deleteUpdate="onDeleteUpdate"
            groupMonth
            groupBlank
            :updateGroup="teamNotes"></update-input>
        </app-dialog>

      <snackbar :message.sync="snack.message"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import { headroom } from 'vue-headroom'
import { MDCDrawer } from '@material/drawer'
import { MDCTopAppBar } from '@material/top-app-bar'
import AppTopBar from './components/AppTopBar'
import AppNavBottom from './components/AppNavBottom.vue'
import AppSideDrawer from './components/AppSideDrawer.vue'
import Snackbar from './components/Snackbar.vue'
import ProfileTasks from './components/ProfileTasks.vue'
import AppSearchDetailed from './components/AppSearchDetailed.vue'
import UpdateInput from './components/UpdateInput.vue'
import AppDialog from './components/AppDialog.vue'

import './app.css'
export default {
  name: 'app',
  data () {
    return {
      notesOpen: false,
      navActive: null,
      unreadopen: false,
      profileNotification: false,
      bottomNavHidden: false,
      topNavHidden: false,
      drawer: undefined,
      topAppBar: undefined,
      desktop: false,
      open: false,
      links: [],
      windowWidth: window.innerWidth,
      browserClass: '',
      headroomClass: 'app-headroom--pinned',
      initializePage: false,
      inputFocused: false,
      isMobile: false,
      isTablet: false,
      isTabletLarge: false,
      isDesktopLarge: false,
      inSearch: false,
      searchEnabled: false,
      searchValue: '',
      searchFilter: '',
      searchOptions: {},
      searchEvents: {},
      filterJobs: false,
      filterPartners: false,
      mobileHistoryLength: 0,
      initialPageLoad: true
    }
  },
  components: {
    AppTopBar,
    AppNavBottom,
    AppSideDrawer,
    headroom,
    Snackbar,
    ProfileTasks,
    AppSearchDetailed,
    UpdateInput,
    AppDialog
  },
  created () {
    this.windowWidth = window.innerWidth
    this.browserClass = this.browserClasses()
  },
  mounted () {
    const url = window.location.href
    const subdomain = url.split('://')[1]

    if (subdomain.toLowerCase().startsWith('teams') || url.includes('app.flexibly.co')) {
      this.setTeamMode(true)
      this.setRemote(true)
    }

    if (url.includes('boots')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/brands/boots.png',
        id: 'tkiSki6UaLrr0wp89kmW'
      })
    } else if (url.includes('tls')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/brands/law-society-logo.png',
        id: 'biyc5DMinvF3OjAaST59'
      })
      this.$root.$emit('setSearchFilter', 'TLS')
    } else if (url.includes('abi')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/brands/abi.png',
        id: 'blDhckg0zeBo6olAJ98a'
      })
    } else if (url.includes('duoyou')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/brands/DuoYou.svg',
        id: 'KgiB4rUYkwgAhvocNkyQ'
      })
    } else if (url.includes('workingfamilies')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/brands/workingfamilies.svg',
        id: 'mPCZgIYAdO66IHiD0DPm'
      })
    } else if (url.includes('duomedemo')) {
      this.setIsBranded(true)
      this.setBrand({
        logo: '/static/img/logo-black.svg',
        id: '9lICvZdtVVG1V7dC8nAu'
      })
    }

    this.topAppBar = MDCTopAppBar.attachTo(this.$el.querySelector('.mdc-top-app-bar'))
    this.topAppBar.setScrollTarget(document.getElementById('main-content'))

    this.$root.$on('toggleSidebar', () => {
      if (!this.desktop) {
        if (this.drawer) {
          this.drawer.open = !this.drawer.open
        }
      }
    })
    this.$root.$on('setSearchQuery', (val) => {
      // console.log('App.setSearchQuery')
      this.$set(this, 'searchValue', val)
    })

    this.$root.$on('setSearchFilter', (filter) => {
      // console.log('App.setSearchFilter', filter)
      this.searchFilter = filter
      if (filter) {
        // Set filter, clear search value
        this.$set(this, 'searchValue', '')
        // eslint-disable-next-line node/handle-callback-err
        this.$router.push('/dash').catch((error) => {
          console.debug('App.onSearchFilterClear', error)
        })
      }
    })

    this.$root.$on('enableSearch', (val, options, events) => {
      this.searchEnabled = val
      if (options) {
        this.searchOptions = options
      } else {
        this.searchOptions = {}
      }
      if (events) {
        this.searchEvents = events
      } else {
        this.searchEvents = {}
      }
    })

    this.$root.$on('onSearch', (query, res) => {
      console.log('App.$root.onSearch', query)
      this.onSearch(query, res)
    })

    document.addEventListener('click', this.handleClickScrim)

    window.addEventListener('popstate', (event) => {
      if (this.inputFocused) {
        this.inputFocused = false
      }
    })

    window.addEventListener('resize', () => {
      if (!this.throttled) {
        this.throttled = true
        setTimeout(() => {
          this.throttled = false
          this.windowWidth = window.innerWidth
        }, 200)
      }
    })
  },
  watch: {
    user: {
      handler () {
        if (this.user) {
          const url = window.location.href
          const subdomain = url.split('://')[1]
          if (subdomain.toLowerCase().startsWith('teams') || url.includes('app.flexibly.co')) {
            this.setTeamMode(true)
            this.setRemote(true)
          }
        }
      },
      immediate: true
    },
    windowWidth: {
      handler: function (newW, oldW) {
        this.$set(this, 'desktop', this.windowWidth >= 840)
        this.$set(this, 'isMobile', this.windowWidth <= 470)
        this.$set(this, 'isTablet', this.windowWidth >= 470 && this.windowWidth <= 670)
        this.$set(this, 'isTabletLarge', this.windowWidth >= 670 && this.windowWidth <= 1180)
        this.$set(this, 'isDesktopLarge', this.windowWidth >= 1480)
        if (!this.desktop) {
          this.$nextTick(() => {
            this.mountDrawer()
          })
        }
      },
      immediate: true
    },
    pageLoad: {
      handler () {
        if (this.initialPageLoad && !this.pageLoad) {
          this.initialPageLoad = false
        }
      }
    },
    $route: {
      handler (nr, oldr) {
        // console.log('App.watch:$route', nr, oldr)
        if (nr.name !== oldr.name) {
          this.searchValue = ''
        }
        // reset zoom level
        const viewportmeta = document.querySelector('meta[name="viewport"]')
        viewportmeta.setAttribute('content', 'initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0')
      }
    },
    action: function (action, previousAction) {
      if ((previousAction === 'aggregated') && (action === 'jobs' || action === 'partners')) {
        // console.log('App.watch:action scroll bottom', document.documentElement.scrollTop, document.documentElement.scrollHeight)
        // document.documentElement.scrollTop = document.documentElement.scrollHeight
      } else {
        // console.log('App.watch:action scroll top', document.documentElement.scrollTop)
        document.documentElement.scrollTop = 0
      }

      if (this.action === 'jobs') {
        this.filterPartners = false
        this.filterJobs = true
      } else if (this.action === 'partners') {
        this.filterPartners = true
        this.filterJobs = false
      } else if (this.action === 'bookmarked') {
        // no filters, clear query
        this.filterPartners = false
        this.filterJobs = false
        this.searchValue = ''
      } else {
        this.filterPartners = false
        this.filterJobs = false
      }
    },
    inputFocused: {
      handler: function (newif, oldif) {
        if (this.inputFocused) {
          const state = window.history.state
          window.history.pushState(state, document.title + ' - input', window.location.href)
          // console.log('App.watch:inputFocused-true history.length:', window.history.length)
          this.mobileHistoryLength = window.history.length
        } else {
          // console.log('App.watch:inputFocused-true history.length:', window.history.length)
          if (this.mobileHistoryLength === window.history.length) {
            window.history.back()
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'snack',
      'connections',
      'profiles',
      'profile',
      'loading',
      'pageLoad',
      'company',
      'isBranded',
      'brand',
      'action',
      'remote',
      'team',
      'teamNotes',
      'teamMode'
    ]),
    brandStyle () {
      if (this.isBranded) {
        return {
          linktext: {
            color: 'black'
          },
          sidebar: {
            background: '#FCFCFC',
            'border-right': 'none',
            color: 'black'
          },
          topbar: {
            background: '#FFFFFF',
            'box-shadow': 'inset 0px -1px 1px #E9E9E9'
          },
          bottombar: {
            background: '#FFFFFF'
          },
          bottombarItem: {
            color: 'rgba(0,0,0,.87)'
          }
        }
      }
      if (this.teamMode) {
        return {
          linktext: {
            color: 'black'
          },
          sidebar: {
            background: '#FCFCFC',
            'border-right': 'none',
            color: 'black'
          },
          topbar: {
            background: '#FFFFFF',
            'box-shadow': 'inset 0px -1px 1px #E9E9E9'
          },
          bottombar: {
            background: '#FFFFFF'
          },
          bottombarItem: {
            color: 'rgba(0,0,0,.87)'
          }
        }
      }
      return {
        linktext: {
          color: 'black'
        },
        sidebar: {
          background: '#FCFCFC',
          'border-right': 'none',
          color: 'black'
        },
        topbar: {
          background: '#FFFFFF',
          'box-shadow': 'inset 0px -1px 1px #E9E9E9'
        },
        bottombar: {
          background: '#FFFFFF'
        },
        bottombarItem: {
          color: 'rgba(0,0,0,.87)'
        }
      }
    },
    profileReady () {
      if (this.profile) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'clearUnread',
      'setIsBranded',
      'setBrand',
      'setAction',
      'setTeamMode',
      'setRemote',
      'updateProfile',
      'saveUpdate',
      'deleteUpdate'
    ]),
    openNotesModal () {
      console.log('openNotesModal')
      this.notesOpen = true
    },
    onDeleteUpdate (teamId, updateId) {
      console.log('onDeleteUpdate', teamId, updateId)
      this.deleteUpdate({ teamId: teamId, updateId })
    },
    updateNotes () {
      const notes = this.$refs.notes.getContent()
      console.log('app.updateNotes', this.$refs.notes)
      console.log('app.updateNotes.content', notes)
      for (const note of notes) {
        if (note.changed) {
          note.group = 'notes'
          this.saveUpdate({ teamId: this.profile.team, updateId: note.id, update: note })
        }
      }
    },
    onSearch (query, res) {
      console.log('App.onSearch', query, res)
      this.searchValue = query
      this.$router.push({ path: '/dash', query: { q: query } }).catch((error) => {
        console.log('App.onSearch.router.push:error', error)
      })
    },
    onSearchClear () {
      console.log('App.onSearchClear')
      this.searchValue = ''
      // eslint-disable-next-line handle-callback-err
      this.$router.push('/dash').catch((error) => {
        console.error('App.onSearchClear', error)
      })
    },
    onAction (action) {
      console.log('onAction', action)
      if (action === 'clearFilter') {
        this.onFilterClear()
      }
    },
    onFilterClear (filter) {
      console.log('onFilterClear', filter)
      this.searchFilter = ''
      this.$refs.view.clearFilter(filter)
    },
    loadMore (event) {
      this.$refs.searchbar.loadMore(event)
    },
    pageLoading () {
      this.initialPageLoad = false
      this.$store.dispatch('setPageLoad', true)
    },
    finishPageLoad () {
      setTimeout(() => {
        this.$store.dispatch('setPageLoad', false)
      }, 400)
    },
    clearAllUnread () {
      const uid = this.user.uid
      const self = this
      Object.values(this.connections).forEach(function (con) {
        if (con.unread) {
          if (con.unread[uid] > 0) {
            self.clearUnread({ connectionId: con.id })
          }
        }
      })
    },
    mountDrawer () {
      if (!this.drawer) {
        this.drawer = MDCDrawer.attachTo(this.$el.querySelector('.mdc-drawer'))
      }
    },
    goHome () {
      if (this.user) {
        this.$router.push('/dash')
        return
      }
      this.$router.push('/')
    },
    setNavActive (nav) {
      this.navActive = nav
    },
    setLinks (links) {
      this.links = links
    },
    hideBottomNav (val) {
      this.bottomNavHidden = val
    },
    hideTopNav (val) {
      this.topNavHidden = val
    },
    handleClickScrim (evt) {
      if (evt.target.className === 'mdc-drawer-scrim') {
        this.drawer.open = false
      }
    },
    signOut () {
      firebase.auth().signOut().then(() => {
        window.location = '/signin'
      })
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.user.uid]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return null
    },
    browserClasses () {
      const browser = `${this.$browserDetect.meta.name}`.toLowerCase()
      // console.log('App.clientBrowserDetected: ', browser)
      return browser
    },
    removeInputFocus () {
      const self = this
      setTimeout(function () {
        self.inputFocused = false
      }, 400)
    },
    focusInput (hasContent) {
      // console.log('App.portal:contentChange', hasContent)
      if (!hasContent) {
        self.inputFocused = false
      }
    }
  }
}
</script>

<style>
body.mdc-dialog-scroll-lock {
  width:100%;
  min-width: 340px;
  min-height:100%;
  max-height:100%;
  height:100%
}

.app-header {
  position: fixed;
  width: 100vw;
  z-index:11;
}
.app-header .headroom {
  height: 50px;
}
.app-header.headroom--pinned > div {
  transform: translate3d(0px, 0px, 0px) !important;
}
.no-height-grid {
  height: 0px;
  padding-top: 0;
  margin: 0;
  padding-bottom: 0;
}
.mobile-input-overlay {
  z-index:99999;
  width:calc(100% - 32px);
  height:calc(100% - 32px);
  min-height:calc(100vh - 32px);
  position:fixed;
  top:0;
  left:0;
  padding:16px;
  background-color:white;
  opacity:0.95;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-input-overlay .autocomplete {
   background:white;
   margin-right:0 !important;
   width:100%;
}
.mobile-input-overlay .autocomplete .pac-container {
  position:relative !important;
  top:12px !important;
  left: 2px !important;
  width: calc(100% - 8px) !important;
}
.prompt-mobile-overlay {
  display: none !important
}
.mobile-input-overlay .prompt {
  display: none !important
}
.mobile-input-overlay .prompt-mobile-overlay {
  display: flex !important
}
.mobile-input-overlay .mdc-text-field-wrapper {
  width:100%;
}
.tooltip.popover {
  z-index:1;
  max-width: 300px;
}
.tooltip.popover .popover-inner {
  background: rgba(0,0,0,0.9);
  padding-top:6px;
  padding-bottom:6px;
}

.notes-modal .mdc-dialog__surface{
  height: calc(100% - 32px);
}
.notes-modal .mdc-dialog__surface .mdc-dialog__content {
  position: relative;
  padding:0;
  padding-bottom:16px;
  padding-top:16px;
}
</style>
