<template>
  <div class="main-content learn">
    <div class="main-wrapper">
      <div v-if="!postsLoading" class="post-tag">
        <a tag="span" @click="gotoHome()" class="blog" :class="{ 'current-page': !slugClicked && !pageNumberClicked }">LEARN</a>
        <mdc-text tag="span" class="slash"> {{ slugClicked || pageNumberClicked ? '/' : '' }}</mdc-text>
        <a v-if="slugClicked" :class="{ 'current-page': slugClicked }">{{ slugClicked }}</a>
        <a v-if="pageNumberClicked && !slugClicked" :class="{ 'current-page': resultPage }">PAGE {{ resultPage }}</a>
      </div>
      <div class="pages-grid" ref="pages-grid">
        <article :ref="'page-card' + page.id + index" class="page-card " v-for="(page, index) in pages" :key="page.id + index">
          <a class="image-link" @click="gotoPage(page.slug)">
            <img class="image"
                sizes="(max-width: 1000px) 400px, 800px"
                :src="page.feature_image"
                :alt="page.title"
            />
          </a>
          <div class="page-card-content">
              <a class="page-card-content-link" @click="gotoPage(page.slug)">
                <header class="page-card-header">
                    <div @click.stop="gotoSlugPosts(page.primary_tag.slug)" v-if="page.primary_tag" class="page-card-primary-tag">{{page.primary_tag.name}}</div>
                    <h2 class="page-card-title">{{page.title}}</h2>
                </header>
                <section class="page-card-excerpt">
                    <p v-if="page.excerpt">{{ replaceUrl(page.excerpt) }}
                    </p>
                </section>
              </a>
              <footer class="page-card-meta">
                  <ul class="author-list">
                      <li class="author-list-item" v-for="author in page.authors" :key="author.id + author.name">
                        <a v-if="author.profile_image" class="static-avatar">
                          <img class="author-profile-image" :src="author.profile_image" :alt="author.name" />
                        </a>
                        <!--below line is doubtful to refer icons/avatar file and check -->
                        <a v-else class="static-avatar author-profile-image">{{ "icons/avatar" }}</a>
                      </li>
                  </ul>
                  <div class="page-card-byline-content">
                      <span v-if="page.authors && page.authors.length > 2">"Multiple authors"</span>
                      <span v-else class="author-name">
                        <a v-for="(author, index) in page.authors" :key="author.id + author.name + 'name'">{{author.name}}{{ index === page.authors.length - 1 ? '' : ', ' }}</a>
                      </span>
                      <span class="page-card-byline-date">
                        <time :datetime="page.published_at ">{{dateFormat(page.published_at)}}</time>
                      </span>
                  </div>
              </footer>
          </div>
        </article>
      </div>
      <div class="page-numbers" v-if="!postsLoading && !slugClicked">
        <mdc-text class="page-nav" v-if="resultPage !== 1" tag="span" typo="caption" @click.native="gotoPageNumber('prev')">Prev</mdc-text>
        <div class="number-text" v-for="index in totalPages" :key="'page' + index" @click.stop="gotoPageNumberPosts(index)">
          <mdc-text tag="span" typo="caption" class="text" :class="resultPage === index ? 'current-page' : ''">{{index}}</mdc-text>
        </div>
        <mdc-text class="page-nav" v-if="resultPage !== totalPages" tag="span" typo="caption" @click.native="gotoPageNumber('next')">Next</mdc-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as ghost from '../utils/ghost'
export default {
  name: 'Learn',
  data () {
    return {
      postsLoading: false,
      resultPage: 1,
      totalPages: null,
      pages: [],
      slugClicked: '',
      pageNumberClicked: ''
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'company'
    ])
  },
  filters: {
  },
  methods: {
    ...mapActions([
    ]),
    initialize () {
      const pageNumberParam = new URLSearchParams(window.location.search).get('page')
      const slugParam = new URLSearchParams(window.location.search).get('tag')
      // eslint-disable-next-line no-undef
      if (pageNumberParam) {
        this.pageNumberClicked = pageNumberParam
        this.getAllPosts(pageNumberParam, 'learn').then(() => {
          this.postsLoading = false
        })
      } else if (slugParam) {
        this.slugClicked = slugParam
        this.getAllPosts(1, slugParam).then(() => {
          this.postsLoading = false
        })
      } else {
        this.pageNumberClicked = 1
        this.getAllPosts(1, 'learn').then(() => {
          this.postsLoading = false
        })
      }
      setTimeout(() => {
        const element = document.getElementsByClassName('mdc-drawer-app-content')
        if (element && element[0]) {
          element[0].scrollTo(0, 0)
        }
      }, 5)
    },
    async getAllPosts (pageNumber, slug) {
      this.postsLoading = true
      const result = await ghost.posts(pageNumber, slug)
      this.pages = [...result.posts]
      this.totalPages = result.meta && result.meta.pagination.pages
      this.resultPage = result.meta && result.meta.pagination.page
    },
    gotoHome () {
      if (this.slugClicked) {
        this.slugClicked = ''
      }
      this.$router.push('/learn')
      this.initialize()
    },
    gotoPage (slug) {
      this.$router.push('/learn/' + slug)
      this.initialize()
    },
    gotoPageNumberPosts (pageNumber) {
      this.$router.push('/learn/?page=' + pageNumber)
      this.initialize()
    },
    gotoSlugPosts (slug) {
      this.$router.push('/learn/?tag=' + slug)
      this.initialize()
    },
    replaceUrl (content) {
      if (content) {
        return content.replace(/\[.*\]/gm, '')
      }
    },
    dateFormat (date) {
      return new Date(date).toDateString().slice(4)
    },
    gotoPageNumber (from) {
      let pageNumber
      if (from === 'prev') {
        pageNumber = this.resultPage - 1
      } else {
        pageNumber = this.resultPage + 1
      }
      this.gotoPageNumberPosts(pageNumber)
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$root.$emit('enableSearch', false)
  },
  async mounted () {
    // this.$emit('setLinks', this.links)
    this.$emit('setLinks', [])
    this.initialize()
    this.$root.$emit('enableSearch', true, { context: 'learn' })
  },
  watch: {
    'company.assignedCompany': {
      handler: async function (newac, oldac) {
        if (newac) {
          let result
          if (this.company.assignedCompany) {
            if (this.company.assignedCompany.id === 'mPCZgIYAdO66IHiD0DPm') {
              result = await ghost.posts(1, 'learn')
            } else {
              console.log(`Learn.mounted - get ghost posts by tag ${this.company.assignedCompany.name}`)
              result = await ghost.posts(1, this.company.assignedCompany.name)
            }
          } else {
            result = await ghost.posts(1, 'learn')
          }
          this.pages = result.posts
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.learn-item .short-content {
  height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.learn-item .mdc-card-header:last-child {
  padding-bottom: 24px;
}
.learn-item .mdc-card-header {
  background-color: rgba(0,0,0,0.3);
  height:calc(100% - 40px);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 16px;
}
.learn-item .mdc-card__title  {
  display:block;
  color: white;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: .01786em;
}
.learn-item .mdc-card__subtitle  {
  display:block;
  color: white;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.6rem;
}
.learn-item .mdc-card__title, .mdc-card__title--large {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  text-decoration: inherit;
  text-transform: inherit;
}
.learn-item .mdc-card__supporting-text:last-child {
   padding-bottom: 24px;
}
.learn-item .mdc-card__supporting-text {
  font-family: Roboto,sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: .03125em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0,0,0,.87);
  color: var(--mdc-theme-text-primary-on-light,rgba(0,0,0,.87));
  box-sizing: border-box;
  padding: 8px 16px;
}
</style>
<style lang="scss">
.learn {
  --mdc-typography-font-family: 'Nunito Sans', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  div.main-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    // width: 100%;
    position: relative;
    padding: 0 4vmin;
    top: 80px;
    padding-bottom: 80px;
    min-height: 100vh;
    width: calc(100% - 4*(var(--mdc-layout-grid-margin-desktop, 24px)));
    @media (min-width: 571px) and (max-width: 840px) {
      width: calc(100% - 46px - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
    }
    @media (min-width: 481px) and (max-width: 570px) {
      width: calc(100% - 4*(var(--mdc-layout-grid-margin-phone, 16px)));
    }
    @media (max-width: 480px) {
      width: calc(100% - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
    }
  }
  .loading-posts {
    margin: 10px 10px 10px 0;
    display: block;
    font-weight: 600;
  }
  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  .page-numbers .number-text {
    cursor: pointer;
    margin-right: 5px;
  }
  .page-numbers .page-nav {
    font-weight: 800;
    cursor: pointer;
    font-size: 16px;
  }
  .page-numbers .page-nav:first-of-type {
    margin-right: 10px;
  }
  .page-numbers .page-nav:last-of-type {
    margin-left: 10px;
  }
  .page-numbers .text {
    color: #000000;
    font-weight: 800;
    font-size: 16px;
  }
  .page-numbers .text.current-page {
    color: #b10000;
    cursor: default;
  }
  .pages-grid {
    position: relative;
    display: grid;
    grid-gap: 4vmin;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4vmin 0;
  }
  .page-card {
    position: relative;
    flex: 1 1 301px;
    display: flex;
    flex-direction: column;
    min-height: 220px;
    background-size: cover;
  }
  .image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;
  }
  img.image {
    display: inline-block;
    width: auto;
    max-height: 310px;
    max-width: 100%;
    border-radius: 7px;
    width: 100%;
    height: 200px;
    background: #c5d2d9 no-repeat 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .page-card-content {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .page-card-content-link {
    position: relative;
    display: block;
    color: #15171a;
    cursor: pointer;
  }
  .page-card-header {
    margin: 20px 0 0;
  }
  .page-card-primary-tag {
    margin: 0 0 .2em;
    color: #cc0000;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .2px;
    text-transform: uppercase;
    line-height: 24px;
  }
  .page-card-title {
    line-height: 1.15;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    letter-spacing: -.01em;
    margin: 0 0 .4em;
    font-size: 24px;
    transition: color .2s ease-in-out;
    font-family: 'Lato', sans-serif;
  }
  .page-card-excerpt {
    max-width: 56em;
    color: #60757e;
  }
  .page-card-excerpt p {
    font-family: 'Nunito Sans';
    margin-bottom: 1em;
    display: -webkit-box;
    overflow-y: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    font-weight: 400;
    line-height: 24px;
  }
  .page-card-meta {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: unset;
  }
  .author-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
  }
  .author-list-item {
    position: relative;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
  }
  .static-avatar {
    display: block;
    overflow: hidden;
    margin: 0 0 0 -6px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    // box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 20%);
    pointer-events: none;
    cursor: default;
  }
  .author-profile-image,
  .avatar-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    background: #e4eaed;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .page-card-byline-content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 8px;
    color: #90a2aa;
    font-size: 1.4rem;
    line-height: 1.2em;
    font-weight: 400;
  }
  .page-card-byline-content span {
    margin: 0;
  }
  .page-card-byline-content a {
    font-size: 15px;
    color: #373c44;
    font-weight: 600;
    cursor: default;
  }
  .page-card-byline-date {
    line-height: 1.5em;
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    .pages-grid {
        grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 700px) {
    .pages-grid {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }
  .post-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    color: #738a94;
    font-size: 13px;
    line-height: 1.4em;
    letter-spacing: .02em;
    font-weight: 600;
    position: relative;
  }
  .post-tag a {
    margin-right: 10px;
    font-weight: 800;
    color: #000000;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
  }
  .post-tag a.current-page {
    color: #cc0000 !important;
    cursor: default;
    margin-left: 10px;
  }
  .post-tag .slash {
    font-weight: 800;
    color: #000000;
    font-size: 13px;
  }
}
</style>
