<template>
  <div class="main-content cookies">

      <div class="back-layer-wrapper">
        <div class="back-layer">

          <highlight-section></highlight-section>

          <mdc-title style="padding-left:16px;margin-bottom:0" v-if="section === 'dummy'">Partner Profiles</mdc-title>

          <mdc-layout-grid v-if="section === 'dummy'">
            <mdc-layout-cell v-for="prof in dummyProfiles" :key="prof.id" span=4 class="profiles">
              <router-link to="/cookies" class="profile-card-link">
                <profile-card hideShare class="desktoponly" :profile="prof"></profile-card>
                <profile-card-mobile hideShare class="mobileonly" :profile="prof"></profile-card-mobile>
              </router-link>
            </mdc-layout-cell>
          </mdc-layout-grid>
        </div>
      </div>

      <div class="back-overlay"></div>

      <div class="cookies-card-wrapper">
        <mdc-card class="cookies-card">
          <mdc-card-header>
            <img v-if="isBranded" class="cookies-logo" :src="brand.logo" :alt="brand.name" />
            <img v-else class="cookies-logo" style="width:150px;" src="/static/img/duome-logo-blacklogo.svg" alt="DuoMe" />
          </mdc-card-header>
          <div style="margin-bottom:16px;">
            <mdc-body typo="body2" style="margin:12px;">We use cookies to improve your experience on our site and improve the service we provide. We don't target you with advertising or sell your data to third parties. </mdc-body>
            <round-button icon="done" style="margin-top:24px;margin-left:auto;margin-right:auto;z-index:99;" @click="confirmAllCookies" high hasSlot>I'm OK with that</round-button>
            <button class="pref-button" onclick="event.stopPropagation(); window.consentManager.openConsentManager()" type="button">Manage my Preferences</button>
          </div>
        </mdc-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import HighlightSection from '../components/HighlightSection.vue'
import ProfileCard from '../components/profile-v2/ProfileCard.vue'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
export default {
  name: 'cookies',
  props: {
    desktop: {
      type: Boolean
    }
  },
  data () {
    return {
      cookiesSet: false,
      cookiesTimeStamp: '',
      helptext: '',
      section: 'dummy',
      dummyProfiles: [
        {
          id: 0,
          cardDesign: 'profile-colour-magenta',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 1,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'
        },
        {
          id: 2,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FdyLW0RDgkDfbzH59wQ41wxKdqBu2%2Favatar%2Fface%202.jpeg?alt=media&token=3e6c04e5-2daf-41f0-9b10-be71798a67a1'
        },
        {
          id: 3,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 4,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 5,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 6,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'
        },
        {
          id: 7,
          cardDesign: 'profile-colour-magenta',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'

        },
        {
          id: 8,
          cardDesign: 'profile-colour-orange',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FdyLW0RDgkDfbzH59wQ41wxKdqBu2%2Favatar%2Fface%202.jpeg?alt=media&token=3e6c04e5-2daf-41f0-9b10-be71798a67a1'
        },
        {
          id: 9,
          cardDesign: 'profile-colour-orange',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FkBGtn2WDMwehlRbwJcMPRgceBQx2%2Fphoto%2Fface%203.jpeg?alt=media&token=503b9132-16d1-4e1a-901d-e95a6141b9cf'

        },
        {
          id: 10,
          cardDesign: 'profile-colour-cyan',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FZLnQ9fyXvXYHBtVrHsYMxNA23ub2%2Favatar%2FFace%206.png?alt=media&token=da765abd-200d-4b4f-9dea-2f0779c9ebc6'
        },
        {
          id: 11,
          cardDesign: 'profile-colour-yellow',
          cardDesignBackground: 'profile-bg-bottom',
          photoURL: 'https://firebasestorage.googleapis.com/v0/b/duome-demo.appspot.com/o/user%2FmV0lG3UUZdZQlRJaXQW444rH2642%2Favatar%2FScreenshot%20from%202019-01-09%2008-21-53.png?alt=media&token=bf155048-f007-437b-aa9a-2ef3877abe9e'

        }
      ]
    }
  },
  components: {
    HighlightSection,
    ProfileCard,
    ProfileCardMobile
  },
  computed: {
    ...mapState([
      'isBranded',
      'user',
      'profile',
      'brand'
    ])
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setSnackMessage',
      'setPageLoad'
    ]),
    onSelected (idx) {
      this.isSignin = (idx === 0)
    },
    async confirmAllCookies () {
      try {
        window.consentManager.preferences.savePreferences(
          {
            destinationPreferences: {
              'Customer.io': true,
              FullStory: true,
              'Google Tag Manager': true,
              'Google Analytics': true
            },
            customPreferences: {
              functional: true,
              marketingAndAnalytics: true
            }
          }
        )
      } catch (error) {
        this.confirmCookies()
        console.error(error)
      }
    },
    async confirmCookies (prefs) {
      // console.log('confirmCookies', prefs.destinationPreferences, prefs.customPreferences)
      this.cookiesTimeStamp = new Date().toISOString()
      return this.updateProfile({ profile: { cookiesTimeStamp: this.cookiesTimeStamp }, noSnack: true }).then(() => {
        // console.log('confirmCookies:cookiesTimeStamp', this.cookiesTimeStamp)
        setTimeout(() => {
          // console.log('confirmCookies -reloading page')
          window.location.reload()
        }, 500)
        return true
      })
    },
    showAlertMessage (err) {
      this.isValid = false
      this.helptext = this.userFriendlyMessage(err)
      this.setSnackMessage(this.helptext)
    },
    initialize () {
      const self = this
      setTimeout(() => {
        this.setPageLoad(false)
        if (window.consentManager) {
          const prefs = window.consentManager.preferences
          // console.log('window.consentManager.preferences', prefs)
          prefs.onPreferencesSaved(self.confirmCookies)
        }
      }, 200)
    }
  },
  mounted () {
    this.$emit('hideBottomNav', true)
    this.$emit('hideTopNav', true)
    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.add('noscroll')
    }
    if (this.profile) {
      this.initialize()
    }
  },
  beforeDestroy () {
    this.$emit('hideBottomNav', false)
    this.$emit('hideTopNav', false)
    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.remove('noscroll')
    }
  },
  watch: {
    profile (newp, oldp) {
      if (newp && !oldp.id) {
        this.initialize()
      }
    }
  }
}
</script>

<style>
.cookies button.pref-button {
    display: inline;
    color: inherit;
    cursor: pointer;
    padding: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    font: inherit;
    text-decoration: underline;
    color: rgba(0,0,0,.6);
    margin-bottom:24px;
    margin-top:24px;
}

.cookies .mdc-body {
  color:rgba(0,0,0,.6)
}
.cookies .mdc-typography--caption {
  color:rgba(0,0,0,.6)
}
.cookies .helptext {
  margin-bottom:0;
  color: #b00020;
}
.cookies .mdc-button {
  text-transform: none;
}
.cookies .mdc-button i.material-icons, .cookies .mdc-button svg {
  font-size:24px;
  margin-right:16px;
}
.cookies .mdc-button i.material-icons {
  margin-top:-6px;
  margin-right:20px;
}
.cookies .or {
  margin-top: 12px;
  margin-bottom:0px;
}
.cookies .or span{
  width:auto;
  margin-left:auto;
  margin-right:auto;
  background:white;
  padding-left:16px;
  padding-right:16px;
}
.cookies .or::before{
  content: '';
  position:absolute;
  width: calc(100% - 48px);
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(0,0,0,.4);
  left:0;
  height: 10px;
  margin-left: 24px;
  margin-right: 24px;
  z-index:-1;
}
.link {
  text-decoration: underline;
  cursor:pointer;
  color:rgba(0,0,0,.6)
}
.link.disabled {
  color:rgba(0,0,0,.34)
}
.link-text {
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-text span, .link-text .material-icons {
  color:rgba(0,0,0,.6)
}
.mdc-layout-app.noscroll {
  overflow:hidden;
}
.mdc-layout-app.noscroll .mdc-layout-app--main-container {
  height:100%;
  overflow:hidden;
}
.mdc-snackbar {
  z-index: 999;
}

.cookies .mdc-tab-bar {
  margin: 0px 24px;
  width: auto;
}

.cookies .mdc-tab--active {
  background-color: #F2F2F2;
  border-radius: 5px 5px 0px 0px;
}

.cookies .mdc-textfield-wrapper {
  margin-top:12px;
}

.cookies .mdc-card__primary {
  padding: 16px;
  padding-bottom: 4px;
}
.cookies .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
  width: 100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height: 50px;
  margin-top:8px;
}
.cookies .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) input.mdc-text-field__input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
</style>
<style scoped>
.main-content {
  text-align: center;
  min-height: 500px;
}
.back-layer {
  position:fixed;
  background-color:white;
  width:100%;
  height:100%;
  overflow:visible;
  z-index:0;
  filter:blur(3px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  left:0;
  right:0;
}
.back-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.6);
  position:fixed;
  left:0;
  top:0;
  overflow: hidden;
  z-index:998;
}

.cookies-card-wrapper {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  height:100%;
  width:100%;
  z-index:999;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
}

.cookies-card-wrapper .mdc-card {
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  z-index:999;
  position: relative !important;
  height:auto;
  overflow-y:auto;
  margin-top: auto;
  margin-bottom: auto;
  padding:6px;
}

.profile-card-link {
  text-decoration: none;
  color: #333;
}

.mdc-button {
  width: 300px;
  margin-top:16px;
}

.cookies-logo {
  height:45px;
  width:auto;
  text-align: center;
  margin-bottom:6px;
}

.main-content.cookies {
  margin-top:-30px;
  margin-bottom:30px;
}

/* Override grid media queries for cards
 * Built in screen sizes are not granular enough so need custom size
 * to allow profile content to file while maintaining aspect ratio.
 */

@media (min-width: 481px) and (max-width: 595px) {
  .profiles {
      grid-column-end: span 8;
  }
}
@media (min-width: 595px) and (max-width: 671px) {
  .profiles {
      grid-column-end: span 12;
  }
}
@media (min-width: 840px) and (max-width: 920px) {
  .profiles {
      grid-column-end: span 6;
  }
}
@media (min-width: 920px) and (max-width:   1180px) {
  .profiles {
      grid-column-end: span 6;
  }
}
@media (min-width: 1180px) and (max-width:   1380px) {
  .profiles {
      grid-column-end: span 4;
  }
}
@media (min-width: 1480px) {
  .profiles {
      grid-column-end: span 3;
  }
}

@media (min-width: 840px) {
  .back-layer {
    width: calc(100% - 240px);
    left: 240px;
  }
}

</style>
