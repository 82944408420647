<template>
  <div class="main-content" style="margin-top: 0px;">

    <div class="job-header-wrapper">
      <div class="job-header">
        <div
        id="coverimg"
        :style="{
          'background-repeat': 'no-repeat',
          'background-image': 'url(/static/img/job-covers/' + jobBackground,
          'background-position': 'center',
          'background-size': 'cover',
          'height': '300px',
          'width': '100%'
        }"
        >
        </div>
      </div>
    </div>

    <div v-if="jobrole && profile" class="main-wrapper main-content-wrapper">

      <div class="job-col-wrapper">

        <div class="upshift job-col-1">
          <!-- Description -->
          <app-section class="description-section"
          :editable="false"
          title="Description"
          >
          <span slot="post-title" >
            <a v-if="profile.experienceCompleted" style="text-decoration: none;" target="_blank" :href="jobrole.url">
              <round-button style="max-width: unset;padding-left:16px;" hasSlot icon="launch" raised>Apply</round-button>
            </a>
            <round-button v-else style="max-width: unset;padding-left:16px;" hasSlot icon="launch" @click="$emit('openProfileTasks')" raised>Apply</round-button>
          </span>

          <mdc-title>{{jobrole.rolename}}</mdc-title>
          <mdc-body typo="body2">
            <div v-if="jobrole.company==='TLS'" v-html="highlightCategory(jobrole.description).replace(/<img[^>]*>/g,'')"></div>
            <div v-else style="white-space: pre-wrap;" v-html="highlightCategory(jobrole.description).replace(/<img[^>]*>/g,'')"></div>
          </mdc-body>

          </app-section>

          <app-section class="details-section"
          :editable="false"
          title="Details"
          >
            <mdc-body typo="body2">
              <div>{{jobrole.jobtype}}</div>
              <div>{{jobrole.full_part}}</div>
              <div>{{jobrole.location}}</div>
            </mdc-body>

          </app-section>

          <app-section class="interest-section mobile"
          v-if="!notInterested"
          :editable="false"
          title="Interested Users"
          >
            <div>
              <router-link  v-for="profId in interested" :key="profId" class="profile-card-link" :to="{ name: 'User', params: {id: profId} }">
                <profile-card-mobile  hoverHigh hideShare :profile="getProfile(profId)"></profile-card-mobile>
              </router-link>
            </div>
          </app-section>
        </div>

        <div class="upshift--tablet job-col-2">
          <job-role-card :likelyFlexFilter="jobCategory === 'Flexible Working'" highlight :jobrole="jobrole"></job-role-card>

          <div class="jobrole-actions">
            <round-button hasSlot icon="remove" style="whitespace:no-wrap;" high v-if="notApplied && !notInterested" @click="setInterest(false)">Remove Interest</round-button>
            <round-button hasSlot icon="add" high v-else-if="notApplied && notInterested && !profile.experienceCompleted"  @click="$emit('openProfileTasks')">Add Interest</round-button>
            <round-button hasSlot icon="add" high v-else-if="notApplied && notInterested"  @click="confirm = true">Add Interest</round-button>
            <round-button hasSlot icon="add" v-else @click="comfirm = true" disabled>Interested</round-button>

            <span v-if="notApplied && notInterested" style="width: 25px;height: 25px; margin-left:12px;">
              <hotspot
                @shown="updateHotspot('interest')"
                :visible="!profile.hotspotClicked.interest && (profile.hotspotSeen.interest <= 5 || !profile.hotspotSeen.interest)" :diameter="20">
                <round-button
                  background="transparent"
                  @mouseover.native="mouseOverTooltip"
                  @click="showTooltip = true"
                  noHover
                  class="inline-button tooltip-icon"
                  style="padding:0;color:#828282;height:32px;width:20px;min-width:20px;margin:0;margin-top: 3.985px;margin-left: 1px;"
                  icon="help_outline"/>
              </hotspot>
            </span>
            <v-popover
              offset="1"
              delay="100"
              class="interest-modal-tooltip"
              container='#main-content'
              style="margin-left:-16px;"
              placement='bottom-end'
              :disabled="false"
              trigger="manual"
              :open="showTooltip"
              @show="showInterestHelp"
              @hide="showTooltip = false"
              autoHide
            >
              <template slot="popover">
                <mdc-title>Job Interest</mdc-title>
                <div>
                  <p>Want to find a Job Share Partner for jobs like these? Add interest to be notified when others show interest.</p>
                </div>
                <div style="width:100%;text-align:right">
                  <a class="mdc-button" @click="showTooltip = false"><mdc-icon style="height: 24px;" icon="clear"/> CLOSE</a>
                </div>
              </template>
            </v-popover>

          </div>

          <app-section class="interest-section tablet"
          v-if="!notInterested && interested.length > 0"
          :editable="false"
          title="Interested Users"
          >
            <div>
              <router-link  v-for="profId in interested" :key="profId" class="profile-card-link" :to="{ name: 'User', params: {id: profId} }">
                <profile-card-mobile  hoverHigh hideShare :profile="getProfile(profId)"></profile-card-mobile>
              </router-link>
            </div>
          </app-section>
        </div>

      </div>
    </div>

    <app-dialog
      v-model="confirm"
      @click.native.prevent.stop
      class="confirm-modal"
      title="I'm Interested"
      accept="Confirm"
      cancel="Cancel"
      @accept="setInterest(true)"
      >

      <div style="padding:16px;">
        <mdc-subheading v-html="jobrole.title"></mdc-subheading>
        <mdc-body typo="body2">By confirming interest your profile is made visible to other interested users. This lets you connect with others based on jobs you're interested in.<br><br> <b>Note:</b> No details are shared with employers, if you'd like to apply click the button on the main job page.</mdc-body>
      </div>
    </app-dialog>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JobRoleCard from '../components/profile-v2/JobRoleCard.vue'
import ProfileCardMobile from '../components/profile-v2/ProfileCardMobile.vue'
import AppSection from '../components/profile-v2/AppSection.vue'
import AppDialog from '../components/AppDialog.vue'
import Hotspot from '../components/Hotspot'
import { jobShareFilter, remoteWorkFilter, flexHoursFilter, flexFilter } from '../utils/filters'

import * as backend from '../utils/backend'
import { setTimeout } from 'timers'

export default {
  name: 'jobdetail',
  components: {
    JobRoleCard,
    ProfileCardMobile,
    AppSection,
    AppDialog,
    Hotspot
  },
  props: {
    jobId: {
      type: String
    }
  },
  data () {
    return {
      jobRoleId: null,
      section: 'info',
      showTooltip: false,
      jobrole: {},
      confirm: false,
      eligibleUK: false,
      screeningOK: true,
      interest: {},
      links: [
        {
          title: 'All',
          icon: 'done_all',
          action: 'aggregated',
          route: '/dash'
        },
        {
          title: 'Partners',
          svgicon: 'account',
          action: 'partners',
          route: '/dash'
        },
        {
          title: 'Jobs',
          svgicon: 'work',
          action: 'jobs',
          route: '/dash',
          filter: 'jobsEnabled'
        },
        {
          title: 'Bookmarked',
          icon: 'star_outline',
          action: 'bookmarked',
          route: '/dash'
        }
      ]
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles'
    ]),
    ...mapState({
      jobroles: (state) => state.jobs.jobs
    }),
    jobCategory () {
      if (this.jobrole) {
        const description = `${this.jobrole.title} ${this.jobrole.category} ${this.jobrole.description}`.toLowerCase()
        const includesShare = jobShareFilter.some(substring => description.includes(substring.toLowerCase()))
        const includesRemote = remoteWorkFilter.some(substring => description.includes(substring.toLowerCase()))
        const includesFlex = flexHoursFilter.some(substring => description.includes(substring.toLowerCase()))
        const includesLikelyFlex = flexFilter.some(substring => description.includes(substring.toLowerCase()))
        if (includesShare) {
          if (includesRemote || includesFlex) {
            return 'Job Share+'
          }
          return 'Job Share'
        } else if (includesRemote) {
          if (includesFlex) {
            return 'Remote Work+'
          }
          return 'Remote Work'
        } else if (includesFlex) {
          return 'Flex Time'
        }
        if (includesLikelyFlex) {
          return 'Flexible Working'
        }
      }
      return ''
    },
    jobBackground () {
      let rndish = 0
      const covers = ['job-cover1.jpg', 'job-cover2.jpg', 'job-cover3.jpg', 'job-cover4.jpg']
      for (const num of `${this.jobRoleId}`) {
        if (!isNaN(num)) {
          rndish += num
          rndish = rndish % (covers.length)
        }
      }
      return `${covers[rndish]}`
    },
    interested () {
      if (this.interest) {
        const self = this
        return Object.keys(this.interest).filter(profile => profile !== self.profile.id)
      }
      return []
    },
    notInterested () {
      if (this.interest) {
        return !this.interest[this.profile.id]
      }
      return true
    },
    notApplied () {
      if (this.applicants) {
        return !this.applicants[this.profile.id]
      }
      return true
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'setPageLoad',
      'setJobInterest',
      'setAction',
      'updateProfile'
    ]),
    highlightCategory (text) {
      if (this.jobCategory) {
        if (this.jobCategory.includes('Job Share')) {
          jobShareFilter.forEach(term => {
            const re = new RegExp(term, 'ig')
            text = text.replace(re, `<em class="text-highlight highlight-target">${term}</em>`)
          })
        }
        if (this.jobCategory.includes('Remote Work')) {
          remoteWorkFilter.forEach(term => {
            const re = new RegExp(term, 'ig')
            text = text.replace(re, `<em class="text-highlight highlight-target">${term}</em>`)
          })
        }
        if (this.jobCategory.includes('Flex Time')) {
          flexHoursFilter.forEach(term => {
            const re = new RegExp(term, 'ig')
            text = text.replace(re, `<em class="text-highlight highlight-target">${term}</em>`)
          })
        }
        if (this.jobCategory.includes('Flexible Working')) {
          flexFilter.forEach(term => {
            const re = new RegExp(term, 'ig')
            text = text.replace(re, `<em class="text-highlight highlight-target">${term}</em>`)
          })
        }
        return `${text}`
      }
      return `${text}`
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      return profileMap.get(profileId)
    },
    setInterest (val) {
      // eslint-disable-next-line no-undef
      analytics.track('setJobInterest', {
        id: this.profile.id,
        role: this.profile.role,
        company: this.profile.company,
        journeyStage: this.profile.journeyStage
      })
      let interest = Object.keys(this.interest)
      if (!val) {
        interest = interest.filter((e) => { return e !== this.profile.id })
      }
      // console.log('JobDetail.setInterest - interest', interest)
      this.setJobInterest({ jobId: this.jobRoleId, profileId: this.profile.id, interest: val }).then(() => {
        // console.log('JobDetail.setInterest - myInterest', this.jobRoleId, val)
        setTimeout(async () => {
          this.getInterest()
          this.jobrole = { ...this.jobrole, interested: interest.length }
          // console.log('JobDetail.setInterest - interest after get', interest, interest.length, val)
          const hasInterest = val || interest.length > 0
          let interestVal = 0
          if (hasInterest) {
            interestVal = 1
          }
          const res = await backend.search('job-interest', {
            post: { id: this.jobRoleId, interest: interestVal }
          })
          if (res.error) {
            console.log('jobDetail.job-interest.error', res, res.error)
          }
        }, 400)
      })
    },
    getInterest () {
      // console.log('getInterest')
      if (this.jobroles) {
        const jobroleseMap = new Map(this.jobroles.map(i => [i.id, i]))
        this.interest = {}
        const job = jobroleseMap.get(this.jobRoleId)
        // console.log(this.jobRoleId, job, jobroleseMap)
        if (job) {
          if (job.interest) {
            this.interest = job.interest
          }
        }
      }
    },
    initialize () {
      if (this.jobId) {
        this.jobRoleId = this.jobId
      }
      this.jobsSearch(`q=id:${this.jobRoleId}`).then(result => {
        this.jobrole = result[0]
        this.getInterest()
      })
    },
    async jobsSearch (query, limit) {
      const res = await backend.search('jobs', {
        query: { query }
      })
      if (res.error) {
        console.log(res.error)
        return
      }
      const result = res.result.response.docs
      return result
    },
    mouseOverTooltip () {
      if (!this.profile.hotspotClicked.interest) {
        this.showTooltip = true
      }
    },
    updateHotspot (hotspot) {
      if (!this.profile.hotspotSeen[hotspot]) {
        this.profile.hotspotSeen[hotspot] = 1
      } else {
        this.profile.hotspotSeen[hotspot] = this.profile.hotspotSeen[hotspot] + 1
      }
      this.updateProfile({ profile: { hotspotSeen: this.profile.hotspotSeen }, noSnack: true })
    },
    showInterestHelp () {
      this.showTooltip = true
      this.profile.hotspotClicked.interest = true
      this.updateProfile({ profile: { hotspotClicked: this.profile.hotspotClicked }, noSnack: true })
    }
  },
  beforeDestroy () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', null)
  },
  watch: {
    jobrole (newj, prevj) {
      if (newj && !prevj) {
        this.getInterest()
      }
    }
  },
  async beforeRouteUpdate (to, from, next) {
    // console.log('JobDetail.beforeRouteUpdate')
    this.jobrole = null
    this.jobRoleId = to.params.id
    // console.log('JobDetail.beforeRouteUpdate.disableSearch')
    // this.$root.$emit('enableSearch', false)
    setTimeout(() => {
      // console.log('JobDetail.beforeRouteUpdate.enabledSearch')
      this.$root.$emit('enableSearch', true, { back: true })
    }, 300)
    this.initialize()
    next()
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.setAction('jobs')
    this.$emit('setNavActive', 'Discover')
    this.jobRoleId = (this.$route) ? this.$route.params.id : null
    this.$root.$emit('enableSearch', true, { back: true })
    this.initialize()
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.interest-section .section-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
em.text-highlight {
  font-style: normal;
  border-radius: 1em 0 1em 0;
  text-shadow: 1px 1px 1px #fff;
  background-image: linear-gradient(-100deg, rgba(255,250,150,0.15), rgba(248, 181, 23, 0.65) 100%, rgba(255,250,150,0.25) );
  padding:2px;
}
.jobrole-actions, .details-section, .interest-section {
  margin-top:16px;
  margin-bottom:16px;
  width:100%;
  display:flex;
  align-items: center;
}
.jobrole-actions .app-section, .details-section .app-section, .interest-section .app-section {
  flex:1
}
.jobdetail .mdc-layout-grid {
  padding:0;
}
.jobdetail .preview-wrapper {
  max-width:350px;
}
.jobdetail .desc-wrapper {
  padding:16px;
}
.jobdetail .mdc-floating-label {
  margin-left:12px;
}
.mdc-select--box .mdc-floating-label {
  left: unset;
}
.jobdetail .mdc-textfield-wrapper {
  width:100%;
}
.jobdetail .mdc-textfield {
  width:100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height:56px;
}

.jobdetail .mdc-textfield input.mdc-text-field__input {
  padding-left:12px !important;
}

.jobdetail .input-wrapper {
  padding-top:12px;
  padding-bottom:22px;
}
.jobdetail .input-wrapper.input-wrapper-top {
  padding-top:0px;
}
.jobdetail .input-wrapper .mdc-text-field-helper-text {
  margin-bottom:8px;
  color: rgba(0, 0, 0, 0.6);
}
.jobdetail .steps {
  margin: 0;
}
.jobdetail .steps .mdc-tab-bar__indicator {
  display:none;
}
.jobdetail .steps .mdc-tab {
  color:silver;
  text-align: left;
  min-width: unset;
}
.jobdetail .steps .mdc-tab.disabled {
  cursor:default;
}
.jobdetail .steps .mdc-tab--active  {
  border: 2px solid var(--mdc-theme-primary, #2D9CDB);
  color: var(--mdc-theme-primary, #2D9CDB);
  background-color:#abe0f0;
}
.jobdetail .next-button {
  margin-top:16px;
  margin-bottom:50px;
}
.jobdetail .mdc-switch-label {
  padding-left:24px;
}

.job-col-wrapper .upshift {
  margin-top:-100px;
  margin-bottom: -16px;
}

.job-col-wrapper .upshift--tablet {
  margin-top:-100px;
}

.job-col-wrapper .tablet {
  display:block;
}

.job-col-wrapper .mobile {
  display:none;
}

@media (max-width: 650px) {
  .job-col-wrapper .upshift--tablet {
    margin-top: -108px;
  }
  .job-col-wrapper .upshift {
    margin-top:0px;
  }
  .job-col-wrapper .tablet {
    display:none;
  }

  .job-col-wrapper .mobile {
    display:block;
  }
}

.job-col-wrapper {
  display:flex;
  flex-direction: row;
}

.job-col-1 {
  margin-right:16px;
  flex:5;
  margin-left:24px;
}

.job-col-2 {
  flex:2;
  margin-right:24px;
  max-width:380px
}

@media (max-width: 1060px) {
  .job-col-1 {
    margin-left:16px;
  }
  .job-col-2 {
    margin-right:16px;
  }
}

@media (min-width: 480px) and (max-width: 650px) {
  .job-col-wrapper {
    flex-direction: column;
  }
  .job-col-1 {
    order :2;
  }
  .job-col-2 {
    margin-left: 16px;
    order :1;
    max-width:100%;
  }
}

@media(max-width: 480px) {
  .job-col-wrapper {
    flex-direction: column;
  }
  .job-col-1 {
    margin-left: 0px;
    margin-right: 0px;
      order :2;
  }
  .job-col-2 {
    margin-left: 0px;
    margin-right: 0px;
    order :1;
    max-width:100%;
  }
}

@media(max-width: 570px) {
  .interest-section .section-content {
    padding-left:16px !important;
  }
}
</style>
