<template>
  <div class="mdc-layout-app--drawer-wrapper">
    <aside :class="{'mdc-drawer--modal' :!desktop}" class="mdc-drawer mdc-top-app-bar--fixed-adjust sidebar"
          :style="drawerStyle">

      <div v-if="profileReady" class="sidebar-scroll">

        <div v-if="!$route.meta.loggedOutDrawer" class="mdc-drawer-header mdc-drawer__header">
          <div class="mdc-drawer__header-content">
            <router-link v-if="profile" class="profile-card-link" @click.native="$root.$emit('toggleSidebar')" :to="{ name: 'User', params: {id: profile.id} }">
              <my-profile-card :profile="profile"></my-profile-card>
            </router-link>
          </div>
        </div>

        <div v-if="displayLinks[0]">
          <component v-if="displayLinks[0].component" :currentRoute="currentRoute" v-bind:is="displayLinks[0].component"/>

          <div v-else class="mdc-list sidebar-content">
            <div class="mdc-list-item side-menu-item"
              v-for="(link, index) in displayLinks" :key="index"
              :aria-label="link.title"
              :to="link.to"
              v-bind:class="{ 'mdc-ripple-upgraded--foreground-activation': action ===  link.action}"
              @click="linkAction(link.action, link.route, link.scrollTop)">

              <div v-if="link.icon || link.title" class="side-menu-heading">
                <span v-if="link.icon" class="mdc-list-item__graphic">
                  <mdc-icon slot="start-detail" :outlined="link.iconOutlined" :icon="link.icon"></mdc-icon>
                </span>
                <span v-if="link.svgicon" class="mdc-list-item__graphic">
                  <mdc-icon slot="start-detail">
                    <img class="active"  v-bind:src="'/static/img/icons/' + link.svgicon + '-white.svg'">
                    <img class="nonactive" v-bind:src="'/static/img/icons/' + link.svgicon + '-dark.svg'">
                  </mdc-icon>
                </span>
                <span v-if="link.title" style="font-weight:500">{{link.title}}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <span @click="$emit('showNotes')" class="note-button" v-if="team && teamMode && remote">
        <mdc-icon icon="add"/>
        <mdc-body typo="body2">Capture Notes</mdc-body>
      </span>
        <a v-if="isBranded" href="https://duome.co" target="_blank" class="link powered-by-link">
          <img class="powered-by-logo" alt="Powered By DuoMe" src="/static/img/powered-by-duome-new.svg"/>
        </a>
    </aside>
    <div v-if="!desktop" class="mdc-drawer-scrim"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MyProfileCard from './MyProfileCard.vue'
export default {
  name: 'AppSideDrawer',
  props: {
    profile: Object,
    desktop: Boolean,
    profileReady: Boolean,
    currentRoute: {},
    links: {
      type: Array,
      default: function () {
        return []
      }
    },
    drawerStyle: {
      type: Object,
      required: false
    },
    open: {
      type: Boolean
    }
  },
  computed: {
    ...mapState([
      'action',
      'isBranded',
      'remote',
      'team',
      'teamMode'
    ]),
    ...mapState({
      assignedCompany: state => state.company.assignedCompany,
      assignedSubCompany: state => state.company.assignedSubCompany
    }),
    jobsDisabled () {
      if (this.assignedSubCompany) {
        return this.assignedSubCompany.jobsDisabled
      } else if (this.assignedCompany) {
        return this.assignedCompany.jobsDisabled
      }
      return false
    },
    displayLinks () {
      if (this.profile) {
        const companyId = this.profile.companyId
        if (companyId) {
          return this.links.filter((link) => link.title !== 'Companies' && (link.filter !== 'remoteMode' || this.remote) && (link.filter !== 'jobsEnabled' || !this.jobsDisabled))
        }
      }
      return this.links.filter(link => !link.filter || link.filter !== 'remoteMode' || link.filter !== 'jobsEnabled' || this.remote)
    }
  },
  components: {
    MyProfileCard
  },
  methods: {
    ...mapActions([
      'setAction'
    ]),
    linkAction: function (action, route, scrollTop) {
      if (action) {
        this.setAction(action)
        this.$root.$emit('toggleSidebar')
      }
      if (route) {
        this.$router.push(route).catch((error) => {
          console.error('AppSideDrawer.linkAction', error)
        })
      }
      if (scrollTop) {
        document.documentElement.scrollTop = 0
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.sidebar :not(.mdc-list--non-interactive)>:not(.mdc-list-item--disabled).mdc-list-item::before {
  background-color: #000;
  top: calc(50% - 100%);
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
  transition: opacity 15ms linear,background-color 15ms linear;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.sidebar :not(.mdc-list--non-interactive)>:not(.mdc-list-item--disabled).mdc-list-item:hover::before {
  opacity: .04;
}
aside.sidebar.mdc-drawer--open {
  display:flex;
}
.powered-by-link {
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:12px !important;
  text-decoration: none !important;
}
.powered-by-logo {
  width:120px;
  bottom: 5px;
  margin-left: 10px;
}
.sidebar .mdc-drawer__header {
  padding: 0;
}
.sidebar .mdc-list-item {
  padding: 0;
  border-radius: 0 9px 9px 0;
  margin: 0 6px 0 0;
}
.sidebar .mdc-drawer__content {
  position: fixed;
  width: 240px;
}

.note-button {
  display:flex;
  padding:6px;
  align-items: center;
  color:#777777;
  border-top:1px solid silver;
  cursor:pointer;
}

.note-button:hover {
   color:#555555;
   background-color:#DDDDDD
}

.note-button .mdc-body {
  margin:0;
}
.note-button .mdc-icon {
  margin-right:16px;
}

.side-menu-heading .mdc-icon {
  display:flex;
}

.side-menu-heading .mdc-list-item__graphic .mdc-icon {
  color: #333333;
}

.side-menu-heading .mdc-list-item__graphic .active {
  display:none;
}

.mdc-ripple-upgraded--foreground-activation .side-menu-heading {
  background-color: #008AD8;
  color: #F7FFFF;
  border-radius: 0 9px 9px 0;
}
.mdc-ripple-upgraded--foreground-activation .side-menu-heading .mdc-list-item__graphic .active {
  display:block;
}
.mdc-ripple-upgraded--foreground-activation .side-menu-heading .mdc-list-item__graphic .nonactive {
  display:none;
}

.mdc-ripple-upgraded--foreground-activation .mdc-list-item__graphic {
  color: white;
}
.mdc-ripple-upgraded--foreground-activation .side-menu-heading .material-icons {
  color: #F7FFFF;
}
.sidebar-scroll {
  padding-top: 0;
  height: calc(100vh - 48px);
  overflow-y: auto;
  overflow-x: hidden;
}
.side-menu-item {
  flex-wrap: wrap;
  height: auto !important;
  color: #333333;
  margin-right: 6px;
  align-items: center;
}
.profile-card-link {
  text-decoration: none;
  color: #333;
}
.side-menu-item a {
  width:100%;
}

li.mdc-list-item .side-menu-heading {
  width: auto;
}
.side-menu-item .mdc-list{
  padding:0;
  width: 100%;
}
.side-menu-heading {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  font-Size: 18px;
  line-height: 21px;
  align-items: center;
  display:flex;
  justify-content: flex-start;
  color: #333333;
  padding-left: 6px;
  width:100%;
}
.side-menu-heading div {
    margin-right: 12px;
    overflow: hidden;
}
.sublist1 {
  padding-left:12px!important;
}
.sublist2 {
  padding-left:25px!important;
}

.side-menu-heading a.router-link-active,
.side-menu-heading a.router-link-active .mdc-icon,
.side-menu-heading a.router-link-active > *,
.side-menu-item a.router-link-active,
.side-menu-item a.router-link-active .mdc-icon,
.side-menu-item a.router-link-active > * {
  color:white;
}
.side-menu-heading a.router-link-active,
.side-menu-item a.router-link-active {
  background-color:#008AD8;
  border-radius: 0 9px 9px 0;
}

.side-menu-item a.router-link-active .mdc-list-item__graphic .active {
  display:block;
}
.side-menu-item a.router-link-active .mdc-list-item__graphic .nonactive {
  display:none;
}

.profile-card-link {
  margin-top:6px;
  width:100%;
}
.mdc-drawer .mdc-drawer__header::before {
  padding-top:0;
}
.mdc-drawer .mdc-drawer__header-content {
  padding:6px;
  flex-flow: row wrap;
  align-items: flex-start;
  position:relative;
}
.avatar {
    width:56px;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.mdc-drawer {
  background-color:#E9E9E9;
  position: fixed;
  width: 240px;
  padding-top: 54px;
}

.mdc-drawer {
  z-index: inherit;
}

.mdc-drawer.mdc-drawer--modal {
  z-index:7;
}

.mdc-layout-app .mdc-layout-app--drawer-wrapper > div,
.mdc-layout-app .mdc-layout-app--drawer-wrapper .mdc-drawer {
  height: 100%;
}
.mdc-drawer.sidebar.hidden {
  width: 0px;
  border: none;
}

.mdc-drawer.mdc-drawer--modal.sidebar {
  width: 231px;
}

.mdc-drawer.mdc-drawer--modal.sidebar .sidebar-scroll{
  width: 230px;
}

.bottom-menu-btn {
  position: fixed;
  bottom:0;
  text-align: center;
  padding: 10px 0px 10px 0px;
  z-index:-1;
  height:46px;
  align-items: center;
}

.bottom-icon {
  position:fixed;
  left:0;
  bottom:16px;
}

.bottom-icon::before {
  opacity: 0 !important;
}

.mdc-drawer-app-content {
    flex: auto;
    overflow: auto;
    min-height: 100vh;
    position: absolute;
    z-index: auto;
    right: 0px;
    width: calc(100% - 240px);
    height: auto;
    top: 0;
    max-height: 100%;
    margin: 48px 0;
    overflow-x: hidden;
}

@media all and (max-width: 840px) {
  .mdc-drawer-app-content {
    width: calc(100%);
    z-index:1;
  }
  .mdc-layout-app--drawer-wrapper {
    position: fixed;
    height: calc(100vh);
    z-index:7;
  }
}
</style>
