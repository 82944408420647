<template>
  <div class="main-content update-view" style="margin-top: 16px;">
      <div class="main-wrapper" style="margin-top: 56px;">
      <div class="content-wrapper">
      <app-section
        v-if="update"
        :editable="false"
        style="margin:16px;margin-top:16px;"
        :title="groupDisplay(update.group)"
        :footer="'Update ' + update.date">
          <div style="padding:16px;padding-bottom:32px;">
          <update-section style="margin-top:12px;" :showReplyCount="false" :updates="[update]" :profiles="teamProfiles" :team="profile.team"/>

          <div style="display:flex;align-items:center;">
            <round-button icon="rss_feed" style="border: 1px solid;width:160px !important;" hasSlot>
              <span v-if="isSubscriber(update)" @click="unsubscribe(update)">Unsubscribe</span>
              <span v-else @click="subscribe(update)">Subscribe</span>
            </round-button>
            <round-button icon="thumb_up" @click="toggleLike(update)" :iconOutline="!likes(update)"></round-button>
            <span v-for="like in update.likes" :key="'like-'+like" :title="getProfile(like).displayName" class="avatar avatar-link">
            <router-link class="update-avatar" v-if="like !== 'bot'" :to="'/user/' + like">
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </router-link>
            <span class="update-avatar" v-else>
                <img v-if="hasPhoto(getProfile(like))" :src="getPhoto(getProfile(like))"/>
                <default-avatar v-else fontSize="16px" :profile="getProfile(like)"/>
            </span>
            </span>
          </div>
          <mdc-title style="display:flex;margin-top: 0;margin-bottom: 12px;">
            <span class="update-reply-count-wrapper">
              <span class="update-reply-count">{{commentCount(update)}}</span>
            </span>
            Comments
          </mdc-title>

          <update-section v-if="update" showDateLines :updates="updateComments" :profiles="teamProfiles" :team="profile.team"/>
          </div>
          <message-bar style="width:100%;max-width:100%;" oneline relative v-on:focus="setInputFocused" :after="[]" :disabled="sendDisabled" :profile="profile" @send="addComment" ></message-bar>
      </app-section>
      <mdc-body v-else typo="body2">Update not found</mdc-body>
      </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppSection from '../components/profile-v2/AppSection.vue'
import UpdateSection from '../components/UpdateSection.vue'
import MessageBar from '../components/MessageBar.vue'
import ConnectMenu from '../components/ConnectMenu.vue'
import ConnectMenuRemote from '../components/ConnectMenuRemote.vue'

export default {
  name: 'initiative',
  components: {
    AppSection,
    UpdateSection,
    MessageBar
  },
  props: {
    isMobile: {}
  },
  data () {
    return {
      links: [
        { component: ConnectMenu }
      ],
      sendDisabled: false,
      updateId: '',
      update: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'initiatives',
      'teamProfiles',
      'updateComments',
      'remote'
    ])
  },
  methods: {
    ...mapActions([
      'setLoading',
      'saveInitiative',
      'getUpdatesByTag',
      'saveTaskList',
      'saveUpdate',
      'saveComment',
      'getUpdate',
      'loadUpdateComments'
    ]),
    commentCount (update) {
      if (update.commentCount) {
        return update.commentCount
      }
      return 0
    },
    setInputFocused () {
    },
    likes (update) {
      if (update.likes) {
        return update.likes.includes(this.profile.id)
      }
      return false
    },
    toggleLike (update) {
      if (!update.likes) {
        update.likes = []
      }
      if (update.likes.includes(this.profile.id)) {
        update.likes = update.likes.filter((prof) => prof !== this.profile.id)
      } else {
        update.likes.push(this.profile.id)
      }
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { likes: this.update.likes } })
    },
    isSubscriber (update) {
      if (update.subscribers) {
        return update.subscribers.includes(this.profile.id)
      }
      return false
    },
    subscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (!update.subscribers.includes(this.profile.id)) {
        update.subscribers.push(this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    unsubscribe (update) {
      if (!update.subscribers) {
        update.subscribers = []
      }
      if (update.subscribers.includes(this.profile.id)) {
        update.subscribers = update.subscribers.filter((prof) => prof !== this.profile.id)
        this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { subscribers: this.update.subscribers } })
      }
    },
    groupDisplay (group) {
      if (group === 'lookingForward') {
        return 'Looking Forward'
      }
      if (group === 'roundTable') {
        return 'Round Table'
      }
      return group
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/icons-192.png'
        }
      }
      let prof = {}
      if (this.teamProfiles) {
        const profileMap = new Map(this.teamProfiles.map(i => [i.id, i]))
        prof = profileMap.get(profileId)
        if (!prof) {
          prof = {}
        }
      }
      return prof
    },
    hasPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return true
        }
      }
      return false
    },
    getPhoto (profile) {
      if (profile) {
        if (profile.photoURL) {
          return profile.photoURL
        }
      }
      return '/static/img/user.jpg'
    },
    addComment (message) {
      console.log('DashTeam.addComment', message)
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date()
      }
      this.saveComment({ teamId: this.profile.team, updateId: this.update.id, commentId: comment.id, comment })
      this.update.commentCount = this.updateComments.length + 1
      this.saveUpdate({ teamId: this.profile.team, updateId: this.update.id, update: { commentCount: this.update.commentCount } })
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
  },
  async beforeRouteUpdate (to, from, next) {
    this.$root.$emit('enableSearch', true, { back: true })
    this.setLoading(false)
    next()
  },
  async mounted () {
    if (this.remote) {
      this.links = [{ component: ConnectMenuRemote }]
    } else {
      this.links = [{ component: ConnectMenu }]
    }
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Connect')
    this.$root.$emit('enableSearch', true, { back: true })
    this.setLoading(true)
    this.updateId = (this.$route) ? this.$route.params.id : ''
    console.log('Update.mounted', this.profile.team, this.updateId)
    this.getUpdate({ teamId: this.profile.team, updateId: this.updateId }).then((update) => {
      this.update = update
      console.log('Update.mounted', this.profile.team, this.updateId, this.update)
      this.loadUpdateComments({ teamId: this.profile.team, updateId: this.updateId })
    })
    this.setLoading(false)
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .update-view .content-wrapper {
    padding:12px;
    padding-top:6px;
  }
  .avatar {
    width: 31px;
    height: 31px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    overflow: hidden;
    position: relative;
  }
  .avatar img {
      height: 100%;
      width: 100%;
      object-fit: cover;
  }
  @media (max-width:840px) {
    .update-view .content-wrapper {
      padding-left:0px;
      padding-right:0px;
    }
  }
  @media (max-width:481px) {
    .update-view .content-wrapper {
      margin-left:-16px;
      margin-right:-16px;
    }
  }
</style>
<style>
.update-view .bottom-bar {
  padding: 0 !important;
    margin-top: 6px;
}
</style>
