<template>
  <div class="">
      <mdc-layout-grid>
        <mdc-layout-cell>
          <div id="remoteTrack"></div>
        </mdc-layout-cell>
        <mdc-layout-cell>
          <div id="localTrack"></div>
        </mdc-layout-cell>
      </mdc-layout-grid>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import fb from '../store/firebase'

export default {
  name: 'Twilio',
  data () {
    return {
      title: 'Twilio',
      links: [],
      mytrack: null,
      localTrack: false,
      room: null
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'setPageLoad'
    ]),
    closeConnection () {
      console.log('disconnecting')
      if (this.room) {
        this.room.disconnect()
        this.room = null
      }
      if (this.mytrack) {
        this.mytrack.detach().forEach(element => element.remove())
        this.mytrack.stop()
        this.mytrack = null
      }
      this.localTrack = false
      document.getElementById('localTrack').remove()
    }
  },
  async mounted () {
    const connectionId = this.$route.params.id

    const videoToken = fb.functions.httpsCallable('videoToken')
    const res = await videoToken()
    const token = res.data.token

    const Video = require('twilio-video')

    Video.connect(token, { name: connectionId }).then(room => {
      console.log('Connected to Room "%s"', room.name)

      room.participants.forEach(participantConnected)

      room.on('participantConnected', participantConnected)

      room.on('participantDisconnected', participantDisconnected)
      // eslint-disable-next-line node/handle-callback-err
      room.once('disconnected', error => room.participants.forEach(participantDisconnected))

      this.room = room
    })

    function participantConnected (participant) {
      console.log('Participant "%s" connected', participant.identity)

      const div = document.createElement('div')
      div.id = participant.sid
      // div.innerText = participant.identity

      participant.on('trackSubscribed', track => trackSubscribed(div, track))
      participant.on('trackUnsubscribed', trackUnsubscribed)

      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          trackSubscribed(div, publication.track)
        }
      })

      const remoteMediaContainer = document.getElementById('remoteTrack')
      remoteMediaContainer.appendChild(div)
    }

    function participantDisconnected (participant) {
      console.log('Participant "%s" disconnected', participant.identity)
      document.getElementById(participant.sid).remove()
    }

    function trackSubscribed (div, track) {
      div.appendChild(track.attach())
    }

    function trackUnsubscribed (track) {
      track.detach().forEach(element => element.remove())
    }

    if (!this.localTrack) {
      Video.createLocalVideoTrack().then(track => {
        const localMediaContainer = document.getElementById('localTrack')
        localMediaContainer.appendChild(track.attach())
        this.mytrack = track
        this.localTrack = true
      })
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  }
}
</script>

<style>
video {
  background-color: #ddd;
  border-radius: 7px;
  margin: 10px 0px 0px 10px;
}
#localTrack video {
  width: 250px;
}
#remoteTrack video {
  width: 400px;
}
</style>
